import React from 'react';

export default function AlertIcon(props: SVGProps): JSX.Element {
  const { title = 'Alert', ...restProps } = props;

  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M32 58.667C34.9467 58.667 37.3334 56.2803 37.3334 53.3337H26.6667C26.6667 56.2803 29.0534 58.667 32 58.667ZM48 42.667V29.3337C48 21.1337 43.64 14.2937 36 12.4803V10.667C36 8.45366 34.2134 6.66699 32 6.66699C29.7867 6.66699 28 8.45366 28 10.667V12.4803C20.36 14.2937 16 21.1337 16 29.3337V42.667L10.6667 48.0003V50.667H53.3334V48.0003L48 42.667ZM42.6667 45.3337H21.3334V29.3337C21.3334 22.707 25.3734 17.3337 32 17.3337C38.6267 17.3337 42.6667 22.707 42.6667 29.3337V45.3337Z" />
    </svg>
  );
}
