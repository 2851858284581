import React from 'react';

export default function WorkIcon(props: SVGProps): JSX.Element {
  const { title = 'Work Icon', ...restProps } = props;

  return (
    <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9801 4V2H8.27148V4H11.9801ZM2.7086 6V17H17.5429V6H2.7086ZM17.5429 4C18.5721 4 19.3972 4.89 19.3972 6V17C19.3972 18.11 18.5721 19 17.5429 19H2.7086C1.67947 19 0.854309 18.11 0.854309 17L0.863581 6C0.863581 4.89 1.67947 4 2.7086 4H6.41719V2C6.41719 0.89 7.24235 0 8.27148 0H11.9801C13.0092 0 13.8344 0.89 13.8344 2V4H17.5429Z"
      />
    </svg>
  );
}
