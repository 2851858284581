import React from 'react';

export default function EyeIcon(props: SVGProps): JSX.Element {
  const { title = 'Eye', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17.005 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.345 9 9 10.345 9 12C9 13.655 10.345 15 12 15C13.655 15 15 13.655 15 12C15 10.345 13.655 9 12 9Z" />
    </svg>
  );
}
