import { cva } from 'class-variance-authority';

export const textAreaVariants = cva(
  'flex items-center justify-center [&>svg]:fill-current',
  {
    variants: {
      size: {
        tiny: [
          'gap-spacing-comp-button-tiny-text-area',
          'px-spacing-comp-button-tiny-text-area',
          '[&>svg]:w-sizing-comp-button-tiny-icon',
          '[&>svg]:h-sizing-comp-button-tiny-icon',
        ],
        small: [
          'gap-spacing-comp-button-small-text-area',
          'px-spacing-comp-button-small-text-area',
          '[&>svg]:w-sizing-comp-button-small-icon',
          '[&>svg]:h-sizing-comp-button-small-icon',
        ],
        medium: [
          'gap-spacing-comp-button-medium-text-area',
          'px-spacing-comp-button-medium-text-area',
          '[&>svg]:w-sizing-comp-button-medium-icon',
          '[&>svg]:h-sizing-comp-button-medium-icon',
        ],
      },
      loading: { true: 'invisible', false: '' },
    },
  },
);
