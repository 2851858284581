import React from 'react';

export default function QRCodeIcon(props: SVGProps): JSX.Element {
  const { title = 'QRCode', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M2.75 10.0833H4.58333V11.9167H2.75V10.0833ZM10.0833 4.58333H11.9167V8.25H10.0833V4.58333ZM8.25 10.0833H11.9167V13.75H10.0833V11.9167H8.25V10.0833ZM13.75 10.0833H15.5833V11.9167H17.4167V10.0833H19.25V11.9167H17.4167V13.75H19.25V17.4167H17.4167V19.25H15.5833V17.4167H11.9167V19.25H10.0833V15.5833H13.75V13.75H15.5833V11.9167H13.75V10.0833ZM17.4167 17.4167V13.75H15.5833V17.4167H17.4167ZM13.75 2.75H19.25V8.25H13.75V2.75ZM15.5833 4.58333V6.41667H17.4167V4.58333H15.5833ZM2.75 2.75H8.25V8.25H2.75V2.75ZM4.58333 4.58333V6.41667H6.41667V4.58333H4.58333ZM2.75 13.75H8.25V19.25H2.75V13.75ZM4.58333 15.5833V17.4167H6.41667V15.5833H4.58333Z" />
    </svg>
  );
}
