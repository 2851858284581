import {
  FloatingPortal,
  autoUpdate,
  offset,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import React, { useState } from 'react';
import { components } from 'react-select-v5';
// Types
import type { GroupBase } from 'react-select-v5';

// Styles
import css from './index.css';

import type { OptionProps } from '../../types';

import Text from '../../../Text';

const Option = <
  Option extends {
    hoverContent?: React.ReactNode;
    [key: string]: any;
  },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  { data, ...props }: OptionProps<Option, IsMulti, Group>,
) => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: 4, crossAxis: 4 })],
  });

  const { styles } = useTransitionStyles(context, {
    duration: 350,
    initial: {
      opacity: 0,
    },
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      {data.hoverContent ? (
        <>
          <div ref={refs.setReference} {...getReferenceProps()}>
            <components.Option data={data} {...props} />
          </div>
          <FloatingPortal>
            {isOpen && (
              <div
                className={css.tooltip}
                ref={refs.setFloating}
                style={{
                  zIndex: 10000,
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  ...styles,
                }}
                {...getFloatingProps()}
              >
                <Text size="small" className={css.tooltipText}>
                  {data.hoverContent}
                </Text>
              </div>
            )}
          </FloatingPortal>
        </>
      ) : (
        <components.Option data={data} {...props} />
      )}
    </>
  );
};

export default Option;
