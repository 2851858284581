import React from 'react';

export default function MembershipsIcon(props: SVGProps): JSX.Element {
  const { title = 'Memberships', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 0.5C1.67525 0.5 0.5 1.67525 0.5 3.125V18.875C0.5 20.3247 1.67525 21.5 3.125 21.5H18.875C20.3247 21.5 21.5 20.3247 21.5 18.875V3.125C21.5 1.67525 20.3247 0.5 18.875 0.5H3.125ZM2.25 3.125C2.25 2.64175 2.64175 2.25 3.125 2.25H9.25V10.9294C9.25 12.3968 10.9474 13.2126 12.0932 12.2959L13.6306 11.066L15.1705 12.2729C16.3191 13.173 18 12.3547 18 10.8954V2.25H18.875C19.3582 2.25 19.75 2.64175 19.75 3.125V18.875C19.75 19.3582 19.3582 19.75 18.875 19.75H3.125C2.64175 19.75 2.25 19.3582 2.25 18.875V3.125ZM16.25 2.25H11V10.9294L12.5374 9.69951C13.1714 9.1923 14.071 9.18778 14.7101 9.68861L16.25 10.8954V2.25Z"
      />
    </svg>
  );
}
