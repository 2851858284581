import React from 'react';

import styles from '../index.css';

type Props = {
  noDataMessage?: string | React.ReactNode;
  showNoDataMessage: boolean;
};

const NoData = (
  { noDataMessage = 'No results available', showNoDataMessage }: Props,
): JSX.Element | null => {
  if (!showNoDataMessage) {
    return null;
  }

  return (
    <div data-testid="table-no-data" className={styles.noData}>
      {noDataMessage}
    </div>
  );
};

export default NoData;
