import React from 'react';
// Utils
import { v4 as uuidv4 } from 'uuid';

// Components
import SnackbarContainer from './_components/SnackbarContainer';

// Types
import {
  AddSnackbarSignature,
  CloseType,
  RemoveSnackbarSignature,
  SnackbarConfig,
  SnackbarConfigWithOptionals,
  UpdateSnackbarSignature,
} from './_types';

// Constants
import { DEFAULT_DISMISS_DURATION } from './_constants';

// Local Types
export type ContextProps = {
  addSnackbar: AddSnackbarSignature;
  removeSnackbar: RemoveSnackbarSignature;
  updateSnackbar: UpdateSnackbarSignature;
};

// set an empty object as default state
export const SnackbarContext = React.createContext<ContextProps>(
  {} as ContextProps,
);

type SnackbarProviderProps = {
  children?: React.ReactNode;
};

export default function SnackbarProvider(
  { children }: SnackbarProviderProps,
): React.ReactElement {
  const [snackbars, setSnackbars] = React.useState<Array<SnackbarConfig>>([]);

  const removeSnackbar = React.useCallback(
    (snackbarId: string, type: CloseType) =>
      setSnackbars((currentSnackbars) => {
        const removedConfig = currentSnackbars.find(
          ({ id }) => id === snackbarId,
        );
        if (removedConfig && removedConfig.onClose) {
          removedConfig.onClose(type);
        }

        return currentSnackbars.filter(({ id }) => id !== snackbarId);
      }),
    [],
  );

  const autodismiss = React.useCallback(
    (config: SnackbarConfig) => {
      // If there is no action to be taken autodismiss snackbar
      if (config.actionProps || config.duration === 0) {
        return;
      }

      const timer = setTimeout(() => {
        removeSnackbar(config.id, 'autodismiss');
        clearTimeout(timer);
      }, config.duration);
    },
    [removeSnackbar],
  );

  const updateSnackbar = React.useCallback(
    (snackbarId: string, updatedConfig: any) => {
      setSnackbars((currentSnackbars) =>
        currentSnackbars.map((currentSnackbar) => {
          if (currentSnackbar.id !== snackbarId) {
            return currentSnackbar;
          }

          const newConfig = {
            ...currentSnackbar,
            ...updatedConfig,
          };

          autodismiss(newConfig);

          return newConfig;
        }),
      );
    },
    [],
  );

  const addSnackbar = React.useCallback(
    (newSnackbar: SnackbarConfigWithOptionals) => {
      const newConfig: SnackbarConfig = {
        ...newSnackbar,
        duration: newSnackbar.duration ?? DEFAULT_DISMISS_DURATION,
        id: uuidv4(),
      };

      setSnackbars((prevSnackbars) => [...prevSnackbars, newConfig]);
      autodismiss(newConfig);

      return newConfig.id;
    },
    [],
  );

  const removeSnackbarForProvider = React.useCallback(
    (configId: string): void => removeSnackbar(configId, 'programatic'),
    [removeSnackbar],
  );

  const providerValue = React.useMemo(
    () => ({
      addSnackbar,
      removeSnackbar: removeSnackbarForProvider,
      updateSnackbar,
    }),
    [addSnackbar, removeSnackbar, updateSnackbar],
  );

  return (
    <SnackbarContext.Provider value={providerValue}>
      {children}
      <SnackbarContainer
        snackbars={snackbars}
        removeSnackbar={removeSnackbar}
      />
    </SnackbarContext.Provider>
  );
}
