import React from 'react';

export default function CopyIcon(props: SVGProps): JSX.Element {
  const { title = 'Copy', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 16" {...restProps}>
      <title>{title}</title>
      <path d="M9.66666 0.666504H1.66666C0.929995 0.666504 0.333328 1.26317 0.333328 1.99984V11.3332H1.66666V1.99984H9.66666V0.666504ZM11.6667 3.33317H4.33333C3.59666 3.33317 2.99999 3.92984 2.99999 4.6665V13.9998C2.99999 14.7365 3.59666 15.3332 4.33333 15.3332H11.6667C12.4033 15.3332 13 14.7365 13 13.9998V4.6665C13 3.92984 12.4033 3.33317 11.6667 3.33317ZM11.6667 13.9998H4.33333V4.6665H11.6667V13.9998Z" />
    </svg>
  );
}
