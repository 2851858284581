import React from 'react';

export default function RestoreIcon(props: SVGProps): JSX.Element {
  const { title = 'Restore', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18" {...restProps}>
      <title>{title}</title>
      <path d="M11.995 0C7.02 0 3 4.03 3 9H0L3.895 12.895L3.965 13.04L8 9H5C5 5.135 8.135 2 12 2C15.865 2 19 5.135 19 9C19 12.865 15.865 16 12 16C10.065 16 8.32 15.21 7.055 13.945L5.64 15.36C7.265 16.99 9.51 18 11.995 18C16.97 18 21 13.97 21 9C21 4.03 16.97 0 11.995 0ZM11 5V10L15.28 12.54L16 11.325L12.5 9.25V5H11Z" />
    </svg>
  );
}
