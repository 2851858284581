import React from 'react';

interface SettlementsIconProps extends SVGProps {
  className?: string;
  stroke?: string;
}

export default function SettlementsIcon(
  props: SettlementsIconProps,
): JSX.Element {
  const { title = 'SettlementsIcon', stroke = 'black', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 136"
      fill="none"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_326_121027)">
        <path
          d="M74.3236 100.584H16.339C8.06234 100.584 1.35303 93.8708 1.35303 85.5893C1.35303 77.3077 8.06234 70.5944 16.339 70.5944H35.2552C43.5319 70.5944 50.2412 63.8811 50.2412 55.5996"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M60.965 67.3281H111.008C112.292 67.3281 113.334 68.3716 113.334 69.6564V116.882H58.6382V69.6564C58.6382 68.3716 59.6811 67.3281 60.965 67.3281Z"
          fill="#3882FA"
        />
        <path
          d="M68.1177 90.6917L75.4841 82.5278L81.8226 87.507L88.6766 80.1362L93.4057 82.3287L100.67 76.4629"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M101.809 77.8775C102.59 77.2472 102.71 76.1072 102.08 75.3261C101.45 74.5449 100.311 74.4243 99.5303 75.0546C98.7526 75.6849 98.6291 76.8249 99.259 77.606C99.8889 78.3871 101.028 78.5078 101.809 77.8775Z"
          fill="black"
        />
        <path
          d="M95.3676 65.5034H76.4814C75.4127 65.5034 74.5464 66.3703 74.5464 67.4396V67.6809C74.5464 68.7502 75.4127 69.617 76.4814 69.617H95.3676C96.4362 69.617 97.3026 68.7502 97.3026 67.6809V67.4396C97.3026 66.3703 96.4362 65.5034 95.3676 65.5034Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M82.2897 99.2842H78.5342V109.918H82.2897V99.2842Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M90.2531 96.7388H86.4976V109.921H90.2531V96.7388Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M74.3234 103.081H70.5679V109.921H74.3234V103.081Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M68.0093 109.909H97.6134"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M32.591 113.694C40.165 113.694 46.305 107.55 46.305 99.9717C46.305 92.3931 40.165 86.2495 32.591 86.2495C25.0169 86.2495 18.877 92.3931 18.877 99.9717C18.877 107.55 25.0169 113.694 32.591 113.694Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M32.5907 103.44C34.4567 103.44 35.9694 101.927 35.9694 100.059C35.9694 98.1923 34.4567 96.6787 32.5907 96.6787C30.7246 96.6787 29.2119 98.1923 29.2119 100.059C29.2119 101.927 30.7246 103.44 32.5907 103.44Z"
          fill="#EEEEEE"
          stroke="#EEEEEE"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M32.5907 107.816C29.3235 106.531 26.0532 104.031 26.0532 101.042V94.9804C29.7696 94.0938 32.5907 92.1274 32.5907 92.1274C32.5907 92.1274 35.1587 94.142 39.1282 94.9804V101.042C39.1282 104.031 35.861 106.534 32.5907 107.816Z"
          stroke="#EEEEEE"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M30.9453 100.098L32.1208 101.274L34.2367 98.6748"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M109.227 106.387V135.375C109.227 135.662 108.994 135.897 108.705 135.897H104.814C104.528 135.897 104.292 135.665 104.292 135.375V106.387"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M109.227 128.556V135.77C109.227 135.842 108.994 135.9 108.705 135.9H104.814C104.528 135.9 104.292 135.842 104.292 135.77V128.556"
          fill="black"
        />
        <path
          d="M109.227 128.556V135.77C109.227 135.842 108.994 135.9 108.705 135.9H104.814C104.528 135.9 104.292 135.842 104.292 135.77V128.556"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M106.761 106.613C113.957 106.613 119.791 100.776 119.791 93.5751C119.791 86.3747 113.957 80.5376 106.761 80.5376C99.5646 80.5376 93.731 86.3747 93.731 93.5751C93.731 100.776 99.5646 106.613 106.761 106.613Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M115.483 22.2109H133.104C141.688 22.2109 148.647 29.1745 148.647 37.7637C148.647 46.3529 141.688 53.3165 133.104 53.3165H122.268C113.684 53.3165 106.725 60.2801 106.725 68.8692V80.5346"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M87.0005 40.7734H114.766V2.6289H87.0005V40.7734Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M107.132 4.38428L94.6325 4.38428V3.45238C94.6325 2.15556 95.6844 1.10303 96.9805 1.10303L104.781 1.10303C106.077 1.10303 107.129 2.15556 107.129 3.45238V4.38428H107.132Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M90.7983 28.6074H102.086"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M90.7983 32.6548H109.525"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M90.7983 36.7021H109.525"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M91.793 20.9355L94.5539 16.8521L97.3117 20.9355H91.793Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M119.308 18.4834L114.739 25.2449L110.173 18.4834H119.308Z"
          fill="#3882FA"
          stroke="#3882FA"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M114.01 93.4638L106.761 88.8164L99.5093 93.4638L106.761 98.1083L114.01 93.4638Z"
          fill="black"
        />
        <path
          d="M70.7157 54.3213H29.7998V56.2846H70.7157V54.3213Z"
          fill="black"
        />
        <path d="M67.2496 53.3049V28.9609H64.4917V53.3049" fill="black" />
        <path
          d="M67.2496 53.3049V28.9609H64.4917V53.3049"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M68.1118 52.6084H63.6299V54.1887H68.1118V52.6084Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M56.8419 53.3049V28.9609H54.0811V53.3049" fill="black" />
        <path
          d="M56.8419 53.3049V28.9609H54.0811V53.3049"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M57.7041 52.6084H53.2222V54.1887H57.7041V52.6084Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M46.4313 53.3049V28.9609H43.6704V53.3049" fill="black" />
        <path
          d="M46.4313 53.3049V28.9609H43.6704V53.3049"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M47.2934 52.6084H42.8115V54.1887H47.2934V52.6084Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M36.0241 53.3049V28.9609H33.2632V53.3049" fill="black" />
        <path
          d="M36.0241 53.3049V28.9609H33.2632V53.3049"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M36.8828 52.6084H32.4009V54.1887H36.8828V52.6084Z"
          fill="black"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M50.2473 17.1206L68.8832 28.9609H31.7349L50.2473 17.1206Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M61.4445 35.876H59.8862V39.5794H61.4445V35.876Z"
          fill="black"
        />
        <path
          d="M61.4445 43.9736H59.8862V47.6771H61.4445V43.9736Z"
          fill="black"
        />
        <path
          d="M40.6261 35.876H39.0679V39.5794H40.6261V35.876Z"
          fill="black"
        />
        <path
          d="M40.6261 43.9736H39.0679V47.6771H40.6261V43.9736Z"
          fill="black"
        />
        <path
          d="M51.0339 35.876H49.4756V39.5794H51.0339V35.876Z"
          fill="black"
        />
        <path
          d="M51.0339 43.9736H49.4756V47.6771H51.0339V43.9736Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_326_121027">
          <rect
            width="148.5"
            height="136"
            fill="white"
            transform="translate(0.75 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
