// Utils
import { noop } from 'lodash';
import { createContext, useContext } from 'react';

// Props
import { ButtonGroupContextProps } from './_types';

export const ButtonGroupContext = createContext<ButtonGroupContextProps<any>>({
  selectedButton: '',
  setSelectedButton: noop,
  type: 'secondary',
});

export default () => useContext(ButtonGroupContext);
