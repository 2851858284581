import React from 'react';

export default function DirectoryIcon(props: SVGProps): JSX.Element {
  const { title = 'Directory', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11" {...restProps}>
      <title>{title}</title>
      <path d="M4.99997 3H1.99997V2H4.99997V3Z" />
      <path d="M4.99997 5H1.99997V4H4.99997V5Z" />
      <path d="M8.99997 3H12V2H8.99997V3Z" />
      <path d="M8.99997 5H12V4H8.99997V5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99997 0.835668C6.58749 0.325917 5.95679 -1.02733e-07 5.24997 3.27836e-07L1.49997 0C0.671543 -7.24234e-08 -2.96146e-05 0.671573 -2.96871e-05 1.5L-3.05176e-05 8.5C-3.05176e-05 9.32843 0.671544 10 1.49997 10H5.49997C6.05226 10 6.49997 10.4477 6.49997 11H7.49997C7.49997 10.4477 7.94769 10 8.49998 10H12.5C13.3284 10 14 9.32843 14 8.5L14 1.5C14 0.671573 13.3284 -7.24234e-08 12.5 0L8.74997 3.27836e-07C8.04316 -1.02733e-07 7.41246 0.325917 6.99997 0.835668ZM6.49997 2.25L6.49997 9.26756C6.2058 9.09739 5.86426 9 5.49997 9H1.49997C1.22383 9 0.99997 8.77614 0.99997 8.5L0.99997 1.5C0.99997 1.22386 1.22383 1 1.49997 1L5.24997 1C5.94033 1 6.49997 1.55964 6.49997 2.25ZM7.49998 9.26756C7.79415 9.09739 8.13569 9 8.49998 9H12.5C12.7761 9 13 8.77614 13 8.5L13 1.5C13 1.22386 12.7761 1 12.5 1L8.74997 1C8.05962 1 7.49998 1.55964 7.49998 2.25L7.49998 9.26756Z"
      />
    </svg>
  );
}
