// @Generated

// AUTO-GENERATED FROM tailwind.config.js and scripts/generate-style-vars.js
// DO NOT MODIFY

const opacity = {
  10: '0.1',
  30: '0.3',
  40: '0.4',
  '05': '0.05',
};

export default opacity;
