import React from 'react';

export default function RefreshIcon(props: SVGProps): JSX.Element {
  const { title = 'Refresh', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M16.1791 5.82085C14.8545 4.49169 13.0258 3.66669 10.9999 3.66669C6.94828 3.66669 3.67578 6.94835 3.67578 11C3.67578 15.0517 6.94828 18.3334 10.9999 18.3334C14.4145 18.3334 17.2745 15.9959 18.0858 12.8334H16.1791C15.4229 14.9692 13.397 16.5 10.9999 16.5C7.9612 16.5 5.49995 14.0388 5.49995 11C5.49995 7.96127 7.9612 5.50002 10.9999 5.50002C12.517 5.50002 13.8783 6.13252 14.8729 7.1271L11.9166 10.0834H18.3333V3.66669L16.1791 5.82085Z" />
    </svg>
  );
}
