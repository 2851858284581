import React from 'react';

export default function HourglassIcon(props: SVGProps): JSX.Element {
  const { title = 'Hourglass', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 246"
      fill="none"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_3125_116719)">
        <path
          d="M86.2794 21.3848H297.729C301.819 21.3848 305.149 24.7048 305.149 28.8048V224.615H78.8594V28.8048C78.8594 24.7048 82.1794 21.3848 86.2794 21.3848Z"
          stroke="#5681F6"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M259.139 41.2747C261.271 41.2747 262.999 39.5465 262.999 37.4147C262.999 35.2829 261.271 33.5547 259.139 33.5547C257.007 33.5547 255.279 35.2829 255.279 37.4147C255.279 39.5465 257.007 41.2747 259.139 41.2747Z"
          fill="white"
        />
        <path
          d="M272.559 41.2747C274.691 41.2747 276.419 39.5465 276.419 37.4147C276.419 35.2829 274.691 33.5547 272.559 33.5547C270.427 33.5547 268.699 35.2829 268.699 37.4147C268.699 39.5465 270.427 41.2747 272.559 41.2747Z"
          fill="white"
        />
        <path
          d="M285.979 41.2747C288.111 41.2747 289.839 39.5465 289.839 37.4147C289.839 35.2829 288.111 33.5547 285.979 33.5547C283.847 33.5547 282.119 35.2829 282.119 37.4147C282.119 39.5465 283.847 41.2747 285.979 41.2747Z"
          fill="white"
        />
        <path
          d="M174.61 57.8252C174.61 57.8252 153.57 89.5852 184.47 116.155C184.47 116.155 190.95 121.695 189.38 126.105C188.22 131.115 179.32 130.785 179.32 130.785C138.6 132.145 135.1 170.075 135.1 170.075L206.36 195.155C206.36 195.155 227.4 163.395 196.5 136.825C196.5 136.825 189.36 131.515 191.59 126.875C193.13 122.465 201.65 122.195 201.65 122.195C242.37 120.835 245.87 82.9052 245.87 82.9052"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M213.049 196.724L128.975 167.133L127.003 172.736L211.077 202.327L213.049 196.724Z"
          fill="#5681F6"
        />
        <path
          d="M254.037 79.9649L169.963 50.374L167.991 55.9771L252.065 85.568L254.037 79.9649Z"
          fill="#5681F6"
        />
        <mask
          id="mask0_3125_116719"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="135"
          y="57"
          width="111"
          height="139"
        >
          <path
            d="M206.36 195.155C206.36 195.155 227.4 163.395 196.5 136.825C196.5 136.825 189.36 131.515 191.59 126.875C193.13 122.465 201.65 122.195 201.65 122.195C242.37 120.835 245.87 82.9052 245.87 82.9052L174.61 57.8252C174.61 57.8252 153.57 89.5852 184.47 116.155C184.47 116.155 190.95 121.695 189.38 126.105C188.22 131.115 179.32 130.785 179.32 130.785C138.6 132.145 135.1 170.075 135.1 170.075L206.36 195.155Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3125_116719)">
          <path
            d="M215.539 178.105C213.289 173.895 202.129 170.765 196.689 170.895C191.249 171.025 183.059 168.855 181.899 162.925C180.739 156.995 193.199 126.685 193.199 126.685L226.249 118.135C191.419 120.785 169.999 99.5947 169.999 99.5947L188.409 125.005C188.409 125.005 182.119 147.615 179.199 156.515C176.279 165.415 167.819 167.395 153.469 163.425C139.119 159.455 132.299 162.775 132.299 162.775V172.425L207.009 199.375L215.539 178.115V178.105Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3125_116719">
          <rect
            width="228.78"
            height="205.73"
            fill="white"
            transform="translate(77.6094 20.1348)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
