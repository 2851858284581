import classnames from 'classnames';
import React from 'react';
import { MetaBase, Row, TableBodyProps } from 'react-table';

import styles from '../index.css';

type GetTableBodyProps = (
  propGetter?:
    | Partial<TableBodyProps>
    | Partial<TableBodyProps>[]
    | ((
        props: Partial<TableBodyProps>,
        meta: MetaBase<object>,
      ) => Partial<any> | Partial<any>[])
    | undefined,
) => TableBodyProps;

type Props = {
  getTableBodyProps: GetTableBodyProps;
  isLoading?: boolean;
  page: any;
  prepareRow: (row: Row<object>) => void;
  // All other props
  [x: string]: any;
};

const TableBody = ({
  getTableBodyProps,
  isLoading = false,
  page,
  prepareRow,
  ...restProps
}: Props): JSX.Element | null => {
  if (isLoading || page.length === 0) {
    return null;
  }

  return (
    <tbody data-testid="table-body" {...getTableBodyProps()} {...restProps}>
      {page.map((row: any, rIndex: number) => {
        prepareRow(row);

        const isRowClickable = !!row.original.onRowClick;

        return (
          <tr
            key={rIndex}
            className={classnames({
              [styles.borderBottom]: true,
              [styles.tableBodyClickable]: isRowClickable,
            })}
            data-testid={`table-body-tr-${rIndex}`}
            {...row.getRowProps()}
            role={isRowClickable ? 'button' : 'row'}
            onClick={row.original.onRowClick || null}
          >
            {row.cells.map((cell: any, cIndex: number) => {
              const cellValue = cell.value;

              return (
                <td
                  key={cIndex}
                  className={styles.tableBodyText}
                  data-testid={`table-body-tr-${rIndex}-td-${cIndex}`}
                  style={cellValue.styles || null}
                  {...cell.getCellProps()}
                >
                  {cellValue.value || cellValue}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
