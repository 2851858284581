import React from 'react';

function GridViewIcon(props: SVGProps): JSX.Element {
  const { title = 'GridViewIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>

      <path d="M13.3333 1.33337H2.66665C1.92998 1.33337 1.33331 1.93004 1.33331 2.66671V13.3334C1.33331 14.07 1.92998 14.6667 2.66665 14.6667H13.3333C14.07 14.6667 14.6666 14.07 14.6666 13.3334V2.66671C14.6666 1.93004 14.07 1.33337 13.3333 1.33337ZM5.33331 13.3334H2.66665V10.6667H5.33331V13.3334ZM5.33331 9.33337H2.66665V6.66671H5.33331V9.33337ZM5.33331 5.33337H2.66665V2.66671H5.33331V5.33337ZM9.33331 13.3334H6.66665V10.6667H9.33331V13.3334ZM9.33331 9.33337H6.66665V6.66671H9.33331V9.33337ZM9.33331 5.33337H6.66665V2.66671H9.33331V5.33337ZM13.3333 13.3334H10.6666V10.6667H13.3333V13.3334ZM13.3333 9.33337H10.6666V6.66671H13.3333V9.33337ZM13.3333 5.33337H10.6666V2.66671H13.3333V5.33337Z" />
    </svg>
  );
};

export default GridViewIcon;
