import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../index.css';

// Utils
import useInputContext from '../useInputContext';

const InputWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className = '',
  children,
  ...restProps
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    const wrapperElement$ = ref.current;
    const inputElement$ = wrapperElement$.getElementsByTagName('input')[0];

    function handleClick(): void {
      if (!inputElement$) {
        return;
      }
      inputElement$.focus();
    }

    wrapperElement$.addEventListener('click', handleClick);

    return (): void => {
      wrapperElement$.removeEventListener('click', handleClick);
    };
  }, [ref]);

  const { error, disabled } = useInputContext();

  const wrapperClasses = cn({
    [styles.wrapper]: true,
    [styles.errorBorder]: error,
    [styles.disabled]: disabled,
    [className]: className,
  });

  return (
    <div className={wrapperClasses} ref={ref} {...restProps}>
      {children}
    </div>
  );
};

export default InputWrapper;
