import React from 'react';

export default function BracketsIcon(props: SVGProps): JSX.Element {
  const { title = 'BracketsIcon', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M7.83341 13.8333L4.04175 10L7.87508 6.16667L6.66675 5L1.66675 10L6.66675 15L7.83341 13.8333ZM12.1667 13.8333L16.0001 10L12.1667 6.16667L13.3334 5L18.3334 10L13.3334 15L12.1667 13.8333V13.8333Z" />
    </svg>
  );
}
