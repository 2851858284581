import React from 'react';

export default function CloudDoneIcon(props: SVGProps): JSX.Element {
  const { title = 'CloudDone', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path
        d="M12.9033 6.69002C12.45 4.39669 10.4267 2.66669 8 2.66669C6.07333 2.66669 4.40333 3.76002 3.56667 5.35669C1.56333 5.57335 0 7.27002 0 9.33335C0 11.5434 1.79 13.3334 4 13.3334H12.6667C14.5067 13.3334 16 11.84 16 10C16 8.24002 14.63 6.81335 12.9033 6.69002ZM6.66667 11.3334L4.33333 9.00002L5.27667 8.05669L6.66667 9.44669L10.1167 5.99669L11.06 6.94002L6.66667 11.3334Z"
        fill="#A2A4A9"
      />
    </svg>
  );
}
