import React from 'react';

function DAppIcon(props: SVGProps): JSX.Element {
  const { title = 'DAppIcon', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <rect width="48" height="48" rx="8" fill="#CDCDCD" />
      <rect width="48" height="24" transform="translate(0 12)" fill="#808080" />
      <path
        d="M8.74362 30.8199L9.80091 27.9079H14.9256L16.0454 30.8199H18.8594L13.7268 18.0001H10.9911L6 30.8199H8.74362ZM12.3314 20.9911L14.096 25.7466H10.5984L12.3314 20.9911Z"
        fill="#CDCDCD"
      />
      <path
        d="M22.8245 25.9859H24.5108C25.6819 25.9859 26.5779 25.9227 27.1952 25.801C27.6512 25.703 28.0992 25.4984 28.5355 25.1918C28.9756 24.8852 29.3375 24.4648 29.6243 23.9266C29.9112 23.3884 30.0526 22.7278 30.0526 21.9416C30.0526 20.9198 29.8053 20.0909 29.3098 19.4461C28.8144 18.8014 28.1979 18.3849 27.467 18.1928C26.9874 18.0632 25.9617 18 24.3898 18H20.2356V30.8198H22.8252L22.8245 25.9859ZM22.8245 20.1698H24.0745C25.0062 20.1698 25.6266 20.1974 25.9372 20.256C26.3576 20.3304 26.7037 20.5231 26.9787 20.8258C27.2537 21.1284 27.3912 21.5133 27.3912 21.9811C27.3912 22.3581 27.2932 22.6923 27.0965 22.9792C26.8998 23.2661 26.6327 23.4746 26.2866 23.6082C25.9445 23.7417 25.2609 23.8089 24.2391 23.8089H22.8246L22.8245 20.1698Z"
        fill="#CDCDCD"
      />
      <path
        d="M34.7679 25.9859H36.4542C37.6253 25.9859 38.5213 25.9227 39.1386 25.801C39.5946 25.703 40.0426 25.4984 40.4828 25.1918C40.923 24.8852 41.2848 24.4648 41.5717 23.9266C41.8585 23.3923 42 22.7278 42 21.9416C42 20.9198 41.7526 20.0909 41.2572 19.4461C40.7618 18.8014 40.1453 18.3849 39.4144 18.1928C38.9347 18.0632 37.909 18 36.3371 18H32.183V30.8198H34.7726L34.7719 25.986L34.7679 25.9859ZM34.7679 20.1698H36.0179C36.9496 20.1698 37.57 20.1974 37.8806 20.256C38.301 20.3304 38.6471 20.5231 38.9221 20.8258C39.1971 21.1284 39.3346 21.5133 39.3346 21.9811C39.3346 22.3581 39.2366 22.6923 39.0399 22.9792C38.8432 23.2661 38.5761 23.4746 38.23 23.6082C37.8839 23.7417 37.2043 23.8089 36.1825 23.8089H34.768L34.7679 20.1698Z"
        fill="#CDCDCD"
      />
    </svg>
  );
};

export default DAppIcon;
