import React from 'react';

import styles from './index.css';

import { ErrorOutlineIcon } from '../Icons';

function ErrorMessageWrapper({
  children,
  ...restProps
}: {
  children: React.ReactNode;
  [key: string]: any;
}): JSX.Element {
  return (
    <div className={styles.errorMessage} {...restProps}>
      {children}
    </div>
  );
}

type Props = {
  errorMessage?: string;
  showErrorMessage?: boolean;
  [key: string]: any;
};

function ErrorMessage(
  { errorMessage = '', showErrorMessage = false, ...restProps }: Props,
): JSX.Element | null {
  if (!errorMessage || errorMessage.length === 0 || !showErrorMessage) {
    return null;
  }

  return (
    <ErrorMessageWrapper {...restProps}>
      <ErrorOutlineIcon className={styles.errorIcon} />
      <div className={styles.errorMessageText}>{errorMessage}</div>
    </ErrorMessageWrapper>
  );
}

export default ErrorMessage;
