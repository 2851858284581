import cn from 'classnames';
// Utils
import { getMonth, getYear } from 'date-fns';
import _omit from 'lodash/omit';
import * as React from 'react';

// Styles
import css from '../index.css';

// Types
import type { DatePickerHeaderProps } from '../_types';

// Components
import Button from '../../Button';
import { ChevronIcon, FirstPageIcon } from '../../Icons';
import Text from '../../Text';
// Constants
import { MONTHS } from '../_constants';

const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  date,
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  nextMonthButtonDisabled,
  nextYearButtonDisabled,
  prevMonthButtonDisabled,
  prevYearButtonDisabled,
  className = '',
  ...restProps
}) => {
  const cleanedProps = _omit(restProps, [
    'changeMonth',
    'changeYear',
    'monthDate',
    'selectingDate',
    'customHeaderCount',
    'monthContainer',
  ]);

  const classes = cn({
    [css.headerContainer]: true,
    [className]: !!className,
  });

  return (
    <div className={classes} {...cleanedProps}>
      <Button
        Icon={FirstPageIcon}
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
        type="tertiary"
      />
      <Button
        Icon={ChevronIcon}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="tertiary"
      />
      <Text className={css.text}>
        {`${MONTHS[getMonth(date)]} ${getYear(date)}`}
      </Text>
      <Button
        className={css.nextButton}
        Icon={ChevronIcon}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="tertiary"
      />
      <Button
        className={css.nextButton}
        Icon={FirstPageIcon}
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
        type="tertiary"
      />
    </div>
  );
};

export default DatePickerHeader;
