// Utils
import { ResizeObserver } from '@juggle/resize-observer';
import { SpringConfig, TransitionFn, useTransition } from '@react-spring/web';
import React from 'react';
import mergeRefs from 'react-merge-refs';
import useMeasure, { RectReadOnly } from 'react-use-measure';

export const ANIMATION_CONFIG_ENTER = {
  mass: 1,
  tension: 250,
  friction: 25,
};

export const ANIMATION_CONFIG_LEAVE = {
  mass: 1,
  tension: 250,
  friction: 25,
  clamp: true,
};
type Transitions = TransitionFn<
  boolean,
  {
    opacity: number;
    height: number;
    overflow: string;
  }
>;

export function useHeight(
  ref: React.MutableRefObject<any>,
  bounds: RectReadOnly
) {
  return React.useMemo(() => {
    if (!ref?.current) {
      return 0;
    }

    const { marginTop, marginBottom } = window.getComputedStyle(ref?.current);

    const marginTopConverted = parseFloat(marginTop) || 0;
    const marginBottomConverted = parseFloat(marginBottom) || 0;

    return marginTopConverted + marginBottomConverted + bounds.height;
  }, [bounds.height, ref]);
}

export default function useAnimateHeight(
  isVisible: boolean,
  config: SpringConfig | null = null
): [React.LegacyRef<HTMLDivElement>, Transitions] {
  const localRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

  const height = useHeight(localRef, bounds);

  const defaultConfig = React.useMemo(
    () => (isVisible ? ANIMATION_CONFIG_ENTER : ANIMATION_CONFIG_LEAVE),
    [isVisible]
  );

  const transitions = useTransition(isVisible, {
    update: { height },
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    enter: { opacity: 1, height, overflow: 'visible' },
    leave: { opacity: 0, height: 0, overflow: 'hidden' },
    config: config || defaultConfig,
  });

  const mergedRefs = mergeRefs([localRef, ref]);

  return [mergedRefs, transitions];
}
