import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

type Props = {
  children: React.ReactNode[];
  disabled?: boolean;
  className?: string;
  // All other props
  [x: string]: any;
};

const Container = React.forwardRef<HTMLDivElement, Props>(
  ({ children, disabled = false, className = '', ...restProps }, ref) => {
    const classes = classnames({
      [className]: Boolean(className),
      [styles.container]: true,
      [styles.disabled]: disabled,
    });
    return (
      <div
        className={classes}
        data-testid="card-container"
        ref={ref}
        {...restProps}
      >
        {children}
        {disabled && (
          <div
            data-testid="card-container-disabled"
            className={styles.overlay}
          />
        )}
      </div>
    );
  },
);

Container.displayName = 'Container';

export default Container;
