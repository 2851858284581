import * as React from 'react';

export default function InvoiceWithUserIcon(props: SVGProps): JSX.Element {
  const { title = 'InvoiceWithUser', ...restProps } = props;

  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M5.25 6.125C5.25 5.64175 5.64175 5.25 6.125 5.25H21.875C22.3582 5.25 22.75 5.64175 22.75 6.125V14H24.5V6.125C24.5 4.67525 23.3247 3.5 21.875 3.5H6.125C4.67525 3.5 3.5 4.67525 3.5 6.125V23.0063C3.5 23.8312 4.16876 24.5 4.99372 24.5C5.38988 24.5 5.76981 24.3426 6.04994 24.0625L7 23.1124L8.13128 24.2437C8.47299 24.5854 9.02701 24.5854 9.36872 24.2437L10.5 23.1124L11.6313 24.2437C11.973 24.5854 12.527 24.5854 12.8687 24.2437L14.3624 22.75H14.875V21H14C13.7679 21 13.5454 21.0922 13.3813 21.2563L12.25 22.3876L11.1187 21.2563C10.777 20.9146 10.223 20.9146 9.88128 21.2563L8.75 22.3876L7.61872 21.2563C7.27701 20.9146 6.72299 20.9146 6.38128 21.2563L5.25 22.3876V6.125Z" />
      <path d="M18.375 24.5C17.4085 24.5 16.625 25.2835 16.625 26.25H25.375C25.375 25.2835 24.5915 24.5 23.625 24.5H18.375Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 19.25C17.5 17.317 19.067 15.75 21 15.75C22.933 15.75 24.5 17.317 24.5 19.25C24.5 21.183 22.933 22.75 21 22.75C19.067 22.75 17.5 21.183 17.5 19.25ZM21 17.5C20.0335 17.5 19.25 18.2835 19.25 19.25C19.25 20.2165 20.0335 21 21 21C21.9665 21 22.75 20.2165 22.75 19.25C22.75 18.2835 21.9665 17.5 21 17.5Z"
      />
      <path d="M7 14L17.5 14V12.25L7 12.25V14Z" />
      <path d="M15.75 10.5L7 10.5V8.75L15.75 8.75V10.5Z" />
      <path d="M21 10.5L19.25 10.5V8.75L21 8.75V10.5Z" />
      <path d="M19.25 14L21 14V12.25L19.25 12.25V14Z" />
      <path d="M7 17.5L14 17.5V15.75L7 15.75V17.5Z" />
    </svg>
  );
}
