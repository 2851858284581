// Styles
import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

type Props = {
  backgroundColor?: string;
  className?: string;
  fillColor?: string;
  percentage: number;
  // rest of Props
  [key: string]: any;
};

type Styles = {
  backgroundColor?: string;
};

type InnerStyles = Styles & {
  transform: string;
};

const ProgressBar = ({
  backgroundColor,
  className = '',
  fillColor,
  percentage,
  ...restProps
}: Props): JSX.Element => {
  let scale = '0';
  if (percentage >= 100) {
    scale = '1';
  } else if (percentage < 10) {
    scale = `0.0${percentage}`;
  } else {
    scale = `0.${percentage}`;
  }

  const outerClasses = classnames({
    [styles.outerBar]: true,
    [className]: Boolean(className),
  });

  const outerStyles: Styles = {};
  if (backgroundColor) {
    outerStyles.backgroundColor = backgroundColor;
  }

  const innerStyles: InnerStyles = { transform: `scale(${scale}, 1)` };
  if (fillColor) {
    innerStyles.backgroundColor = fillColor;
  }

  return (
    <div className={outerClasses} style={outerStyles} {...restProps}>
      <div className={styles.innerBar} style={innerStyles} />
    </div>
  );
};

export default ProgressBar;
