import React from 'react';

export default function UserIcon({
  title = 'User Circle',
  ...restProps
}: SVGProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...restProps}>
      <title>{title}</title>
      <path
        d="M20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0ZM20 6C23.31 6 26 8.69 26 12C26 15.32 23.31 18 20 18C16.69 18 14 15.32 14 12C14 8.69 16.69 6 20 6ZM20 34.4C14.99 34.4 10.59 31.84 8 27.96C8.05 23.99 16.01 21.8 20 21.8C23.99 21.8 31.94 23.99 32 27.96C29.41 31.84 25.01 34.4 20 34.4Z"
        fill="currentColor"
      />
    </svg>
  );
}
