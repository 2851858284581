import React from 'react';

// Types
import type { OnCloseFunction } from './_types';

export type ModalContextProps = {
  onClose: OnCloseFunction;
  isOpen: boolean;
};

export const ModalContext = React.createContext<ModalContextProps>({
  onClose: () => {},
  isOpen: false,
});

export default (): ModalContextProps => React.useContext(ModalContext);
