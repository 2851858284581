import React from 'react';

export default function RemoveOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'RemoveOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M5.41683 9.08337V10.9167H14.5835V9.08337H5.41683ZM10.0002 0.833374C4.93558 0.833374 0.833496 4.93546 0.833496 10C0.833496 15.0646 4.93558 19.1667 10.0002 19.1667C15.0647 19.1667 19.1668 15.0646 19.1668 10C19.1668 4.93546 15.0647 0.833374 10.0002 0.833374ZM10.0002 17.3334C5.95766 17.3334 2.66683 14.0425 2.66683 10C2.66683 5.95754 5.95766 2.66671 10.0002 2.66671C14.0427 2.66671 17.3335 5.95754 17.3335 10C17.3335 14.0425 14.0427 17.3334 10.0002 17.3334Z" />
    </svg>
  );
}
