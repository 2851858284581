import React from 'react';

function AlphabeticalIcon(props: SVGProps): JSX.Element {
  const { title = 'AlphabeticalIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>

      <path d="M29.8801 9.3201H20.4401L25.1601 4.6001L29.8801 9.3201ZM20.5001 38.7401H29.8201L25.1601 43.4001L20.5001 38.7401ZM12.2001 12.5401L3.2001 35.4601H6.8801L8.7201 30.5601H18.9401L20.7801 35.4601H24.4601L15.4801 12.5401H12.2001ZM9.9401 27.2801L13.8201 16.9201L17.7001 27.2801H9.9401ZM31.4601 32.2801H43.7001V35.4601H26.6401V32.8801L38.4801 15.7601H26.7201V12.5601H43.3201V15.0801L31.4601 32.2801Z" />
    </svg>
  );
};

export default AlphabeticalIcon;
