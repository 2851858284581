import React from 'react';

export default function SwapIcon(props: SVGProps): JSX.Element {
  const { title = 'Swap', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <path d="M8.155 12.8334L3.5 17.5L8.155 22.1667V18.6667H16.3333V16.3334H8.155V12.8334ZM24.5 10.5L19.845 5.83337V9.33337H11.6667V11.6667H19.845V15.1667L24.5 10.5Z" />
    </svg>
  );
}
