import React from 'react';

export default function LocationOffIcon(props: SVGProps): JSX.Element {
  const { title = 'LocationOff', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 9.735 14.175 10.39 13.67 10.85L17.3 14.48C18.275 12.62 19 10.685 19 9C19 5.135 15.865 2 12 2C10.02 2 8.235 2.825 6.965 4.145L10.15 7.33C10.605 6.825 11.265 6.5 12 6.5ZM16.375 16.1L11.75 11.475L11.64 11.365L3.27 3L2 4.275L5.18 7.455C5.065 7.95 5 8.465 5 9C5 14.25 12 22 12 22C12 22 13.67 20.15 15.375 17.65L18.725 21L20 19.725L16.375 16.1Z" />
    </svg>
  );
}
