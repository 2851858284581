import cn from 'classnames';
import noop from 'lodash/noop';
import React from 'react';

import css from './index.css';

type Props = {
  children: string | React.ReactElement;
  onClick?: (event: any) => void;
  className?: string;
  disabled?: boolean;
  // All other props
  [x: string]: any;
};

const DropdownMenuItem = (
  { children, onClick, className = '', disabled = false, ...restProps }: Props,
): React.ReactElement => (
  <div
    data-testid="dropdown-menu-item"
    className={cn({
      [css.dropdownMenuItem]: true,
      [css.disabled]: disabled,
      [className]: !!className,
    })}
    aria-disabled={disabled}
    onClick={disabled ? noop : onClick}
    onKeyDown={disabled ? noop : onClick}
    role="menuitem"
    tabIndex={0}
    {...restProps}
  >
    {children}
  </div>
);

export default DropdownMenuItem;
