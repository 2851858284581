import React from 'react';

export default function LineChart(props: SVGProps): JSX.Element {
  const { title = 'LineChart', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M16.1114 7.54834L19.7361 3.92369L18.7638 2.95142L15.1391 6.57607C14.9822 6.73306 14.759 6.80458 14.54 6.76808L11.9375 6.33434C11.1777 6.20771 10.4106 6.51504 9.94841 7.13128L7.19333 10.8047C6.95722 11.1195 6.50552 11.1723 6.20321 10.9204L3.19007 8.4094L2.30981 9.46571L5.32295 11.9767C6.2299 12.7324 7.58498 12.5742 8.29333 11.6297L11.0484 7.95628C11.2025 7.75087 11.4582 7.64843 11.7114 7.69064L14.314 8.12437C14.971 8.23387 15.6404 8.01933 16.1114 7.54834Z" />
      <path d="M19.7868 10.0544L16.3562 14.3427C15.7486 15.1021 14.6931 15.334 13.8233 14.899L11.5389 13.7568C11.284 13.6294 10.977 13.6724 10.7669 13.865L8.22552 16.1946C7.4644 16.8923 6.30465 16.9196 5.51146 16.2586L2.30981 13.5906L3.19007 12.5343L6.39171 15.2023C6.65611 15.4227 7.04269 15.4135 7.2964 15.181L9.83778 12.8514C10.468 12.2737 11.3892 12.1447 12.1538 12.527L14.4382 13.6692C14.7281 13.8142 15.08 13.7369 15.2825 13.4837L18.7131 9.19548L19.7868 10.0544Z" />
      <path d="M2.74991 19.25V17.875H19.2499V19.25H2.74991Z" />
    </svg>
  );
}
