import React from 'react';

export default function PauseIcon(props: SVGProps): JSX.Element {
  const { title = 'Pause', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M5.5 17.4166H9.16667V4.58325H5.5V17.4166ZM12.8333 4.58325V17.4166H16.5V4.58325H12.8333Z" />
    </svg>
  );
}
