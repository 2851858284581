import React from 'react';

export default function CompleteOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'CompleteOutline', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M9.625 12.7778L7.36114 10.5139L6.38887 11.4862L8.65273 13.7501C9.18971 14.287 10.0603 14.287 10.5973 13.7501L15.6111 8.73619L14.6389 7.76392L9.625 12.7778Z" />
      <path d="M11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75ZM4.125 11C4.125 7.20304 7.20304 4.125 11 4.125C14.797 4.125 17.875 7.20304 17.875 11C17.875 14.797 14.797 17.875 11 17.875C7.20304 17.875 4.125 14.797 4.125 11Z" />
    </svg>
  );
}
