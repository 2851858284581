// Utils
import { animated, useTransition } from '@react-spring/web';
import React from 'react';

// Styles
import styles from './index.css';

// Types
import { RemoveSnackbarSignatureWithType, SnackbarConfig } from '../_types';

// Components
import Snackbar from './Snackbar';

type SnackbarContainerProps = {
  snackbars: Array<SnackbarConfig>;
  removeSnackbar: RemoveSnackbarSignatureWithType;
  [key: string]: any;
};

function snackbarContainer({
  snackbars,
  removeSnackbar,
  ...restProps
}: SnackbarContainerProps): JSX.Element {
  const [refMap] = React.useState(() => new WeakMap());
  const transitions = useTransition(snackbars, {
    keys: (item: SnackbarConfig) => item.id,
    from: {
      height: 0,
      opacity: 0,
      marginTop: 0,
      translateY: 0,
    },
    enter: (item) => async (next) =>
      next({
        height: refMap.get(item).offsetHeight,
        opacity: 1,
        marginTop: 10,
        translateY: 0,
      }),
    leave: () => async (next) =>
      next({
        height: 0,
        opacity: 0,
        marginTop: 0,
        translateY: -100,
      }),
    config: (_item, _index, state) =>
      state === 'leave'
        ? { duration: 250 }
        : { tension: 200, friction: 20, precision: 0.1 },
  });

  return (
    <div className={styles.snackbarContainer} {...restProps}>
      {transitions(({ translateY, ...style }, item) => (
        <animated.div
          style={{
            ...style,
            transform: translateY.to((y: number) => `translate3d(0, ${y}%, 0)`),
          }}
        >
          <Snackbar
            {...item}
            ref={(ref): void => {
              refMap.set(item, ref);
            }}
            removeSnackbar={removeSnackbar}
          />
        </animated.div>
      ))}
    </div>
  );
}

export default snackbarContainer;
