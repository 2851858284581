// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from '../index.css';

const StepTrackerContentWrapper: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({ className = '', ...restProps }) => {
  const classes = cn({
    [className]: Boolean(className),
    [css.textSection]: true,
  });

  return <div className={classes} {...restProps} />;
};

StepTrackerContentWrapper.displayName = 'StepTrackerContentWrapper';

export default StepTrackerContentWrapper;
