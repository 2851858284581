// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import * as css from './index.css';

// Components
import Loader from '../Loader';
import TableCell from './TableCell';
import TableRow from './TableRow';

export interface TableBodyProps extends Record<string, any> {
  isLoadingMoreItems?: boolean;
  loaderClassName?: string;
}

// eslint-disable-next-line react/display-name
const TableBody = React.forwardRef<HTMLTableSectionElement, TableBodyProps>(
  (
    {
      children,
      className = '',
      isLoadingMoreItems = false,
      loaderClassName = '',
      ...restProps
    },
    ref
  ) => {
    const classes = cn({
      [css.table]: true,
      [className]: Boolean(className),
    });

    return (
      <tbody className={classes} ref={ref} {...restProps}>
        {children}
        {isLoadingMoreItems ? (
          <TableRow>
            <TableCell colSpan={1000}>
              <Loader className={loaderClassName} />
            </TableCell>
          </TableRow>
        ) : null}
      </tbody>
    );
  }
);

export default TableBody;
