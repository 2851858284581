import React from 'react';

// Types
import { UploadedFileType } from './_types';

// Components
import UploadedFile from './UploadedFile';

export type Props = {
  uploadedFiles?: UploadedFileType[];
  onDelete?: (index: number) => void;
};

const FilesList = ({ uploadedFiles, onDelete }: Props): JSX.Element => {
  if (!uploadedFiles) {
    return <></>;
  }

  return <UploadedFile uploadedFiles={uploadedFiles} onDelete={onDelete} />;
};

export default FilesList;
