import React from "react"

export default function ClockCircleIcon(props: SVGProps): JSX.Element {
  const { title = "Clock", ...restProps } = props

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <rect width="24" height="24" rx="12" fill="#676767" />
      <path
        d="M11.5 8.5V11.7929C11.5 12.0581 11.6054 12.3125 11.7929 12.5L13.6464 14.3536L14.3536 13.6464L12.5 11.7929V8.5H11.5Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
        fill="#E3E8F1"
      />
    </svg>
  )
}
