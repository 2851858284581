import React from 'react';

import { LoaderProps } from './_types';

import DelayedLoader from './DelayedLoader';
import SimpleLoader from './SimpleLoader';

export default function Loader(
  { delay, ...props }: LoaderProps
): CommonComponentReturn {
  const accessibilityProps = {
    role: 'status',
    'aria-busy': true,
  };

  return delay ? (
    <DelayedLoader delay={delay} {...accessibilityProps} {...props} />
  ) : (
    <SimpleLoader {...accessibilityProps} {...props} />
  );
}
