import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../../index.css';

// Types
import { BannerIconProps, BannerType } from '../../_types';

// Components
import { CancelIcon, CompleteIcon, ErrorIcon, InfoIcon } from '../../../Icons';
// Hooks & Utils
import useBannerContext from '../../useBannerContext';

// Local Constants
const ICON_MAP: Record<BannerType, IconComponent> = {
  info: InfoIcon,
  error: CancelIcon,
  success: CompleteIcon,
  warning: ErrorIcon,
};

type IconComponent = React.FunctionComponent<SVGProps>;

const BannerIcon: React.FC<BannerIconProps> = ({
  Icon,
  className = '',
  ...restProps
}) => {
  const { type, hasActions } = useBannerContext();
  const iconClasses = cn({
    className: !!className,
    [styles[type]]: type,
    [styles.icon]: true,
    [styles.topAlignmentSpacing]: hasActions,
  });

  const IconToDisplay = Icon || ICON_MAP[type];

  return (
    <IconToDisplay
      data-testid={`icon-${type}`}
      className={iconClasses}
      {...restProps}
    />
  );
};

export default BannerIcon;
