import React from 'react';

export default function CalendarIcon(props: SVGProps): JSX.Element {
  const { title = 'Calendar', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M8.25 10.083H6.41667V11.9163H8.25V10.083ZM11.9167 10.083H10.0833V11.9163H11.9167V10.083ZM15.5833 10.083H13.75V11.9163H15.5833V10.083ZM17.4167 3.66634H16.5V1.83301H14.6667V3.66634H7.33333V1.83301H5.5V3.66634H4.58333C3.56583 3.66634 2.75917 4.49134 2.75917 5.49967L2.75 18.333C2.75 19.3413 3.56583 20.1663 4.58333 20.1663H17.4167C18.425 20.1663 19.25 19.3413 19.25 18.333V5.49967C19.25 4.49134 18.425 3.66634 17.4167 3.66634ZM17.4167 18.333H4.58333V8.24967H17.4167V18.333Z" />
    </svg>
  );
}
