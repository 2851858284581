// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

interface Props extends React.HTMLProps<HTMLInputElement> {
  isError?: boolean;
  type?: 'radio';
}

export default function RadioButton({
  children,
  className = '',
  disabled = false,
  isError = false,
  ...restProps
}: Props): JSX.Element {
  const wrapperClasses = cn({
    [styles.wrapper]: true,
    [styles.disabled]: disabled,
    [styles.error]: isError && !disabled,
    [className]: Boolean(className),
  });

  return (
    <label className={wrapperClasses}>
      <input
        className={styles.input}
        type="radio"
        disabled={disabled}
        {...restProps}
      />
      {Boolean(children) && <span className={styles.label}>{children}</span>}
    </label>
  );
}
