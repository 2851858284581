import React from 'react';

import { CircularProgressProps } from './types';

import { colors } from '../../../../styles';
import { useFormWizardContext } from '../../FormWizardProvider';

// Inspired by https://dev.to/jackherizsmith/making-a-progress-circle-in-react-3o65
const RADIUS = 21;
const CIRC = 2 * Math.PI * RADIUS;

const CircularProgress: React.FC<CircularProgressProps> = ({
  baseColor = colors.marinaBlue40,
  progressColor = colors.marinaBlue90,
}) => {
  const { getProgressText, displayedMaxSteps, currentStepElement } =
    useFormWizardContext();
  const displayCurrentStep = currentStepElement.displayedStepNumber ?? 0;
  const percentage = (1 - displayCurrentStep / displayedMaxSteps) * CIRC;

  return (
    <svg width={46} height={46}>
      <g transform="rotate(-90 23 21)">
        <circle
          r={RADIUS}
          cx={RADIUS}
          cy={RADIUS}
          fill="transparent"
          stroke={baseColor}
          strokeWidth={4}
          strokeDasharray={CIRC}
          strokeDashoffset={0}
        />
        <circle
          r={RADIUS}
          cx={RADIUS}
          cy={RADIUS}
          fill="transparent"
          stroke={progressColor}
          strokeWidth={4}
          strokeDasharray={CIRC}
          strokeDashoffset={percentage}
          strokeLinecap="round"
        />
      </g>
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="14px"
        fill={colors.marinaBlue90}
      >
        {getProgressText(displayCurrentStep, displayedMaxSteps)}
      </text>
    </svg>
  );
};

export default CircularProgress;
