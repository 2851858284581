// Utils
import { isEqual } from 'lodash';
import React from 'react';
// Components
import { Controller } from 'react-hook-form';

// Styles
import css from './styles.css';

import type { FieldValues, FormOptions } from '../../_types';

import RadioButton from '../../../RadioButton';
import Text from '../../../Text';

export interface RadioButtonOption
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'type' | 'value' | 'label' | 'checked'
  > {
  label: React.ReactNode;
  value: string | number;
  extraContent?: JSX.Element;
  onlyShowExtraContentIfSelected?: boolean;
}

export interface FormRadioGroupProps<T extends FieldValues = FieldValues>
  extends FormOptions<T> {
  options: RadioButtonOption[];
}

const FormRadioGroup = <T extends FieldValues = FieldValues>(
  { name, control, options, defaultValue, rules }: FormRadioGroupProps<T>,
) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <>
        {options.map(
          (
            { label, extraContent, onlyShowExtraContentIfSelected, ...opt },
            index,
          ) => (
            <React.Fragment key={`${name}-${index}`}>
              <RadioButton
                {...opt}
                data-testid={`${name}-${index}`}
                isError={!!error}
                checked={
                  value === opt.value
                  || (Array.isArray(value)
                    && value.some((val: string) => isEqual(val, opt.value)))
                }
                value={opt.value}
                onBlur={onBlur}
                onChange={onChange}
              >
                {label}
              </RadioButton>
              {onlyShowExtraContentIfSelected
                ? value === opt.value && extraContent
                : extraContent}
            </React.Fragment>
          ),
        )}
        {error && (
          <Text size="small" className={css.error}>
            {error.message}
          </Text>
        )}
      </>
    )}
  />
);

export default FormRadioGroup;
