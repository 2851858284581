import React from 'react';

// Types
import { BannerContextProps } from './_types';

export const BannerContext = React.createContext<BannerContextProps>({
  type: 'info',
  onClose: () => {},
  hasActions: false,
  setHasActions: () => {},
});

export default (): BannerContextProps => React.useContext(BannerContext);
