// truncateString returns a string formatted with ellipses in the middle to
// shorten the length. It will keep numChars characters on each side of the ...
// ex: 0x331...12ab3 if numChars == 5
export default function truncateString(
  str?: string | null,
  numChars: number | [number, number] = 4,
  truncateLength?: number
) {
  if (!str) {
    return '';
  }

  const sep = '...';
  let firstTruncateLen;
  let secondTruncateLen;

  if (Array.isArray(numChars)) {
    firstTruncateLen = numChars[0];
    secondTruncateLen = numChars[1];
  } else {
    firstTruncateLen = numChars;
    secondTruncateLen = numChars;
  }

  const truncatedLen =
    truncateLength ?? firstTruncateLen + secondTruncateLen + sep.length;

  // Do not truncate a string that doesn't exist or is shorter than the
  // truncated version
  if (!str || str.length < truncatedLen) return str;

  const firstDigits = str.substring(0, firstTruncateLen);
  const lastDigits = str.substring(str.length - secondTruncateLen);

  return `${firstDigits}${sep}${lastDigits}`;
}
