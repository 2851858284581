import React from 'react';

// Styles
import styles from './index.css';

// Components
import Button from '../Button/index';
import { CloseIcon } from '../Icons';

type HeaderWrapperProps = {
  children: React.ReactNode;
  [key: string]: any;
};

export function HeaderWrapper(
  { children, ...restProps }: HeaderWrapperProps,
): JSX.Element {
  return (
    <div className={styles.header} {...restProps}>
      {children}
    </div>
  );
}

export type ClosedBy = 'cancel' | 'close' | 'backdrop';

type Props = {
  onCancel?: (closedBy?: ClosedBy) => any;
  testId?: string;
  title: string;
  [key: string]: any;
};

function Header(
  { testId = '', title, onCancel, ...restProps }: Props,
): JSX.Element {
  return (
    <HeaderWrapper {...restProps}>
      <div className={styles.title}>{title}</div>
      {onCancel && (
        <Button
          type="tertiary"
          className={styles.closeButton}
          onClick={(): void => {
            onCancel('close');
          }}
          data-testid={`close-${testId}`}
          Icon={CloseIcon}
          iconClassName={styles.closeIcon}
        />
      )}
    </HeaderWrapper>
  );
}

export default Header;
