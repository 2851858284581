import React from 'react';

export default function NewReleaseIcon(props: SVGProps): JSX.Element {
  const { title = 'NewRelease', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M23 12L20.56 9.22001L20.9 5.53501L17.29 4.72001L15.4 1.54001L12 3.00001L8.6 1.54001L6.71 4.72001L3.1 5.53501L3.44 9.22001L1 12L3.44 14.78L3.1 18.465L6.71 19.28L8.6 22.46L12 21L15.4 22.46L17.29 19.28L20.9 18.465L20.56 14.78L23 12ZM13 17H11V15H13V17ZM13 13H11V7.00001H13V13Z" />
    </svg>
  );
}
