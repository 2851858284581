import React from 'react';

export default function MailIcon(props: SVGProps): JSX.Element {
  const { title = 'Mail', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.334 0.666992H2.66732C1.6544 0.666992 0.843151 1.48741 0.843151 2.50033L0.833984 13.5003C0.833984 14.5132 1.6544 15.3337 2.66732 15.3337H17.334C18.3469 15.3337 19.1673 14.5132 19.1673 13.5003V2.50033C19.1673 1.48741 18.3469 0.666992 17.334 0.666992ZM17.334 13.5003H2.66732V4.33366L10.0007 8.91699L17.334 4.33366V13.5003ZM10.0007 7.08366L2.66732 2.50033H17.334L10.0007 7.08366Z"
      />
    </svg>
  );
}
