import React from 'react';

function JiraLinkIcon(props: SVGProps): JSX.Element {
  const { title = 'JiraLink', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path
        d="M13.4857 2H7.70859C7.70859 3.44 8.87434 4.60571 10.3143 4.60571H11.3771V5.6343C11.3771 7.0743 12.5428 8.24 13.9828 8.24V2.49714C13.9828 2.22285 13.76 2 13.4857 2Z"
        fill="white"
      />
      <path
        d="M10.6228 4.87988H4.8457C4.8457 6.31988 6.0114 7.48558 7.4514 7.48558H8.51425V8.51418C8.51425 9.95418 9.67995 11.1199 11.1199 11.1199V5.37703C11.1371 5.10273 10.9142 4.87988 10.6228 4.87988Z"
        fill="url(#paint0_linear_3518_5265)"
      />
      <path
        d="M7.7771 7.75977H2C2 9.19977 3.1657 10.3655 4.60569 10.3655H5.66855V11.3941C5.66855 12.8341 6.83425 13.9998 8.27425 13.9998V8.25692C8.27425 7.98262 8.0514 7.75977 7.7771 7.75977Z"
        fill="url(#paint1_linear_3518_5265)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3518_5265"
          x1="11.0077"
          y1="4.88616"
          x2="8.54955"
          y2="7.42108"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3518_5265"
          x1="8.311"
          y1="7.78162"
          x2="5.4689"
          y2="10.5469"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default JiraLinkIcon;
