import React from 'react';

export default function HumanUserIcon(props: SVGProps): JSX.Element {
  const { title = 'HumanUser', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.625 21.5C6.625 20.5335 7.4085 19.75 8.375 19.75H13.625C14.5915 19.75 15.375 20.5335 15.375 21.5H6.625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 13.625C10.0335 13.625 9.25 14.4085 9.25 15.375C9.25 16.3415 10.0335 17.125 11 17.125C11.9665 17.125 12.75 16.3415 12.75 15.375C12.75 14.4085 11.9665 13.625 11 13.625ZM7.5 15.375C7.5 13.442 9.067 11.875 11 11.875C12.933 11.875 14.5 13.442 14.5 15.375C14.5 17.308 12.933 18.875 11 18.875C9.067 18.875 7.5 17.308 7.5 15.375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.25C10.0335 2.25 9.25 3.0335 9.25 4C9.25 4.9665 10.0335 5.75 11 5.75C11.9665 5.75 12.75 4.9665 12.75 4C12.75 3.0335 11.9665 2.25 11 2.25ZM7.5 4C7.5 2.067 9.067 0.5 11 0.5C12.933 0.5 14.5 2.067 14.5 4C14.5 5.933 12.933 7.5 11 7.5C9.067 7.5 7.5 5.933 7.5 4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 16.25C0.5 15.2835 1.2835 14.5 2.25 14.5H5.75V16.25H0.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 16.25V14.5H19.75C20.7165 14.5 21.5 15.2835 21.5 16.25H16.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.875 8.375C3.9085 8.375 3.125 9.1585 3.125 10.125C3.125 11.0915 3.9085 11.875 4.875 11.875C5.8415 11.875 6.625 11.0915 6.625 10.125C6.625 9.1585 5.8415 8.375 4.875 8.375ZM1.375 10.125C1.375 8.192 2.942 6.625 4.875 6.625C6.808 6.625 8.375 8.192 8.375 10.125C8.375 12.058 6.808 13.625 4.875 13.625C2.942 13.625 1.375 12.058 1.375 10.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.125 8.375C16.1585 8.375 15.375 9.1585 15.375 10.125C15.375 11.0915 16.1585 11.875 17.125 11.875C18.0915 11.875 18.875 11.0915 18.875 10.125C18.875 9.1585 18.0915 8.375 17.125 8.375ZM13.625 10.125C13.625 8.192 15.192 6.625 17.125 6.625C19.058 6.625 20.625 8.192 20.625 10.125C20.625 12.058 19.058 13.625 17.125 13.625C15.192 13.625 13.625 12.058 13.625 10.125Z"
      />
    </svg>
  );
}
