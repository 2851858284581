import React from 'react';

export default function BankTransferInIcon(props: SVGProps): JSX.Element {
  const { title = 'BankTransferIn', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M.208 12.738V9.79h2.938V7.827l3.916 3.438-3.916 3.437v-1.964H.208zM19.791 6.55v1.277H8.041V6.55l5.875-3.633 5.875 3.633zm-11.75 8.152h11.75v1.964H8.041v-1.964zm4.896-5.893h1.959v4.911h-1.959V8.81zm-3.916 0h1.958v4.911H9.02V8.81zm7.833 0h1.958v4.911h-1.958V8.81z" />
    </svg>
  );
}
