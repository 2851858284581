import React from 'react';

export default function PhoneLockIcon(props: SVGProps): JSX.Element {
  const { title = 'PhoneLock', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M19 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V6H9V4H19V20H9V18H7V21C7 21.5304 7.21071 22.0391 7.58579 22.4142C7.96086 22.7893 8.46957 23 9 23H19C19.5304 23 20.0391 22.7893 20.4142 22.4142C20.7893 22.0391 21 21.5304 21 21V3C21 2.46957 20.7893 1.96086 20.4142 1.58579C20.0391 1.21071 19.5304 1 19 1ZM10.8 11V9.5C10.7368 8.8061 10.4126 8.16205 9.89287 7.69799C9.37312 7.23393 8.6966 6.98446 8 7C7.3034 6.98446 6.62688 7.23393 6.10713 7.69799C5.58738 8.16205 5.26317 8.8061 5.2 9.5V11C4.88867 11.0211 4.59553 11.1542 4.37489 11.3749C4.15425 11.5955 4.02106 11.8887 4 12.2V15.7C4.00134 16.0275 4.12528 16.3425 4.3474 16.5832C4.56951 16.8238 4.87369 16.9725 5.2 17H10.7C11.0275 16.9987 11.3425 16.8747 11.5832 16.6526C11.8238 16.4305 11.9725 16.1263 12 15.8V12.3C11.9987 11.9725 11.8747 11.6575 11.6526 11.4168C11.4305 11.1762 11.1263 11.0275 10.8 11ZM9.5 11H6.5V9.5C6.50863 9.31457 6.55485 9.13282 6.63586 8.9658C6.71687 8.79878 6.83098 8.64996 6.97126 8.52838C7.11155 8.40681 7.27507 8.31501 7.45191 8.25856C7.62875 8.20211 7.81522 8.18218 8 8.2C8.18478 8.18218 8.37125 8.20211 8.54809 8.25856C8.72493 8.31501 8.88845 8.40681 9.02874 8.52838C9.16902 8.64996 9.28313 8.79878 9.36414 8.9658C9.44515 9.13282 9.49137 9.31457 9.5 9.5V11Z" />
    </svg>
  );
}
