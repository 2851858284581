import React from 'react';

export default function DesktopIcon(props: SVGProps): JSX.Element {
  const { title = 'Desktop', ...restProps } = props;
  return (
    <svg
      width="244"
      height="195"
      viewBox="0 0 244 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        d="M241.88 164.67H1V5.7102C1 3.3602 2.91 1.4502 5.26 1.4502H237.61C239.96 1.4502 241.87 3.3602 241.87 5.7102V164.66L241.88 164.67Z"
        fill="black"
      />
      <path
        d="M241.88 164.67H1V5.7102C1 3.3602 2.91 1.4502 5.26 1.4502H237.61C239.96 1.4502 241.87 3.3602 241.87 5.7102V164.66L241.88 164.67Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M150.41 182.65L146.67 164.67H96.2002L92.4602 182.66C91.8002 185.84 88.9902 188.12 85.7402 188.12H75.7402V194.06H167.12V188.12H157.12C153.87 188.12 151.06 185.84 150.4 182.66L150.41 182.65Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M241.88 143.74H1V164.67H241.88V143.74Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M129.91 150.74H112.96C111.508 150.74 110.33 151.918 110.33 153.37V153.38C110.33 154.833 111.508 156.01 112.96 156.01H129.91C131.363 156.01 132.54 154.833 132.54 153.38V153.37C132.54 151.918 131.363 150.74 129.91 150.74Z"
        fill="black"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.7598 40.6201L7.75977 40.8101"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M32.9697 35.8398L7.63965 36.1698"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M98.6295 54.3506H64.0195"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.12 68H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M79.6695 68H64.0195"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M79.6695 112.25H64.0195"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M79.6695 90.1299H64.0195"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M186.19 73.6299H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.12 90.1299H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M186.19 95.75H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M181.12 112.25H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M186.19 117.87H165.47"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M49.1299 1V143.07"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M33.6196 19.9298L20.5896 11.5898L7.55957 19.9298L20.5896 28.2798L33.6196 19.9298Z"
        fill="#F4F5F5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2 49.6504H49.45"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2 57.7705H49.45"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2 65.8799H49.45"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2 74H49.45"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2 82.1201H49.45"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M231.79 14.7899C233.071 14.7899 234.11 13.7467 234.11 12.4599C234.11 11.1731 233.071 10.1299 231.79 10.1299C230.508 10.1299 229.47 11.1731 229.47 12.4599C229.47 13.7467 230.508 14.7899 231.79 14.7899Z"
        fill="#F4F5F5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M223.74 14.7899C225.021 14.7899 226.06 13.7467 226.06 12.4599C226.06 11.1731 225.021 10.1299 223.74 10.1299C222.459 10.1299 221.42 11.1731 221.42 12.4599C221.42 13.7467 222.459 14.7899 223.74 14.7899Z"
        fill="#F4F5F5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M215.68 14.7899C216.962 14.7899 218 13.7467 218 12.4599C218 11.1731 216.962 10.1299 215.68 10.1299C214.399 10.1299 213.36 11.1731 213.36 12.4599C213.36 13.7467 214.399 14.7899 215.68 14.7899Z"
        fill="#F4F5F5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M230.41 42.3799C231.31 42.3799 232.04 43.2699 232.04 44.3699V126.59C232.04 127.69 231.31 128.58 230.41 128.58H59.0499C58.1499 128.58 57.4199 127.69 57.4199 126.59V44.3699C57.4199 43.2699 58.1499 42.3799 59.0499 42.3799H230.41Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M166.6 54.3506H187.69C188.32 54.3506 188.83 53.8402 188.83 53.2106V49.8706C188.83 49.241 188.32 48.7306 187.69 48.7306H166.6C165.97 48.7306 165.46 49.241 165.46 49.8706V53.2106C165.46 53.8402 165.97 54.3506 166.6 54.3506Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M201.99 95.75H223.08C223.709 95.75 224.22 95.2396 224.22 94.61V91.27C224.22 90.6404 223.709 90.13 223.08 90.13H201.99C201.36 90.13 200.85 90.6404 200.85 91.27V94.61C200.85 95.2396 201.36 95.75 201.99 95.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M201.99 117.87H223.08C223.709 117.87 224.22 117.36 224.22 116.73V113.39C224.22 112.761 223.709 112.25 223.08 112.25H201.99C201.36 112.25 200.85 112.761 200.85 113.39V116.73C200.85 117.36 201.36 117.87 201.99 117.87Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M64.0195 81.0898H224.22"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M64.0195 104.23H224.22"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
