import React from 'react';

export default function SecurityIcon(props: SVGProps): JSX.Element {
  const { title = 'Security', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" {...restProps}>
      <title>{title}</title>
      <path d="M8 1.167L2 3.833v4c0 3.704 2.557 7.157 6 8 3.443-.843 6-4.296 6-8v-4L8 1.167zm0 7.326h4.667C12.313 11.24 10.483 13.687 8 14.45V8.5H3.333V4.7L8 2.627v5.866z" />
    </svg>
  );
}
