import classnames from 'classnames';
import React from 'react';
import { HeaderGroup } from 'react-table';

import styles from '../index.css';

type HeadProps = {
  headerGroups: HeaderGroup[];
  sort?: boolean;
  // All other props
  [x: string]: any;
};

type ArrowProps = {
  direction: 'up' | 'down';
  color?: string;
};

// TODO 08/05/2020 - Maybe to common icons?
function Arrow({ direction, color }: ArrowProps): JSX.Element {
  const cn = classnames({
    [styles.rotate180]: direction === 'up',
    [styles.arrow]: true,
  });

  return (
    <svg
      className={cn}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M11.3327 6.00008L10.3927 5.06008L6.66602 8.78008V0.666748H5.33268V8.78008L1.61268 5.05342L0.666016 6.00008L5.99935 11.3334L11.3327 6.00008Z"
        fill={color}
      />
    </svg>
  );
}

const TableHead = ({
  headerGroups,
  sort = false,
  ...restProps
}: HeadProps): JSX.Element => (
  <thead className={styles.tableHead} data-testid="table-head" {...restProps}>
    {headerGroups.map((headerGroup, hGIndex: number) => {
      const { key, ...rest } = headerGroup.getHeaderGroupProps();

      return (
        <tr data-testid={`table-head-tr-${hGIndex}`} key={key} {...rest}>
          {headerGroup.headers.map((column, cIndex) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { isSorted, isSortedDesc, isSortable } = column;
            const isColumnSortable = sort || isSortable;

            const { key: headerKey, ...restHeaderProps } = isColumnSortable
              ? column.getHeaderProps(column.getSortByToggleProps())
              : column.getHeaderProps();

            const containerClassName = classnames({
              [styles.tableHeadTitle]: true,
              [styles.sort]: isColumnSortable,
            });

            return (
              <th
                className={containerClassName}
                data-testid={`table-head-tr-${hGIndex}-th-${cIndex}`}
                key={headerKey}
                {...restHeaderProps}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style={column.styles || null}
              >
                {column.render('Header')}
                {isSortable && (
                  <Arrow
                    color={!isSorted ? 'transparent' : '#BFCEE7'}
                    direction={isSortedDesc ? 'down' : 'up'}
                  />
                )}
              </th>
            );
          })}
        </tr>
      );
    })}
  </thead>
);

export default TableHead;
