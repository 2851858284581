import React from 'react';

export default function ExitIcon(props: SVGProps): JSX.Element {
  const { title = 'Exit', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M4.8125 4.125L11 4.125V2.75L4.8125 2.75C3.67341 2.75 2.75 3.67341 2.75 4.8125L2.75 17.1875C2.75 18.3266 3.67341 19.25 4.8125 19.25H11V17.875H4.8125C4.4328 17.875 4.125 17.5672 4.125 17.1875L4.125 4.8125C4.125 4.4328 4.4328 4.125 4.8125 4.125Z" />
      <path d="M13.6076 6.67369L17.2465 10.3126L7.90625 10.3126L7.90625 11.6876L17.2465 11.6876L13.6076 15.3264L14.5799 16.2987L18.9062 11.9723C19.4432 11.4354 19.4432 10.5648 18.9062 10.0278L14.5799 5.70142L13.6076 6.67369Z" />
    </svg>
  );
}
