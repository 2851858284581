import React from 'react';

// Components
import Switch from './component';
// Types
import type { BaseProps as Props } from './component';

const useSwitch: ComponentHook<boolean, Props> = (initialState = false) => {
  const [state, setState] = React.useState(() => initialState);

  const Component = (props: Props): JSX.Element => (
    <Switch
      {...props}
      checked={state}
      onChange={(): void => {
        setState((prevState) => !prevState);
      }}
    />
  );

  return [Component, state, { setState }];
};

export default useSwitch;
