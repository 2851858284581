// Utils
import cn from 'classnames';
import _omit from 'lodash/omit';
import React from 'react';

// Styles
import css from '../index.css';

// Types
import type { TextProps } from '../../Text/_types';

// Components
import Text from '../../Text';
// Constants
import { STEP_VARIANTS } from '../_constants';
// Hooks
import useStepContext from '../useStepContext';

const StepTrackerTitle: React.FC<TextProps> = ({
  className = '',
  ...restProps
}) => {
  const { variant: stepVariant } = useStepContext();

  const classes = cn({
    [css.title]: true,
    [css.opacity]: stepVariant === STEP_VARIANTS.incomplete,
    [className]: Boolean(className),
  });

  return (
    <Text
      type="body"
      size="small"
      className={classes}
      {..._omit(restProps, ['variant', 'size'])}
    />
  );
};

StepTrackerTitle.displayName = 'StepTrackerTitle';

export default StepTrackerTitle;
