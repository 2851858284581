import React from 'react';

function KeyIcon(props: SVGProps): JSX.Element {
  const { title = 'Access', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 8" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M.563 4a3.938 3.938 0 017.835-.563h8.477v1.126H8.398A3.938 3.938 0 01.563 4zM4.5 1.187a2.813 2.813 0 100 5.626 2.813 2.813 0 000-5.625z"
        clipRule="evenodd"
      />
      <path d="M12.938 6.813v1.125h1.124V6.813h1.688v1.125h1.125V6.813c0-.622-.504-1.125-1.125-1.125h-1.688c-.62 0-1.124.503-1.124 1.125z" />
    </svg>
  );
}

export default KeyIcon;
