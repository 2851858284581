import React from 'react';

export default function ReceiptIcon(props: SVGProps): JSX.Element {
  const { title = 'Receipt', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" {...restProps}>
      <title>{title}</title>
      <path d="M18 17.8333H4V15.5H18V17.8333ZM18 13.1667H4V10.8333H18V13.1667ZM18 8.5H4V6.16667H18V8.5ZM0.5 23.6667L2.25 21.9167L4 23.6667L5.75 21.9167L7.5 23.6667L9.25 21.9167L11 23.6667L12.75 21.9167L14.5 23.6667L16.25 21.9167L18 23.6667L19.75 21.9167L21.5 23.6667V0.333336L19.75 2.08334L18 0.333336L16.25 2.08334L14.5 0.333336L12.75 2.08334L11 0.333336L9.25 2.08334L7.5 0.333336L5.75 2.08334L4 0.333336L2.25 2.08334L0.5 0.333336V23.6667Z" />
    </svg>
  );
}
