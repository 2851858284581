import React from 'react';

export default function GasStationIcon(props: SVGProps): JSX.Element {
  const { title = 'GasStation', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M13.18 4.82L13.19 4.81L10.7067 2.33333L10 3.04L11.4067 4.44667C10.78 4.68667 10.3334 5.29 10.3334 6C10.3334 6.92 11.08 7.66667 12 7.66667C12.2367 7.66667 12.4634 7.61667 12.6667 7.52667V12.3333C12.6667 12.7 12.3667 13 12 13C11.6334 13 11.3334 12.7 11.3334 12.3333V9.33333C11.3334 8.59667 10.7367 8 10 8H9.33335V3.33333C9.33335 2.59667 8.73669 2 8.00002 2H4.00002C3.26335 2 2.66669 2.59667 2.66669 3.33333V14H9.33335V9H10.3334V12.3333C10.3334 13.2533 11.08 14 12 14C12.92 14 13.6667 13.2533 13.6667 12.3333V6C13.6667 5.54 13.48 5.12333 13.18 4.82ZM8.00002 6.66667H4.00002V3.33333H8.00002V6.66667ZM12 6.66667C11.6334 6.66667 11.3334 6.36667 11.3334 6C11.3334 5.63333 11.6334 5.33333 12 5.33333C12.3667 5.33333 12.6667 5.63333 12.6667 6C12.6667 6.36667 12.3667 6.66667 12 6.66667Z" />
    </svg>
  );
}
