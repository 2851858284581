import React from 'react';

export default function HyperlinkIcon(props: SVGProps): JSX.Element {
  const { title = 'Hyperlink', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M17.4167 18.4147H4.58333V5.58138H11V3.74805H4.58333C3.57042 3.74805 2.75 4.56846 2.75 5.58138V18.4147C2.75 19.4276 3.57042 20.248 4.58333 20.248H17.4167C18.4296 20.248 19.25 19.4276 19.25 18.4147V11.998H17.4167V18.4147ZM12.8333 3.74805V5.58138H16.1196L7.10875 14.5922L8.40583 15.8893L17.4167 6.87846V10.1647H19.25V3.74805H12.8333Z" />
    </svg>
  );
}
