import * as React from 'react';

type Props = React.HTMLAttributes<SVGElement>;

const EmptyPageAddIcon: React.FunctionComponent<Props> = (props) => {
  const { title = 'EmptyPageAdd', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 200 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_1740_29515)">
        <path
          d="M4.92506 1H183.185C185.245 1 186.925 2.68 186.925 4.74V145.06H1.18506V4.74C1.18506 2.68 2.86506 1 4.92506 1Z"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M16.6853 127.34H5.67529"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5953 120.3H5.67529"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.545 26.3896L5.625 26.6496"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M105.105 22.7803L50.0752 22.9303"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M69.9051 16.54L49.9751 16.8"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M97.9155 64.8896L86.8955 65.0296"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M120.895 64.8896L109.885 65.0296"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M69.9051 64.8301L49.9751 65.0901"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M162.035 64.8301L142.115 65.0901"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5953 113.27H5.67529"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5953 106.24H5.67529"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M17.9853 99.2002H5.67529"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M38.2651 1.13965V145.06"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M26.0652 13.8796L15.8152 7.30957L5.55518 13.8796L15.8152 20.4396L26.0652 13.8796Z"
          fill="#A2A4A8"
        />
        <path
          d="M1.18506 37.2598H38.5251"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M49.9751 37.2598H74.2851"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M79.5449 37.2598H103.845"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M1.18506 50.0303H38.5251"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M1.18506 62.7998H38.5251"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M180.355 45.6699C181.365 45.6699 182.175 46.3699 182.175 47.2299V54.8499C182.175 55.7099 181.355 56.4099 180.355 56.4099H46.215C45.205 56.4099 44.395 55.7099 44.395 54.8499V47.2299C44.395 46.3699 45.215 45.6699 46.215 45.6699H180.355Z"
          fill="#0167A7"
        />
        <path
          d="M49.9751 73.0303H176.025"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M173.885 157.88C188.212 157.88 199.825 146.266 199.825 131.94C199.825 117.614 188.212 106 173.885 106C159.559 106 147.945 117.614 147.945 131.94C147.945 146.266 159.559 157.88 173.885 157.88Z"
          fill="white"
        />
        <path
          d="M173.885 119.9V143.98"
          stroke="#272525"
          strokeWidth="2.2"
          strokeMiterlimit="10"
        />
        <path
          d="M185.925 131.94H161.845"
          stroke="#272525"
          strokeWidth="2.2"
          strokeMiterlimit="10"
        />
        <path
          d="M94.0555 50.9697L86.8955 51.1197"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M126.035 50.9697L109.885 51.1197"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.2951 50.9102L49.9751 51.1702"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M155.885 50.9102L142.115 51.1702"
          stroke="#A2A4A8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1740_29515">
          <rect
            width="199.63"
            height="157.88"
            fill="white"
            transform="translate(0.185059)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyPageAddIcon;
