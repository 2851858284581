// @Generated
// This is a generated file, DO NOT MODIFY
import CheckboxCheckedIcon from './CheckboxCheckedIcon';
import CheckboxIndeterminateIcon from './CheckboxIndeterminateIcon';
import CheckboxOutlineIcon from './CheckboxOutlineIcon';

export { default as CheckboxCheckedIcon } from './CheckboxCheckedIcon';
export { default as CheckboxIndeterminateIcon } from './CheckboxIndeterminateIcon';
export { default as CheckboxOutlineIcon } from './CheckboxOutlineIcon';

export default {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxOutlineIcon,
};
