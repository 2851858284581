// @Generated

// AUTO-GENERATED FROM tailwind.config.js and scripts/generate-style-vars.js
// DO NOT MODIFY

const spacing = {
  '0': '0',
  1: '4px',
  px: '4px',
};

export default spacing;
