import React from 'react';

export default function ErrorIcon(props: SVGProps): JSX.Element {
  const { title = 'Error', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M12 2C6.48 2 2 6.475 2 12C2 17.525 6.48 22 12 22C17.52 22 22 17.525 22 12C22 6.475 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" />
    </svg>
  );
}
