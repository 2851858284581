/* eslint-disable import/prefer-default-export, no-nested-ternary */
import { GroupBase, StylesConfig } from 'react-select-v5';

import { colors } from '../../styles';
import { SHARED_STYLES } from './constants';

export const getSelectStyles = <
  Option extends { hoverContent?: string; [key: string]: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> => ({
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    color: 'white',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '0.25rem',
    backgroundColor: colors.marinaBlue20,
    borderColor: 'transparent',
    overflow: 'hidden',
  }),
  option: (base, { isFocused, isSelected, isDisabled }) => ({
    ...base,
    ...SHARED_STYLES,
    backgroundColor: isDisabled
      ? colors.marinaBlue20
      : isSelected
      ? colors.basaltGray30
      : isFocused
      ? colors.basaltGray40
      : colors.marinaBlue20,
    color: isSelected
      ? colors.buttonTextPrimary
      : isDisabled
      ? colors.basaltGray60
      : 'white',
    ':active': {
      ...base[':active'],
      ...SHARED_STYLES,
      backgroundColor: isDisabled ? colors.marinaBlue20 : colors.marinaBlue40,
      color: isDisabled ? colors.basaltGray60 : colors.buttonTextPrimary,
    },
    ':hover': {
      ...base[':hover'],
      ...SHARED_STYLES,
      color: isDisabled ? colors.basaltGray60 : 'white',
      backgroundColor: isDisabled ? colors.marinaBlue20 : colors.basaltGray40,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
  }),
});
