import React from 'react';

export default function EditIcon(props: SVGProps): JSX.Element {
  const { title = 'Edit', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...restProps}>
      <title>{title}</title>
      <path d="M0.75 13.8125V17.25H4.1875L14.3304 7.10711L10.8929 3.66961L0.75 13.8125ZM16.9796 4.45794C17.3371 4.10044 17.3371 3.51836 16.9796 3.16086L14.8392 1.02044C14.4817 0.662944 13.8996 0.662944 13.5421 1.02044L11.8646 2.69794L15.3021 6.13544L16.9796 4.45794Z" />
    </svg>
  );
}
