import type { ApolloError } from '@apollo/client';

const isUnauthorizedError = (error: ApolloError | undefined) => {
  if (!error) {
    return false;
  }

  const { networkError } = error;
  if (
    networkError
    && 'statusCode' in networkError
    && networkError.statusCode === 401
  ) {
    return true;
  }

  return false;
};

export default isUnauthorizedError;