import {
  FunctionType,
  LocalStorageContext,
  LocalStorageProvider,
} from 'providers';
import { useContext, useEffect, useMemo } from 'react';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, (newValue: T | FunctionType<T>) => void] => {
  const { getItem, setItem } = useContext(LocalStorageContext);

  useEffect(() => {
    const item = getItem<T>(key);

    if (item === undefined && initialValue !== undefined) {
      setItem<T>(key, initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => getItem<T>(key) ?? initialValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, getItem],
  );

  const setValue = (newValue: T | FunctionType<T>) => setItem(key, newValue);

  return [value, setValue];
};

export { LocalStorageProvider };
