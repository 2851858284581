import React from 'react';
// Components
import { Controller } from 'react-hook-form';

import type { FieldValues, FormOptions } from '../../_types';

// Types
import type { Props } from '../../../Input';
import TextArea from '../../../TextArea';

export type FormTextAreaProps<T extends FieldValues = FieldValues> = Omit<
  Props,
  'onBlur' | 'onChange' | 'value' | 'isError'
> &
  FormOptions<T>;

const FormTextArea = <T extends FieldValues = FieldValues>({
  name,
  control,
  helperText,
  defaultValue,
  rules,
  ...textAreaProps
}: FormTextAreaProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <TextArea
        {...textAreaProps}
        data-testid={name}
        helperText={error?.message ?? helperText}
        value={(value as string) ?? ''}
        onBlur={onBlur}
        onChange={onChange}
        isError={!!error}
      />
    )}
  />
);

export default FormTextArea;
