import React from "react"

export default function VerifiedIcon(props: SVGProps): JSX.Element {
  const { title = "Verified", ...restProps } = props

  return (
    <svg
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_183_23108)">
        <path d="M81.2502 62.0499L61.8502 49.6299L42.4502 62.0499L61.8502 74.4699L81.2502 62.0499Z" />
        <path d="M60.5498 67.9098L56.0098 63.3598L57.9098 61.4698L60.3498 63.9098L65.6998 57.3398L67.7798 59.0298L60.5498 67.9098Z" fill="#011300" />
        <path d="M101.49 80.65V49.33C103.39 46.55 104.5 43.19 104.5 39.58C104.5 30.01 96.7195 22.23 87.1495 22.23C79.0595 22.23 72.2595 27.81 70.3495 35.32H27.4695C25.4095 35.32 23.7295 37 23.7295 39.06V80.65H17.5195V83.88C17.5195 87.09 20.1295 89.69 23.3295 89.69H101.66C104.87 89.69 107.47 87.08 107.47 83.88V80.65H101.48H101.49ZM87.1495 24.73C95.3395 24.73 102 31.39 102 39.58C102 44.13 99.9395 48.2 96.7095 50.93C96.3495 45.96 92.2195 42.04 87.1595 42.04C82.0995 42.04 77.9595 45.96 77.5995 50.93C74.3695 48.2 72.3095 44.13 72.3095 39.58C72.3095 31.39 78.9695 24.73 87.1595 24.73H87.1495ZM26.2195 39.06C26.2195 38.37 26.7795 37.82 27.4595 37.82H69.8895C69.8295 38.4 69.7995 38.99 69.7995 39.58C69.7995 49.15 77.5795 56.93 87.1495 56.93C91.7295 56.93 95.8795 55.14 98.9895 52.23V80.66H26.2295V39.07L26.2195 39.06ZM104.97 83.88C104.97 85.71 103.48 87.19 101.66 87.19H23.3295C21.4995 87.19 20.0195 85.7 20.0195 83.88V83.15H104.98V83.88H104.97Z" />
        <path d="M87.1597 41.8102C90.5797 41.8102 93.3497 39.0402 93.3497 35.6302C93.3497 32.2202 90.5797 29.4502 87.1597 29.4502C83.7397 29.4502 80.9697 32.2202 80.9697 35.6302C80.9697 39.0402 83.7397 41.8102 87.1597 41.8102Z" />
        <path d="M62.5 123.75C28.73 123.75 1.25 96.27 1.25 62.5C1.25 28.73 28.73 1.25 62.5 1.25C96.27 1.25 123.75 28.73 123.75 62.5C123.75 96.27 96.27 123.75 62.5 123.75ZM62.5 3.75C30.1 3.75 3.75 30.11 3.75 62.5C3.75 94.89 30.1 121.25 62.5 121.25C94.9 121.25 121.25 94.89 121.25 62.5C121.25 30.11 94.89 3.75 62.5 3.75Z" />
      </g>
      <defs>
        <clipPath id="clip0_183_23108">
          <rect width="123" height="123" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  )
}
