import cn from 'classnames';
import React from 'react';

import styles from './index.css';

import { CheckboxWrapperProps } from '../../types';

const CheckboxWrapper = ({
  className = '',
  testId = '',
  ...restProps
}: CheckboxWrapperProps): JSX.Element => (
  <div
    className={cn({
      [styles.wrapper]: true,
      [className]: className,
    })}
    data-testid={testId}
    {...restProps}
  />
);

export default CheckboxWrapper;
