// Utils
import cn from 'classnames';
import React from 'react';

// CSS
import css from './index.css';

// Components
import { ChevronIcon } from '../Icons';
import Text from '../Text';

export interface BreadcrumbBaseProps {
  // Changes styles; usually used for the last breadcrumb (current/active page)
  isActive?: boolean;
  // Hide the chevron that comes before this breadcrumb
  isChevronHidden?: boolean;
}

export type BreadcrumbProps = BreadcrumbBaseProps &
  React.HTMLAttributes<HTMLDivElement>;

function Breadcrumb(
  {
    children,
    className = '',
    isActive = false,
    isChevronHidden = false,
    onClick,
    ...restProps
  }: BreadcrumbProps,
): React.ReactElement {
  const wrapperClasses = cn({
    [css.breadcrumb]: true,
    [css.activeBreadcrumb]: isActive,
    [className]: Boolean(className),
  });

  return (
    <div className={wrapperClasses} {...restProps}>
      {isChevronHidden ? null : (
        <ChevronIcon className={css.icon} direction="right" />
      )}
      <Text className={css.text} onClick={onClick} type="body" size="small">
        {children}
      </Text>
    </div>
  );
}

export default Breadcrumb;
