import React from 'react';

export default function NoImageIcon(props: SVGProps): JSX.Element {
  const { title = 'No Image', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 100"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M93.27 0a3.833 3.833 0 00-3.19 1.636L23.696 93.55a3.843 3.843 0 006.224 4.508l6.223-8.616h80.026a3.835 3.835 0 003.83-3.83V14.123a3.828 3.828 0 00-3.83-3.83H93.312l2.992-4.149A3.83 3.83 0 0093.272.001h-.001zM3.432 10.292a3.833 3.833 0 00-3.43 3.83v71.49a3.83 3.83 0 003.829 3.83h11.529a3.83 3.83 0 100-7.66h-7.7v-8.618L33.191 55.77l2.554 1.756a3.835 3.835 0 004.308-6.344l-4.707-3.23a3.833 3.833 0 00-2.554-.639 3.824 3.824 0 00-1.755.639L7.659 63.909V17.952h58.883a3.83 3.83 0 100-7.66H3.83a4.28 4.28 0 00-.4 0h.001zm84.335 7.66h24.574v37.459L91.834 37.978a3.826 3.826 0 00-2.832-.878 3.832 3.832 0 00-2.115.878l-29.68 24.934-1.277-.877 31.836-44.083zm-53.298 3.83c-5.595 0-10.213 4.618-10.213 10.213 0 5.595 4.618 10.212 10.213 10.212 5.595 0 10.213-4.617 10.213-10.212s-4.618-10.213-10.213-10.213zm0 7.66c1.455 0 2.553 1.097 2.553 2.553 0 1.455-1.098 2.553-2.553 2.553-1.455 0-2.553-1.098-2.553-2.553 0-1.456 1.098-2.554 2.553-2.554zm54.893 16.476l22.979 19.548v16.316H41.688l9.774-13.524 3.83 2.633a3.825 3.825 0 004.626-.24l29.443-24.733z" />
    </svg>
  );
}
