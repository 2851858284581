import React from 'react';

// Components
import ActionsWrapper, { Props } from '../../../shared/ActionsWrapper';
// Context
import useBannerContext from '../../useBannerContext';

const BannerActionsWrapper: React.FC<Props> = ({ children, ...restProps }) => {
  const { onClose, setHasActions } = useBannerContext();

  React.useEffect(() => {
    setHasActions(true);
    return (): void => setHasActions(false);
  }, []);

  return (
    <ActionsWrapper onClose={onClose} {...restProps}>
      {children}
    </ActionsWrapper>
  );
};

export default BannerActionsWrapper;
