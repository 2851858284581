import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

// Pretty print an enum for display use.
// Ex: `TRUSTED_DESTINATION_REMOVE` --> `Trusted Destination Remove`
export default function formatEnum(
  str: string,
  onlyFirstWordCapital = false,
): string {
  const lowerCaseStr = lowerCase(str);
  if (onlyFirstWordCapital) {
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  }
  return startCase(lowerCaseStr);
}
