import React from 'react';

export default function ArchiveIcon(props: SVGProps): JSX.Element {
  const { title = 'Archive', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path d="M13.697 3.483l-.924-1.12A1.014 1.014 0 0012 2H4c-.31 0-.587.143-.77.363l-.923 1.12a1.321 1.321 0 00-.307.85v8.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V4.333c0-.323-.113-.616-.303-.85zM8 11.667L4.333 8h2.334V6.667h2.666V8h2.334L8 11.667zM3.417 3.333l.543-.666h8l.623.666H3.417z" />
    </svg>
  );
}
