import React from 'react';

export default function ConfusedAstronautIcon(props: SVGProps): JSX.Element {
  const { title = 'ConfusedAstronaut', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 255 212"
      fill="none"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M130.155 100.695C122.028 95.9185 111.603 100.442 109.54 109.641C107.838 117.231 112.693 124.742 120.313 126.306L136.726 129.675C140.111 130.37 143.613 130.232 146.932 129.273L173.604 121.57C177.677 120.393 178.926 115.234 175.842 112.324C174.864 111.401 173.58 110.871 172.235 110.833L152.131 110.278C148.229 110.171 144.419 109.077 141.053 107.099L130.155 100.695Z"
        fill="#00254B"
      />
      <path
        d="M80.8432 138.349C87.9378 135.322 96.0407 139.464 97.7416 146.988C99.3284 154.007 94.6045 160.891 87.4847 161.936L61.5457 165.741C53.0315 166.99 44.6531 162.743 40.6279 155.137L36.0184 146.427C34.5521 143.657 35.0781 140.252 37.3122 138.053C40.4518 134.963 45.6192 135.452 48.1239 139.076L50.3599 142.311C53.1695 146.376 58.4514 147.902 62.9964 145.963L80.8432 138.349Z"
        fill="#00254B"
      />
      <path
        d="M77.3018 135.234C83.1912 129.236 92.1519 127.453 99.8886 130.741C115.336 137.306 116.729 158.659 102.267 167.176L73.94 183.858C72.4314 184.747 70.8602 185.525 69.2388 186.185L67.8224 186.762C56.8459 191.235 44.3222 189.567 34.899 182.378L21.5897 172.223C18.346 169.748 17.0053 165.503 18.2392 161.614C20.1264 155.666 27.0715 153.038 32.424 156.246L37.8039 159.472C43.6014 162.947 51.0254 161.997 55.761 157.174L77.3018 135.234Z"
        fill="#002F53"
      />
      <path
        d="M44.4355 140.996C45.5721 140.049 46.1364 138.581 45.9264 137.117C45.4505 133.799 41.4964 132.302 38.7318 134.197C38.0084 134.693 37.3131 135.135 36.7077 135.463C35.7763 135.967 34.2548 136.572 32.6035 137.165C28.7606 138.545 27.0188 143.293 30.0013 146.082C30.7671 146.798 31.5118 147.362 32.1522 147.617C34.6885 148.626 38.4568 145.922 39.0308 145.493C39.0825 145.454 39.13 145.415 39.1796 145.374L44.4355 140.996Z"
        fill="#00254B"
      />
      <path
        d="M33.1835 162.404C34.7219 160.671 35.2635 158.271 34.6178 156.046C33.2976 151.496 27.8174 149.704 24.4062 152.991C23.3973 153.963 22.4345 154.959 21.6303 155.911C19.9374 157.915 15.4321 161.223 11.1672 164.145C6.70353 167.203 6.18234 173.898 11.0446 176.271C12.4286 176.947 13.7594 177.413 14.856 177.463C19.042 177.65 24.625 171.977 25.3151 171.258C25.3629 171.209 25.4089 171.158 25.4548 171.106L33.1835 162.404Z"
        fill="#00254B"
      />
      <path
        d="M139.757 133.72C146.819 121.996 144.44 106.894 134.118 97.9071L132.595 96.5815C113.482 79.9417 83.5836 92.4155 81.9636 117.705L81.8257 119.859C81.4541 125.66 82.6863 131.451 85.3872 136.598L88.2998 142.149C92.6482 150.435 99.2868 158.119 108.521 159.637C111.071 160.057 113.411 160.008 114.929 159.033C119.051 156.383 131.371 143.52 136.234 138.374C137.577 136.953 138.748 135.395 139.757 133.72Z"
        fill="#00254B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.2579 37.7535C89.8135 37.2185 91.1821 36.4059 94.7968 37.1239C102.69 38.6918 109.85 42.714 115.128 46.4664C117.746 48.3278 119.851 50.087 121.294 51.3739C122.015 52.0165 122.568 52.5388 122.934 52.894C123.117 53.0716 123.254 53.2071 123.341 53.2948C123.385 53.3387 123.416 53.3705 123.435 53.3897L123.453 53.4087L123.454 53.4093C123.453 53.4088 123.453 53.4082 126.066 50.8948C128.68 48.3815 128.68 48.3807 128.679 48.38L128.673 48.3736L128.66 48.3607L128.62 48.3199C128.588 48.2862 128.542 48.2395 128.483 48.1806C128.366 48.0628 128.198 47.8961 127.982 47.6865C127.549 47.2675 126.923 46.6761 126.121 45.9609C124.518 44.5322 122.205 42.5995 119.33 40.5557C113.622 36.4977 105.501 31.8561 96.2098 30.0106C91.2325 29.0219 87.0963 29.7672 84.2277 32.5291C81.5321 35.1245 80.7555 38.7705 80.6027 41.7463C80.4451 44.8142 80.9125 47.8901 81.3786 50.1087C81.6157 51.2379 81.8623 52.1899 82.0515 52.8659C82.1463 53.2046 82.2273 53.4761 82.2863 53.6683C82.3158 53.7644 82.3399 53.8408 82.3576 53.896C82.3664 53.9236 82.3736 53.9459 82.3791 53.9628L82.386 53.9839L82.3894 53.9943C82.3898 53.9955 82.3902 53.9967 85.8327 52.8574C89.2753 51.7182 89.2756 51.7192 89.2759 51.7201L89.2756 51.7192L89.2658 51.6888C89.2561 51.6583 89.2401 51.6077 89.2188 51.5383C89.1762 51.3995 89.1125 51.1866 89.0354 50.9112C88.8809 50.359 88.6747 49.5639 88.476 48.6179C88.0703 46.6863 87.7334 44.2996 87.8454 42.1183C87.9622 39.8449 88.5293 38.455 89.2579 37.7535Z"
        fill="#004B7D"
      />
      <circle
        r="38.3336"
        transform="matrix(-1 0 0 1 145.233 66.8248)"
        fill="#00254B"
      />
      <path
        d="M133.71 68.9197C133.769 76.704 144.215 90.1856 144.215 90.1856C144.215 90.1856 156.189 105.596 162.503 102.712C173.957 97.4799 182.748 86.4584 184.913 72.672C188.272 51.2855 174.415 31.3441 153.962 28.1318C148.206 27.2279 133.651 61.1355 133.71 68.9197Z"
        fill="#004B7D"
      />
      <path
        d="M163.857 58.4732C170.47 66.9532 169.994 88.3484 169.994 88.3484C174.641 84.6816 178.469 77.527 179.823 68.9058C181.923 55.5317 177.276 43.6927 169.444 42.4624C167.24 42.1163 157.243 49.9933 163.857 58.4732Z"
        fill="#126094"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.124 99.9228C123.55 92.9372 139.152 100.717 141.29 114.812C141.718 117.64 141.555 120.553 140.803 123.313L138.097 133.238C137.787 134.379 137.347 135.47 136.791 136.494C136.337 135.445 135.978 134.354 135.722 133.232L133.229 122.354C131.676 115.575 124.041 112.196 117.979 115.604C112.89 118.465 111.148 124.951 114.119 129.977L121.416 142.321C121.863 143.078 122.372 143.794 122.935 144.462C121.022 144.405 119.111 143.987 117.306 143.2L115.839 142.56C112.139 140.947 109.079 138.151 107.141 134.611L103.235 127.478C97.8712 117.683 101.389 105.395 111.124 99.9228Z"
        fill="#004B7D"
      />
      <path
        d="M139.606 118.6C137.12 108.17 125.159 103.227 116.035 108.858C108.55 113.478 106.306 123.335 111.052 130.74L119.279 143.576C122.423 148.481 126.898 152.392 132.18 154.851L156.543 166.192C158.767 167.228 161.411 166.577 162.9 164.629C164.447 162.605 164.31 159.762 162.576 157.897L148.848 143.128C145.855 139.909 143.747 135.97 142.728 131.695L139.606 118.6Z"
        fill="#399E9B"
      />
      <path
        d="M250.82 23.8479C250.653 22.7379 249.065 22.703 248.85 23.8046L248.324 26.489C248.292 26.6544 248.218 26.809 248.11 26.9383L246.278 29.1307C245.962 29.5083 245.968 30.0593 246.292 30.4299L248.086 32.4836C248.209 32.6252 248.291 32.7987 248.32 32.9843L248.744 35.6475C248.921 36.7588 250.514 36.7762 250.716 35.6691L251.202 32.9933C251.237 32.8021 251.326 32.6251 251.46 32.4841L253.38 30.4594C253.74 30.0798 253.746 29.4869 253.395 29.0994L251.458 26.9652C251.326 26.8191 251.239 26.6371 251.21 26.442L250.82 23.8479Z"
        fill="#A6C0D9"
      />
      <path
        d="M211.669 5.63752C211.504 4.54477 209.941 4.51046 209.73 5.59496L209.713 5.67994C209.681 5.845 209.607 5.99925 209.5 6.12839L208.46 7.37446C208.145 7.75176 208.151 8.30182 208.474 8.67218L209.477 9.82256C209.599 9.96248 209.679 10.1339 209.708 10.3172C209.883 11.4165 211.461 11.4226 211.66 10.3273C211.694 10.138 211.783 9.96227 211.915 9.82254L212.976 8.70168C213.335 8.32225 213.341 7.73041 212.991 7.34318L211.912 6.15193C211.782 6.00821 211.697 5.82925 211.669 5.63752Z"
        fill="#A6C0D9"
      />
      <ellipse
        rx="7.48659"
        ry="9.17711"
        transform="matrix(-0.983057 -0.1833 -0.1833 0.983057 134.119 56.498)"
        fill="#F96D4C"
      />
      <ellipse
        rx="4.45441"
        ry="4.32882"
        transform="matrix(-0.983057 -0.1833 -0.1833 0.983057 166.998 62.648)"
        fill="#A6C0D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.035 108.858C125.159 103.227 137.12 108.17 139.606 118.6L142.728 131.694C143.747 135.97 145.855 139.909 148.848 143.128L162.576 157.897C164.31 159.762 164.447 162.605 162.9 164.628C161.411 166.577 158.767 167.227 156.543 166.192L132.18 154.851C131.921 154.73 131.664 154.606 131.41 154.479C132.598 151.82 132.191 148.254 130.087 145.283C127.158 141.147 122.049 139.731 118.674 142.12C118.599 142.174 118.524 142.229 118.452 142.285L111.052 130.74C106.306 123.335 108.55 113.478 116.035 108.858Z"
        fill="#002F53"
      />
      <path
        d="M180.938 109.226C180.276 108.561 180.77 105.786 180.404 104.27C180.171 103.302 178.934 102.919 177.961 103.134C175.692 103.636 172.871 106.677 172.362 107.242C172.306 107.304 172.257 107.363 172.206 107.429C171.789 107.966 169.785 110.62 169.48 112.439C169.191 114.155 170.856 117.018 171.382 117.868C171.483 118.032 171.595 118.186 171.723 118.33C172.817 119.562 178.565 125.466 184.769 121.023C191.667 116.084 183.07 111.369 180.938 109.226Z"
        fill="#00254B"
      />
      <path
        d="M165.847 158.013C165.746 156.939 167.464 156.074 168.399 154.647C169.207 153.412 168.396 151.787 166.981 151.37C164.82 150.732 162.279 151.331 160.789 151.817C159.938 152.095 159.044 152.272 158.176 152.489C156.937 152.798 155.034 153.602 153.313 155.687C151.164 158.292 150.863 161.133 150.845 162.375C150.839 162.802 150.929 163.218 151.084 163.616C152.086 166.188 156.527 175.957 164.831 171.862C174.355 167.165 166.179 161.543 165.847 158.013Z"
        fill="#00254B"
      />
      <path
        d="M83.7709 129.435C82.0233 126.56 77.8181 125.66 75.3152 127.908C73.5901 129.458 73.2349 132.017 74.5499 133.927C77.3956 138.061 82.6443 145.232 88.3297 150.749C94.3782 156.617 101.851 160.506 105.646 162.256C107.198 162.971 108.978 162.878 110.473 162.05C113.073 160.61 116.81 158.384 116.207 157.801C116.167 157.761 116.117 157.74 116.066 157.715C114.873 157.111 98.449 148.702 90.7989 139.29C87.6322 135.393 85.3593 132.049 83.7709 129.435Z"
        fill="#004B7D"
      />
      <path
        d="M163.69 36.3807C166.869 36.984 169.148 33.2809 167.139 30.7447C167.137 30.7426 167.137 30.7429 167.135 30.7407C167.08 30.6724 163.229 25.9266 152.367 25.1122C142.555 24.3765 138.528 27.6775 137.801 28.3669C137.715 28.4487 137.761 28.41 137.676 28.4926C135.264 30.8196 138.468 34.8538 141.796 34.4571C144.634 34.119 147.831 33.947 150.753 34.2743C154.284 34.6699 159.303 35.5483 163.69 36.3807Z"
        fill="#399E9B"
      />
      <path
        d="M108.916 91.0405C111.877 83.8342 111.047 75.6339 106.728 69.1499C96.8456 54.3155 74.6937 55.4471 66.3745 71.2114L49.9382 102.357C44.0204 113.57 47.2548 127.429 57.525 134.865C68.2901 142.659 83.5266 141.073 91.3504 130.329C92.5313 128.707 93.4585 127.329 93.9491 126.397C95.6551 123.156 103.654 103.845 108.916 91.0405Z"
        fill="#002F53"
      />
      <path
        d="M234.814 56.048C229.586 55.6825 224.976 59.6283 224.606 64.9221C224.51 66.2946 225.548 67.4176 226.854 67.5089C228.161 67.6003 229.35 66.5671 229.441 65.2602C229.62 62.7116 231.857 60.7664 234.406 60.9446C237.085 61.132 238.913 63.164 238.735 65.7126L238.721 65.9086C238.621 67.3463 237.991 70.7172 233.301 71.1117C232.111 71.2255 231.127 72.1415 231.041 73.3832L230.579 79.9834C230.483 81.356 231.521 82.4789 232.828 82.5703C234.135 82.6616 235.323 81.6284 235.415 80.3215L235.739 75.6815C242.015 74.2162 243.365 68.9909 243.57 66.0503C244.067 60.8319 240.042 56.4137 234.814 56.0481L234.814 56.048Z"
        fill="#F96D4C"
      />
      <path
        d="M232.753 85.5204C230.989 85.397 229.516 86.7387 229.397 88.4377C229.279 90.1366 230.616 91.6748 232.315 91.7936C234.014 91.9125 235.552 90.5753 235.671 88.8763C235.789 87.1774 234.452 85.6392 232.753 85.5204Z"
        fill="#F96D4C"
      />
    </svg>
  );
}
