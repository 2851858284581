import React from 'react';

export default function PieChartIcon(props: SVGProps): JSX.Element {
  const { title = 'Pie chart icon', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M16.245 7.755A5.997 5.997 0 0012 6v6l-4.245 4.245a5.996 5.996 0 008.485 0 6.007 6.007 0 00.005-8.49zM12 2C6.475 2 2 6.475 2 12s4.475 10 10 10 10-4.48 10-10c0-5.525-4.475-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </svg>
  );
}
