import { useCallback, useMemo, useState } from 'react';

export const DEFAULT_PAGE_SIZE = 10;

type Cursor = string | null;

export type PageInfo = {
  startCursor?: string;
  endCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
};

export type PaginationVariables = {
  first?: number;
  after?: Cursor;
  last?: number;
  before?: Cursor;
};

const DEFAULT_PAGINATION_VARIABLES: PaginationVariables = {
  first: DEFAULT_PAGE_SIZE,
};

export type TablePagination = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
};

export type Params = {
  // follwing the spec defined in https://relay.dev/graphql/connections.htm
  specCompliantPrevNext?: boolean;
};

export type ParamsAndPaginationVariables = PaginationVariables & Params;

export type CursorBasedPagination = {
  // Variables to be passed down to the query
  paginationVariables: PaginationVariables;
  // Clear the pagination variables for the query and table
  resetPagination: () => void;
  // Variables to be passed down to the table
  tablePagination: TablePagination;
  // Function to update the pagination when new
  // pageInfo is returned from the query
  updatePagination: (pageInfo: PageInfo) => void;
};

type ResolvePrevNextArgs = {
  specCompliantPrevNext: boolean;
  isForward: boolean;
  pageInfo: PageInfo;
  resolveNext: boolean;
};

function resolvePrevNext(
  {
    specCompliantPrevNext,
    isForward,
    pageInfo,
    resolveNext,
  }: ResolvePrevNextArgs,
) {
  if (!specCompliantPrevNext) {
    if (resolveNext) {
      return !!pageInfo.hasNextPage;
    }
    return !!pageInfo.hasPreviousPage;
  }
  if (resolveNext) {
    if (isForward) {
      return !!pageInfo.hasNextPage;
    }
    return !!pageInfo.hasPreviousPage;
  }
  if (isForward) {
    return !!pageInfo.hasPreviousPage;
  }
  return !!pageInfo.hasNextPage;
}

function useCursorBasedPagination(
  params: ParamsAndPaginationVariables = DEFAULT_PAGINATION_VARIABLES,
): CursorBasedPagination {
  const {
    specCompliantPrevNext,
    ...initialPagination
  }: ParamsAndPaginationVariables = params;

  const [paginationVariables, setPaginationVariables] =
    useState<PaginationVariables>(initialPagination);

  const pageSize: number = useMemo(
    () =>
      initialPagination.first || initialPagination.last || DEFAULT_PAGE_SIZE,
    [initialPagination],
  );
  const [isForward, setForward] = useState<boolean>(true);

  const [pageInfo, setPageInfo] = useState<PageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const tablePaginationOnLoading = useCallback(() => {
    // Prevent double clicking when we don't know if the next page exists.
    setPageInfo((prevState) => ({
      ...prevState,
      hasNextPage: false,
      hasPreviousPage: false,
    }));
  }, [setPageInfo]);

  const onNext = useCallback(() => {
    setForward(true);
    const { endCursor } = pageInfo;
    tablePaginationOnLoading();
    setPaginationVariables({
      first: pageSize,
      after: endCursor,
    });
  }, [pageInfo, tablePaginationOnLoading, setPaginationVariables, pageSize]);

  const onPrevious = useCallback(() => {
    const { startCursor } = pageInfo;
    setForward(false);

    tablePaginationOnLoading();
    setPaginationVariables({
      last: pageSize,
      before: startCursor,
    });
  }, [pageInfo, tablePaginationOnLoading, setPaginationVariables, pageSize]);

  const updatePagination = useCallback(
    (newPageInfo: PageInfo) => {
      setPageInfo(newPageInfo);
    },
    [setPageInfo],
  );

  const resetPagination = useCallback(() => {
    tablePaginationOnLoading();
    setPaginationVariables({ first: pageSize });
  }, [tablePaginationOnLoading, setPaginationVariables, pageSize]);

  return {
    paginationVariables,
    resetPagination,
    tablePagination: {
      hasNextPage: resolvePrevNext({
        specCompliantPrevNext: !!specCompliantPrevNext,
        isForward,
        pageInfo,
        resolveNext: true,
      }),
      hasPreviousPage: resolvePrevNext({
        specCompliantPrevNext: !!specCompliantPrevNext,
        isForward,
        pageInfo,
        resolveNext: false,
      }),
      onNext,
      onPrevious,
    },
    updatePagination,
  };
}

export default useCursorBasedPagination;
