import React from 'react';

// Types
import type { StepContextProps } from './_types';

// Constants
import { STEP_VARIANTS } from './_constants';

export const StepContext = React.createContext<StepContextProps>({
  variant: STEP_VARIANTS.incomplete,
});

export default (): StepContextProps => React.useContext(StepContext);
