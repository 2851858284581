import React from 'react';

import TooltipInner from './_components/TooltipInner';
// Building blocks
import TooltipWrapper from './_components/TooltipWrapper';

// Shared Types
import type { Background, Position } from './_types';

// Shared Constants
import { DEFAULT_BACKGROUND, DEFAULT_POSITION } from './_constants';

export type Props = {
  background?: Background;
  children: string | JSX.Element;
  position?: Position;
  title: string | JSX.Element;
  showOnlyIfTruncated?: boolean; // if true, tooltips will only show if
  childId?: string; // child element is truncated
  // All other props
  [x: string]: any;
};

const isChildTruncated = (
  parentRef: React.RefObject<HTMLDivElement>,
  childId: string
) => {
  const child = parentRef?.current?.querySelector(`#${childId}`) as HTMLElement;

  return child && child.offsetWidth < child.scrollWidth;
};

const Tooltip = ({
  background = DEFAULT_BACKGROUND,
  children,
  position = DEFAULT_POSITION,
  title,
  showOnlyIfTruncated = false,
  childId,
  ...restProps
}: Props): JSX.Element => {
  const [isTruncated, setIsTruncated] = React.useState(showOnlyIfTruncated);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!wrapperRef.current || !showOnlyIfTruncated || !childId)
      return undefined;

    const resizeObserver = new ResizeObserver(() => {
      if (isChildTruncated(wrapperRef, childId)) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    });

    resizeObserver.observe(wrapperRef.current);

    return () => resizeObserver.disconnect();
  }, [wrapperRef, showOnlyIfTruncated, childId]);

  return (
    <TooltipWrapper
      ref={wrapperRef}
      background={background}
      position={position}
      {...restProps}
    >
      {children}
      {(!showOnlyIfTruncated || isTruncated) && (
        <TooltipInner background={background} position={position}>
          {title}
        </TooltipInner>
      )}
    </TooltipWrapper>
  );
};

export { default as TooltipWrapper } from './_components/TooltipWrapper';
export { default as TooltipInner } from './_components/TooltipInner';
export default Tooltip;
