import React from 'react';

export default function VPNLockIcon(props: SVGProps): JSX.Element {
  const { title = 'VPNLock', ...restProps } = props;

  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M22.5 4.21973V3.71973C22.5 2.33973 21.38 1.21973 20 1.21973C18.62 1.21973 17.5 2.33973 17.5 3.71973V4.21973C16.945 4.21973 16.5 4.66973 16.5 5.21973V9.21973C16.5 9.76973 16.945 10.2197 17.5 10.2197H22.5C23.055 10.2197 23.5 9.76973 23.5 9.21973V5.21973C23.5 4.66973 23.055 4.21973 22.5 4.21973ZM21.7 4.21973H18.3V3.71973C18.3 2.77973 19.06 2.01973 20 2.01973C20.94 2.01973 21.7 2.77973 21.7 3.71973V4.21973ZM19.425 12.2197C19.465 12.5497 19.5 12.8797 19.5 13.2197C19.5 15.2997 18.7 17.1897 17.395 18.6147C17.14 17.8047 16.39 17.2197 15.5 17.2197H14.5V14.2197C14.5 13.6697 14.05 13.2197 13.5 13.2197H7.5V11.2197H9.5C10.05 11.2197 10.5 10.7697 10.5 10.2197V8.21973H12.5C13.605 8.21973 14.5 7.32473 14.5 6.21973V3.67973C13.555 3.37973 12.545 3.21973 11.5 3.21973C5.975 3.21973 1.5 7.69473 1.5 13.2197C1.5 18.7447 5.975 23.2197 11.5 23.2197C17.025 23.2197 21.5 18.7447 21.5 13.2197C21.5 12.8797 21.48 12.5497 21.45 12.2197H19.425ZM10.5 21.1497C6.555 20.6597 3.5 17.2997 3.5 13.2197C3.5 12.6047 3.575 12.0047 3.71 11.4297L8.5 16.2197V17.2197C8.5 18.3247 9.395 19.2197 10.5 19.2197V21.1497Z" />
    </svg>
  );
}
