// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

import Button from '../../Button';
// Components
import { CloseIcon } from '../../Icons';
// Types & Hooks
import { CloseActionEnum } from '../_constants';
import useModalContext from '../useModalContext';

type Props = {
  className?: string;
  [x: string]: any;
};

function CloseButton({ className = '', ...restProps }: Props): JSX.Element {
  const { onClose } = useModalContext();

  const classes = cn({
    [styles.closeButton]: true,
    [className]: Boolean(className),
  });

  return (
    <Button
      className={classes}
      type="tertiary"
      onClick={(): void => onClose(CloseActionEnum.Icon)}
      Icon={CloseIcon}
      {...restProps}
    />
  );
}

export default CloseButton;
