import React from 'react';

function FileIcon(props: SVGProps): JSX.Element {
  const { title = 'File', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M4.99992 1.66667C4.07909 1.66667 3.34159 2.4125 3.34159 3.33333L3.33325 16.6667C3.33325 17.5875 4.07075 18.3333 4.99158 18.3333H14.9999C15.9208 18.3333 16.6666 17.5875 16.6666 16.6667V6.66667L11.6666 1.66667H4.99992ZM10.8333 7.5V2.91667L15.4166 7.5H10.8333Z" />
    </svg>
  );
}

export default FileIcon;
