// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from './index.css';

export const TableHeadCell: CommonComponent<Record<string, any>, 'th'> = ({
  children,
  className = '',
  ...restProps
}) => {
  const classes = cn({
    [css.tableHeadCell]: true,
    [css.tableHeadTitle]: true,
    [className]: Boolean(className),
  });

  return (
    <th className={classes} {...restProps}>
      {children}
    </th>
  );
};

export const TableBodyCell: CommonComponent<Record<string, any>, 'td'> = ({
  children,
  className = '',
  ...restProps
}) => {
  const classes = cn({
    [css.tableBodyText]: true,
    [className]: Boolean(className),
  });

  return (
    <td className={classes} {...restProps}>
      {children}
    </td>
  );
};

type BodyCellProps = { type?: 'body' } & React.ComponentPropsWithoutRef<'td'>;
type HeadCellProps = { type: 'head' } & React.ComponentPropsWithoutRef<'th'>;
type CellProps = BodyCellProps | HeadCellProps;

const element = {
  head: TableHeadCell,
  body: TableBodyCell,
};

const TableCell = ({
  children,
  type = 'body',
  ...props
}: CellProps): JSX.Element => {
  const Cell = element[type];
  return <Cell {...props}>{children}</Cell>;
};

export default TableCell;
