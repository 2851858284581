import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../index.css';

// Utils
import { InputContext } from '../useInputContext';

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  error?: boolean;
  disabled?: boolean;
};

const InputContainer: React.FC<ContainerProps> = ({
  error = false,
  disabled = false,
  children,
  className = '',
  ...restProps
}) => (
  <InputContext.Provider
    value={{ isBeingUsedWithBuildingBlocks: true, error, disabled }}
  >
    <div className={cn(styles.container, className)} {...restProps}>
      {children}
    </div>
  </InputContext.Provider>
);

export default InputContainer;
