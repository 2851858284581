import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

type WrapperProps = {
  children: string | JSX.Element | any[];
  className?: string;
  // All other props
  [x: string]: any;
};

const TooltipWrapper = React.forwardRef(
  (
    { children, className = '', ...restProps }: WrapperProps,
    ref: any
  ): JSX.Element => (
    <div
      ref={ref}
      className={classnames([className, styles.tooltipWrapper])}
      data-testid="tooltip-wrapper"
      {...restProps}
    >
      {children}
    </div>
  )
);

TooltipWrapper.displayName = 'TooltipWrapper';
export default TooltipWrapper;
