import React from 'react';
// Components
import { components } from 'react-select-v5';
// Types
import type { GroupBase, MultiValueRemoveProps } from 'react-select-v5';

// Styles
import styles from './index.css';

import { CloseIcon } from '../../../Icons';

const MultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  innerProps,
  ...restProps
}: MultiValueRemoveProps<Option, IsMulti, Group>): JSX.Element => (
  <components.MultiValueRemove
    innerProps={{
      ...innerProps,
      className: styles.multiValueRemoveContainer,
    }}
    {...restProps}
  >
    <CloseIcon />
  </components.MultiValueRemove>
);

export default MultiValueRemove;
