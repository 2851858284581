// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

type Props = {
  className?: string;
  // All other props
  [x: string]: any;
};

const Footer = ({
  children,
  className = '',
  ...restProps
}: Props): React.ReactElement => {
  const classes = cn({
    [styles.footer]: true,
    [className]: Boolean(className),
  });
  return (
    <footer data-testid="card-footer" className={classes} {...restProps}>
      {children}
    </footer>
  );
};

export default Footer;
