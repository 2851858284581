import cn from 'classnames';
import React from 'react';

// Styles
import * as css from './index.css';

import TableCell from './TableCell';
// Components
import TableRow from './TableRow';

type Props = {
  numberOfColumns: number;
  rowClassName?: string;
  cellClassName?: string;
};

const InTableContainer: CommonComponent<Props, 'td'> = (
  {
    rowClassName = '',
    cellClassName = '',
    children,
    numberOfColumns,
    ...restProps
  },
) => {
  const cellClass = cn({
    [css.centerText]: true,
    [cellClassName]: true,
  });

  return (
    <TableRow className={rowClassName}>
      <TableCell className={cellClass} colSpan={numberOfColumns} {...restProps}>
        {children}
      </TableCell>
    </TableRow>
  );
};

export default InTableContainer;
