import React from 'react';

export default function SettingsIcon(props: SVGProps): JSX.Element {
  const { title = 'Settings', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...restProps}>
      <title>{title}</title>
      <path d="M15.1916 9.81249C15.225 9.54582 15.25 9.27499 15.25 8.99999C15.25 8.72499 15.225 8.45416 15.1916 8.18749L16.9541 6.80832C17.1125 6.68332 17.1583 6.45832 17.0541 6.27499L15.3875 3.38749C15.2833 3.20832 15.0666 3.13332 14.8791 3.20832L12.8041 4.04582C12.375 3.71666 11.9041 3.43749 11.3958 3.22499L11.0833 1.01666C11.0458 0.820823 10.875 0.666656 10.6666 0.666656H7.33331C7.12498 0.666656 6.95415 0.820823 6.92081 1.01666L6.60831 3.22499C6.09998 3.43749 5.62915 3.71249 5.19998 4.04582L3.12498 3.20832C2.93748 3.13749 2.72081 3.20832 2.61665 3.38749L0.949978 6.27499C0.845812 6.45416 0.891645 6.67916 1.04998 6.80832L2.80831 8.18749C2.77498 8.45416 2.74998 8.72499 2.74998 8.99999C2.74998 9.27499 2.77498 9.54582 2.80831 9.81249L1.04998 11.1917C0.891645 11.3167 0.845812 11.5417 0.949978 11.725L2.61665 14.6125C2.72081 14.7917 2.93748 14.8667 3.12498 14.7917L5.19998 13.9542C5.62915 14.2833 6.09998 14.5625 6.60831 14.775L6.92081 16.9833C6.95415 17.1792 7.12498 17.3333 7.33331 17.3333H10.6666C10.875 17.3333 11.0458 17.1792 11.0791 16.9833L11.3916 14.775C11.9 14.5625 12.3708 14.2875 12.8 13.9542L14.875 14.7917C15.0625 14.8625 15.2791 14.7917 15.3833 14.6125L17.05 11.725C17.1541 11.5458 17.1083 11.3208 16.95 11.1917L15.1916 9.81249ZM8.99998 11.9167C7.38748 11.9167 6.08331 10.6125 6.08331 8.99999C6.08331 7.38749 7.38748 6.08332 8.99998 6.08332C10.6125 6.08332 11.9166 7.38749 11.9166 8.99999C11.9166 10.6125 10.6125 11.9167 8.99998 11.9167Z" />
    </svg>
  );
}
