// Utils
import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

// Styles
import styles from './index.css';

import Text, { Props as TextProps } from '../../Text';

const Header: React.FC<PropsWithChildren<TextProps>> = (
  { className = '', children, ...restProps },
) => {
  const classes = cn({
    [styles.header]: true,
    [className]: Boolean(className),
  });

  return (
    <Text type="heading" size="medium" className={classes} {...restProps}>
      {children}
    </Text>
  );
};

export default Header;
