import * as React from 'react';

type Props = React.HTMLAttributes<SVGElement>;

const DownloadKeyIcon: React.FunctionComponent<Props> = (props) => {
  const { title = 'DownloadKey', ...restProps } = props;

  return (
    <svg viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 0V20.3787L27.4393 12.4395L29.5606 14.5608L20.1213 24.0001C18.9497 25.1717 17.0502 25.1717 15.8786 24.0001L6.43927 14.5608L8.56059 12.4395L16.5 20.3789V0H19.5ZM17.9999 21.8788L17.8711 21.75H18.1287L17.9999 21.8788Z"
      />
      <path d="M3 28.5V24H0V28.5C0 30.9853 2.01472 33 4.5 33H31.5C33.9853 33 36 30.9853 36 28.5V24H33V28.5C33 29.3284 32.3284 30 31.5 30H4.5C3.67157 30 3 29.3284 3 28.5Z" />
    </svg>
  );
};

export default DownloadKeyIcon;
