// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from './index.css';

export type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  // All other props
  [x: string]: any;
};

const HeaderContent = (
  { children, className = '', ...restProps }: Props,
): React.ReactElement => {
  const classes = cn({
    [css.headerContent]: true,
    [className]: Boolean(className),
  });

  return (
    <header
      data-testid="accordion-header-content"
      className={classes}
      {...restProps}
    >
      {children}
    </header>
  );
};

export default HeaderContent;
