import React from 'react';

export default function EmptyPersonIcon(props: SVGProps): JSX.Element {
  const { title = 'Empty person icon', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 54C13.5 47.7868 18.5368 42.75 24.75 42.75H47.25C53.4632 42.75 58.5 47.7868 58.5 54V58.5C58.5 60.9853 56.4853 63 54 63H18C15.5147 63 13.5 60.9853 13.5 58.5V54ZM24.75 47.25C21.0221 47.25 18 50.2721 18 54V58.5H54V54C54 50.2721 50.9779 47.25 47.25 47.25H24.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 13.5C31.0294 13.5 27 17.5294 27 22.5C27 27.4706 31.0294 31.5 36 31.5C40.9706 31.5 45 27.4706 45 22.5C45 17.5294 40.9706 13.5 36 13.5ZM22.5 22.5C22.5 15.0442 28.5442 9 36 9C43.4558 9 49.5 15.0442 49.5 22.5C49.5 29.9558 43.4558 36 36 36C28.5442 36 22.5 29.9558 22.5 22.5Z"
      />
    </svg>
  );
}
