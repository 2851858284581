import cx from 'classnames';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import css from './index.css';

import { DrawerProps } from './types';

import { CloseIcon } from '../Icons';

const Drawer: React.FC<DrawerProps> = (
  {
    children,
    onClose,
    open,
    closeIcon = <CloseIcon className={css.closeIcon} />,
    drawerStyles,
    footer,
    title = <></>,
    closable = true,
    isMaskCloseable = true,
    wrapperClass = '',
    width,
    fontSize = '14px',
    noTitle = false,
    zIndex = 98,
    onOpen = noop,
    closeOnEscape = true,
  },
) => {
  const [mounted, setMounted] = useState(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const [wrapperStyles, setWrapperStyles] = useState<any>();
  const $el = window.document.getElementById('drawer-root');

  const handleOnClose = (
    isMask = false,
    event: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
  ) => {
    if (closable && ((isMask && isMaskCloseable) || !isMask)) {
      onClose(event);
    }
  };

  useEffect(() => {
    if(closeOnEscape) {
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          handleOnClose(false, event);
        }
      })
    }

    return document.removeEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          handleOnClose(false, event);
        }
      })
  }, [])

  // When the drawer opens we want to "mount" it and set the drawer closed styles
  // After a few ms we want to apply the "open" style to slide it in
  // When the drawer closes we want to set the closed styles so it slides out
  // Then set mounted to false to destory the component.
  useEffect(() => {
    if (open) {
      clearTimeout(timeoutID);
      onOpen();
      setMounted(true);
      setWrapperStyles(
        cx({
          [css.drawerWrapper]: true,
          [css.drawerWrapperClosed]: true,
          [wrapperClass]: true,
        }),
      );

      setTimeout(() => {
        setWrapperStyles(
          cx({
            [css.drawerWrapper]: true,
            [css.drawerWrapperOpen]: true,
            [wrapperClass]: true,
          }),
        );
      }, 50);
    } else {
      setWrapperStyles(
        cx({
          [css.drawerWrapper]: true,
          [css.drawerWrapperClosed]: true,
          [wrapperClass]: true,
        }),
      );

      setTimeoutID(setTimeout(() => {
        setMounted(false);
      }, 450));
    }
  }, [open]);

  useEffect(() => {
    if (open) {
        document.body.classList.add(css.noScroll);
    } else {
        document.body.classList.remove(css.noScroll);
    }
    // Cleanup on unmount
    return () => document.body.classList.remove(css.noScroll);
  }, [open]);

  if (!mounted) {
    return <></>;
  }

  if (!$el) {
    console.error(
      "No drawer root detected, please create a div with an id of 'drawer-root'",
    );
    return <></>;
  }

  return ReactDOM.createPortal(
    <div className={css.drawerContainer} data-testid="drawerContainer">
      <div
        data-testid="drawerMask"
        className={css.mask}
        onClick={(event) => handleOnClose(true, event)}
        aria-hidden
        style={{ zIndex: zIndex - 1 }}
      />
      <div className={wrapperStyles} style={{ width, zIndex, fontSize }} data-testid="drawerWrapper">
        <div className={css.contentWrapper} style={drawerStyles?.contentWrapper ? drawerStyles?.contentWrapper : undefined} data-testid="contentWrapper">
          {!noTitle ? (
            <div className={css.drawerHeader} style={drawerStyles?.header ? drawerStyles.header : undefined} data-testid="headerWrapper">
              {title}
              <div
                onClick={(event) => handleOnClose(false, event)}
                role="button"
                tabIndex={0}
                aria-label="close"
                className={css.closeWrapper}
                onKeyPress={(event) => handleOnClose(false, event)}
                data-testid="closeBtn"
              >
                {closeIcon}
              </div>
            </div>
          ) : null}
          <div className={css.bodyContent} style={drawerStyles?.body ? drawerStyles.body : undefined} data-testid="bodyWrapper">
            {children}
          </div>
          {footer ? (
            <div className={css.footerContent} style={drawerStyles?.footer ? drawerStyles.footer : undefined} data-testid="footerWrapper">
              {footer}
            </div>
          ) : null}
        </div>
      </div>
    </div>,
    $el,
  );
};

export default Drawer;
