import React from 'react';

// Components

/* eslint-disable import/no-cycle */
import Step from './_components/StepTrackerStep';
import StepTrackerWrapper from './_components/StepTrackerWrapper';

// Types
import type { SimpleStepTrackerProps, StepProps } from './_types';

const StepTracker: React.FC<SimpleStepTrackerProps> = ({
  steps,
  ...restProps
}) => {
  if (steps.length === 0) {
    return null;
  }

  return (
    <StepTrackerWrapper {...restProps}>
      {steps.map((props: StepProps, index: number) => (
        <Step key={`${props.title} ${index}`} {...props} />
      ))}
    </StepTrackerWrapper>
  );
};

StepTracker.displayName = 'StepTracker';

export { default as useStepContext } from './useStepContext';
export default StepTracker;
