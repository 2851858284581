import React from 'react';

export default function HomeIcon(props: SVGProps): JSX.Element {
  const { title = 'Home', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path d="M6.5 13V8.5H9.5V13H13.25V7H15.5L8 0.25L0.5 7H2.75V13H6.5Z" />
    </svg>
  );
}
