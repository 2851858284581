import React from 'react';

function InfoIcon(props: SVGProps): JSX.Element {
  const { title = 'InfoOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M12 2C6.475 2 2 6.475 2 12s4.475 10 10 10 10-4.475 10-10S17.525 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </svg>
  );
}

export default InfoIcon;
