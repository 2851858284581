// eslint-disable-next-line @next/next/no-document-import-in-page
import { Head, Html, Main, NextScript } from 'next/document';
import Script from 'next/script';

export const CSP = {
  nonce: 'uf5sMsDSkkhDv9MKiB2NQGK8gmIgryv6CjOIW9/8DJXNo5dy5QM=',
};

export default function Document() {
  const originUrl =
    (typeof window !== 'undefined' && window.location.origin) || '';

  return (
    <Html>
      <Head nonce={CSP.nonce} />
      <body>
        <Main />
        <NextScript nonce={CSP.nonce} />
        <Script src={`${originUrl}/env.js`} strategy="beforeInteractive" />
      </body>
    </Html>
  );
}
