// Utils
import cn from 'classnames';
import * as React from 'react';

// Styles
import css from '../index.css';

// Types
import type { DateTimePickerDOMProps } from '../_types';

const DateTimePickerWrapper: React.FC<DateTimePickerDOMProps> = ({
  className = '',
  ...restProps
}) => (
  <div className={cn([className, css.datePickerContainer])} {...restProps} />
);

DateTimePickerWrapper.displayName = 'DateTimePickerWrapper';

export default DateTimePickerWrapper;
