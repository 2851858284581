// Utils
import copyToClipboard from 'copy-to-clipboard';
import React from 'react';

import { UIDeveloperError, reportError } from '../../utils/errors';

export default function useCopyToClipboard(
  resetInterval = 0
): [(text: string) => void, boolean] {
  const [isCopied, setCopied] = React.useState(false);

  const copy = React.useCallback((text: string | number) => {
    if (typeof text === 'string' || typeof text === 'number') {
      copyToClipboard(text.toString());
      if (resetInterval > 0) {
        setCopied(true);
      }
    } else {
      setCopied(false);
      reportError(
        new UIDeveloperError(
          `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
        )
      );
    }
  }, []);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval > 0) {
      timeout = setTimeout(() => setCopied(false), resetInterval);
    }
    return (): void => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [copy, isCopied];
}
