import React from 'react';

export default function PersonCalendarIcon(props: SVGProps): JSX.Element {
  const { title = 'PersonCalendar', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-7 3a3 3 0 11.002 6.002A3 3 0 0112 6zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z" />
    </svg>
  );
}
