import React from 'react';

export default function WalletIcon(props: SVGProps): JSX.Element {
  const { title = 'Wallet', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M13.75 10a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z" />
      <path
        fillRule="evenodd"
        d="M3.125 3.75c-1.036 0-1.875.84-1.875 1.875v8.75c0 1.036.84 1.875 1.875 1.875h13.75c1.035 0 1.875-.84 1.875-1.875v-6.25c0-1.036-.84-1.875-1.875-1.875H3.125a.625.625 0 110-1.25H16.25c0-.69-.56-1.25-1.25-1.25H3.125zM2.5 14.375V7.5h14.375c.345 0 .625.28.625.625v6.25c0 .345-.28.625-.625.625H3.125a.625.625 0 01-.625-.625z"
        clipRule="evenodd"
      />
    </svg>
  );
}
