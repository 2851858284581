/* eslint-disable react/prop-types */
// Utils
import cn from 'classnames';
import React from 'react';
import { v4 as uuid } from 'uuid';

// Styles
import styles from './index.css';

export const INPUT_TYPES = {
  COLOR: 'color',
  EMAIL: 'email',
  HIDDEN: 'hidden',
  NUMBER: 'number',
  PASSWORD: 'password',
  SEARCH: 'search',
  TEL: 'tel',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  URL: 'url',
};

type InputProps = {
  id?: string;
  inputClassName?: string;
  label?: string;
  prefix?: string | JSX.Element;
  suffix?: JSX.Element;
  type?: (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];
  rows?: number;
};

export type Props = InputProps & {
  className?: string;
  isError?: boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  helperText?: string;
  placeholder?: string;
  value: string;
  [key: string]: any;
};

const { TEXT, TEXTAREA } = INPUT_TYPES;

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      prefix,
      suffix,
      inputClassName = '',
      type = TEXT,
      rows = 4,
      ...restProps
    },
    inputRef
  ) => {
    const isTextArea = type === TEXTAREA;

    const inputClasses = cn({
      [styles.input]: true,
      [styles.textarea]: isTextArea,
      [styles.withPrefix]: Boolean(prefix),
      [styles.withSuffix]: Boolean(suffix),
      [inputClassName]: Boolean(inputClassName),
    });

    const inputProps = {
      ...restProps,
      className: inputClasses,
    };

    if (isTextArea) {
      // TODO Kristie 06/22/20 - Find a way to make the forwardRef generic and/or
      // remove the ts-ignore so we can support refs in textarea and input
      // https://twitter.com/jaredpalmer/status/1217149616082296833

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore No way to implement with generics at the moment
      const textRef: React.Ref<HTMLTextAreaElement> = inputRef;
      return <textarea ref={textRef} rows={rows} {...inputProps} />;
    }

    return <input ref={inputRef} {...inputProps} />;
  }
);

// Use React.forwardRef to allow consumers access to the underlying DOM
// element for focusing, selecting, etc.
// https://reactjs.org/docs/forwarding-refs.html
const InputWithRef = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = '',
      helperText,
      // If no ID then we create one in order to link the label with the input
      id = `a${uuid()}`, // ID's need to start with a letter
      isError = false,
      label = '',
      prefix,
      suffix,
      inputClassName = '',
      ...restProps
    },
    wrapperRef
  ) => {
    const classes = cn({
      [styles.wrapper]: true,
      [styles.error]: isError,
      [className]: Boolean(className),
    });

    const iconClasses = cn({
      [styles.prefix]: true,
      [styles.iconPrefix]: React.isValidElement(prefix),
    });

    const inputClasses = cn({
      [styles.inputWithPrefixPadding]: React.isValidElement(prefix),
      [inputClassName]: true,
    });

    return (
      <div className={classes}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}
        <div className={styles.inputWrapper}>
          <Input
            id={id}
            ref={wrapperRef}
            prefix={prefix}
            suffix={suffix}
            inputClassName={inputClasses}
            {...restProps}
          />
          {prefix && (
            <button
              type="button"
              onClick={(): void => {
                const inputElem = document.getElementById(id);
                if (inputElem) {
                  inputElem.focus();
                }
              }}
              className={iconClasses}
            >
              {prefix}
            </button>
          )}
          {suffix}
        </div>
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </div>
    );
  }
);

// Without this, the exported component will not show up properly in Storybook
InputWithRef.displayName = 'Input';

export default InputWithRef;
