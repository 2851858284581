import React from 'react';

export default function AddOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'AddOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...restProps}>
      <title>{title}</title>
      <path d="M9.83317 4.83329H8.1665V8.16663H4.83317V9.83329H8.1665V13.1666H9.83317V9.83329H13.1665V8.16663H9.83317V4.83329ZM8.99984 0.666626C4.39567 0.666626 0.666504 4.39579 0.666504 8.99996C0.666504 13.6041 4.39567 17.3333 8.99984 17.3333C13.604 17.3333 17.3332 13.6041 17.3332 8.99996C17.3332 4.39579 13.604 0.666626 8.99984 0.666626ZM8.99984 15.6666C5.32484 15.6666 2.33317 12.675 2.33317 8.99996C2.33317 5.32496 5.32484 2.33329 8.99984 2.33329C12.6748 2.33329 15.6665 5.32496 15.6665 8.99996C15.6665 12.675 12.6748 15.6666 8.99984 15.6666Z" />
    </svg>
  );
}
