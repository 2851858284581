import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

// Components
import Button from '../../Button';
import { CloseIcon } from '../../Icons';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
};

const ActionsWrapper: React.FC<Props> = ({
  children,
  onClose,
  className = '',
  ...restProps
}) => (
  <div
    data-testid="actions-wrapper"
    className={cn(className, styles.root)}
    {...restProps}
  >
    {children}
    {onClose && (
      <Button
        onClick={onClose}
        type="tertiary"
        data-testid="close-action-button"
        Icon={(): JSX.Element => <CloseIcon className={styles.closeIcon} />}
        className={styles.closeButton}
      />
    )}
  </div>
);

export default ActionsWrapper;
