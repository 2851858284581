/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';

type UseDebounceOptions<T> = {
  onChange?: (delayedValue: T) => any;
  immediate?: boolean;
};

// Slightly modified version of:
// https://usehooks.com/useDebounce/
export const useDebounce = <T,>(
  value: T,
  delay: number,
  options?: UseDebounceOptions<T>,
): T => {
  const { onChange, immediate } = options || {};
  const didMountRef = useRef(false);
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return (): void => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  useEffect(() => {
    if (!onChange) {
      return;
    }
    if (!immediate && !didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    onChange(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return debouncedValue;
};
