import React from 'react';

export default function ArrowDownIcon(props: SVGProps): JSX.Element {
  const { title = 'ArrowDown', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M6.79708 7.52588L11 11.7288L15.2029 7.52588L16.5 8.82296L11 14.323L5.5 8.82296L6.79708 7.52588Z" />
    </svg>
  );
}
