import React from 'react';

function HighQuantityIcon(props: SVGProps): JSX.Element {
  const { title = 'HighQuantityIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>

      <path d="M17 6C13.685 6 11 8.685 11 12C11 15.315 13.685 18 17 18C20.315 18 23 15.315 23 12C23 8.685 20.315 6 17 6ZM5 8C2.79 8 1 9.79 1 12C1 14.21 2.79 16 5 16C7.21 16 9 14.21 9 12C9 9.79 7.21 8 5 8ZM5 14C3.895 14 3 13.105 3 12C3 10.895 3.895 10 5 10C6.105 10 7 10.895 7 12C7 13.105 6.105 14 5 14Z" />
    </svg>
  );
};

export default HighQuantityIcon;
