import React from 'react';

export default function FacilitiesIcon(props: SVGProps): JSX.Element {
  const { title = 'Facilities', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <path d="M21.875 24.5C23.3247 24.5 24.5 23.3248 24.5 21.875L24.5 3.5H22.75L22.75 21.875C22.75 22.3583 22.3582 22.75 21.875 22.75L6.125 22.75C5.64175 22.75 5.25 22.3583 5.25 21.875V3.5H3.5V21.875C3.5 23.3248 4.67525 24.5 6.125 24.5L21.875 24.5Z" />
      <path d="M21 12.25H7V14H21V12.25Z" />
      <path d="M7 19.25L21 19.25V21L7 21V19.25Z" />
      <path d="M21 15.75L7 15.75V17.5L21 17.5V15.75Z" />
    </svg>
  );
}
