// Styles
import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

export type SwitchSizes = 'small' | 'medium';

export type BaseProps = {
  className?: string;
  disabled?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    'data-testid'?: string;
  };
  size?: SwitchSizes;
  [key: string]: any;
};

export type Props = BaseProps & {
  checked: boolean;
  onChange: (arg: boolean) => void;
};

const Switch = (
  {
    children,
    className = '',
    disabled: isDisabled = false,
    id,
    inputProps,
    size = 'medium',
    checked,
    onChange,
    ...restOfProps
  }: Props,
): JSX.Element => {
  const wrapperClasses = classnames({
    [styles.wrapper]: true,
    [className]: Boolean(className),
  });

  const inputClasses = classnames({
    [styles.input]: true,
    [styles.checked]: checked,
  });

  const sliderClasses = classnames({
    [styles.slider]: true,
    [styles.disabled]: isDisabled,
    [styles[size]]: true,
    [styles.withChildren]: Boolean(children),
  });

  return (
    <label className={wrapperClasses} htmlFor={id} {...restOfProps}>
      <input
        className={inputClasses}
        checked={checked}
        disabled={isDisabled}
        onChange={(event): void => {
          onChange(event.currentTarget.checked);
        }}
        type="checkbox"
        {...inputProps}
        /* If an id is passed in props we want to override
        the one in inputProps (if it's there) so that the
        htmlFor in the label matches the input */
        id={id}
      />

      <div className={sliderClasses} />

      {typeof children === 'string' ? (
        <span className={styles.label}>{children}</span>
      ) : (
        children
      )}
    </label>
  );
};

export default Switch;
