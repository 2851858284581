import React from 'react';

// Types
import type { ButtonGroupProps } from './_types';

// Components
import ButtonGroupButton from './elements/ButtonGroupButton';
import ButtonGroupWrapper from './elements/ButtonGroupWrapper';

const ButtonGroup = <T,>({
  buttons,
  className = '',
  buttonProps,
  ...restProps
}: ButtonGroupProps<T>): JSX.Element => (
  <ButtonGroupWrapper className={className} {...restProps}>
    {buttons.map(({ btnValue, label, ...restBtnProps }, index) => (
      <ButtonGroupButton
        {...buttonProps}
        {...restBtnProps}
        data-testid={`btn-${index}`}
        btnValue={btnValue}
        key={`${label}-${index}`}
      >
        {label}
      </ButtonGroupButton>
    ))}
  </ButtonGroupWrapper>
);

export default ButtonGroup;
