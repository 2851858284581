// @Generated
// This is a generated file, DO NOT MODIFY
import FileUploadBase from './FileUploadBase';
import FilesList from './FilesList';
import UploadedFile from './UploadedFile';

export { default as FileUploadBase } from './FileUploadBase';
export { default as FilesList } from './FilesList';
export { default as UploadedFile } from './UploadedFile';

export default {
  FileUploadBase,
  FilesList,
  UploadedFile,
};
