// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../../index.css';

// Components
import Text, { Props as TextProps } from '../../../Text';

const BannerSubtitle: React.FC<TextProps> = (
  { children, className = '', ...restProps },
) => (
  <Text
    className={cn([styles.subtitle, className])}
    size="small"
    data-testid="banner-subtitle"
    {...restProps}
  >
    {children}
  </Text>
);

export default BannerSubtitle;
