// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import * as css from './index.css';

const Table: CommonComponent<Record<string, any>, 'table'> = ({
  children,
  className = '',
  ...restProps
}) => {
  const classes = cn({
    [css.table]: true,
    [className]: Boolean(className),
  });

  return (
    <table className={classes} {...restProps}>
      {children}
    </table>
  );
};

export default Table;
