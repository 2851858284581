// Types
import type { PresetOption } from './_types';

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DEFAULT_NAV_PROPS = {
  nextMonthButtonDisabled: false,
  nextYearButtonDisabled: false,
  prevMonthButtonDisabled: false,
  prevYearButtonDisabled: false,
};

export enum TimeOfDay {
  AM = 'AM',
  PM = 'PM',
}

export const BEGINNING_OF_DAY = '00:00:00';

export const NUM_CHARACTERS_IN_TIMESTAMP = 8;

export const TIME_PICKER_CONTAINER_ID = 'custom-time-input-container';

export const PRESET_RANGES: PresetOption[] = [
  {
    title: 'Today',
    getDates: () => {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0);

      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59);
      return [todayStart, todayEnd];
    },
  },
  {
    title: 'Last 7 days',
    getDates: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      startDate.setHours(0, 0, 0);

      const endDate = new Date();
      endDate.setHours(23, 59, 59);
      return [startDate, endDate];
    },
  },
  {
    title: 'Last 30 days',
    getDates: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
      startDate.setHours(0, 0, 0);

      const endDate = new Date();
      endDate.setHours(23, 59, 59);
      return [startDate, endDate];
    },
  },
  {
    title: 'Last 90 days',
    getDates: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 89);
      startDate.setHours(0, 0, 0);

      const endDate = new Date();
      endDate.setHours(23, 59, 59);
      return [startDate, endDate];
    },
  },
  {
    title: 'Last year',
    getDates: () => {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      startDate.setHours(0, 0, 0);

      const endDate = new Date();
      endDate.setHours(23, 59, 59);
      return [startDate, endDate];
    },
  },
];

export const POPPER_DEFAULT_OFFSET = -192;
export const POPPER_TIME_PICKER_ENABLED_OFFSET = -272;
