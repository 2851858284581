import cn from 'classnames';
import React from 'react';
// Types
import type { Row, UseExpandedRowProps } from 'react-table';

import * as css from './index.css';

// Components
import Button from '../Button';
import type { Props as ButtonProps } from '../Button';
import { ChevronIcon } from '../Icons';

export interface ExpandableButtonProps<T extends object> extends ButtonProps {
  row: UseExpandedRowProps<T> & Row<T>;
  shouldDisable?: (row: UseExpandedRowProps<T> & Row<T>) => boolean;
  shouldHide?: (row: UseExpandedRowProps<T> & Row<T>) => boolean;
}

const ExpandableButton = <T extends object>({
  row,
  shouldDisable = () => false,
  shouldHide = () => false,
  className = '',
  onClick,
  ...restProps
}: ExpandableButtonProps<T>) => (
  <>
    {!shouldHide(row) && (
      <Button
        {...restProps}
        className={cn({
          [css.expandable]: true,
          [className]: Boolean(className),
        })}
        disabled={shouldDisable(row)}
        onClick={() => {
          onClick?.();
          row.toggleRowExpanded(!row.isExpanded);
        }}
        Icon={() => (
          <ChevronIcon
            className={css.expandableIcon}
            direction={row.isExpanded ? 'down' : 'right'}
          />
        )}
        type="tertiary"
      />
    )}
  </>
);

export default ExpandableButton;
