import React from 'react';

export default function CheckIcon(props: SVGProps): JSX.Element {
  const { title = 'Checkmark', height, ...restProps } = props;

  return (
    <svg
      height={height || '11'}
      viewBox="0 0 14 11"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M4.75027 8.12772L1.62277 5.00022L0.561523 6.06147L4.75027 10.2502L13.7503 1.25021L12.689 0.188965L4.75027 8.12772Z" />
    </svg>
  );
}
