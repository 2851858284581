import { noop, uniqBy } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useDeepCompare } from '../../../../../../hooks';

import { SelectOption } from '../../../../../SelectV2/types';
import {
  FormAsyncSelectProviderProps,
  FromAsyncSelectProviderValues,
} from '../../types';

export const FormAsyncSelectContext =
  createContext<FromAsyncSelectProviderValues>({
    loadMoreOptions: () => Promise.resolve({ hasMore: false, newOptions: [] }),
    options: [],
    setInputValue: noop,
    loading: false,
    hasMore: true,
    initialOptions: [],
    resetSelectState: noop,
  });

export const useFormAsyncSelectContext = () =>
  useContext(FormAsyncSelectContext);

const FormAsyncSelectProvider = (
  {
    loadOptions,
    value,
    children,
    dynamicVars,
    disabled,
  }: FormAsyncSelectProviderProps,
) => {
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<SelectOption<any>[]>([]);
  const [initialOptions, setInitialOptions] = useState<SelectOption<any>[]>([]);
  const [loading, setLoading] = useState(false);

  const resetSelectState = useCallback(() => {
    setEndCursor(undefined);
    setHasMore(true);
    setIsFirstLoad(true);
    setInputValue(undefined);
    // setOptions([]);
    setLoading(false);
  }, []);

  const loadMoreOptions = useCallback(async () => {
    if (disabled) {
      return;
    }

    if (hasMore) {
      setLoading(true);
      const result = await loadOptions({
        mode: 'paginate',
        inputValue,
        endCursor,
      });

      if (result.endCursor) {
        setEndCursor(result.endCursor);
      }

      setHasMore(result.hasMore);
      setOptions((prevOptions) =>
        uniqBy([...prevOptions, ...result.newOptions], 'value'),
      );
      setLoading(false);
    }
  }, useDeepCompare([loadOptions, inputValue, endCursor, hasMore]));

  // This handles the input changes
  useEffect(() => {
    if (!isFirstLoad) {
      setEndCursor(undefined);
      setHasMore(true);

      setLoading(true);
      loadOptions({
        mode: 'search',
        inputValue,
      })
        .then((result) => {
          setOptions(result.newOptions);
          setHasMore(result.hasMore);
          setEndCursor(result.endCursor);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [inputValue]);

  useEffect(() => {
    if (!isFirstLoad) {
      setEndCursor(undefined);
      setOptions([]);
      setHasMore(true);
      loadMoreOptions();
    }
  }, useDeepCompare([dynamicVars]));

  // This handles the initial load
  useEffect(() => {
    if (value && isFirstLoad) {
      setLoading(true);
      loadOptions({ mode: 'initial', inputValue: value })
        .then((result) => {
          setOptions(result.newOptions);
          setInitialOptions(result.newOptions);
          setLoading(false);
          setIsFirstLoad(false);
        })
        .catch(() => {
          setLoading(false);
          setIsFirstLoad(false);
        });
      return;
    }

    setIsFirstLoad(false);
  }, [isFirstLoad, value]);

  return (
    <FormAsyncSelectContext.Provider
      value={{
        loadMoreOptions,
        setInputValue,
        options,
        loading,
        hasMore,
        initialOptions,
        resetSelectState,
      }}
    >
      {children}
    </FormAsyncSelectContext.Provider>
  );
};

export default FormAsyncSelectProvider;
