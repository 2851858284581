import React from 'react';

export default function InvoiceIcon(props: SVGProps): JSX.Element {
  const { title = 'Invoice', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M4 7.5L12.75 7.5V5.75L4 5.75V7.5Z" />
      <path d="M14.5 11L4 11V9.25L14.5 9.25V11Z" />
      <path d="M11 14.5L4 14.5V12.75L11 12.75V14.5Z" />
      <path d="M16.25 11L18 11V9.25L16.25 9.25V11Z" />
      <path d="M18 14.5L16.25 14.5V12.75L18 12.75V14.5Z" />
      <path d="M16.25 7.5L18 7.5V5.75L16.25 5.75V7.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 0.5C1.67525 0.5 0.5 1.67525 0.5 3.125V20.0063C0.5 20.8312 1.16876 21.5 1.99372 21.5C2.38988 21.5 2.76981 21.3426 3.04994 21.0625L4 20.1124L5.13128 21.2437C5.47299 21.5854 6.02701 21.5854 6.36872 21.2437L7.5 20.1124L8.63128 21.2437C8.97299 21.5854 9.52701 21.5854 9.86872 21.2437L11 20.1124L12.1313 21.2437C12.473 21.5854 13.027 21.5854 13.3687 21.2437L14.5 20.1124L15.6313 21.2437C15.973 21.5854 16.527 21.5854 16.8687 21.2437L18 20.1124L18.9501 21.0625C19.2302 21.3426 19.6101 21.5 20.0063 21.5C20.8312 21.5 21.5 20.8312 21.5 20.0063V3.125C21.5 1.67525 20.3247 0.5 18.875 0.5H3.125ZM2.25 3.125C2.25 2.64175 2.64175 2.25 3.125 2.25H18.875C19.3582 2.25 19.75 2.64175 19.75 3.125V19.3876L18.6187 18.2563C18.277 17.9146 17.723 17.9146 17.3813 18.2563L16.25 19.3876L15.1187 18.2563C14.777 17.9146 14.223 17.9146 13.8813 18.2563L12.75 19.3876L11.6187 18.2563C11.277 17.9146 10.723 17.9146 10.3813 18.2563L9.25 19.3876L8.11872 18.2563C7.77701 17.9146 7.22299 17.9146 6.88128 18.2563L5.75 19.3876L4.61872 18.2563C4.27701 17.9146 3.72299 17.9146 3.38128 18.2563L2.25 19.3876V3.125Z"
      />
    </svg>
  );
}
