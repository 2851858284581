import React from 'react';

export default function ClockIcon(props: SVGProps): JSX.Element {
  const { title = 'Clock', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M10.313 6.188v4.527c0 .365.144.715.402.973l2.549 2.548.972-.972-2.549-2.549V6.188h-1.374z" />
      <path
        fillRule="evenodd"
        d="M2.75 11a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zM11 4.125a6.875 6.875 0 100 13.75 6.875 6.875 0 000-13.75z"
        clipRule="evenodd"
      />
    </svg>
  );
}
