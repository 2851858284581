import React from 'react';

export default function AddCircleIcon(props: SVGProps): JSX.Element {
  const { title = 'AddCircle', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm10 22h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z" />
    </svg>
  );
}
