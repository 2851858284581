// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../index.css';

// Components
import Text from '../../Text';
// Props
import type { Props as TextProps } from '../../Text';

const Label: React.FC<TextProps<'label'>> = ({
  children,
  className = '',
  ...restProps
}) => (
  <Text<'label'>
    className={cn(styles.label, className)}
    size="small"
    tag="label"
    {...restProps}
  >
    {children}
  </Text>
);

export default Label;
