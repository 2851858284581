import React from 'react';

export default function Email(props: SVGProps): JSX.Element {
  const { title = 'Email', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M16.6667 3.33333H3.33341C2.41258 3.33333 1.67508 4.07916 1.67508 4.99999L1.66675 15C1.66675 15.9208 2.41258 16.6667 3.33341 16.6667H16.6667C17.5876 16.6667 18.3334 15.9208 18.3334 15V4.99999C18.3334 4.07916 17.5876 3.33333 16.6667 3.33333ZM16.6667 15H3.33341V6.66666L10.0001 10.8333L16.6667 6.66666V15ZM10.0001 9.16666L3.33341 4.99999H16.6667L10.0001 9.16666Z" />
    </svg>
  );
}
