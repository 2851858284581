import React from 'react';

import styles from './index.css';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  // All other props
  [x: string]: any;
};

function HeaderText({ children, ...restProps }: Props): React.ReactElement {
  return (
    <div data-testid="card-header-text" className={styles.text} {...restProps}>
      {children}
    </div>
  );
}

export default HeaderText;
