import React from 'react';

export default function PersonPinIcon(props: SVGProps): JSX.Element {
  const { title = 'PersonPin', ...restProps } = props;

  return (
    <svg viewBox="0 0 28 40" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M14 0C6.27 0 0 6.27 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.27 21.73 0 14 0ZM14 4C16.21 4 18 5.79 18 8C18 10.21 16.21 12 14 12C11.79 12 10 10.21 10 8C10 5.79 11.79 4 14 4ZM14 24C10.66 24 7.73 22.29 6 19.71C6.03 17.06 11.34 15.6 14 15.6C16.66 15.6 21.96 17.06 22 19.71C20.27 22.29 17.34 24 14 24Z" />
    </svg>
  );
}
