import React from 'react';

import {
  DataContainerActions,
  DataContainerHeader,
  DataContainerText,
  DataContainerWrapper,
} from './_components';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  actions?: React.ReactElement[];
  children: React.ReactNode;
  className?: string;
  counter?: number;
  disabled?: boolean;
  title: string;
};

function DataContainer(
  {
    actions = [],
    children,
    className,
    counter,
    disabled = false,
    title,
    ...restProps
  }: Props,
): React.ReactElement {
  return (
    <DataContainerWrapper
      className={className}
      disabled={disabled}
      {...restProps}
    >
      <DataContainerHeader>
        <DataContainerText counter={counter} title={title} />
        <DataContainerActions actions={actions} />
      </DataContainerHeader>
      {children}
    </DataContainerWrapper>
  );
}

export default DataContainer;
export { default as DataContainerActions } from './_components/DataContainerActions';
export { default as DataContainerHeader } from './_components/DataContainerHeader';
export { default as DataContainerText } from './_components/DataContainerText';
export { default as DataContainerWrapper } from './_components/DataContainerWrapper';
