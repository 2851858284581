import React from 'react';

export default function GiftIcon(props: SVGProps): JSX.Element {
  const { title = 'Gift', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M5 4a4 4 0 017-2.646A4 4 0 0118.465 6H21a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V9a3 3 0 013-3h2.535A3.982 3.982 0 015 4zm8 4v6h9V9a1 1 0 00-1-1h-8zm9 8h-9v6h8a1 1 0 001-1v-5zm-11-2V8H3a1 1 0 00-1 1v5h9zm-9 2h9v6H3a1 1 0 01-1-1v-5zm9-10V4a2 2 0 10-2 2h2zm4 0a2 2 0 10-2-2v2h2z"
        clipRule="evenodd"
      />
    </svg>
  );
}
