import React from 'react';

export default function AutoClaimIcon(props: SVGProps): JSX.Element {
  const { title = 'AutoClaimIcon', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 196 147"
      width="196"
      height="147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6646_288489)">
        <path
          d="M4.28276 0.710938H168.238C170.135 0.710938 171.677 2.25278 171.677 4.14994V133.208H0.84375V4.14994C0.84375 2.25278 2.38559 0.710938 4.28276 0.710938Z"
          fill="white"
        />
        <path
          d="M15.094 116.906H4.96094"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M23.2854 103.969H4.96094"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M16.2932 91.0312H4.96094"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M34.9453 0.84375V133.207"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M0.84375 34.0586H35.183"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M0.84375 45.8047H35.183"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M0.84375 57.5469H35.183"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M29.7521 17.4646L17.5316 9.76172L5.30469 17.4646L17.5316 25.1674L29.7521 17.4646Z"
          fill="black"
        />
        <path
          d="M161.517 11.2574C162.446 11.2574 163.199 10.5046 163.199 9.57596C163.199 8.64733 162.446 7.89453 161.517 7.89453C160.589 7.89453 159.836 8.64733 159.836 9.57596C159.836 10.5046 160.589 11.2574 161.517 11.2574Z"
          fill="black"
        />
        <path
          d="M155.689 11.2574C156.618 11.2574 157.371 10.5046 157.371 9.57596C157.371 8.64733 156.618 7.89453 155.689 7.89453C154.761 7.89453 154.008 8.64733 154.008 9.57596C154.008 10.5046 154.761 11.2574 155.689 11.2574Z"
          fill="black"
        />
        <path
          d="M149.869 11.2574C150.798 11.2574 151.55 10.5046 151.55 9.57596C151.55 8.64733 150.798 7.89453 149.869 7.89453C148.94 7.89453 148.188 8.64733 148.188 9.57596C148.188 10.5046 148.94 11.2574 149.869 11.2574Z"
          fill="black"
        />
        <path
          d="M165.778 21.2305C166.705 21.2305 167.453 21.8713 167.453 22.6644V29.663C167.453 30.4561 166.705 31.097 165.778 31.097H42.5579C41.6315 31.097 40.8828 30.4561 40.8828 29.663V22.6644C40.8828 21.8713 41.6315 21.2305 42.5579 21.2305H165.785H165.778Z"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M72.537 128.168H51.3129C46.5751 128.168 42.7344 132.009 42.7344 136.746C42.7344 141.484 46.5751 145.325 51.3129 145.325H72.537C77.2747 145.325 81.1155 141.484 81.1155 136.746C81.1155 132.009 77.2747 128.168 72.537 128.168Z"
          stroke="#5681F6"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M59.72 136.664C59.72 131.972 55.9177 128.168 51.2272 128.168C46.5367 128.168 42.7344 131.972 42.7344 136.664V136.829C42.7344 141.521 46.5367 145.325 51.2272 145.325C55.9177 145.325 59.72 141.521 59.72 136.829V136.664Z"
          fill="#5681F6"
          stroke="#5681F6"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M195.547 104.898C195.547 107.74 189.614 109.872 189.012 112.531C188.409 115.189 192.793 119.764 191.594 122.239C190.395 124.713 184.113 124.104 182.387 126.268C180.655 128.438 182.673 134.402 180.503 136.141C178.333 137.879 172.978 134.573 170.459 135.792C167.984 136.985 167.223 143.241 164.475 143.869C161.728 144.497 158.422 139.18 155.586 139.18C152.75 139.18 149.349 144.472 146.697 143.869C144.044 143.266 143.188 136.991 140.713 135.792C138.239 134.592 132.833 137.867 130.669 136.141C128.505 134.415 130.517 128.438 128.785 126.268C127.052 124.098 120.79 124.758 119.578 122.239C118.366 119.72 122.789 115.272 122.16 112.531C121.532 109.79 115.625 107.74 115.625 104.898C115.625 102.055 121.558 99.9232 122.16 97.2647C122.763 94.6061 118.379 90.0313 119.578 87.5568C120.777 85.0822 127.059 85.6913 128.785 83.5277C130.51 81.364 128.499 75.3934 130.669 73.6548C132.839 71.9163 138.194 75.222 140.713 74.0038C143.232 72.7856 143.949 66.5547 146.697 65.9266C149.444 65.2984 152.75 70.6156 155.586 70.6156C158.422 70.6156 161.823 65.3238 164.475 65.9266C167.128 66.5294 167.984 72.8046 170.459 74.0038C172.933 75.203 178.339 71.929 180.503 73.6548C182.667 75.3807 180.655 81.3577 182.387 83.5277C184.12 85.6977 190.382 85.0378 191.594 87.5568C192.806 90.0758 188.383 94.5236 189.012 97.2647C189.64 100.006 195.547 102.055 195.547 104.898Z"
          fill="#5681F6"
        />
        <path
          d="M155.594 132.661C170.929 132.661 183.36 120.229 183.36 104.895C183.36 89.5601 170.929 77.1289 155.594 77.1289C140.259 77.1289 127.828 89.5601 127.828 104.895C127.828 120.229 140.259 132.661 155.594 132.661Z"
          stroke="white"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M150.695 53.3709C153.595 53.3709 155.949 51.0232 155.949 48.1172C155.949 51.0169 158.297 53.3709 161.203 53.3709C158.303 53.3709 155.949 55.7185 155.949 58.6246C155.949 55.7249 153.601 53.3709 150.695 53.3709Z"
          fill="black"
        />
        <path
          d="M150.695 53.3709C153.595 53.3709 155.949 51.0232 155.949 48.1172C155.949 51.0169 158.297 53.3709 161.203 53.3709C158.303 53.3709 155.949 55.7185 155.949 58.6246C155.949 55.7249 153.601 53.3709 150.695 53.3709Z"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M96.6484 120.992C99.5481 120.992 101.902 118.644 101.902 115.738C101.902 118.638 104.25 120.992 107.156 120.992C104.256 120.992 101.902 123.34 101.902 126.246C101.902 123.346 99.5545 120.992 96.6484 120.992Z"
          fill="black"
        />
        <path
          d="M96.6484 120.992C99.5481 120.992 101.902 118.644 101.902 115.738C101.902 118.638 104.25 120.992 107.156 120.992C104.256 120.992 101.902 123.34 101.902 126.246C101.902 123.346 99.5545 120.992 96.6484 120.992Z"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M97.6364 110.179C98.7472 110.179 99.6477 109.278 99.6477 108.168C99.6477 107.057 98.7472 106.156 97.6364 106.156C96.5255 106.156 95.625 107.057 95.625 108.168C95.625 109.278 96.5255 110.179 97.6364 110.179Z"
          fill="black"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M138.347 59.347C139.458 59.347 140.359 58.4464 140.359 57.3356C140.359 56.2247 139.458 55.3242 138.347 55.3242C137.236 55.3242 136.336 56.2247 136.336 57.3356C136.336 58.4464 137.236 59.347 138.347 59.347Z"
          fill="black"
          stroke="black"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M178.851 104.896L155.59 90.0039L132.336 104.896L155.59 119.787L178.851 104.896Z"
          stroke="white"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M149.727 105.331L153.914 109.525L161.452 100.262"
          stroke="white"
          strokeWidth="1.90351"
          strokeMiterlimit="8"
        />
        <path
          d="M67.1641 115.69C67.1641 115.69 69.3975 72.3221 116.934 72.9122"
          stroke="#5681F6"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M117.389 67.7891L116.672 79.2165L122.953 74.3498L117.389 67.7891Z"
          stroke="#5681F6"
          strokeWidth="1.90351"
          strokeMiterlimit="10"
        />
        <path
          d="M41.4316 46.707L94.5078 46.707V41.06L41.4316 41.06V46.707Z"
          fill="black"
        />
        <path
          d="M41.4279 58.8477L74.8281 58.8477V53.2006L41.4279 53.2006V58.8477Z"
          fill="black"
        />
        <path
          d="M41.4279 71.5898H74.8281V65.9428H41.4279V71.5898Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6646_288489">
          <rect
            width="194.71"
            height="145.568"
            fill="white"
            transform="translate(0.84375 0.710938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
