import React from 'react';

export default function ListIcon(props: SVGProps): JSX.Element {
  const { title = 'List', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 10.75H21.5V12.5H5.75V10.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.75H4V12.5H0.5V10.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 16H21.5V17.75H5.75V16Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 16H4V17.75H0.5V16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5.5H21.5V7.25H5.75V5.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 5.5H4V7.25H0.5V5.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 0.25H21.5V2H5.75V0.25Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.25H4V2H0.5V0.25Z" />
    </svg>
  );
}
