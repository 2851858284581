// Utils
import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

type AccordionContextProps = {
  isExpanded: boolean;
  toggle: () => void;
  itemId: string;
};

export const AccordionContext = createContext<AccordionContextProps>({
  isExpanded: false,
  toggle: noop,
  itemId: '',
});

export default () => useContext(AccordionContext);
