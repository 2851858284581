import React from "react"

export default function SearchCircleIcon(props: SVGProps): JSX.Element {
  const { title = "Search", ...restProps } = props

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <rect width="24" height="24" rx="12" fill="#B7952A" />
      <path
        d="M14.3333 13.3333H13.8033L13.62 13.15C14.2733 12.3933 14.6667 11.41 14.6667 10.3333C14.6667 7.94 12.7267 6 10.3333 6C7.94 6 6 7.94 6 10.3333C6 12.7267 7.94 14.6667 10.3333 14.6667C11.41 14.6667 12.3933 14.2733 13.15 13.6233L13.3333 13.8067V14.3333L16.6667 17.66L17.66 16.6667L14.3333 13.3333ZM10.3333 13.3333C8.67667 13.3333 7.33333 11.99 7.33333 10.3333C7.33333 8.67667 8.67667 7.33333 10.3333 7.33333C11.99 7.33333 13.3333 8.67667 13.3333 10.3333C13.3333 11.99 11.99 13.3333 10.3333 13.3333Z"
        fill="#1B1300"
      />
    </svg>
  )
}
