import React, { ComponentType, ReactNode } from 'react';
import classnames from 'classnames';
import UIDeveloperError from '../../utils/errors/UIDeveloperError';
import styles from './index.css';

// Define the types and mappings
export type TextTypes = 'heading' | 'label' | 'body' | 'metric' | 'mono' | 'link';
export type TextSizes = 'tiny' | 'small' | 'medium' | 'large' | 'huge';
export type TextMapping = Record<TextTypes, TextSizes[]>;

export const TEXT_MAPPINGS: TextMapping = {
  heading: ['huge', 'large', 'medium', 'small'],
  label: ['medium', 'small', 'tiny'],
  body: ['large', 'medium', 'small', 'tiny'],
  metric: ['large', 'medium', 'small', 'tiny'],
  mono: ['medium', 'small', 'tiny'],
  link: ['medium', 'small', 'tiny'],
};

type HTMLTag = keyof JSX.IntrinsicElements;
export type Props<Tag extends ComponentType<any> | HTMLTag = 'div'> = {
  children?: ReactNode;
  className?: string;
  type?: TextTypes;
  size?: TextSizes;
  tag?: Tag | HTMLTag;
} & (Tag extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[Tag] : any);

// Text component function
function Text<Tag extends ComponentType<any> | HTMLTag = 'div'>({
  children,
  className = '',
  type = 'body',
  size = 'medium',
  tag: TagComponent = 'div',
  ...restProps
}: Props<Tag>): JSX.Element {
  // Validate type and size
  if (!(type as TextTypes in TEXT_MAPPINGS && TEXT_MAPPINGS[type as TextTypes]?.includes(size))) {
    throw new UIDeveloperError(
      `Incorrect type and size options for <Text>. Type '${type}' doesn't have '${size}' size.`,
    );
  }

  // Generate classnames
  const classes = classnames(className, styles[type], styles[size]);

  return (
    <TagComponent className={classes} {...restProps}>
      {children}
    </TagComponent>
  );
}

export default Text;
