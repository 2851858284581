/* eslint-disable import/prefer-default-export */
export const generateSharedPickerSettings = ({
  showTimeInput,
}: {
  showTimeInput: boolean;
}) => ({
  showPopperArrow: false,
  // In risk, day comes in as a date object (reason unknown)
  formatWeekDay: (day: string | object): string => {
    let nameOfDay: string;
    if (typeof day === 'object') {
      nameOfDay = new Date(day as Date).toLocaleString('en-us', {
        weekday: 'long',
      });
    } else {
      nameOfDay = day;
    }

    return nameOfDay.substring(0, 3);
  },
  timeFormat: 'HH:mm:ss',
  placeholderText: showTimeInput ? 'Date/Time' : 'MM/DD/YYYY',
  dateFormat: showTimeInput ? 'MM/dd/yyyy h:mm:ss aa' : 'MM/dd/yyyy',
  showTimeInput,
  // Needed because of a reactdatepicker bug where current highlighted dates
  // show across all months
  // https://github.com/Hacker0x01/react-datepicker/issues/2930
  minDate: null,
  disabledKeyboardNavigation: true,
  // to avoid the picker popup to change height when changing months
  fixedHeight: true,
});
