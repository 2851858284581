import React from 'react';

export default function ErrorOutlineTriangleIcon(props: SVGProps): JSX.Element {
  const { title = 'ErrorOutlineTriangle', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...restProps}>
      <title>{title}</title>
      <path d="M6.50024 8.5V5H7.50024V8.5H6.50024Z" />
      <path d="M6.50024 10.5V9.5H7.50024V10.5H6.50024Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3196 1.63862C7.7525 0.590026 6.24788 0.590025 5.68078 1.63862L0.733475 10.7865C0.192971 11.7859 0.916662 13 2.05288 13H11.9475C13.0837 13 13.8074 11.7859 13.2669 10.7865L8.3196 1.63862ZM6.56039 2.11432C6.74942 1.76479 7.25096 1.76479 7.43999 2.11432L12.3873 11.2622C12.5675 11.5953 12.3262 12 11.9475 12H2.05288C1.67414 12 1.43291 11.5953 1.61308 11.2622L6.56039 2.11432Z"
      />
    </svg>
  );
}
