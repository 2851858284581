import React from 'react';

export default function LockIcon(props: SVGProps): JSX.Element {
  const { title = 'Lock', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 15" {...restProps}>
      <title>{title}</title>
      <path d="M5.99984 11.3334C6.73317 11.3334 7.33317 10.7334 7.33317 10C7.33317 9.26669 6.73317 8.66669 5.99984 8.66669C5.2665 8.66669 4.6665 9.26669 4.6665 10C4.6665 10.7334 5.2665 11.3334 5.99984 11.3334ZM9.99984 5.33335H9.33317V4.00002C9.33317 2.16002 7.83984 0.666687 5.99984 0.666687C4.15984 0.666687 2.6665 2.16002 2.6665 4.00002V5.33335H1.99984C1.2665 5.33335 0.666504 5.93335 0.666504 6.66669V13.3334C0.666504 14.0667 1.2665 14.6667 1.99984 14.6667H9.99984C10.7332 14.6667 11.3332 14.0667 11.3332 13.3334V6.66669C11.3332 5.93335 10.7332 5.33335 9.99984 5.33335ZM3.93317 4.00002C3.93317 2.86002 4.85984 1.93335 5.99984 1.93335C7.13984 1.93335 8.0665 2.86002 8.0665 4.00002V5.33335H3.93317V4.00002ZM9.99984 13.3334H1.99984V6.66669H9.99984V13.3334Z" />
    </svg>
  );
}
