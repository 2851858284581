// Utils
import classnames from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

import { CompleteArrowForwardIcon } from '../Icons';
// Hooks
import Text from '../Text';

type TickerProps = {
  percentage: string | number;
  className?: string;
  [key: string]: any;
};

export default function Ticker(
  { percentage, className = '', ...restProps }: TickerProps,
): React.ReactElement {
  const isPositive = Number(percentage) > 0;
  const isNegative = Number(percentage) < 0;
  const hasSign = isPositive || isNegative;

  const formattedPercentage = Math.abs(Number(percentage)).toFixed(2);

  const classes = classnames({
    [className]: Boolean(className),
    [styles.ticker]: true,
    [styles.positive]: isPositive,
    [styles.negative]: isNegative,
  });

  return (
    <Text size="small" className={classes} {...restProps}>
      {hasSign && (
        <div className={styles.tickerSign}>
          {isPositive && (
            <CompleteArrowForwardIcon
              data-testid="arrow-icon-up"
              className={classnames([styles.arrowIcon, styles.positive])}
              direction="up"
            />
          )}
          {isNegative && (
            <CompleteArrowForwardIcon
              data-testid="arrow-icon-down"
              className={classnames([styles.arrowIcon, styles.negative])}
              direction="down"
            />
          )}
        </div>
      )}
      {formattedPercentage}%
    </Text>
  );
}
