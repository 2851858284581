import React from 'react';

// Components & Types
import Input, { INPUT_TYPES, Props } from '../Input';

function TextArea(props: Props): JSX.Element {
  return <Input {...props} type={INPUT_TYPES.TEXTAREA} />;
}

export default TextArea;
