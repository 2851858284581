// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from '../index.css';

// Types
import type { StepTrackerDOMProps } from '../_types';

const StepTrackerWrapper: React.FC<StepTrackerDOMProps> = ({
  className = '',
  ...restProps
}) => <div className={cn([className, css.root])} {...restProps} />;

StepTrackerWrapper.displayName = 'StepTrackerWrapper';

export default StepTrackerWrapper;
