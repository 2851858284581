import React from 'react';

export default function AddIcon(props: SVGProps): JSX.Element {
  const { title = 'Add', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...restProps}>
      <title>{title}</title>
      <path d="M13.4166 7.91668H7.91659V13.4167H6.08325V7.91668H0.583252V6.08334H6.08325V0.583344H7.91659V6.08334H13.4166V7.91668Z" />
    </svg>
  );
}
