import React from 'react';

import FooterText from './FooterText';

interface FootnotesProps {
  errorFooterText?: React.ReactNode;
  helperFooterText?: React.ReactNode;
}

const Footnotes = (
  { errorFooterText, helperFooterText }: FootnotesProps,
): JSX.Element => (
  <>
    {helperFooterText && (
      <FooterText typeText="helper">{helperFooterText}</FooterText>
    )}
    {errorFooterText && (
      <FooterText typeText="error">{errorFooterText}</FooterText>
    )}
  </>
);

export default Footnotes;
