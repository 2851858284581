import React from 'react';

export default function EthIcon(props: SVGProps): JSX.Element {
  const { title = 'EthIcon', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <g>
        <path d="M14 2.333v8.626l7.29 3.257L14 2.333z" opacity="0.6" />
        <path d="M14 2.333L6.708 14.216 14 10.96V2.333z" />
        <path d="M14 19.806v5.86l7.295-10.092L14 19.806z" opacity="0.6" />
        <path d="M14 25.666v-5.861l-7.292-4.231L14 25.666z" />
        <path d="M14 18.45l7.29-4.234L14 10.961v7.488z" opacity="0.2" />
        <path d="M6.708 14.216L14 18.45v-7.488l-7.292 3.255z" opacity="0.6" />
      </g>
    </svg>
  );
}
