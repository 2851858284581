import React from 'react';

import styles from './index.css';

type ComponentVisibility = {
  ref: React.RefObject<HTMLDivElement>;
  isComponentVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

type VoidFn = () => void;

export function useComponentVisible(
  initialIsVisible: boolean,
  setIsVisible: (isVisible: boolean) => void
): ComponentVisibility {
  const [isComponentVisible, setIsComponentVisible] =
    React.useState<boolean>(initialIsVisible);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any): void => {
    if (ref.current) {
      if (!ref.current.contains(event.target)) {
        setIsVisible(false);
      }
      setIsComponentVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return (): void => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

type Props = {
  // children is an array with a button and a menu (by that order).
  children: React.ReactElement[];
  // All other props
  [x: string]: any;
};

const DropdownContainer = ({
  children,
  ...restProps
}: Props): React.ReactElement => {
  // Opens/closes the menu by clicking on the menu button.
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  // Detects if we are clicking inside or outside of the component.
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, setIsVisible);

  const dropdownButton = children[0];
  const dropdownMenu = children[1];

  // prettier-ignore
  const setIsComponentVisibleFn: (event: React.MouseEvent) => void = (event) => {
    event.stopPropagation();
    setIsComponentVisible(!isComponentVisible);
  }

  const onKeyDownFn: VoidFn = () => null;

  const setIsVisibleFn: (arg: any) => void = (event: any) => {
    event.preventDefault();

    if (event.type === 'click') {
      setIsVisible(!isVisible);
    }
  };

  const setIsVisibleFnOnKeyDown: (arg: any) => void = (event: any) => {
    event.preventDefault();

    const code = event.charCode || event.keyCode;
    const isEnterKey = code && code === 13;
    const isEscapeKey = code && code === 27;
    if (isEnterKey || isEscapeKey) {
      setIsVisible(!isVisible);
    }
  };

  return (
    <div
      ref={ref}
      data-testid="dropdown-container"
      className={styles.dropdown}
      onClick={setIsComponentVisibleFn}
      onKeyDown={onKeyDownFn}
      role="button"
      tabIndex={-2}
      {...restProps}
    >
      <div
        data-testid="dropdown-container-button"
        onClick={setIsVisibleFn}
        onKeyDown={setIsVisibleFnOnKeyDown}
        role="button"
        tabIndex={-3}
      >
        {dropdownButton}
      </div>
      {React.cloneElement(dropdownMenu, {
        // For some reason, it was not assuming isVisible && isComponentVisible
        // result as boolean.
        visible: isVisible && isComponentVisible ? 1 : 0,
        onClick: () => setIsVisible(false),
      })}
    </div>
  );
};

export default DropdownContainer;
