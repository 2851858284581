import classnames from 'classnames';
import React from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import styles from './index.css';

import { ButtonTypes } from '../Button';
import ErrorMessage from './ErrorMessage';
import Footer, { submitButtonText } from './Footer';
import Header, { ClosedBy } from './Header';

type ContentProps = {
  children: React.ReactNode;
  [key: string]: any;
};
export function Content({ children, ...restProps }: ContentProps): JSX.Element {
  return (
    <div className={styles.content} {...restProps}>
      {children}
    </div>
  );
}

type WrapperProps = ReactModalProps & {
  backdropClassName?: string;
  closeOnBackdropClick?: boolean;
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onCancel?: (closedBy?: ClosedBy) => any;
  testId?: string;
  [key: string]: any;
};

export function ModalWrapper(
  {
    backdropClassName = '',
    children,
    className = '',
    isOpen,
    onCancel,
    testId = '',
    ...restProps
  }: WrapperProps,
): JSX.Element {
  const classes = classnames({
    [styles.wrapper]: true,
    [className]: Boolean(className),
  });

  const backdropClasses = classnames({
    [styles.backdrop]: true,
    [backdropClassName]: Boolean(backdropClassName),
  });

  return (
    <ReactModal
      ariaHideApp={false}
      className={classes}
      // closeTimeoutMS matches the duration of the
      // animation in CSS
      closeTimeoutMS={300}
      id={testId}
      isOpen={isOpen}
      onRequestClose={(): void => {
        if (onCancel) {
          onCancel('backdrop');
        }
      }}
      overlayClassName={backdropClasses}
      shouldCloseOnOverlayClick={Boolean(onCancel)}
      {...restProps}
    >
      {children}
    </ReactModal>
  );
}

type Props = WrapperProps & {
  cancelText?: string;
  errorMessage?: string;
  onSubmit: () => any;
  showErrorMessage?: boolean;
  submitText?: string;
  title: string;
  type: ButtonTypes;
};

function Modal(
  {
    backdropClassName = '',
    cancelText,
    children,
    className = '',
    errorMessage = '',
    isOpen = true,
    onCancel,
    onSubmit,
    showErrorMessage = false,
    submitText = submitButtonText,
    testId = '',
    title,
    type = 'primary',
    ...restProps
  }: Props,
): JSX.Element {
  return (
    <ModalWrapper
      backdropClassName={backdropClassName}
      className={className}
      isOpen={isOpen}
      onCancel={onCancel}
      testId={testId}
      {...restProps}
    >
      <Header title={title} onCancel={onCancel} testId={testId} />
      <ErrorMessage
        showErrorMessage={showErrorMessage}
        errorMessage={errorMessage}
      />
      <Content>{children}</Content>
      {onCancel && (
        <Footer
          cancelText={cancelText}
          submitText={submitText}
          onCancel={onCancel}
          onSubmit={onSubmit}
          testId={testId}
          type={type}
        />
      )}
    </ModalWrapper>
  );
}

export default Modal;
