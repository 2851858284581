import React from 'react';

import css from './styles.css';

import Button from '../../../Button';
import { Footer as ModalFooter, useModalContext } from '../../../ModalV2';
import { useFormWizardContext } from '../../FormWizardProvider';
import FormWizardProgressBtns from '../FormWizardProgressBtns';

const FormWizardActions: React.FC = () => {
  const { goToStepIndex } = useFormWizardContext();
  const { onClose } = useModalContext();

  return (
    <ModalFooter className={css.modalFooter}>
      <div className={css.actionContainer}>
        <Button
          type="tertiary"
          onClick={() => {
            onClose();
            goToStepIndex(0);
          }}
        >
          Cancel
        </Button>
        <FormWizardProgressBtns className={css.progressControls} />
      </div>
    </ModalFooter>
  );
};

export default FormWizardActions;
