import { useEffect } from 'react';

interface ThemeProps {
  active?: boolean;
}

const usePortoTheme = ({ active = false }: ThemeProps) => {
  useEffect(() => {
    document.documentElement.classList.add(
      `${active ? 'porto' : 'anchorage'}-dark`,
    );
    return () => {
      document.documentElement.classList.remove(
        `${active ? 'porto' : 'anchorage'}-dark`,
      );
    };
  }, [active]);
};

export default usePortoTheme;
