import React from 'react';

export default function TagIcon(props: SVGProps): JSX.Element {
  const { title = 'Tag', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <g clipPath="url(#clip0_1709_89168)">
        <path d="M14.056 21.056h0l-.004.005A1.455 1.455 0 0113 21.5c-.415 0-.788-.165-1.056-.434l-9-9A1.495 1.495 0 012.5 11V4c0-.824.676-1.5 1.5-1.5h7c.415 0 .788.165 1.056.434l9 9c.271.27.444.655.444 1.066 0 .414-.165.785-.439 1.052h0l-.005.004-7 7zM3.5 5.5c0 1.106.894 2 2 2 1.106 0 2-.894 2-2 0-1.106-.894-2-2-2-1.106 0-2 .894-2 2z" />
      </g>
      <defs>
        <clipPath id="clip0_1709_89168">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
