import React from 'react';

export default function TradeDashboardIcon(props: SVGProps): JSX.Element {
  const { title = 'TradeDashboard', ...restProps } = props;

  return (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5625 18.2651V9H17.4375V18.2651C16.5718 18.51 15.9375 19.3059 15.9375 20.25V23.25C15.9375 24.1941 16.5718 24.99 17.4375 25.2349V28.5H18.5625V25.2349C19.4282 24.99 20.0625 24.1941 20.0625 23.25V20.25C20.0625 19.3059 19.4282 18.51 18.5625 18.2651ZM17.0625 20.25C17.0625 19.7322 17.4822 19.3125 18 19.3125C18.5178 19.3125 18.9375 19.7322 18.9375 20.25V23.25C18.9375 23.7678 18.5178 24.1875 18 24.1875C17.4822 24.1875 17.0625 23.7678 17.0625 23.25V20.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3125 10.5V12.2651C26.1782 12.51 26.8125 13.3059 26.8125 14.25V17.25C26.8125 18.1941 26.1782 18.99 25.3125 19.2349V27H24.1875V19.2349C23.3218 18.99 22.6875 18.1941 22.6875 17.25V14.25C22.6875 13.3059 23.3218 12.51 24.1875 12.2651V10.5H25.3125ZM24.75 13.3125C24.2322 13.3125 23.8125 13.7322 23.8125 14.25V17.25C23.8125 17.7678 24.2322 18.1875 24.75 18.1875C25.2678 18.1875 25.6875 17.7678 25.6875 17.25V14.25C25.6875 13.7322 25.2678 13.3125 24.75 13.3125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8125 25.2349V27H10.6875V25.2349C9.82181 24.99 9.1875 24.1941 9.1875 23.25V15.75C9.1875 14.8059 9.82181 14.01 10.6875 13.7651V10.5H11.8125V13.7651C12.6782 14.01 13.3125 14.8059 13.3125 15.75V23.25C13.3125 24.1941 12.6782 24.99 11.8125 25.2349ZM10.3125 15.75C10.3125 15.2322 10.7322 14.8125 11.25 14.8125C11.7678 14.8125 12.1875 15.2322 12.1875 15.75V23.25C12.1875 23.7678 11.7678 24.1875 11.25 24.1875C10.7322 24.1875 10.3125 23.7678 10.3125 23.25V15.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18ZM18 34.875C8.68019 34.875 1.125 27.3198 1.125 18C1.125 8.68019 8.68019 1.125 18 1.125C27.3198 1.125 34.875 8.68019 34.875 18C34.875 27.3198 27.3198 34.875 18 34.875Z"
      />
    </svg>
  );
}
