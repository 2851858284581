import type { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { ErrorStack } from './_types';

import UIDeveloperError from './UIDeveloperError';

export type ClientError =
  | Error
  | WrappedError
  | GraphQLError
  | ApolloError
  | UIDeveloperError;

export default class WrappedError extends Error {
  info: string;

  cause: ClientError;

  componentStack: string | undefined;

  constructor(info: string, error: Error, stack?: ErrorStack) {
    // Annotate the original error message with the provided info
    super(`${info} [${error.message}]`);
    // Set the name of this error for display purposes
    this.name = this.constructor.name;

    // Keep a reference to the provided info message
    this.info = info;

    // Keep a reference to the original error that caused this
    this.cause = error;

    // React components that create this error with `componentDidCatch` add a
    // stack trace that we want to preserve
    if (stack && stack.componentStack) {
      this.componentStack = stack.componentStack;
    }

    // captureStackTrace may not exist (IE)
    if (Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace')) {
      // Pass this.constructor to get rid of this constructor
      // in the stack trace
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(error.message).stack;
    }
  }
}
