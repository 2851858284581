import React from 'react';

export default function LayersIcon(props: SVGProps): JSX.Element {
  const { title = 'Layers', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...restProps}>
      <title>{title}</title>
      <path d="M8.995 16.541L1.62 10.806 0 12.066l9 7 9-7-1.63-1.265-7.375 5.74zM9 14.001l7.365-5.725L18 7.001l-9-7-9 7 1.63 1.265L9 14.001z" />
    </svg>
  );
}
