// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from '../index.css';

// Types
import type { IconProps } from '../_types';

import CancelIcon from '../../Icons/CancelIcon';
import CircleIcon from '../../Icons/Circle';
import CircleOutlineIcon from '../../Icons/CircleOutline';
// Components
import CompleteIcon from '../../Icons/CompleteIcon';
import ErrorIcon from '../../Icons/ErrorIcon';
// Constants
import { STEP_VARIANTS } from '../_constants';
// Hooks
import useStepContext from '../useStepContext';

const ICON_MAP: Record<STEP_VARIANTS, React.FunctionComponent<SVGProps>> = {
  [STEP_VARIANTS.complete]: CompleteIcon,
  [STEP_VARIANTS.warning]: ErrorIcon,
  [STEP_VARIANTS.active]: CircleOutlineIcon,
  [STEP_VARIANTS.incomplete]: CircleIcon,
  [STEP_VARIANTS.error]: CancelIcon,
};

const StepTrackerIcon: React.FC<IconProps> = ({
  Icon,
  className = '',
  ...restProps
}) => {
  const { variant } = useStepContext();
  const IconToDisplay = Icon || ICON_MAP[variant];
  const classes = cn({
    [className]: Boolean(className),
    [css.icon]: true,
    [css[variant]]: true,
    [css.stepIcon]: true,
  });

  return <IconToDisplay title={variant} className={classes} {...restProps} />;
};

StepTrackerIcon.displayName = 'StepTrackerIcon';

export default StepTrackerIcon;
