import React from 'react';

export type InputContextProps = {
  disabled: boolean;
  error: boolean;
  isBeingUsedWithBuildingBlocks?: boolean;
};

export const InputContext = React.createContext({
  disabled: false,
  error: false,
  isBeingUsedWithBuildingBlocks: false,
});

export default (): InputContextProps => React.useContext(InputContext);
