import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './index.css';

export type MenuPosition =
  | 'bottomCenter'
  | 'bottomLeft'
  | 'bottomRight'
  | 'topCenter'
  | 'topLeft'
  | 'topRight';

export type MenuTrigger = 'click' | 'hover';

type Props = PropsWithChildren & {
  className?: string;
  closeOnOptionClick?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  placement?: MenuPosition;
  trigger?: MenuTrigger;
  visible?: boolean;
  // All other props
  [x: string]: any;
};

const DropdownMenu = (
  {
    children,
    className = '',
    closeOnOptionClick = false,
    disabled = false,
    onClick,
    placement = 'bottomLeft',
    trigger = 'hover',
    visible = true,
    ...restProps
  }: Props,
): React.ReactElement | null => {
  if (disabled) {
    return null;
  }

  const classes = classnames({
    [styles.dropdownMenu]: true,
    [styles[placement]]: true,
    [className]: Boolean(className),
    [styles.dropdownMenuOnHover]: trigger === 'hover',
    [styles.dropdownMenuOnClick]: trigger === 'click' && visible,
  });

  const onClickFn = closeOnOptionClick ? onClick : undefined;

  return (
    <div
      data-testid="dropdown-menu"
      className={classes}
      onClick={onClickFn}
      onKeyDown={onClickFn}
      role="button"
      tabIndex={-1}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default DropdownMenu;
