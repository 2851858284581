import * as React from 'react';

type Props = React.HTMLAttributes<SVGElement>;

const GasIcon: React.FunctionComponent<Props> = (props) => {
  const { title = 'Gas', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 2.5C3.33947 2.5 2.5 3.33947 2.5 4.375V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H10.625C11.6605 17.5 12.5 16.6605 12.5 15.625V9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10V15.625C13.75 16.6605 14.5895 17.5 15.625 17.5C16.6605 17.5 17.5 16.6605 17.5 15.625V9.375H16.25V15.625C16.25 15.9702 15.9702 16.25 15.625 16.25C15.2798 16.25 15 15.9702 15 15.625V10C15 8.96447 14.1605 8.125 13.125 8.125H12.5V4.375C12.5 3.33947 11.6605 2.5 10.625 2.5H4.375ZM3.75 4.375C3.75 4.02982 4.02982 3.75 4.375 3.75H10.625C10.9702 3.75 11.25 4.02982 11.25 4.375V8.125H3.75V4.375ZM3.75 9.375V15.625C3.75 15.9702 4.02982 16.25 4.375 16.25H10.625C10.9702 16.25 11.25 15.9702 11.25 15.625V9.375H3.75Z"
      />
      <path d="M15.1072 3.75H14.375V5H15.1072C15.273 5 15.432 5.06585 15.5492 5.18306L16.0669 5.70083C16.1842 5.81804 16.25 5.97701 16.25 6.14277V8.125H17.5V6.14277C17.5 5.64549 17.3025 5.16857 16.9508 4.81694L16.4331 4.29917C16.0814 3.94754 15.6045 3.75 15.1072 3.75Z" />
    </svg>
  );
};

export default GasIcon;
