import React from 'react';

export default function SortIcon(props: SVGProps): JSX.Element {
  const { title = 'Sort', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M13.75 4.634l-2.683 2.683-.884-.884 3.308-3.308a1.25 1.25 0 011.768 0l3.308 3.308-.884.884L15 4.634V16.25h-1.25V4.634zM8.933 12.683L6.25 15.366V3.75H5v11.616l-2.683-2.683-.884.884 3.308 3.308a1.25 1.25 0 001.768 0l3.308-3.308-.884-.884z" />
    </svg>
  );
}
