// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  // All other props
  [x: string]: any;
};

const Header = (
  { children, className = '', ...restProps }: Props,
): React.ReactElement => {
  const classes = cn({
    [styles.header]: true,
    [className]: Boolean(className),
  });

  return (
    <header data-testid="card-header" className={classes} {...restProps}>
      {children}
    </header>
  );
};

export default Header;
