import React from 'react';

export default function UnarchiveIcon(props: SVGProps): JSX.Element {
  const { title = 'Unarchive', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M19 3.003H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 12h-4c0 1.65-1.35 3-3 3s-3-1.35-3-3H5v-10h14v10zm-7-8l-4 4h2v3h4v-3h2l-4-4z" />
    </svg>
  );
}
