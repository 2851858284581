import React from 'react';
import ReactDOM from 'react-dom';

// Types
import type { ModalBackdropProps, ModalBaseProps } from './_types';

// Components
import ModalBackdrop from './Backdrop';
import ModalWrapper from './Wrapper';
import { ModalContext } from './useModalContext';

export type Props = ModalBackdropProps & ModalBaseProps;

const Modal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose = (): void => {},
  children,
  hasCustomWrapper = false,
  wrapperClassName = '',
  ...restProps
}) => {
  if (typeof document === 'undefined') {
    return null;
  }

  const el$ = document.getElementById('modal-root');

  if (!el$) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ onClose, isOpen }}>
      <ModalBackdrop {...restProps}>
        {hasCustomWrapper ? (
          children
        ) : (
          <ModalWrapper className={wrapperClassName}>{children}</ModalWrapper>
        )}
      </ModalBackdrop>
    </ModalContext.Provider>,
    el$
  );
};

export default Modal;
