// Utils
import classNames from 'classnames';
import React from 'react';
// Components
import ReactSelect, {
  Option,
  OptionValues,
  ReactSelectProps,
} from 'react-select';

import styles from './index.css';
// Styles
import 'react-select/dist/react-select.css';

// Export Option type
export type {
  Option as OptionDeprecated,
  OptionValues as OptionValuesDeprecated,
} from 'react-select';

type OnChangeFn<TValue = OptionValues> = (
  opt: Option<TValue> | Array<Option<TValue>> | null,
) => void;

interface CommonProps<TValue = OptionValues>
  extends React.HTMLAttributes<HTMLDivElement> {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  innerSelectProps?: ReactSelectProps<TValue>;
  isActive?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  noResultsText?: string;
  onChange: OnChangeFn<TValue>;
  onOpen?: () => void;
  options: Array<Option<TValue>>;
  placeholder?: string;
  selectedItem?: Option<TValue>;
}

interface WithLabelProps<TValue> extends CommonProps<TValue> {
  label: string;
  // Required for accessibility
  name: string;
}

interface WithoutLabelProps<TValue> extends CommonProps<TValue> {
  label?: undefined;
  name?: string | undefined;
}

type Props<TValue> = WithLabelProps<TValue> | WithoutLabelProps<TValue>;

// See all custom props here:
// https://github.com/JedWatson/react-select/tree/v1.x#select-props
function SelectDeprecated<TValue = OptionValues>(
  {
    autoFocus,
    className = '',
    disabled = false,
    helperText,
    innerSelectProps = {},
    isError,
    isLoading = false,
    label,
    name = 'select-input',
    noResultsText = 'No results',
    onChange,
    onOpen,
    options,
    placeholder = 'Select an item',
    selectedItem,
    ...restProps
  }: Props<TValue>,
): React.ReactElement {
  const wrapperClasses = classNames({
    [styles.selectWrapper]: true,
    [styles.error]: isError,
    [className]: Boolean(className),
  });

  const innerSelectClassName =
    (innerSelectProps && innerSelectProps.className) || '';

  const selectClasses = classNames({
    [styles.select]: true,
    [innerSelectClassName]: Boolean(innerSelectClassName),
  });

  const arrowRenderer = (): JSX.Element => (
    <svg
      className={styles.arrow}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79708 7.52588L11 11.7288L15.2029 7.52588L16.5 8.82296L11 14.323L5.5 8.82296L6.79708 7.52588Z"
        fill="currentColor"
      />
    </svg>
  );

  const inputProps = {
    autoComplete: 'off',
    id: name,
    injectStyles: false,
  };

  return (
    <div className={wrapperClasses} {...restProps}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <ReactSelect<TValue>
        autoFocus={autoFocus}
        arrowRenderer={arrowRenderer}
        clearable={false}
        disabled={disabled}
        inputProps={inputProps}
        isLoading={isLoading}
        name={name}
        noResultsText={noResultsText}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={selectedItem}
        onOpen={onOpen}
        {...innerSelectProps}
        className={selectClasses}
      />
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </div>
  );
}

export default SelectDeprecated;
