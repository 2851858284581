import * as React from 'react';

type Props = React.HTMLAttributes<SVGElement>;

const EmailKeyIcon: React.FunctionComponent<Props> = (props) => {
  const { title = 'EmailKey', ...restProps } = props;

  return (
    <svg viewBox="0 0 45 36" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M30 24.8787L26.5607 21.4393L24.4393 23.5607L29.3787 28.5C30.5503 29.6716 32.4497 29.6716 33.6213 28.5L38.5607 23.5607L36.4393 21.4393L33 24.8787V15H30V24.8787Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 0C2.01472 0 0 2.01472 0 4.5V25.5C0 27.9853 2.01472 30 4.5 30H20.2733C22.6951 33.618 26.8194 36 31.5 36C38.9558 36 45 29.9558 45 22.5C45 19.2851 43.8762 16.3326 42 14.014V4.5C42 2.01472 39.9853 0 37.5 0H4.5ZM39 11.2733V4.95249L33.8899 9.21089C35.7538 9.54386 37.4852 10.2594 39 11.2733ZM29.2285 9.19028L36.6568 3H5.34331L20.0869 15.2863C22.0941 12.1172 25.385 9.8415 29.2285 9.19028ZM18.7431 18.0716L16.1831 15.9383L5.12132 27H18.7682C18.2707 25.5925 18 24.0779 18 22.5C18 20.9489 18.2616 19.4588 18.7431 18.0716ZM13.8689 14.0098L3 24.8787V4.95237L13.8689 14.0098ZM21 22.5C21 16.701 25.701 12 31.5 12C37.299 12 42 16.701 42 22.5C42 28.299 37.299 33 31.5 33C25.701 33 21 28.299 21 22.5Z"
      />
    </svg>
  );
};

export default EmailKeyIcon;
