import React from 'react';

export default function AccountBalanceIcon(props: SVGProps): JSX.Element {
  const { title = 'AccountBalance', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <path d="M24.5 21V22.1667C24.5 23.4558 23.4558 24.5 22.1667 24.5H5.83333C4.54417 24.5 3.5 23.4558 3.5 22.1667V5.83333C3.5 4.54417 4.54417 3.5 5.83333 3.5H22.1667C23.4558 3.5 24.5 4.54417 24.5 5.83333V7H14C12.7108 7 11.6667 8.04417 11.6667 9.33333V18.6667C11.6667 19.9558 12.7108 21 14 21H24.5ZM14 18.6667H25.6667V9.33333H14V18.6667ZM18.6667 15.75C17.6983 15.75 16.9167 14.9683 16.9167 14C16.9167 13.0317 17.6983 12.25 18.6667 12.25C19.635 12.25 20.4167 13.0317 20.4167 14C20.4167 14.9683 19.635 15.75 18.6667 15.75Z" />
    </svg>
  );
}
