export default function sanitizeNumberInputValue(
  value: string,
  // Number of decimal places
  decimalPlaces?: number
): string {
  const sanitizedValue = value.replace(/[^\d.]+/g, '').trim();

  const [val, dec] = sanitizedValue.split('.');
  const hasDecimal = dec !== undefined;

  // This is so that we return the dot in '100.' if there are no decimals
  if (!hasDecimal) {
    return sanitizedValue;
  }

  // If there is a decimal (can be just a .) and
  // value is not set, set the value to 0.
  // BigInt is used to be able to handle big values
  const finalValue = val ? BigInt(val) : 0;
  // If decimal places are explicitly zero, return the final value
  // (so we don't return the decimal places dot)
  if (decimalPlaces === 0) {
    return finalValue.toString();
  }

  const decimals = decimalPlaces ? dec.substring(0, decimalPlaces) : dec;

  return `${finalValue}.${decimals}`;
}
