import { SentryEnvironments } from 'utils/sentry';

export function getSplitEnvOptions() {
  if (typeof window !== 'undefined') {
    switch (window?.env?.SENTRY_ENVIRONMENT) {
      case SentryEnvironments.STAGING:
        return {
          apiKey: 'r9nhat14obp113p6fgha37ttdjhpsdn9gm0o',
        };
      case SentryEnvironments.PRODUCTION:
        return {
          apiKey: 'pplvshnc6angjvl71bprurs5gba77is6ghs1',
        };
    }
  }

  // We need to do this just in case it doesn't have a window
  // otherwise, it will break.
  return {
    apiKey: 'qdko9v1bakt26peg3rpce8s2orlc3l30i4ab',
  };
}

const FEATURE_FLAG_DEFAULTS = {
  PORTO_SIGNUP_ENABLED: { treatment: 'off', config: {} },
  TEMP_404: { treatment: 'off', config: {} },
  TEMP_LP: { treatment: 'off', config: {} },
};

type FeatureFlags = keyof typeof FEATURE_FLAG_DEFAULTS;

export const FEATURE_FLAGS: { [FeatureFlag in FeatureFlags]: FeatureFlag } =
  Object.keys(FEATURE_FLAG_DEFAULTS).reduce(
    (acc, featureFlag) => {
      return { ...acc, [featureFlag]: featureFlag };
    },
    {} as { [FeatureFlag in FeatureFlags]: FeatureFlag },
  );

export const SPLIT_OPTIONS = {
  defaults: FEATURE_FLAG_DEFAULTS,
  options: {
    ...getSplitEnvOptions(),
    project: 'onboarding',
    flagType: 'organization',
    debug: false,
    trafficType: 'organization',
    trafficKey: 'onboarding',
  },
};
