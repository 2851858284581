import classnames from 'classnames';
import React from 'react';

import styles from '../index.css';

type Props = {
  children?: React.ReactElement[];
  className?: string;
  // All other props
  [x: string]: any;
};

const DataContainerHeader = ({
  className = '',
  children,
  ...restProps
}: Props): React.ReactElement => (
  <div
    className={classnames(styles.header, className)}
    data-testid="data-container-header"
    {...restProps}
  >
    {children}
  </div>
);

export default DataContainerHeader;
