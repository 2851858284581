import React from 'react';

// Types
import type { StepProps } from '../_types';

// Components

/* eslint-disable import/no-cycle */
import StepTracker from '../StepTracker';
import StepTrackerConnector from './StepTrackerConnector';
import StepTrackerContentWrapper from './StepTrackerContentWrapper';
import StepTrackerDescription from './StepTrackerDescription';
import StepTrackerIcon from './StepTrackerIcon';
import StepTrackerStepWrapper from './StepTrackerStepWrapper';
import StepTrackerTitle from './StepTrackerTitle';

const StepTrackerStep: React.FC<StepProps> = ({
  title,
  description,
  substeps,
  isHighlighted = false,
  ...restProps
}) => (
  <StepTrackerStepWrapper {...restProps}>
    <StepTrackerConnector />
    <StepTrackerIcon />
    <StepTrackerContentWrapper>
      <StepTrackerTitle>{title}</StepTrackerTitle>
      {description && (
        <StepTrackerDescription isHighlighted={isHighlighted}>
          {description}
        </StepTrackerDescription>
      )}
      {substeps && <StepTracker steps={substeps} />}
    </StepTrackerContentWrapper>
  </StepTrackerStepWrapper>
);

StepTrackerStep.displayName = 'StepTrackerStep';

export default StepTrackerStep;
