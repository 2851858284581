import React from 'react';

function ImageIcon(props: SVGProps): JSX.Element {
  const { title = 'ImageIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M14 12.6667V3.33333C14 2.59667 13.4033 2 12.6667 2H3.33333C2.59667 2 2 2.59667 2 3.33333V12.6667C2 13.4033 2.59667 14 3.33333 14H12.6667C13.4033 14 14 13.4033 14 12.6667ZM5.66667 9L7.33333 11.0033L9.66667 8L12.6667 12H3.33333L5.66667 9Z" />
    </svg>
  );
};

export default ImageIcon;
