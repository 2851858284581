import React from 'react';

export default function EntityIcon(props: SVGProps): JSX.Element {
  const { title = 'Entity', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M4.73684 9.45H2.63158V16.8H4.73684V9.45ZM11.0526 9.45H8.94737V16.8H11.0526V9.45ZM20 18.9H0V21H20V18.9ZM17.3684 9.45H15.2632V16.8H17.3684V9.45ZM10 2.373L15.4842 5.25H4.51579L10 2.373ZM10 0L0 5.25V7.35H20V5.25L10 0Z" />
    </svg>
  );
}
