import React from 'react';

export default function CheckboxCheckedIcon(props: SVGProps): JSX.Element {
  const { title = 'CheckboxChecked', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5Zm4.646 15.066a.5.5 0 0 0 .708 0l8.294-8.294a.5.5 0 0 0 0-.706l-.704-.71a.5.5 0 0 0-.709-.001l-6.882 6.882a.5.5 0 0 1-.707 0l-2.882-2.874a.5.5 0 0 0-.707 0l-.703.703a.5.5 0 0 0 0 .708l4.292 4.292Z"
      />
    </svg>
  );
}
