// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from '../index.css';

// Constants
import { STEP_VARIANTS } from '../_constants';
// Hooks
import useStepContext from '../useStepContext';

const StepTrackerConnector: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className = '',
  ...restProps
}) => {
  const { variant } = useStepContext();

  const classes = cn({
    [className]: Boolean(className),
    [css.line]: true,
    [css[variant]]: true,
    [css.incomplete]:
      variant === STEP_VARIANTS.active || variant === STEP_VARIANTS.incomplete,
  });

  return <div className={classes} {...restProps} />;
};

StepTrackerConnector.displayName = 'StepTrackerConnector';

export default StepTrackerConnector;
