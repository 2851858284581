import React from 'react';

export default function DollarSignIcon(props: SVGProps): JSX.Element {
  const { title = 'DollarSignIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 21 36" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>

      <path d="M11.6 15.8C7.06001 14.62 5.60001 13.41 5.60001 11.51C5.60001 9.33 7.61 7.8 11 7.8C14.56 7.8 15.88 9.5 16 12H20.42C20.29 8.55 18.18 5.41 14 4.38V0H8V4.32C4.12 5.17 1 7.67 1 11.54C1 16.16 4.83 18.46 10.4 19.8C15.41 21 16.4 22.75 16.4 24.63C16.4 26 15.43 28.2 11 28.2C6.88 28.2 5.25001 26.35 5.04001 24H0.630005C0.880005 28.38 4.15 30.83 8 31.66V36H14V31.7C17.89 30.95 21 28.7 21 24.59C21 18.93 16.14 16.99 11.6 15.8Z" />
    </svg>
  );
}
