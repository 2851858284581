import React from 'react';

// Types
import { DelayedLoaderProps } from './_types';

// Components
import Loader from './SimpleLoader';

export default function DelayedLoader(
  { children = null, delay, ...props }: DelayedLoaderProps,
): CommonComponentReturn {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const id = setTimeout(
      () => setIsVisible(true),
      typeof delay === 'boolean' ? 1000 : delay,
    );

    return (): void => clearTimeout(id);
  });

  if (isVisible) {
    return <Loader {...props} />;
  }

  return React.isValidElement(children) ? children : <>{children}</>;
}
