// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import * as styles from './index.css';

// Types
import { SimpleLoaderProps } from '../_types';

export default function SimpleLoader({
  className = '',
  isCentered = true,
  svgProps,
  title = 'Loader',
  ...restProps
}: SimpleLoaderProps): CommonComponentReturn {
  const classes = cn({
    [styles.loader]: true,
    [styles.centered]: isCentered,
    [className]: Boolean(className),
  });

  return (
    <div className={classes} {...restProps}>
      <svg viewBox="25 25 50 50" {...svgProps}>
        {title ? <title>{title}</title> : null}
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
}
