import React from 'react';

export default function PartyIcon(props: SVGProps): JSX.Element {
  const { title = 'Party', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0155 10.7963C15.7538 10.5598 15.3554 10.5598 15.0937 10.7963L12.0858 13.5144C11.9416 13.6447 11.8593 13.8301 11.8593 14.0245V17.1875C11.8593 17.5672 12.1671 17.875 12.5468 17.875H13.0624V15.8125C13.0624 15.0532 13.678 14.4375 14.4374 14.4375H16.4999C17.2593 14.4375 17.8749 15.0532 17.8749 15.8125V17.875H18.5624C18.9421 17.875 19.2499 17.5672 19.2499 17.1875V14.0245C19.2499 13.8301 19.1676 13.6447 19.0234 13.5144L16.0155 10.7963ZM16.4999 17.875V15.8125H14.4374V17.875H16.4999ZM14.1718 9.77616C14.9571 9.0665 16.1521 9.0665 16.9374 9.77617L19.9453 12.4942C20.378 12.8853 20.6249 13.4412 20.6249 14.0245V17.1875C20.6249 18.3266 19.7015 19.25 18.5624 19.25H12.5468C11.4077 19.25 10.4843 18.3266 10.4843 17.1875V14.0245C10.4843 13.4412 10.7312 12.8853 11.164 12.4942L14.1718 9.77616Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81383 12.6271L5.64228 10.7987L4.67001 9.82642L2.0624 12.434C1.52543 12.971 1.52543 13.8416 2.0624 14.3786L4.67001 16.9862L5.64228 16.0139L3.6305 14.0021H9.10927V12.6271H3.81383Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49992 6.79484L12.5468 6.79484V8.16984L5.49992 8.16984V6.79484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96691 9.99829L11.5745 7.39068L8.96691 4.78306L9.93918 3.81079L12.5468 6.4184C13.0838 6.95538 13.0838 7.82598 12.5468 8.36295L9.93918 10.9706L8.96691 9.99829Z"
      />
    </svg>
  );
}
