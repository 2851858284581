import React from 'react';

export default function OverviewIcon(props: SVGProps): JSX.Element {
  const { title = 'Overview', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 3.125C0.5 1.67525 1.67525 0.5 3.125 0.5H7.5C8.94975 0.5 10.125 1.67525 10.125 3.125V6.625C10.125 8.07475 8.94975 9.25 7.5 9.25H3.125C1.67525 9.25 0.5 8.07475 0.5 6.625V3.125ZM3.125 2.25C2.64175 2.25 2.25 2.64175 2.25 3.125V6.625C2.25 7.10825 2.64175 7.5 3.125 7.5H7.5C7.98325 7.5 8.375 7.10825 8.375 6.625V3.125C8.375 2.64175 7.98325 2.25 7.5 2.25H3.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 13.625C0.5 12.1753 1.67525 11 3.125 11H7.5C8.94975 11 10.125 12.1753 10.125 13.625V18.875C10.125 20.3247 8.94975 21.5 7.5 21.5H3.125C1.67525 21.5 0.5 20.3247 0.5 18.875V13.625ZM3.125 12.75C2.64175 12.75 2.25 13.1418 2.25 13.625V18.875C2.25 19.3582 2.64175 19.75 3.125 19.75H7.5C7.98325 19.75 8.375 19.3582 8.375 18.875V13.625C8.375 13.1418 7.98325 12.75 7.5 12.75H3.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 10.125C11.875 8.67525 13.0503 7.5 14.5 7.5H18.875C20.3247 7.5 21.5 8.67525 21.5 10.125C21.5 11.5747 20.3247 12.75 18.875 12.75H14.5C13.0503 12.75 11.875 11.5747 11.875 10.125ZM14.5 9.25C14.0168 9.25 13.625 9.64175 13.625 10.125C13.625 10.6082 14.0168 11 14.5 11H18.875C19.3582 11 19.75 10.6082 19.75 10.125C19.75 9.64175 19.3582 9.25 18.875 9.25H14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 3.125C11.875 1.67525 13.0503 0.5 14.5 0.5H18.875C20.3247 0.5 21.5 1.67525 21.5 3.125C21.5 4.57475 20.3247 5.75 18.875 5.75H14.5C13.0503 5.75 11.875 4.57475 11.875 3.125ZM14.5 2.25C14.0168 2.25 13.625 2.64175 13.625 3.125C13.625 3.60825 14.0168 4 14.5 4H18.875C19.3582 4 19.75 3.60825 19.75 3.125C19.75 2.64175 19.3582 2.25 18.875 2.25H14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 17.125C11.875 15.6753 13.0503 14.5 14.5 14.5H18.875C20.3247 14.5 21.5 15.6753 21.5 17.125V18.875C21.5 20.3247 20.3247 21.5 18.875 21.5H14.5C13.0503 21.5 11.875 20.3247 11.875 18.875V17.125ZM14.5 16.25C14.0168 16.25 13.625 16.6418 13.625 17.125V18.875C13.625 19.3582 14.0168 19.75 14.5 19.75H18.875C19.3582 19.75 19.75 19.3582 19.75 18.875V17.125C19.75 16.6418 19.3582 16.25 18.875 16.25H14.5Z"
      />
    </svg>
  );
}
