import { noop } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

import { useDeepCompare } from '../../hooks';

import { UIDeveloperError, reportError } from '../../utils/errors';

import {
  FormWizardProviderProps,
  FormWizardProviderValues,
  ProgressBtnProps,
  Step,
} from './types';

const defaultGetText = (currentStep: number, maxSteps: number) =>
  `${currentStep}/${maxSteps}`;

export const FormWizardContext = createContext<FormWizardProviderValues<any>>({
  currentStepIndex: 0,
  goToStepIndex: noop,
  maxSteps: 0,
  displayedMaxSteps: 0,
  currentStepElement: {
    element: <></>,
    title: '',
    formFieldNames: [],
  },
  onSubmit: noop,
  getProgressText: () => '',
  showProgress: true,
  steps: [],
});

export const useFormWizardContext = () => useContext(FormWizardContext);

export const FormWizardProvider = <T extends FieldValues>(
  {
    startingStep = 0,
    children,
    steps,
    onSubmit,
    onInvalid = noop,
    showProgress = true,
    getProgressText = defaultGetText,
    displayedMaxSteps,
    formFieldObject,
  }: FormWizardProviderProps<T>,
) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(startingStep);

  const maxSteps = useMemo(() => steps.length - 1, [steps]);
  const isEndStep = currentStepIndex === maxSteps;

  const defaultOnNext = useCallback(() => {
    setCurrentStepIndex(Math.min(currentStepIndex + 1, maxSteps));
  }, [currentStepIndex]);

  const defaultOnPrevious = useCallback(() => {
    setCurrentStepIndex(Math.max(currentStepIndex - 1, 0));
  }, [currentStepIndex]);

  useEffect(() => {
    // We are using Sets here to remove duplicates from the array
    const fields = Array.from(new Set(Object.keys(formFieldObject)));
    const stepFields: string[] = Array.from(
      steps.reduce((acc: Set<string>, step) => {
        if (!step.formFieldNames) {
          return acc;
        }

        return new Set([...acc, ...step.formFieldNames]);
      }, new Set<string>()),
    );

    if (stepFields.length > fields.length) {
      const missingDefaultValues = stepFields.filter(
        (field) => !fields.includes(field),
      );

      const error = new UIDeveloperError(
        `FormWizardProvider: You are validating fields that do not have a default ${missingDefaultValues.join(
          ', ',
        )}`,
      );

      reportError(error);
    }

    if (stepFields.length < fields.length) {
      const missingDefaultValues = fields.filter(
        (field) => !stepFields.includes(field),
      );

      const error = new UIDeveloperError(
        `FormWizardProvider: You are missing step validation for the following fields ${missingDefaultValues.join(
          ', ',
        )}`,
      );

      reportError(error);
    }
    // Use deep compare hates formFieldObject for some reason
  }, [formFieldObject, ...useDeepCompare([steps])]);

  const currentStepElement: Step<T> = useMemo(() => {
    const x = steps[currentStepIndex];

    const defaultNextProps: ProgressBtnProps = {
      type: 'primary',
      showBtn: true,
      children: isEndStep || x.isSubmitStep ? 'Submit' : 'Next',
    };

    const defaultBackProps: ProgressBtnProps = {
      type: 'secondary',
      showBtn: true,
      isDisabled: currentStepIndex === 0,
      children: 'Back',
    };

    return {
      element: x.element,
      description: x.description,
      title: x.title,
      formFieldNames: x.formFieldNames,
      onNext: x.onNext ?? defaultOnNext,
      onPrevious: x.onPrevious ?? defaultOnPrevious,
      displayedStepNumber: x.displayedStepNumber ?? currentStepIndex + 1,
      showProgress: x.showProgress !== undefined ? x.showProgress : true,
      isSubmitStep: x.isSubmitStep ?? false,
      nextBtnProps: {
        ...defaultNextProps,
        ...(x.nextBtnProps ?? {}),
      },
      backBtnProps: {
        ...defaultBackProps,
        ...(x.backBtnProps ?? {}),
      },
    };
  }, [currentStepIndex, steps]);

  return (
    <FormWizardContext.Provider
      value={{
        currentStepIndex,
        goToStepIndex: setCurrentStepIndex,
        currentStepElement,
        maxSteps,
        onSubmit,
        onInvalid,
        getProgressText,
        showProgress,
        displayedMaxSteps: displayedMaxSteps ?? maxSteps + 1,
        steps,
      }}
    >
      {children}
    </FormWizardContext.Provider>
  );
};
