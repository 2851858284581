import React from 'react';

type Props = SVGProps & { isOutlineOnly?: boolean };

export default function UserIcon({
  isOutlineOnly = false,
  title = 'User',
  ...restProps
}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...restProps}>
      <title>{title}</title>
      {isOutlineOnly ? (
        <path d="M16 3.8C18.32 3.8 20.2 5.68 20.2 8C20.2 10.32 18.32 12.2 16 12.2C13.68 12.2 11.8 10.32 11.8 8C11.8 5.68 13.68 3.8 16 3.8ZM16 21.8C21.95 21.8 28.2 24.71 28.2 26V28.2H3.8V26C3.8 24.71 10.05 21.8 16 21.8ZM16 0C11.58 0 8 3.58 8 8C8 12.41 11.58 16 16 16C20.42 16 24 12.41 24 8C24 3.58 20.42 0 16 0ZM16 18C10.67 18 0 20.67 0 26V32H32V26C32 20.67 21.33 18 16 18Z" />
      ) : (
        <path d="M16 16C20.42 16 24 12.41 24 8C24 3.58 20.42 0 16 0C11.58 0 8 3.58 8 8C8 12.41 11.58 16 16 16ZM16 20C10.67 20 0 22.67 0 28V32H32V28C32 22.67 21.33 20 16 20Z" />
      )}
    </svg>
  );
}
