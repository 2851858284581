import React from 'react';

export default function UploadIcon(props: SVGProps): JSX.Element {
  const { title = 'Upload', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 34" {...restProps}>
      <title>{title}</title>
      <path d="M8 26H20V14H28L14 0L0 14H8V26ZM0 30H28V34H0V30Z" />
    </svg>
  );
}
