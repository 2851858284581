import React from 'react';
// Types
import type { GroupBase, MultiValueGenericProps } from 'react-select-v5';

// Styles
import styles from './index.css';

// Components
import Text from '../../../Text';

const MultiValueLabel = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
}: MultiValueGenericProps<Option, IsMulti, Group>): JSX.Element => (
  <Text size="small" className={styles.multiValueLabelText}>
    {children}
  </Text>
);

export default MultiValueLabel;
