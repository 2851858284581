import React from 'react';

export default function CodeIcon(props: SVGProps): JSX.Element {
  const { title = 'Code', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path d="M10.635 3.013l-2.5 13.75 1.23.224 2.5-13.75-1.23-.224zM13.152 6.536L16.616 10l-3.464 3.464.884.884 3.464-3.464a1.25 1.25 0 000-1.768l-3.464-3.464-.884.884zM6.692 6.692L3.384 10l3.308 3.308-.884.884L2.5 10.884a1.25 1.25 0 010-1.768l3.308-3.308.884.884z" />
    </svg>
  );
}
