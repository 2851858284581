import React, { useCallback } from 'react';

import type { AnchorageComponentHook } from '../../types/hooks';

import type { ModalBackdropDomProps, UseModalAdditionalProps } from './_types';

import type { Props as ModalProps } from './Modal';

export type UseModalProps = Omit<ModalProps, keyof ModalBackdropDomProps>;

const useModal: AnchorageComponentHook<
  UseModalAdditionalProps,
  UseModalProps
> = (
  defaultProps = { isOpen: false }
): [UseModalAdditionalProps & UseModalProps, UseModalProps] => {
  const { isOpen: initialState, ...restProps } = defaultProps;

  const [isOpen, setIsOpen] = React.useState<boolean>(initialState);

  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return [
    {
      isOpen,
      onOpen,
      onClose,
      ...restProps,
    },
    {
      isOpen,
      onClose,
      ...restProps,
    },
  ];
};

export default useModal;
