/* eslint-disable react/display-name */
// Utils
import classnames from 'classnames';
import React from 'react';

import { UIDeveloperError, reportError } from '../../../utils/errors';

// Styles
import styles from './index.css';

import { LoaderProps } from '../../Loader/_types';
// Types
import {
  RemoveSnackbarSignatureWithType,
  SnackbarConfig,
  SnackbarType,
} from '../_types';

// Components
import Button from '../../Button/index';
import { CompleteIcon, ErrorIcon } from '../../Icons';
import Loader from '../../Loader';
import ActionsWrapper from '../../shared/ActionsWrapper';

// Local Types
type IconComponent = React.FunctionComponent<SVGProps & LoaderProps>;

type SnackbarProps = SnackbarConfig & {
  children?: React.ReactNode;
  className?: string;
  removeSnackbar: RemoveSnackbarSignatureWithType;
  [key: string]: any;
};

const SnackbarLoader: IconComponent = (
  { className, width, height, style, ...restProps },
) => (
  <Loader
    className={className}
    style={{
      width: `${width}px`,
      height: `${height}px`,
      padding: '0',
      alignItems: 'flex-start',
      flex: '0 0 auto',
      ...style,
    }}
    {...restProps}
  />
);

// Local Constants
const ICON_MAP: Record<SnackbarType, IconComponent> = {
  error: ErrorIcon,
  success: CompleteIcon,
  loading: SnackbarLoader,
};

const Snackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      className = '',
      type,
      icon,
      text,
      subtext,
      actionProps,
      id,
      removeSnackbar,
      ...restProps
    },
    ref,
  ) => {
    const classes = classnames([className, styles.snackbar]);
    const { className: actionClassName, ...restActionProps } =
      actionProps || {};
    const actionClasses = classnames([
      actionClassName,
      styles.snackbarActionButton,
    ]);
    const iconClasses = classnames({
      [type && styles[type]]: type,
      [styles.snackbarIcon]: true,
    });

    // This should never be possible but since flow
    // can't check TS code I am adding this check.
    if (type && icon) {
      reportError(
        new UIDeveloperError(
          `Property 'type': ${type} and 'icon' can not be used together`,
        ),
      );
    }

    const SnackbarIcon = type ? ICON_MAP[type] : icon;

    return (
      <div data-testid="snackbar" className={classes} {...restProps} ref={ref}>
        {SnackbarIcon && (
          <SnackbarIcon
            data-testid={`icon-${type}`}
            className={iconClasses}
            height={20}
            width={20}
          />
        )}

        <div className={styles.snackbarTextSection}>
          {text}
          <div className={styles.snackbarSubtext}>{subtext}</div>
        </div>

        <ActionsWrapper
          onClose={(): void => {
            removeSnackbar(id, 'dismiss');
          }}
        >
          {actionProps && (
            <Button
              className={actionClasses}
              type="tertiary"
              {...restActionProps}
            />
          )}
        </ActionsWrapper>
      </div>
    );
  },
);

export default Snackbar;
