// @Generated
// This is a generated file, DO NOT MODIFY
import CheckboxInput from './CheckboxInput';
import CheckboxLabel from './CheckboxLabel';
import CheckboxWrapper from './CheckboxWrapper';

export { default as CheckboxInput } from './CheckboxInput';
export { default as CheckboxLabel } from './CheckboxLabel';
export { default as CheckboxWrapper } from './CheckboxWrapper';

export default {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
};
