import React from 'react';

export default function AccountIcon(props: SVGProps): JSX.Element {
  const { title = 'Account', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 7.08465C6.875 5.91583 5.94278 4.98438 4.8125 4.98438C3.68223 4.98437 2.75 5.91583 2.75 7.08465C2.75 7.42277 2.82797 7.74015 2.96567 8.02104L1.73104 8.62628C1.5028 8.16069 1.375 7.63666 1.375 7.08465C1.375 5.17418 2.90521 3.60937 4.8125 3.60938C6.71979 3.60938 8.25 5.17418 8.25 7.08465C8.25 7.63666 8.12221 8.16069 7.89396 8.62628L6.65933 8.02104C6.79703 7.74015 6.875 7.42277 6.875 7.08465Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 10.0065C6.875 8.8377 5.94278 7.90625 4.8125 7.90625C3.68223 7.90625 2.75 8.8377 2.75 10.0065C2.75 10.3446 2.82797 10.662 2.96567 10.9429L1.73104 11.5482C1.50279 11.0826 1.375 10.5585 1.375 10.0065C1.375 8.09606 2.90521 6.53125 4.8125 6.53125C6.71979 6.53125 8.25 8.09606 8.25 10.0065C8.25 10.5585 8.12221 11.0826 7.89396 11.5482L6.65933 10.9429C6.79703 10.662 6.875 10.3446 6.875 10.0065Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 12.8906C6.875 11.7515 5.95159 10.8281 4.8125 10.8281C3.67341 10.8281 2.75 11.7515 2.75 12.8906C2.75 14.0297 3.67341 14.9531 4.8125 14.9531C5.95159 14.9531 6.875 14.0297 6.875 12.8906ZM4.8125 9.45312C6.71098 9.45313 8.25 10.9921 8.25 12.8906C8.25 14.7891 6.71098 16.3281 4.8125 16.3281C2.91402 16.3281 1.375 14.7891 1.375 12.8906C1.375 10.9921 2.91402 9.45312 4.8125 9.45312Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0213 12.5884C14.5385 12.5884 14.1471 12.9798 14.1471 13.4626C14.1471 13.9454 14.5385 14.3368 15.0213 14.3368C15.5041 14.3368 15.8955 13.9454 15.8955 13.4626C15.8955 12.9798 15.5041 12.5884 15.0213 12.5884ZM12.7721 13.4626C12.7721 12.2204 13.7791 11.2134 15.0213 11.2134C16.2635 11.2134 17.2705 12.2204 17.2705 13.4626C17.2705 14.7048 16.2635 15.7118 15.0213 15.7118C13.7791 15.7118 12.7721 14.7048 12.7721 13.4626Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6487 9.96875C9.6487 8.82966 10.5721 7.90625 11.7112 7.90625H18.5151C19.6542 7.90625 20.5776 8.82966 20.5776 9.96875V16.5889C20.5776 17.728 19.6542 18.6514 18.5151 18.6514H11.7112C10.5721 18.6514 9.6487 17.728 9.6487 16.5889V9.96875ZM11.7112 9.28125C11.3315 9.28125 11.0237 9.58905 11.0237 9.96875V16.5889C11.0237 16.9686 11.3315 17.2764 11.7112 17.2764H18.5151C18.8948 17.2764 19.2026 16.9686 19.2026 16.5889V9.96875C19.2026 9.58905 18.8948 9.28125 18.5151 9.28125H11.7112Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28125 15.2998C9.28125 14.6665 9.7947 14.153 10.4281 14.153C11.0614 14.153 11.5749 14.6665 11.5749 15.2998V15.851C11.5749 16.4844 11.0614 16.9979 10.4281 16.9979C9.7947 16.9979 9.28125 16.4844 9.28125 15.851V15.2998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28125 10.8904C9.28125 10.257 9.7947 9.74353 10.4281 9.74353C11.0614 9.74353 11.5749 10.257 11.5749 10.8904V11.4415C11.5749 12.0749 11.0614 12.5884 10.4281 12.5884C9.7947 12.5884 9.28125 12.0749 9.28125 11.4415V10.8904Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6773 17.9639V19.25H11.3023V17.9639H12.6773Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.924 17.9639V19.25H17.549V17.9639H18.924Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.583 12.6832H18.0528V14.0582H16.583V12.6832Z"
      />
    </svg>
  );
}
