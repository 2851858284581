import React from 'react';

function BulletListIcon(props: SVGProps): JSX.Element {
  const { title = 'Bullet List', ...restProps } = props;

  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M3 10.5H6V7.5H3V10.5ZM3 14.25H6V11.25H3V14.25ZM3 6.75H6V3.75H3V6.75ZM6.75 10.5H15.75V7.5H6.75V10.5ZM6.75 14.25H15.75V11.25H6.75V14.25ZM6.75 3.75V6.75H15.75V3.75H6.75Z" />
    </svg>
  );
}

export default BulletListIcon;
