import React from 'react';

export default function ErrorOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'ErrorOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M22 30H26V34H22V30ZM22 14H26V26H22V14ZM23.99 4C12.94 4 4 12.95 4 24C4 35.05 12.94 44 23.99 44C35.04 44 44 35.05 44 24C44 12.95 35.04 4 23.99 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40Z" />
    </svg>
  );
}
