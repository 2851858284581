import classnames from 'classnames';
import React from 'react';

import styles from '../index.css';

type Props = {
  actions?: React.ReactElement[];
  className?: string;
  // All other props
  [x: string]: any;
};

const DataContainerActions = ({
  actions = [],
  className = '',
  ...restProps
}: Props): React.ReactElement => (
  <div
    className={classnames(styles.actionsContainer, className)}
    data-testid="data-container-actions"
    {...restProps}
  >
    {actions.map((action: React.ReactElement, index: number) => (
      <span
        key={index}
        data-testid={`data-container-action-${index}`}
        className={styles.actionContainer}
      >
        {action}
      </span>
    ))}
  </div>
);

export default DataContainerActions;
