import React from 'react';

export default function ChatIcon(props: SVGProps): JSX.Element {
  const { title = 'Chat', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M20 2H4C2.895 2 2.01 2.895 2.01 4L2 22L6 18H20C21.105 18 22 17.105 22 16V4C22 2.895 21.105 2 20 2ZM6 9H18V11H6V9ZM14 14H6V12H14V14ZM18 8H6V6H18V8Z" />
    </svg>
  );
}
