import { get } from 'lodash';
import React from 'react';
// Utils
import { FieldValues, useFormState } from 'react-hook-form';

// Styles
import css from './styles.css';

// types
import type { AddRemoveControlProps, FormArrayProps } from './types';

// Components
import Button from '../../../Button';
import { AddOutlineIcon, ChevronIcon, RemoveOutlineIcon } from '../../../Icons';
import Text from '../../../Text';

export const AddRemoveControls = <T extends FieldValues>({
  insert,
  index,
  remove,
  defaultValues,
  fields,
  ...restProps
}: AddRemoveControlProps<T>) => (
  <div className={css.addRemoveBtnContainer} {...restProps}>
    <Button
      type="tertiary"
      data-testid={`add-btn-${index}`}
      Icon={() => <AddOutlineIcon className={css.controlBtn} />}
      onClick={() => insert(index + 1, defaultValues)}
    />
    {fields.length > 0 && (
      <Button
        type="tertiary"
        data-testid={`remove-btn-${index}`}
        onClick={() => remove(index)}
        Icon={() => <RemoveOutlineIcon className={css.controlBtn} />}
      />
    )}
  </div>
);

const FormArray = <T extends FieldValues>({
  control,
  name,
  renderFields,
  isMoveable = true,
  emptyMessage = 'This field is currently empty',
  fields,
  move,
  ...restProps
}: FormArrayProps<T>) => {
  const { errors } = useFormState({ control });
  const fieldError = get(errors, name);

  if (fields.length === 0) {
    return (
      <Text className={css.emptyMessage} {...restProps}>
        {emptyMessage}
      </Text>
    );
  }

  return (
    <div {...restProps}>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div className={css.formContainer}>{renderFields(index)}</div>
          {isMoveable && (
            <div className={css.arrayControls}>
              <Button
                data-testid={`move-up-btn-${index}`}
                type="secondary"
                disabled={index === 0}
                onClick={() => move(index, index - 1)}
                Icon={() => (
                  <ChevronIcon className={css.controlBtn} direction="up" />
                )}
              />
              <Button
                data-testid={`move-down-btn-${index}`}
                type="secondary"
                disabled={index === fields.length - 1}
                onClick={() => move(index, index + 1)}
                Icon={() => (
                  <ChevronIcon className={css.controlBtn} direction="down" />
                )}
              />
            </div>
          )}
        </div>
      ))}
      {fieldError && (
        <Text className={css.error}>{fieldError.message as string}</Text>
      )}
    </div>
  );
};

export default FormArray;
