// Utils
import cn from 'classnames';
import React from 'react';

import styles from '../styles.css';

// Types
import { ButtonGroupWrapperProps } from '../_types';

import { ButtonGroupContext } from '../useButtonGroupContext';

const ButtonGroupWrapper = <T,>({
  className = '',
  selectedButton,
  setSelectedButton,
  type,
  ...restProps
}: ButtonGroupWrapperProps<T>) => (
  <ButtonGroupContext.Provider
    value={{
      selectedButton,
      setSelectedButton,
      type,
    }}
  >
    <div className={cn([styles.wrapper, className])} {...restProps} />
  </ButtonGroupContext.Provider>
);

export default ButtonGroupWrapper;
