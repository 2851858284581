import React from 'react';

export default function BrowserTransferIcon(props: SVGProps): JSX.Element {
  const { title = 'BrowserTransfer', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M12 14.25H16.0018L14.4697 12.7179L15.5304 11.6572L17.8126 13.9394C18.3983 14.5252 18.3983 15.4749 17.8126 16.0607L15.5304 18.3429L14.4697 17.2822L16.002 15.75H12V14.25Z" />
      <path d="M7.99816 11.25H12V12.75H7.99805L9.53027 14.2822L8.46961 15.3429L6.18744 13.0607C5.60166 12.4749 5.60166 11.5252 6.18744 10.9394L8.46961 8.65723L9.53027 9.71789L7.99816 11.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.25C3 4.00736 4.00736 3 5.25 3H18.75C19.9926 3 21 4.00736 21 5.25V18.75C21 19.9926 19.9926 21 18.75 21H5.25C4.00736 21 3 19.9926 3 18.75V5.25ZM5.25 4.5C4.83579 4.5 4.5 4.83579 4.5 5.25V6H19.5V5.25C19.5 4.83579 19.1642 4.5 18.75 4.5H5.25ZM19.5 7.5H4.5V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H18.75C19.1642 19.5 19.5 19.1642 19.5 18.75V7.5Z"
      />
    </svg>
  );
}
