// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  [x: string]: any;
};

// TODO (@volkan-anchor) [05/14/2021]: Create focus trap
function Wrapper(
  { className = '', children, ...restProps }: Props,
): JSX.Element {
  const classes = cn({
    [styles.wrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <div className={classes} {...restProps}>
      {children}
    </div>
  );
}

export default Wrapper;
