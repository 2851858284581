import React from 'react';

export default function UserUnionIcon(props: SVGProps): JSX.Element {
  const { title = 'UserUnion', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6875 3.8004C10.9581 3.14779 9.99421 2.75 8.9375 2.75C6.65933 2.75 4.8125 4.59683 4.8125 6.875C4.8125 9.15317 6.65933 11 8.9375 11C9.99421 11 10.9581 10.6022 11.6875 9.9496C12.4172 10.6028 13.381 11 14.4375 11C16.7157 11 18.5625 9.15317 18.5625 6.875C18.5625 4.59683 16.7157 2.75 14.4375 2.75C13.381 2.75 12.4172 3.14719 11.6875 3.8004ZM8.9375 4.125C7.41872 4.125 6.1875 5.35622 6.1875 6.875C6.1875 8.39378 7.41872 9.625 8.9375 9.625C9.66928 9.625 10.3343 9.33966 10.8276 8.87259C10.5173 8.31309 10.3333 7.67385 10.3142 6.99338C10.3131 6.95406 10.3125 6.91459 10.3125 6.875C10.3125 6.83269 10.3131 6.79053 10.3144 6.74853C10.3348 6.07108 10.5185 5.43469 10.8276 4.87741C10.3343 4.41034 9.66928 4.125 8.9375 4.125ZM11.6884 6.80258C11.6878 6.82664 11.6875 6.85078 11.6875 6.875C11.6875 6.90395 11.6879 6.93278 11.6888 6.96151C11.7345 8.44026 12.9477 9.625 14.4375 9.625C15.9563 9.625 17.1875 8.39378 17.1875 6.875C17.1875 5.35622 15.9563 4.125 14.4375 4.125C12.9429 4.125 11.7268 5.31727 11.6884 6.80258Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1875 13.0625C4.28902 13.0625 2.75 14.6015 2.75 16.5V17.875C2.75 18.6344 3.36561 19.25 4.125 19.25H19.25C20.0094 19.25 20.625 18.6344 20.625 17.875V16.5C20.625 14.6015 19.086 13.0625 17.1875 13.0625H6.1875ZM8.93725 14.4375H6.1875C5.04841 14.4375 4.125 15.3609 4.125 16.5V17.875H8.25V16.5C8.25 15.7261 8.50571 15.012 8.93725 14.4375ZM9.625 17.875H19.25V16.5C19.25 15.3609 18.3266 14.4375 17.1875 14.4375H11.6875C10.5484 14.4375 9.625 15.3609 9.625 16.5V17.875Z"
      />
    </svg>
  );
}
