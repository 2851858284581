import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import numeral from 'numeral';

import { UIDeveloperError, reportError } from '../errors';

export default function formatPercentage(
  percentage: number | string,
  format?: 'long' | 'short',
  isAlreadyPercent?: boolean
): string {
  let value = toNumber(percentage);

  // Gracefully handle this programmer error. Use 0 so that the UI does not
  // break but report the error
  if (isNaN(value) || percentage === null) {
    value = 0;
    reportError(
      new UIDeveloperError(
        `formatPercentage called with invalid input: ${percentage}`
      )
    );
  }

  const numeralFormat = format === 'short' ? '0%' : '0.00%';

  // Ex. value is 10 to represent 10%
  if (isAlreadyPercent) {
    value = value / 100;
  }

  const formattedAmount = numeral(value).format(numeralFormat);

  if (formattedAmount.replace('%', '') !== 'NaN') {
    return formattedAmount;
  }

  // Handle very small numbers that become NaN%
  // https://github.com/adamwdraper/Numeral-js/issues/596
  return numeral(0).format(numeralFormat);
}
