import React from 'react';

export default function RiskDashboardIcon(props: SVGProps): JSX.Element {
  const { title = 'RiskDashboard', ...restProps } = props;

  return (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7135 11.4455C15.7697 11.4873 13.8329 11.9548 12.0469 12.8478L7.24884 15.2468L7.75195 16.2531L12.55 13.854C13.0788 13.5896 13.6221 13.3659 14.1754 13.183C13.4043 14.0714 12.9375 15.2312 12.9375 16.5C12.9375 19.2959 15.2041 21.5625 18 21.5625C20.7959 21.5625 23.0625 19.2959 23.0625 16.5C23.0625 15.231 22.5956 14.0711 21.8243 13.1827C22.3779 13.3656 22.9216 13.5894 23.4508 13.8541L28.2488 16.2531L28.752 15.2468L23.9539 12.8478C22.1675 11.9546 20.2303 11.4871 18.286 11.4454C18.1914 11.4402 18.096 11.4375 18 11.4375C17.9038 11.4375 17.8083 11.4402 17.7135 11.4455ZM18.241 12.5698C18.0802 12.5666 17.9194 12.5666 17.7586 12.5698C15.6964 12.6945 14.0625 14.4064 14.0625 16.5C14.0625 18.6746 15.8254 20.4375 18 20.4375C20.1746 20.4375 21.9375 18.6746 21.9375 16.5C21.9375 14.4063 20.3034 12.6943 18.241 12.5698Z"
      />
      <path d="M12.55 22.146C15.9811 23.8615 20.0197 23.8615 23.4508 22.1459L28.2488 19.7469L28.752 20.7532L23.9539 23.1522C20.2061 25.0261 15.7947 25.0261 12.0469 23.1522L7.24884 20.7532L7.75195 19.7469L12.55 22.146Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18ZM18 34.875C8.68019 34.875 1.125 27.3198 1.125 18C1.125 8.68019 8.68019 1.125 18 1.125C27.3198 1.125 34.875 8.68019 34.875 18C34.875 27.3198 27.3198 34.875 18 34.875Z"
      />
    </svg>
  );
}
