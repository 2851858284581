import React from 'react';

export default function ProductTradeIcon(props: SVGProps): JSX.Element {
  const { title = 'ProductTrade', ...restProps } = props;

  return (
    <svg viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5625 11.2215C10.5225 11.3979 11.25 12.239 11.25 13.25V16.25C11.25 17.261 10.5225 18.1021 9.5625 18.2785V21.5H8.4375V18.2785C7.47746 18.1021 6.75 17.261 6.75 16.25V13.25C6.75 12.239 7.47746 11.3979 8.4375 11.2215L8.4375 0.5H9.5625L9.5625 11.2215ZM10.125 13.25C10.125 12.7322 9.70527 12.3125 9.1875 12.3125H8.8125C8.29473 12.3125 7.875 12.7322 7.875 13.25V16.25C7.875 16.7678 8.29473 17.1875 8.8125 17.1875H9.1875C9.70527 17.1875 10.125 16.7678 10.125 16.25V13.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6875 3.5L1.6875 5.22151C0.727456 5.39785 0 6.23897 0 7.25V10.25C0 11.261 0.727456 12.1021 1.6875 12.2785L1.6875 20H2.8125L2.8125 12.2785C3.77254 12.1021 4.5 11.261 4.5 10.25V7.25C4.5 6.23897 3.77254 5.39785 2.8125 5.22151L2.8125 3.5H1.6875ZM2.4375 6.3125C2.95527 6.3125 3.375 6.73223 3.375 7.25V10.25C3.375 10.7678 2.95527 11.1875 2.4375 11.1875H2.0625C1.54473 11.1875 1.125 10.7678 1.125 10.25V7.25C1.125 6.73223 1.54473 6.3125 2.0625 6.3125H2.4375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1875 18.2785V20H16.3125V18.2785C17.2725 18.1021 18 17.261 18 16.25V8.75C18 7.73897 17.2725 6.89785 16.3125 6.72151V3.5H15.1875V6.72151C14.2275 6.89785 13.5 7.73897 13.5 8.75V16.25C13.5 17.261 14.2275 18.1021 15.1875 18.2785ZM16.875 8.75C16.875 8.23223 16.4553 7.8125 15.9375 7.8125H15.5625C15.0447 7.8125 14.625 8.23223 14.625 8.75V16.25C14.625 16.7678 15.0447 17.1875 15.5625 17.1875H15.9375C16.4553 17.1875 16.875 16.7678 16.875 16.25V8.75Z"
      />
    </svg>
  );
}
