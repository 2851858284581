import React from 'react';

// Styles
import styles from './index.css';

// Components
import { ArrowDownIcon } from '../../../Icons';

const DropdownIndicator = (): JSX.Element => (
  <ArrowDownIcon className={styles.arrowDown} />
);

export default DropdownIndicator;
