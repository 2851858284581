import React from "react"

export default function UnsupportedAssetCircleIcon(
  props: SVGProps
): JSX.Element {
  const { title = "UnsupportedAsset", ...restProps } = props

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <rect width="24" height="24" rx="12" />
      <path
        d="M16.6667 6H7.33333C6.6 6 6 6.6 6 7.33333V16.6667C6 17.4 6.6 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.33333C18 6.6 17.4 6 16.6667 6ZM15.3333 12.6667H8.66667V11.3333H15.3333V12.6667Z"
        fill="white"
      />
    </svg>
  )
}
