// Utils
import _isNumber from 'lodash/isNumber';

// Constants
import spacingStandard from '../../styles/spacing';

type AssignedMultipliers = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

type IndexedMultipliers = [number, number, number?, number?];

export type Spacing =
  | undefined
  | AssignedMultipliers
  | number
  | IndexedMultipliers;

type ReturnType =
  | {
      margin: number | string;
    }
  | Record<string, any>;

const SCALE = parseInt(spacingStandard.px, 10);

const positionMap: Record<keyof AssignedMultipliers, string> = {
  top: 'marginTop',
  bottom: 'marginBottom',
  left: 'marginLeft',
  right: 'marginRight',
};

const ordinalMapByLength: Record<number, number[]> = {
  2: [0, 1],
  3: [0, 1, 2, 1],
  4: [0, 1, 2, 3],
};

function generateMarginStyles(
  length: number,
  spacing: number[]
): { margin: string } {
  let margins = '';
  const ordinalMap = ordinalMapByLength[length];
  ordinalMap.forEach((index) => {
    margins += `${spacing[index]}px `;
  });
  return { margin: margins.trim() };
}

const useSpacing = (spacing: Spacing): ReturnType => {
  if (!spacing) {
    return {};
  }

  if (Array.isArray(spacing)) {
    const scaledSpacing = spacing
      .filter(_isNumber)
      .map((scale) => scale * SCALE);

    return generateMarginStyles(spacing.length, scaledSpacing);
  }

  if (spacing.constructor.name === 'Object') {
    const multipliers = spacing as AssignedMultipliers;

    const positionStyles: Record<string, number> = {};
    Object.entries(positionMap).forEach(([position, marginKey]) => {
      const multiplierPosition = position as keyof AssignedMultipliers;
      const multiplier = multipliers[multiplierPosition];
      if (_isNumber(multiplier)) {
        positionStyles[marginKey] = multiplier * SCALE;
      }
    });

    return positionStyles;
  }

  return { margin: `${Number(spacing) * SCALE}px` };
};

export default useSpacing;
