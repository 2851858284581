import cn from 'classnames';
// Utils
import _omit from 'lodash/omit';
import * as React from 'react';

import inputStyles from '../../InputV2/index.css';
// Styles
import css from '../index.css';

// Types
import type { TimePickerInputProps } from '../_types';

import { ClockIcon } from '../../Icons';
// Components
import {
  Container,
  HelperText,
  Label,
  Suffix,
  Wrapper,
} from '../../InputV2/elements';
// Constants
import { NUM_CHARACTERS_IN_TIMESTAMP } from '../_constants';

const TimePickerInput = React.forwardRef<
  HTMLInputElement,
  TimePickerInputProps
>(
  (
    {
      disabled,
      label,
      Icon,
      value,
      errorMessage,
      containerClassName = '',
      ...restProps
    },
    ref
  ) => {
    const IconToDisplay = Icon || ClockIcon;

    const errorToDisplay: string = React.useMemo(() => {
      if (errorMessage) {
        return errorMessage;
      }

      if (
        value === ''
        || value?.toString().length !== NUM_CHARACTERS_IN_TIMESTAMP
      ) {
        return 'Invalid time format';
      }

      return '';
    }, [value, errorMessage]);

    const helperTextClasses = cn({
      [inputStyles.error]: Boolean(errorMessage),
    });

    return (
      <Container
        className={containerClassName}
        disabled={disabled}
        error={Boolean(errorToDisplay)}
      >
        {label && <Label htmlFor={restProps.id}>{label}</Label>}
        <Wrapper>
          <input
            {..._omit(restProps, ['type', 'step'])}
            value={value}
            type="time"
            step="1"
            data-testid="time-picker-input"
            ref={ref}
          />
          <Suffix>
            <IconToDisplay className={css.calendarIcon} />
          </Suffix>
        </Wrapper>
        {errorToDisplay ? (
          <HelperText className={helperTextClasses}>
            {errorToDisplay}
          </HelperText>
        ) : null}
      </Container>
    );
  }
);

TimePickerInput.displayName = 'TimePickerInput';

export default TimePickerInput;
