import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../index.css';

const Suffix: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = '',
  ...restProps
}) => (
  <div className={cn(className, styles.suffix)} {...restProps}>
    {children}
  </div>
);

export default Suffix;
