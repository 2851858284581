import React from 'react';

export default function SearchIcon(props: SVGProps): JSX.Element {
  const { title = 'Search', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M9.10938 2.75C5.59719 2.75 2.75 5.59719 2.75 9.10938C2.75 12.6216 5.59719 15.4688 9.10938 15.4688C10.6178 15.4688 12.0036 14.9436 13.0938 14.0661L18.0764 19.0487L19.0487 18.0764L14.0661 13.0938C14.9436 12.0036 15.4688 10.6178 15.4688 9.10938C15.4688 5.59719 12.6216 2.75 9.10938 2.75ZM4.125 9.10938C4.125 6.35658 6.35658 4.125 9.10938 4.125C11.8622 4.125 14.0938 6.35658 14.0938 9.10938C14.0938 11.8622 11.8622 14.0938 9.10938 14.0938C6.35658 14.0938 4.125 11.8622 4.125 9.10938Z" />
    </svg>
  );
}
