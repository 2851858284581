import * as Sentry from '@sentry/browser';

import {
  SENTRY_IGNORE_ERRORS,
  shouldSendErrorToSentry,
} from '@anchorage/common/dist/utils/errors/_helpers';

import type {
  Event as SentryEvent,
  EventHint as SentryEventHint,
} from '@sentry/types';

type SentryInitOptions = Parameters<typeof Sentry.init>[0];

const nodeEnvironment = process.env.NODE_ENV;

export enum SentryEnvironments {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  RELEASE = 'release',
}

const beforeSend = (event: SentryEvent, hint?: SentryEventHint) => {
  const err = hint?.originalException ?? null;

  if (!shouldSendErrorToSentry(err as string)) {
    return null;
  }

  return event;
};

const sentryDefaultOptions = {
  ignoreErrors: SENTRY_IGNORE_ERRORS,
  beforeSend,
};

const initSentry = (appOptions: SentryInitOptions) => {
  // Do not initialize the Sentry instance with a DSN if you don't want to report
  // errors for that environment
  if (nodeEnvironment === 'production' && appOptions?.dsn) {
    if (!appOptions.release) {
      console.error('no sentry release defined');
      return;
    }

    if (!appOptions.environment) {
      console.error('no sentry environment defined');
      return;
    }

    // NOTE: It is up to the consumer of the error reporter to set the user
    // in the application code using the setErrorUser function in the other code
    Sentry.init({
      ...sentryDefaultOptions,
      ...appOptions,
    } as Sentry.BrowserOptions);
  }
};

export default initSentry;
