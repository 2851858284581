import React from 'react';

export default function TrashBinIcon(props: SVGProps): JSX.Element {
  const { title = 'Trash bin', ...restProps } = props;

  return (
    <svg viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M1.49992 15.4167C1.49992 16.4296 2.32034 17.25 3.33325 17.25H10.6666C11.6795 17.25 12.4999 16.4296 12.4999 15.4167V4.41667H1.49992V15.4167ZM13.4166 1.66667H10.2083L9.29158 0.75H4.70825L3.79158 1.66667H0.583252V3.5H13.4166V1.66667Z" />
    </svg>
  );
}
