// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from '../index.css';

// Types
import type { StepWrapperProps } from '../_types';

// Components
import { StepContext } from '../useStepContext';

const StepTrackerStepWrapper: React.FC<StepWrapperProps> = ({
  className = '',
  variant,
  ...restProps
}) => {
  const classes = cn({
    [className]: Boolean(className),
    [css.stepRoot]: true,
  });

  const providerValue = React.useMemo(() => ({ variant }), [variant]);

  return (
    <StepContext.Provider value={providerValue}>
      <div className={classes} {...restProps} />
    </StepContext.Provider>
  );
};

StepTrackerStepWrapper.displayName = 'StepTrackerStepWrapper';

export default StepTrackerStepWrapper;
