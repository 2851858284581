import React from 'react';

export default function CalendarV2Icon(props: SVGProps): JSX.Element {
  const { title = 'Calendar', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path d="M6 9V8H4v1h2zM6 10.5v1H4v-1h2zM9 8H7v1h2V8zM7 10.5h2v1H7v-1zM12 9V8h-2v1h2zM10 11.5v-1h2v1h-2z" />
      <path
        fillRule="evenodd"
        d="M11 2h1.5A1.5 1.5 0 0114 3.5v9a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 12.5v-9A1.5 1.5 0 013.5 2H5V1h1v1h4V1h1v1zM3.5 3H5v1h1V3h4v1h1V3h1.5a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}
