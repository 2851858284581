// Styles
import classnames from 'classnames';
import React from 'react';

// Utils
import { formatFileSize } from '../../../utils/format';

import styles from './UploadedFile.css';

// Types
import { UploadedFileType } from './_types';

import Button from '../../Button';
// Components
import { CheckIcon, CloseIcon } from '../../Icons';
import ProgressBar from '../../ProgressBar';

type Props = {
  className?: string;
  onDelete?: (index: number) => void;
  uploadedFiles: UploadedFileType[];
};
// TypeGuard
const isNumber = (arg: any): arg is number => typeof arg === 'number';

const getMessage = (
  size: number,
  percentage?: number,
  error?: Error
): string => {
  const formattedSize = formatFileSize(size);

  if (isNumber(percentage) && !error) {
    return `Uploading ${formatFileSize(
      Math.floor(size * (percentage / 100))
    )} of ${formattedSize}`;
  }

  if (error) {
    return error.message;
  }

  return `Uploaded ${formattedSize}`;
};

const UploadedFile = ({
  className = '',
  onDelete,
  uploadedFiles,
}: Props): JSX.Element => {
  const wrapperClasses = classnames({
    [styles.fileWrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <>
      {uploadedFiles.map(
        ({ error, uploadProgress: percentage, name, size }, index) => (
          <li className={wrapperClasses} key={name}>
            {onDelete && name && (
              <Button
                type="tertiary"
                className={styles.closeButton}
                onClick={() => onDelete(index)}
                Icon={CloseIcon}
              />
            )}
            <div className={styles.filename}>{name}</div>
            <div
              className={classnames({
                [styles.status]: true,
                [styles.loading]: isNumber(percentage) && percentage < 100,
                [styles.error]: Boolean(error),
              })}
            >
              {getMessage(size, percentage, error)}
              {
                // prettier-ignore
                !error
          && (!isNumber(percentage) || percentage === 100)
          && <CheckIcon className={styles.checkIcon} />
                // prettier-ignore
              }
              {
                // prettier-ignore
                isNumber(percentage)
          && percentage < 100
          && !error
          && <ProgressBar className={styles.bar} percentage={percentage} />
                // prettier-ignore
              }
            </div>
          </li>
        )
      )}
    </>
  );
};

export default UploadedFile;
