import cn from 'classnames';
import React from 'react';

import css from './index.css';

import { FormWizardSidebarStepProps } from '../../types';

import Button from '../../../Button';
import Text from '../../../Text';

const FormWizardSidebarStep = (
  { title, Icon, status, onClick }: FormWizardSidebarStepProps,
) => {
  const classes = cn({
    [css.sidebarStep]: true,
    [css.current]: status === 'current',
    [css.unclickableStep]: !onClick,
  });
  const iconClasses = cn({
    [css.icon]: true,
    [css.defaultIcon]: status !== 'incomplete',
    [css.incompleteIcon]: status === 'incomplete',
  });

  const textClasses = cn({
    [css.incompleteText]: status === 'incomplete',
  });

  return (
    <Button type="tertiary" className={classes} onClick={onClick}>
      {Icon ? <Icon className={iconClasses} /> : null}
      <Text size="small" className={textClasses}>
        {title}
      </Text>
    </Button>
  );
};

export default FormWizardSidebarStep;
