import React from 'react';

export default function MoreIcon(props: SVGProps): JSX.Element {
  const { title = 'More', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M11 7.33335C12.0129 7.33335 12.8333 6.51294 12.8333 5.50002C12.8333 4.4871 12.0129 3.66669 11 3.66669C9.98704 3.66669 9.16663 4.4871 9.16663 5.50002C9.16663 6.51294 9.98704 7.33335 11 7.33335ZM11 9.16669C9.98704 9.16669 9.16663 9.9871 9.16663 11C9.16663 12.0129 9.98704 12.8334 11 12.8334C12.0129 12.8334 12.8333 12.0129 12.8333 11C12.8333 9.9871 12.0129 9.16669 11 9.16669ZM11 14.6667C9.98704 14.6667 9.16663 15.4871 9.16663 16.5C9.16663 17.5129 9.98704 18.3334 11 18.3334C12.0129 18.3334 12.8333 17.5129 12.8333 16.5C12.8333 15.4871 12.0129 14.6667 11 14.6667Z" />
    </svg>
  );
}
