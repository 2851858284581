import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../index.css';

// Components
import Text, { Props as TextProps } from '../../Text';
// Utils
import useInputContext from '../useInputContext';

const HelperText: React.FC<TextProps> = ({
  children,
  className = '',
  ...restProps
}) => {
  const { error } = useInputContext();

  const helperTextClasses = cn({
    [styles.helperText]: true,
    [styles.error]: error,
    [className]: className,
  });
  return (
    <Text className={helperTextClasses} size="small" {...restProps}>
      {children}
    </Text>
  );
};

export default HelperText;
