const formatFileSize = (fileSize: number): string => {
  let i = 0;
  const byteUnits = ['kB', 'MB', 'GB', 'TB'];

  let fileSizeInBytes = fileSize / 1024;
  while (fileSizeInBytes > 1024) {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i += 1;
  }

  return `${Math.max(fileSizeInBytes, 0.01).toFixed(2)} ${byteUnits[i]}`;
};

export default formatFileSize;
