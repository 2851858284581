import React from 'react';

import Action from './_components/Action';
import Container from './_components/Container';
import Counter from './_components/Counter';
import Footer from './_components/Footer';
import Header from './_components/Header';
import HeaderText from './_components/HeaderText';

export type Props = {
  action?: React.ReactNode;
  addFooter?: boolean | React.ReactNode;
  children: React.ReactNode;
  counter?: number;
  disabled?: boolean;
  actionPosition?: 'header' | 'footer';
  title?: string;
  // All other props
  [x: string]: any;
};

const Card = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      action,
      addFooter = false,
      children,
      counter,
      disabled = false,
      actionPosition = 'header',
      title,
      ...restProps
    },
    ref,
  ) => {
    // TODO: This is a bandage, we need to merge this component with DataContainer
    const isHeaderVisible = [title, counter, action].some(
      (p) => typeof p !== 'undefined',
    );

    const MaybeFooter: React.FC = () => {
      const hasActionInFooter = actionPosition === 'footer';
      if (!addFooter && !hasActionInFooter) {
        return null;
      }

      const isFooterElement: boolean = typeof addFooter !== 'boolean';
      const hasActionForFooter: boolean = hasActionInFooter && Boolean(action);

      return (
        <Footer>
          {isFooterElement && addFooter}
          {hasActionForFooter && <Action>{action}</Action>}
        </Footer>
      );
    };

    return (
      <Container disabled={disabled} ref={ref} {...restProps}>
        {isHeaderVisible && (
          <Header>
            <HeaderText>
              {title}
              {counter && <Counter>{counter}</Counter>}
            </HeaderText>
            {action && actionPosition === 'header' && <Action>{action}</Action>}
          </Header>
        )}
        {children}
        <MaybeFooter />
      </Container>
    );
  },
);

Card.displayName = 'Card';

export default Card;
export { default as CardAction } from './_components/Action';
export { default as CardContainer } from './_components/Container';
export { default as CardCounter } from './_components/Counter';
export { default as CardFooter } from './_components/Footer';
export { default as CardHeader } from './_components/Header';
export { default as CardHeaderText } from './_components/HeaderText';
