// Utils
import { isEqual as _isEqual, noop } from 'lodash';
import React from 'react';

// Types
import type { ButtonGroupButtonProps } from '../_types';

// Components
import Button from '../../Button';
// Hooks
import useButtonGroupContext from '../useButtonGroupContext';

const ButtonGroupButton = <T,>({
  btnValue,
  children,
  onClick = noop,
  ...restProps
}: ButtonGroupButtonProps<T>) => {
  const { selectedButton, setSelectedButton, type } = useButtonGroupContext();

  const onClickHandler = (newVal: T) => {
    onClick(newVal);
    setSelectedButton(newVal);
  };

  return (
    <Button
      onClick={() => onClickHandler(btnValue)}
      isActive={_isEqual(selectedButton, btnValue)}
      type={type}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default ButtonGroupButton;
