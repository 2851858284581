import React from 'react';

import css from './styles.css';

import {
  CloseButton as ModalCloseButton,
  Header as ModalHeader,
} from '../../../ModalV2';
import Text from '../../../Text';
import { useFormWizardContext } from '../../FormWizardProvider';
import CircularProgress from '../CircularProgress';

const FormWizardHeader: React.FC = () => {
  const { currentStepElement, showProgress } = useFormWizardContext();

  const shouldShowProgress = showProgress && currentStepElement.showProgress;

  return (
    <ModalHeader className={css.modalHeader}>
      <div className={css.headerContainer}>
        <div className={css.progressContainer}>
          {shouldShowProgress ? <CircularProgress /> : null}
          <div className={css.titleContainer}>
            {currentStepElement.title ? (
              <Text>{currentStepElement.title}</Text>
            ) : null}
            {currentStepElement.description ? (
              <Text size="small" className={css.description}>
                {currentStepElement.description}
              </Text>
            ) : null}
          </div>
        </div>
        <ModalCloseButton />
      </div>
    </ModalHeader>
  );
};

export default FormWizardHeader;
