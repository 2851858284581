import { useState } from 'react';

/**
 * A custom React hook that manages state which can be controlled externally or internally.
 * It allows a component to use its own state logic or delegate control to a parent component.
 *
 * @template T
 * @param {T} initialValue - The default initial value for the internal state.
 * @param {T} [controlledValue] - An external value controlled by the parent component.
 * @param {(value: T) => void} [controlledSetValue] - An external setter function controlled by the parent component.
 * @returns {[T, (value: T) => void]} An array containing the current value and a setter function.
 *
 * @example
 * // Usage within a component:
 * const [open, setOpen] = useControllableState(false, props.open, props.setOpen);
 *
 * // 'open' and 'setOpen' will use external control if 'props.open' and 'props.setOpen' are provided,
 * // otherwise they'll fall back to internal state management.
 *
 * @remarks
 * This hook is essential for building components that can operate in both controlled and uncontrolled modes.
 * It enhances flexibility by allowing parent components to control state when necessary,
 * while still providing an internal state mechanism as a fallback.
 * This is particularly useful in reusable components where consumers might want to control
 * the component's state externally (e.g., open/close states in modals, toggles, etc.).
 */
export const useControllableState = <T,>(
  initialValue: T,
  controlledValue?: T,
  controlledSetValue?: (value: T) => void,
): [T, (value: T) => void] => {
  const [internalValue, setInternalValue] = useState<T>(initialValue);

  const isControlled =
    controlledValue !== undefined && controlledSetValue !== undefined;

  const value = isControlled ? controlledValue : internalValue;
  const setValue = isControlled ? controlledSetValue : setInternalValue;

  return [value, setValue];
};
