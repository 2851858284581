// @Generated

// AUTO-GENERATED FROM tailwind.config.js and scripts/generate-style-vars.js
// DO NOT MODIFY

const colors = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  userGreetingAccent: 'var(--user-greeting-accent)',
  buttonTextPrimary: 'var(--button-text-primary)',
  buttonTextPrimaryHover: 'var(--button-text-primary-hover)',
  drawerBackground: 'var(--drawer-background)',
  modalBackground: 'var(--modal-background)',
  textLinkColor: 'var(--text-link-color)',
  tabUnselected: 'var(--tab-unselected)',
  'tabUnselected-rgb': 'var(--tab-unselected-rgb)',
  marinaBlue10: 'var(--color-marinaBlue-10)',
  marinaBlue20: 'var(--color-marinaBlue-20)',
  marinaBlue30: 'var(--color-marinaBlue-30)',
  marinaBlue40: 'var(--color-marinaBlue-40)',
  marinaBlue50: 'var(--color-marinaBlue-50)',
  marinaBlue60: 'var(--color-marinaBlue-60)',
  marinaBlue70: 'var(--color-marinaBlue-70)',
  marinaBlue80: 'var(--color-marinaBlue-80)',
  marinaBlue90: 'var(--color-marinaBlue-90)',
  'marinaBlue10-rgb': 'var(--color-marinaBlue-10-rgb)',
  'marinaBlue20-rgb': 'var(--color-marinaBlue-20-rgb)',
  'marinaBlue30-rgb': 'var(--color-marinaBlue-30-rgb)',
  'marinaBlue40-rgb': 'var(--color-marinaBlue-40-rgb)',
  'marinaBlue50-rgb': 'var(--color-marinaBlue-50-rgb)',
  'marinaBlue60-rgb': 'var(--color-marinaBlue-60-rgb)',
  'marinaBlue70-rgb': 'var(--color-marinaBlue-70-rgb)',
  'marinaBlue80-rgb': 'var(--color-marinaBlue-80-rgb)',
  'marinaBlue90-rgb': 'var(--color-marinaBlue-90-rgb)',
  basaltGray10: 'var(--color-basaltGray-10)',
  basaltGray20: 'var(--color-basaltGray-20)',
  basaltGray30: 'var(--color-basaltGray-30)',
  basaltGray40: 'var(--color-basaltGray-40)',
  basaltGray50: 'var(--color-basaltGray-50)',
  basaltGray60: 'var(--color-basaltGray-60)',
  basaltGray70: 'var(--color-basaltGray-70)',
  basaltGray80: 'var(--color-basaltGray-80)',
  basaltGray90: 'var(--color-basaltGray-90)',
  'basaltGray10-rgb': 'var(--color-basaltGray-10-rgb)',
  'basaltGray20-rgb': 'var(--color-basaltGray-20-rgb)',
  'basaltGray30-rgb': 'var(--color-basaltGray-30-rgb)',
  'basaltGray40-rgb': 'var(--color-basaltGray-40-rgb)',
  'basaltGray50-rgb': 'var(--color-basaltGray-50-rgb)',
  'basaltGray60-rgb': 'var(--color-basaltGray-60-rgb)',
  'basaltGray70-rgb': 'var(--color-basaltGray-70-rgb)',
  'basaltGray80-rgb': 'var(--color-basaltGray-80-rgb)',
  'basaltGray90-rgb': 'var(--color-basaltGray-90-rgb)',
  seafoamTeal10: 'var(--color-seafoamTeal-10)',
  seafoamTeal20: 'var(--color-seafoamTeal-20)',
  seafoamTeal30: 'var(--color-seafoamTeal-30)',
  seafoamTeal40: 'var(--color-seafoamTeal-40)',
  seafoamTeal50: 'var(--color-seafoamTeal-50)',
  seafoamTeal60: 'var(--color-seafoamTeal-60)',
  seafoamTeal70: 'var(--color-seafoamTeal-70)',
  seafoamTeal80: 'var(--color-seafoamTeal-80)',
  seafoamTeal90: 'var(--color-seafoamTeal-90)',
  'seafoamTeal10-rgb': 'var(--color-seafoamTeal-10-rgb)',
  'seafoamTeal20-rgb': 'var(--color-seafoamTeal-20-rgb)',
  'seafoamTeal30-rgb': 'var(--color-seafoamTeal-30-rgb)',
  'seafoamTeal40-rgb': 'var(--color-seafoamTeal-40-rgb)',
  'seafoamTeal50-rgb': 'var(--color-seafoamTeal-50-rgb)',
  'seafoamTeal60-rgb': 'var(--color-seafoamTeal-60-rgb)',
  'seafoamTeal70-rgb': 'var(--color-seafoamTeal-70-rgb)',
  'seafoamTeal80-rgb': 'var(--color-seafoamTeal-80-rgb)',
  'seafoamTeal90-rgb': 'var(--color-seafoamTeal-90-rgb)',
  clownfishOrange10: 'var(--color-clownfishOrange-10)',
  clownfishOrange20: 'var(--color-clownfishOrange-20)',
  clownfishOrange30: 'var(--color-clownfishOrange-30)',
  clownfishOrange40: 'var(--color-clownfishOrange-40)',
  clownfishOrange50: 'var(--color-clownfishOrange-50)',
  clownfishOrange60: 'var(--color-clownfishOrange-60)',
  clownfishOrange70: 'var(--color-clownfishOrange-70)',
  clownfishOrange80: 'var(--color-clownfishOrange-80)',
  clownfishOrange90: 'var(--color-clownfishOrange-90)',
  'clownfishOrange10-rgb': 'var(--color-clownfishOrange-10-rgb)',
  'clownfishOrange20-rgb': 'var(--color-clownfishOrange-20-rgb)',
  'clownfishOrange30-rgb': 'var(--color-clownfishOrange-30-rgb)',
  'clownfishOrange40-rgb': 'var(--color-clownfishOrange-40-rgb)',
  'clownfishOrange50-rgb': 'var(--color-clownfishOrange-50-rgb)',
  'clownfishOrange60-rgb': 'var(--color-clownfishOrange-60-rgb)',
  'clownfishOrange70-rgb': 'var(--color-clownfishOrange-70-rgb)',
  'clownfishOrange80-rgb': 'var(--color-clownfishOrange-80-rgb)',
  'clownfishOrange90-rgb': 'var(--color-clownfishOrange-90-rgb)',
  lobsterRed10: 'var(--color-lobsterRed-10)',
  lobsterRed20: 'var(--color-lobsterRed-20)',
  lobsterRed30: 'var(--color-lobsterRed-30)',
  lobsterRed40: 'var(--color-lobsterRed-40)',
  lobsterRed50: 'var(--color-lobsterRed-50)',
  lobsterRed60: 'var(--color-lobsterRed-60)',
  lobsterRed70: 'var(--color-lobsterRed-70)',
  lobsterRed80: 'var(--color-lobsterRed-80)',
  lobsterRed90: 'var(--color-lobsterRed-90)',
  'lobsterRed10-rgb': 'var(--color-lobsterRed-10-rgb)',
  'lobsterRed20-rgb': 'var(--color-lobsterRed-20-rgb)',
  'lobsterRed30-rgb': 'var(--color-lobsterRed-30-rgb)',
  'lobsterRed40-rgb': 'var(--color-lobsterRed-40-rgb)',
  'lobsterRed50-rgb': 'var(--color-lobsterRed-50-rgb)',
  'lobsterRed60-rgb': 'var(--color-lobsterRed-60-rgb)',
  'lobsterRed70-rgb': 'var(--color-lobsterRed-70-rgb)',
  'lobsterRed80-rgb': 'var(--color-lobsterRed-80-rgb)',
  'lobsterRed90-rgb': 'var(--color-lobsterRed-90-rgb)',
  submarineYellow10: 'var(--color-submarineYellow-10)',
  submarineYellow20: 'var(--color-submarineYellow-20)',
  submarineYellow30: 'var(--color-submarineYellow-30)',
  submarineYellow40: 'var(--color-submarineYellow-40)',
  submarineYellow50: 'var(--color-submarineYellow-50)',
  submarineYellow60: 'var(--color-submarineYellow-60)',
  submarineYellow70: 'var(--color-submarineYellow-70)',
  submarineYellow80: 'var(--color-submarineYellow-80)',
  submarineYellow90: 'var(--color-submarineYellow-90)',
  'submarineYellow10-rgb': 'var(--color-submarineYellow-10-rgb)',
  'submarineYellow20-rgb': 'var(--color-submarineYellow-20-rgb)',
  'submarineYellow30-rgb': 'var(--color-submarineYellow-30-rgb)',
  'submarineYellow40-rgb': 'var(--color-submarineYellow-40-rgb)',
  'submarineYellow50-rgb': 'var(--color-submarineYellow-50-rgb)',
  'submarineYellow60-rgb': 'var(--color-submarineYellow-60-rgb)',
  'submarineYellow70-rgb': 'var(--color-submarineYellow-70-rgb)',
  'submarineYellow80-rgb': 'var(--color-submarineYellow-80-rgb)',
  'submarineYellow90-rgb': 'var(--color-submarineYellow-90-rgb)',
  algaeGreen10: 'var(--color-algaeGreen-10)',
  algaeGreen20: 'var(--color-algaeGreen-20)',
  algaeGreen30: 'var(--color-algaeGreen-30)',
  algaeGreen40: 'var(--color-algaeGreen-40)',
  algaeGreen50: 'var(--color-algaeGreen-50)',
  algaeGreen60: 'var(--color-algaeGreen-60)',
  algaeGreen70: 'var(--color-algaeGreen-70)',
  algaeGreen80: 'var(--color-algaeGreen-80)',
  algaeGreen90: 'var(--color-algaeGreen-90)',
  'algaeGreen10-rgb': 'var(--color-algaeGreen-10-rgb)',
  'algaeGreen20-rgb': 'var(--color-algaeGreen-20-rgb)',
  'algaeGreen30-rgb': 'var(--color-algaeGreen-30-rgb)',
  'algaeGreen40-rgb': 'var(--color-algaeGreen-40-rgb)',
  'algaeGreen50-rgb': 'var(--color-algaeGreen-50-rgb)',
  'algaeGreen60-rgb': 'var(--color-algaeGreen-60-rgb)',
  'algaeGreen70-rgb': 'var(--color-algaeGreen-70-rgb)',
  'algaeGreen80-rgb': 'var(--color-algaeGreen-80-rgb)',
  'algaeGreen90-rgb': 'var(--color-algaeGreen-90-rgb)',
};

export default colors;
