import React from 'react';

export default function FileInvoiceIcon(props: SVGProps): JSX.Element {
  const { title = 'FileInvoice', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11L7.5 11V9.25L18 9.25V11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 14.5L7.5 14.5V12.75L14.5 12.75V14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.5L7.5 7.5V5.75L18 5.75V7.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11L5.75 11V9.25L4 9.25V11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 14.5L5.75 14.5V12.75L4 12.75V14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.5L5.75 7.5V5.75L4 5.75V7.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 2.25C2.64175 2.25 2.25 2.64175 2.25 3.125V19.3876L3.38128 18.2563C3.72299 17.9146 4.27701 17.9146 4.61872 18.2563L5.75 19.3876L6.88128 18.2563C7.22299 17.9146 7.77701 17.9146 8.11872 18.2563L9.25 19.3876L10.3813 18.2563C10.723 17.9146 11.277 17.9146 11.6187 18.2563L12.75 19.3876L13.8813 18.2563C14.223 17.9146 14.777 17.9146 15.1187 18.2563L16.25 19.3876L17.3813 18.2563C17.723 17.9146 18.277 17.9146 18.6187 18.2563L19.75 19.3876V3.125C19.75 2.64175 19.3582 2.25 18.875 2.25H3.125ZM0.5 3.125C0.5 1.67525 1.67525 0.5 3.125 0.5H18.875C20.3247 0.5 21.5 1.67525 21.5 3.125V20.0063C21.5 20.8312 20.8312 21.5 20.0063 21.5C19.6101 21.5 19.2302 21.3426 18.9501 21.0625L18 20.1124L16.8687 21.2437C16.527 21.5854 15.973 21.5854 15.6313 21.2437L14.5 20.1124L13.3687 21.2437C13.027 21.5854 12.473 21.5854 12.1313 21.2437L11 20.1124L9.86872 21.2437C9.52701 21.5854 8.97299 21.5854 8.63128 21.2437L7.5 20.1124L6.36872 21.2437C6.02701 21.5854 5.47299 21.5854 5.13128 21.2437L4 20.1124L3.04994 21.0625C2.76981 21.3426 2.38988 21.5 1.99372 21.5C1.16876 21.5 0.5 20.8312 0.5 20.0063V3.125Z"
      />
    </svg>
  );
}
