import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

// Shared Types
import { Background, Position } from '../_types';

// Shared Constants
import { DEFAULT_BACKGROUND, DEFAULT_POSITION } from '../_constants';

type InnerProps = {
  background?: Background;
  children: React.ReactNode;
  position?: Position;
  className?: string;
  // All other props
  [x: string]: any;
};

export default function TooltipInner(
  {
    children,
    className = '',
    background = DEFAULT_BACKGROUND,
    position = DEFAULT_POSITION,
    ...restProps
  }: InnerProps,
): JSX.Element {
  return (
    <div
      className={classnames([
        className,
        styles.tooltipInner,
        styles[position],
        styles[background],
      ])}
      data-testid="tooltip-title"
      {...restProps}
    >
      {children}
    </div>
  );
}
