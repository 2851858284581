// If an additional error is present, add both messages
const formatMessage = (message: string, error?: Error): string => {
  if (error) {
    return `${message}: ${error.message}`;
  }

  return message;
};

// UIDeveloperError helps indicate that an error we encounter is due to a
// bug or issue that a UI developer has caused or can fix. This is helpful to
// the on-call team when triaging.

// Flow allows classes to operate as both a value and a type
export default class UIDeveloperError extends Error {
  cause: Error | null;

  constructor(message: string, error?: Error) {
    // Format the message and add the error if present
    super(formatMessage(message, error));

    // Set the name of this error for display purposes
    this.name = this.constructor.name;

    // Keep a reference to the original error that caused this
    this.cause = error || null;

    // captureStackTrace may not exist (IE)
    if (Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace')) {
      // Pass this.constructor to get rid of this constructor
      // in the stack trace
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(formatMessage(message, error)).stack;
    }
  }
}
