import React from 'react';

function BalanceIcon(props: SVGProps): JSX.Element {
  const { title = 'BalanceIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M5.5 42C5.06667 42 4.70833 41.8583 4.425 41.575C4.14167 41.2917 4 40.9333 4 40.5C4 40.0667 4.14167 39.7083 4.425 39.425C4.70833 39.1417 5.06667 39 5.5 39H22.5V14.8C21.6333 14.5 20.8583 14.0083 20.175 13.325C19.4917 12.6417 19 11.8667 18.7 11H10.75L16.55 25C16.7167 25.3667 16.825 25.775 16.875 26.225C16.925 26.675 16.9167 27.0333 16.85 27.3C16.5167 28.5 15.7583 29.5 14.575 30.3C13.3917 31.1 12.0333 31.5 10.5 31.5C8.96667 31.5 7.60833 31.1 6.425 30.3C5.24167 29.5 4.48333 28.5 4.15 27.3C4.08333 27.0333 4.075 26.675 4.125 26.225C4.175 25.775 4.28333 25.3667 4.45 25L10.25 11H7.5C7.06667 11 6.70833 10.8583 6.425 10.575C6.14167 10.2917 6 9.93333 6 9.5C6 9.06667 6.14167 8.70833 6.425 8.425C6.70833 8.14167 7.06667 8 7.5 8H18.7C19.1 6.83333 19.7833 5.875 20.75 5.125C21.7167 4.375 22.8 4 24 4C25.2 4 26.2833 4.375 27.25 5.125C28.2167 5.875 28.9 6.83333 29.3 8H40.5C40.9333 8 41.2917 8.14167 41.575 8.425C41.8583 8.70833 42 9.06667 42 9.5C42 9.93333 41.8583 10.2917 41.575 10.575C41.2917 10.8583 40.9333 11 40.5 11H37.75L43.55 25C43.7167 25.3667 43.825 25.775 43.875 26.225C43.925 26.675 43.9167 27.0333 43.85 27.3C43.5167 28.5 42.7583 29.5 41.575 30.3C40.3917 31.1 39.0333 31.5 37.5 31.5C35.9667 31.5 34.6083 31.1 33.425 30.3C32.2417 29.5 31.4833 28.5 31.15 27.3C31.0833 27.0333 31.075 26.675 31.125 26.225C31.175 25.775 31.2833 25.3667 31.45 25L37.25 11H29.3C29 11.8667 28.5083 12.6417 27.825 13.325C27.1417 14.0083 26.3667 14.5 25.5 14.8V39H42.5C42.9333 39 43.2917 39.1417 43.575 39.425C43.8583 39.7083 44 40.0667 44 40.5C44 40.9333 43.8583 41.2917 43.575 41.575C43.2917 41.8583 42.9333 42 42.5 42H5.5ZM33.75 26H41.25L37.5 16.8L33.75 26ZM6.75 26H14.25L10.5 16.8L6.75 26ZM24 12C24.7 12 25.2917 11.75 25.775 11.25C26.2583 10.75 26.5 10.1667 26.5 9.5C26.5 8.8 26.2583 8.20833 25.775 7.725C25.2917 7.24167 24.7 7 24 7C23.3333 7 22.75 7.24167 22.25 7.725C21.75 8.20833 21.5 8.8 21.5 9.5C21.5 10.1667 21.75 10.75 22.25 11.25C22.75 11.75 23.3333 12 24 12Z" />
    </svg>
  );
};

export default BalanceIcon;
