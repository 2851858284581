import React from 'react';

export default function DocumentCoinsIcon(props: SVGProps): JSX.Element {
  const { title = 'DocumentCoins', ...restProps } = props;

  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4.8125C2.75 3.67341 3.67341 2.75 4.8125 2.75H17.1875C18.3266 2.75 19.25 3.67341 19.25 4.8125V12.375H17.875V4.8125C17.875 4.4328 17.5672 4.125 17.1875 4.125H4.8125C4.4328 4.125 4.125 4.4328 4.125 4.8125V17.1875C4.125 17.5672 4.4328 17.875 4.8125 17.875H6.875V19.25H4.8125C3.67341 19.25 2.75 18.3266 2.75 17.1875V4.8125ZM5.5 5.5H11V6.875H5.5V5.5ZM15.125 5.5H16.5V6.875H15.125V5.5ZM5.5 8.25H13.75V9.625H5.5V8.25ZM15.125 8.25H16.5V9.625H15.125V8.25ZM5.5 11H8.25V12.375H5.5V11ZM11.3815 15.125C10.2127 15.125 9.28125 16.0572 9.28125 17.1875C9.28125 18.3178 10.2127 19.25 11.3815 19.25C11.4279 19.25 11.4738 19.2485 11.5194 19.2457C11.0857 18.673 10.8281 17.9613 10.8281 17.1875C10.8281 16.4137 11.0857 15.702 11.5194 15.1293C11.4738 15.1265 11.4279 15.125 11.3815 15.125ZM12.8426 14.0678C12.3975 13.8636 11.9019 13.75 11.3815 13.75C9.47106 13.75 7.90625 15.2802 7.90625 17.1875C7.90625 19.0948 9.47106 20.625 11.3815 20.625C11.9019 20.625 12.3975 20.5114 12.8426 20.3072C13.2874 20.5113 13.7826 20.625 14.3034 20.625C14.8194 20.625 15.3109 20.5133 15.7531 20.3124C16.1897 20.5131 16.6755 20.625 17.1875 20.625C19.086 20.625 20.625 19.086 20.625 17.1875C20.625 15.289 19.086 13.75 17.1875 13.75C16.6755 13.75 16.1897 13.8619 15.7531 14.0626C15.3109 13.8617 14.8194 13.75 14.3034 13.75C13.7826 13.75 13.2874 13.8637 12.8426 14.0678ZM14.4343 15.1289C14.391 15.1263 14.3474 15.125 14.3034 15.125C13.1346 15.125 12.2031 16.0572 12.2031 17.1875C12.2031 18.3178 13.1346 19.25 14.3034 19.25C14.3474 19.25 14.391 19.2487 14.4343 19.2461C14.0046 18.6722 13.75 17.9596 13.75 17.1875C13.75 16.4154 14.0046 15.7028 14.4343 15.1289ZM17.1875 15.125C16.0484 15.125 15.125 16.0484 15.125 17.1875C15.125 18.3266 16.0484 19.25 17.1875 19.25C18.3266 19.25 19.25 18.3266 19.25 17.1875C19.25 16.0484 18.3266 15.125 17.1875 15.125Z"
      />
    </svg>
  );
}
