import * as React from 'react';
import type { MultiValue } from 'react-select-v5';

// Types
import type { StandaloneInputProps } from '../InputV2/_types';

// Components
import Select from '../SelectV2';

const DEFAULT_ERROR_MESSAGE = 'This value is already in the list';

function getSelectValue(
  options: Array<string> | undefined
): Array<Option> | undefined {
  return options?.map((option) => ({ label: option, value: option }));
}

type Option = {
  readonly label: string;
  readonly value: string;
};

export interface Props
  extends Omit<StandaloneInputProps, 'onChange' | 'value' | 'defaultValue'> {
  defaultValue?: Array<string> | undefined;
  onChange: (newValues: Array<string> | undefined) => void;
  validateUniqueMessage?: string | ((inputValue: string) => string);
  value: Array<string> | undefined;
}

export default function MultiInput({
  defaultValue,
  helperText,
  onChange,
  validateUniqueMessage,
  value,
  ...props
}: Props) {
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);

  function getErrorMessage() {
    if (!validateUniqueMessage) {
      return inputValue
        ? `${inputValue} is already in the list`
        : DEFAULT_ERROR_MESSAGE;
    }

    if (typeof validateUniqueMessage === 'string') {
      return validateUniqueMessage;
    }

    return validateUniqueMessage(inputValue);
  }

  function handleKeyDown(
    event: Parameters<React.KeyboardEventHandler<HTMLDivElement>>[0]
  ) {
    if ((inputValue && event.key === 'Enter') || event.key === 'Tab') {
      event.preventDefault();
      const prevValue = value ?? [];

      if (prevValue.includes(inputValue)) {
        setError(getErrorMessage);
      } else {
        onChange([...prevValue, inputValue]);
        setInputValue('');
      }
    }
  }

  function handleSelectChange(newValue: MultiValue<Option>) {
    onChange(newValue.map((option) => option.value));
  }

  function handleInputChange(newValue: string) {
    if (error) {
      setError(null);
    }

    setInputValue(newValue);
  }

  return (
    <Select<Option, true>
      {...props}
      components={{ DropdownIndicator: null }}
      defaultValue={getSelectValue(defaultValue)}
      errorFooterText={error}
      hasCreatableOptions
      helperFooterText={error ? '' : helperText}
      inputValue={inputValue}
      isError={!!error}
      isMulti
      menuIsOpen={false}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      value={getSelectValue(value)}
    />
  );
}
