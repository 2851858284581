import React from 'react';

import DropdownContainer from './_components/DropdownContainer';
import DropdownMenu, {
  MenuPosition,
  MenuTrigger,
} from './_components/DropdownMenu';
import DropdownMenuItem from './_components/DropdownMenuItem';

import css from './index.css';

import Tooltip from '../Tooltip';

export type Option = {
  onClick?: (event: any) => void;
  value: string | React.ReactElement;
  'data-testid'?: string;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
};

type Props = {
  // children is the dowpdown button.
  children: React.ReactElement;
  closeOnOptionClick?: boolean;
  disabled?: boolean;
  options: Option[];
  placement?: MenuPosition;
  trigger?: MenuTrigger;
  dropdownMenuClassName?: string;
  // All other props
  [x: string]: any;
};

const Dropdown = (
  {
    children,
    closeOnOptionClick = false,
    disabled = false,
    options,
    placement,
    trigger,
    dropdownMenuClassName = '',
    ...restProps
  }: Props,
): React.ReactElement => (
  <DropdownContainer data-testid="dropdown-container" {...restProps}>
    {children}
    <DropdownMenu
      closeOnOptionClick={closeOnOptionClick}
      disabled={disabled}
      placement={placement}
      trigger={trigger}
      className={dropdownMenuClassName}
    >
      {options.map((option: Option, index: number) => {
        const { value, ...restOptions } = option;

        if (option.tooltip) {
          return (
            <Tooltip
              className={css.dropdownItemTooltip}
              title={option.tooltip}
              position="leftTop"
              key={index}
            >
              <DropdownMenuItem {...restOptions}>{value}</DropdownMenuItem>
            </Tooltip>
          );
        }

        return (
          <DropdownMenuItem key={index} {...restOptions}>
            {value}
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  </DropdownContainer>
);

export default Dropdown;
export { default as DropdownContainer } from './_components/DropdownContainer';
export { default as DropdownMenu } from './_components/DropdownMenu';
export { default as DropdownMenuItem } from './_components/DropdownMenuItem';
