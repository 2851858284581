import React from 'react';

export default function LaptopIcon(props: SVGProps): JSX.Element {
  const { title = 'Laptop', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M20 18C21.105 18 21.99 17.105 21.99 16L22 5C22 3.895 21.105 3 20 3H4C2.895 3 2 3.895 2 5V16C2 17.105 2.895 18 4 18H0C0 19.105 0.895 20 2 20H22C23.105 20 24 19.105 24 18H20ZM4 5H20V16H4V5ZM12 19C11.45 19 11 18.55 11 18C11 17.45 11.45 17 12 17C12.55 17 13 17.45 13 18C13 18.55 12.55 19 12 19Z" />
    </svg>
  );
}
