import React from 'react';

// Components
import Text, { Props as TextProps } from '../../../Text';

const BannerTitle: React.FC<TextProps> = ({ title, ...restProps }) => (
  <Text size="small" data-testid="banner-title" {...restProps}>
    {title}
  </Text>
);

export default BannerTitle;
