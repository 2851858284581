import React, { CSSProperties } from 'react';

import type { Direction, SVGProps } from './_types';

type FirstPageIconProps = SVGProps & { direction: Direction };

const DIRECTION_MAPPING: Record<Direction, CSSProperties> = {
  left: { transform: 'rotate(0deg)' },
  right: { transform: 'rotate(180deg)' },
  up: { transform: 'rotate(90deg)' },
  down: { transform: 'rotate(270deg)' },
};

export default function FirstPageIcon(props: FirstPageIconProps): JSX.Element {
  const { direction = 'left', style = {}, ...restProps } = props;

  return (
    <svg
      style={{ ...DIRECTION_MAPPING[direction], ...style }}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fill="#fff"
        d="M16.876 15.207L12.668 11l4.208-4.207L15.583 5.5l-5.5 5.5 5.5 5.5 1.293-1.293zM5.5 5.5h1.833v11H5.5v-11z"
      />
    </svg>
  );
}
