import React from 'react';

export default function VideocamOffIcon(props: SVGProps): JSX.Element {
  const { title = 'VideocamOff', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M21 6.5L17 10.5V7C17 6.45 16.55 6 16 6H9.82L21 17.18V6.5ZM3.275 2L2 3.275L4.725 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.205 18 16.385 17.925 16.545 17.815L19.73 21L21 19.725L3.275 2Z" />
    </svg>
  );
}
