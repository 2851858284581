// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import css from './index.css';

const TableRow: CommonComponent<Record<string, any>, 'tr'> = ({
  children,
  className = '',
  ...restProps
}) => {
  const classes = cn({
    [css.borderBottom]: true,
    [className]: Boolean(className),
  });

  return (
    <tr className={classes} {...restProps}>
      {children}
    </tr>
  );
};

export default TableRow;
