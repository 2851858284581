import React from 'react';

import { BrandThemeSvgProps } from './_types';

import { BrandThemeColors, BrandThemeEnum } from './_constants';

export default function PortoIcon(props: BrandThemeSvgProps): JSX.Element {
  const {
    title = 'PortoIcon',
    theme = BrandThemeEnum.Dark,
    color,
    ...restProps
  } = props;

  const logoColor = color ?? BrandThemeColors[theme];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={logoColor}
      viewBox="0 0 280 280"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M270.128 140c0 77.32-62.267 140-139.077 140V0c76.81 0 139.077 62.68 139.077 140zm-139.077 0L11.667 63.041V216.96L131.05 140z"
        clipRule="evenodd"
      />
    </svg>
  );
}
