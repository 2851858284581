function isValidEmail(email: string): boolean {
  const atIndex = email.indexOf('@');

  return atIndex >= 0;
}

/**
 * Truncates an email address to a specified length.
 * If the email is not valid or empty, it returns an empty string.
 * @param email - The email address to truncate.
 * @param length - The maximum length of the truncated email address. Default is 40.
 * @returns The truncated email address.
 */
export default function truncateEmailAddress(
  email?: string | null,
  length = 40
) {
  if (!email || !isValidEmail(email)) {
    return email || '';
  }

  if (email.length <= length) {
    return email;
  }

  const atIndex = email.indexOf('@');
  const username = email.slice(0, atIndex);
  const domain = email.slice(atIndex);

  const truncatedUsername = `${username.slice(
    0,
    length - domain.length - 3
  )}...`;

  return truncatedUsername + domain;
}
