import cn from 'classnames';
import React from 'react';

import css from './index.css';

export interface Props extends React.PropsWithChildren {
  className?: string;
}

const FormWizardContentWrapper: React.FC<Props> = (
  { children, className = '', ...restProps },
) => (
  <div className={cn(css.contentWrapperRoot, className)} {...restProps}>
    {children}
  </div>
);

export default FormWizardContentWrapper;
