import React from 'react';

export default function PolicyIcon(props: SVGProps): JSX.Element {
  const { title = 'Policy', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...restProps}>
      <title>{title}</title>
      <path d="M21.5 5l-9-4-9 4v6c0 5.55 3.84 10.74 9 12 2.3-.56 4.33-1.9 5.88-3.71l-3.12-3.12a4.994 4.994 0 01-6.29-.64 5.003 5.003 0 010-7.07 5.003 5.003 0 017.07 0 5.006 5.006 0 01.64 6.29l2.9 2.9c1.21-1.96 1.92-4.27 1.92-6.65V5z" />
      <path d="M12.5 15a3 3 0 100-6 3 3 0 000 6z" />
    </svg>
  );
}
