import React from 'react';

function DeleteIcon(props: SVGProps): JSX.Element {
  const { title = 'Delete', ...restProps } = props;

  return (
    <svg viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M0.999674 10.6667C0.999674 11.4 1.59967 12 2.33301 12H7.66634C8.39967 12 8.99967 11.4 8.99967 10.6667V2.66667H0.999674V10.6667ZM2.64301 5.92L3.58634 4.97667L4.99967 6.39L6.41301 4.97667L7.35634 5.92L5.94301 7.33333L7.35634 8.74667L6.41301 9.69L4.99967 8.27667L3.58634 9.69L2.64301 8.74667L4.05634 7.33333L2.64301 5.92ZM7.33301 0.666667L6.66634 0H3.33301L2.66634 0.666667H0.333008V2H9.66634V0.666667H7.33301Z" />
    </svg>
  );
}

export default DeleteIcon;
