type FormatFixedLocaleStringProps = {
  decimals?: number;
  locale?: string;
};
// Formats the value with fixed decimal numbers and with locale
export default function formatFixedLocaleString(
  value: number,
  options: FormatFixedLocaleStringProps = {}
): string {
  const { locale = 'en-US', decimals = 2, ...rest } = options;

  return value.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    ...rest,
  });
}
