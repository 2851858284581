import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import React, { useCallback } from 'react';

import css from './index.css';

import { ArrowDownIcon } from '../../Icons';
import { ACCORDION_TRANSITION_DURATION } from '../_constants';
import AccordionHeaderContent, { Props as HeaderContentProps } from './HeaderContent';
import useAccordionContext from './useAccordionContext';

type Props = {
  children:
    | React.ReactNode
    | React.ReactNode[]
    | (({
        isExpanded,
      }: {
        isExpanded: boolean;
      }) => React.ReactNode | React.ReactNode[]);
  onClick?: ({
    isExpanded,
    itemId,
  }: {
    isExpanded: boolean;
    itemId: string;
  }) => void;
  className?: string;
  // All other props
  [x: string]: any;
  headerContentProps?: Partial<HeaderContentProps>;
};

const AccordionIcon = animated(ArrowDownIcon);

const Header = ({
  children,
  className = '',
  onClick,
  headerContentProps = {},
  ...restProps
}: Props): React.ReactElement => {
  const { isExpanded, toggle, itemId } = useAccordionContext();

  const iconStyle = useSpring({
    transform: isExpanded ? `scaleY(-1)` : `scaleY(1)`,
    config: {
      duration: ACCORDION_TRANSITION_DURATION / 10,
    },
  });

  const classes = cn({
    [css.headerWrapper]: true,
    [className]: Boolean(className),
  });

  const onClickHandler = useCallback(() => {
    onClick?.({ isExpanded, itemId });
    toggle();
  }, [onClick, toggle, isExpanded, itemId]);

  return (
    <button
      id={`accordion-header-${itemId}`}
      data-testid={`accordion-header-${itemId}`}
      aria-controls={`accordion-panel-${itemId}`}
      aria-expanded={isExpanded}
      type="button"
      className={classes}
      onClick={onClickHandler}
      {...restProps}
    >
      <AccordionHeaderContent {...headerContentProps}>
        {typeof children === 'function' ? children({ isExpanded }) : children}
      </AccordionHeaderContent>
      <AccordionIcon
        fill="white"
        width="24px"
        className={css.icon}
        aria-hidden
        style={iconStyle}
      />
    </button>
  );
};

export default Header;
