// Utils
import cn from 'classnames';
import * as React from 'react';

// Styles
import css from '../index.css';

// Types
import type { DateTimePickerDOMProps } from '../_types';

const TimePickerButtonsWrapper: React.FC<DateTimePickerDOMProps> = ({
  className = '',
  ...restProps
}) => (
  <div
    className={cn([className, css.timePickerButtonsContainer])}
    {...restProps}
  />
);

TimePickerButtonsWrapper.displayName = 'TimePickerButtonsWrapper';

export default TimePickerButtonsWrapper;
