import React from 'react';
import { TableOptions, usePagination, useSortBy, useTable } from 'react-table';

import Loading from './_components/Loading';
import NoData from './_components/NoData';
import Pagination, { PaginationProps } from './_components/Pagination';
import TableBody from './_components/TableBody';
import TableHead from './_components/TableHead';

import styles from './index.css';

const DEFAULT_PAGE_SIZE = 15;

export type HookParams = TableOptions<any>;

type Props = {
  columns: any;
  data: any;
  isLoading?: boolean;
  noDataMessage?: string | React.ReactNode;
  pagination?: PaginationProps;
  hookParams?: HookParams;
  showHeader?: boolean;
  showPagination?: boolean;
  sort?: boolean;
};

const Table = (
  {
    columns,
    data,
    hookParams,
    isLoading,
    noDataMessage,
    pagination,
    showHeader = true,
    showPagination = true,
    sort,
  }: Props,
): JSX.Element => {
  const pageSize =
    pagination && pagination.pageSize ? pagination.pageSize : DEFAULT_PAGE_SIZE;

  // We don't want to override the pageSize in the initial state
  const hookParamsInitialState = hookParams && hookParams.initialState;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      ...hookParams,
      initialState: { pageSize, ...hookParamsInitialState },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <table className={styles.table} data-testid="table" {...getTableProps()}>
        {showHeader && <TableHead headerGroups={headerGroups} sort={sort} />}
        <TableBody
          getTableBodyProps={getTableBodyProps}
          isLoading={isLoading}
          page={showPagination ? page : rows}
          prepareRow={prepareRow}
          rows={rows}
        />
      </table>
      <Loading isLoading={isLoading} />
      <NoData
        showNoDataMessage={!isLoading && page.length === 0}
        noDataMessage={noDataMessage}
      />
      {showPagination && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          isLoading={isLoading}
          nextPage={nextPage}
          pagination={pagination}
          previousPage={previousPage}
        />
      )}
    </>
  );
};

export default Table;
