import React from 'react';

function ImportContactsIcon(props: SVGProps): JSX.Element {
  const { title = 'ImportContacts', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M10.004 4.315c1.209-.917 2.959-1.25 4.584-1.25 1.208 0 2.491.183 3.558.658a1.698 1.698 0 011.017 1.542v9.4c0 1.091-1.017 1.891-2.067 1.616-.817-.208-1.684-.3-2.517-.3-1.3 0-2.683.209-3.8.767-.492.25-1.058.25-1.558 0-1.117-.55-2.5-.767-3.8-.767-.834 0-1.7.092-2.517.3a1.662 1.662 0 01-2.067-1.616v-9.4c0-.675.409-1.267 1.017-1.542 1.075-.475 2.358-.658 3.567-.658 1.625 0 3.375.333 4.583 1.25zm6.5 10.175c.517.091 1-.292 1-.817V5.78a.845.845 0 00-.667-.816 10.572 10.572 0 00-2.25-.234c-1.416 0-3.458.542-4.583 1.25v9.592c1.125-.708 3.167-1.25 4.584-1.25.641 0 1.291.05 1.916.167z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ImportContactsIcon;
