import classnames from 'classnames';
import React from 'react';

import css from './index.css';

import Text, { TextSizes, TextTypes } from '../Text';
import getInitials from './helpers';

export type Size = 'tiny' | 'small' | 'medium' | 'large' | number;

export interface CommonProps {
  children?: React.ReactNode;
  className?: string;
  hasBorder?: boolean;
  style?: React.CSSProperties;
  size?: Size;
  // All other props
  [x: string]: any;
}

export interface NameProps extends CommonProps {
  name: string;
  src?: string;
  count?: never;
}

export interface CountProps extends CommonProps {
  count: number;
  src?: never;
  name?: never;
}

export type Props = NameProps | CountProps;

type TextProps = { type: TextTypes; size: TextSizes };
type SizeMapping = Record<Size, TextProps>;
const AVATAR_SIZE_TO_TEXT_MAPPING: SizeMapping = {
  tiny: {
    type: 'body',
    size: 'tiny',
  },
  small: {
    type: 'heading',
    size: 'small',
  },
  medium: {
    type: 'heading',
    size: 'small',
  },
  large: {
    type: 'heading',
    size: 'medium',
  },
};

function Avatar(
  {
    children,
    className,
    style,
    hasBorder = false,
    name,
    count,
    size = 'medium',
    src,
    ...remainingProps
  }: Props,
): React.ReactElement {
  const hasCustomSize = typeof size === 'number';
  const hasImage = Boolean(src);
  const isInteractive = Boolean(remainingProps.onClick);

  let classes = classnames({
    [css.avatar]: true,
    [css[size]]: !hasCustomSize,
    [css.bordered]: hasBorder,
    [css.interactive]: isInteractive,
  });

  if (className) {
    classes = classnames(className, classes);
  }

  const styles: React.CSSProperties = {};
  if (hasImage) {
    styles.backgroundImage = `url('${src}')`;
  }

  if (hasCustomSize) {
    styles.height = `${size}px`;
    styles.width = `${size}px`;
  }

  const text = name ? getInitials(name) : `+${count}`;
  const content = hasCustomSize ? (
    text
  ) : (
    <Text {...AVATAR_SIZE_TO_TEXT_MAPPING[size]}>{text}</Text>
  );

  return (
    <div
      data-testid="avatar-wrapper"
      className={classes}
      style={{ ...styles, ...style }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={isInteractive ? 0 : -1}
      {...remainingProps}
    >
      {!src && content}
      {children}
    </div>
  );
}

export default Avatar;
