import React from 'react';

export default function TestIcon(props: SVGProps): JSX.Element {
  const { title = 'Test', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10" {...restProps}>
      <title>{title}</title>
      <path d="M5.00634 6.33301H0.333008V7.66634H5.00634V9.66634L7.66634 6.99967L5.00634 4.33301V6.33301ZM8.99301 5.66634V3.66634H13.6663V2.33301H8.99301V0.333008L6.33301 2.99967L8.99301 5.66634Z" />
    </svg>
  );
}
