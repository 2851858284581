import React from 'react';

export default function ExposureIcon(props: SVGProps): JSX.Element {
  const { title = 'Exposure', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6711 6.76068C10.0977 6.85733 9.54858 7.07425 9.06086 7.40013C8.38074 7.85457 7.85066 8.50048 7.53763 9.25619C7.22461 10.0119 7.14271 10.8435 7.30229 11.6457C7.46187 12.448 7.85576 13.1849 8.43415 13.7633C9.01254 14.3417 9.74946 14.7356 10.5517 14.8951C11.354 15.0547 12.1855 14.9728 12.9412 14.6598C13.6969 14.3468 14.3428 13.8167 14.7973 13.1366C15.1232 12.6488 15.3401 12.0997 15.4367 11.5264H11.3586C11.1762 11.5264 11.0013 11.4539 10.8724 11.325C10.7435 11.1961 10.6711 11.0212 10.6711 10.8389V6.76068ZM8.29695 6.25686C9.20319 5.65133 10.2686 5.32814 11.3586 5.32814C11.7382 5.32814 12.0461 5.63594 12.0461 6.01564V10.1514H16.1818C16.5615 10.1514 16.8693 10.4592 16.8693 10.8389C16.8693 11.9288 16.5461 12.9942 15.9406 13.9005C15.335 14.8067 14.4744 15.513 13.4674 15.9301C12.4605 16.3472 11.3524 16.4563 10.2835 16.2437C9.21449 16.0311 8.23257 15.5062 7.46188 14.7355C6.69119 13.9649 6.16634 12.9829 5.95371 11.914C5.74108 10.845 5.85021 9.73696 6.2673 8.73C6.68439 7.72305 7.39072 6.86239 8.29695 6.25686Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7607 8.95897C13.381 8.95897 13.0732 8.65117 13.0732 8.27147L13.0732 3.44824C13.0732 3.06855 13.381 2.76074 13.7607 2.76074C14.8507 2.76074 15.9161 3.08394 16.8223 3.68947C17.7286 4.29499 18.4349 5.15565 18.852 6.16261C19.1303 6.83445 19.2715 7.55128 19.2715 8.27147C19.2715 8.45381 19.199 8.62868 19.0701 8.75761C18.9412 8.88654 18.7663 8.95897 18.584 8.95897H13.7607ZM14.4482 7.58397H17.8389C17.7874 7.2784 17.7015 6.97804 17.5817 6.6888C17.2686 5.93309 16.7385 5.28718 16.0584 4.83274C15.5707 4.50686 15.0216 4.28994 14.4482 4.19328V7.58397Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32422 10.6297C3.63981 12.3141 3.63805 15.0389 5.31487 16.7157C6.9917 18.3926 9.71651 18.3908 11.4009 16.7064C11.8886 16.2187 12.2343 15.6453 12.4398 15.0359L13.7427 15.4753C13.471 16.2811 13.0139 17.038 12.3732 17.6787C10.1542 19.8976 6.55882 19.9042 4.3426 17.688C2.12638 15.4718 2.133 11.8764 4.35195 9.65742C4.99264 9.01673 5.74956 8.55959 6.55536 8.28786L6.99472 9.59077C6.38527 9.79629 5.81188 10.142 5.32422 10.6297Z"
      />
    </svg>
  );
}
