import React from 'react';

export default function ThreeCoinsIcon(props: SVGProps): JSX.Element {
  const { title = 'ThreeCoins', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94111 6.875C4.61755 6.875 2.75 8.73064 2.75 11C2.75 13.2694 4.61755 15.125 6.94111 15.125C7.61303 15.125 8.24594 14.9699 8.80675 14.6949L9.412 15.9296C8.66647 16.295 7.82691 16.5 6.94111 16.5C3.8759 16.5 1.375 14.0464 1.375 11C1.375 7.95362 3.8759 5.5 6.94111 5.5C7.82691 5.5 8.66647 5.70496 9.412 6.07043L8.80675 7.30506C8.24594 7.03013 7.61303 6.875 6.94111 6.875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8942 6.875C8.57067 6.875 6.70312 8.73064 6.70312 11C6.70312 13.2694 8.57067 15.125 10.8942 15.125C11.5662 15.125 12.1991 14.9699 12.7599 14.6949L13.3651 15.9296C12.6196 16.295 11.78 16.5 10.8942 16.5C7.82903 16.5 5.32812 14.0464 5.32812 11C5.32812 7.95362 7.82903 5.5 10.8942 5.5C11.78 5.5 12.6196 5.70496 13.3651 6.07043L12.7599 7.30506C12.1991 7.03013 11.5662 6.875 10.8942 6.875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8672 6.875C12.53 6.875 10.6562 8.73325 10.6562 11C10.6562 13.2668 12.53 15.125 14.8672 15.125C17.2043 15.125 19.0781 13.2668 19.0781 11C19.0781 8.73325 17.2043 6.875 14.8672 6.875ZM9.28125 11C9.28125 7.95101 11.7937 5.5 14.8672 5.5C17.9407 5.5 20.4531 7.95101 20.4531 11C20.4531 14.049 17.9407 16.5 14.8672 16.5C11.7937 16.5 9.28125 14.049 9.28125 11Z"
      />
    </svg>
  );
}
