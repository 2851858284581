import React from 'react';

function AppIcon(props: SVGProps): JSX.Element {
  const { title = 'AppIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2C2.67157 2 2 2.67157 2 3.5V6C2 6.82843 2.67157 7.5 3.5 7.5H6C6.82843 7.5 7.5 6.82843 7.5 6V3.5C7.5 2.67157 6.82843 2 6 2H3.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H6C6.27614 3 6.5 3.22386 6.5 3.5V6C6.5 6.27614 6.27614 6.5 6 6.5H3.5C3.22386 6.5 3 6.27614 3 6V3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C9.17157 2 8.5 2.67157 8.5 3.5V6C8.5 6.82843 9.17157 7.5 10 7.5H12.5C13.3284 7.5 14 6.82843 14 6V3.5C14 2.67157 13.3284 2 12.5 2H10ZM9.5 3.5C9.5 3.22386 9.72386 3 10 3H12.5C12.7761 3 13 3.22386 13 3.5V6C13 6.27614 12.7761 6.5 12.5 6.5H10C9.72386 6.5 9.5 6.27614 9.5 6V3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 9.17157 2.67157 8.5 3.5 8.5H6C6.82843 8.5 7.5 9.17157 7.5 10V12.5C7.5 13.3284 6.82843 14 6 14H3.5C2.67157 14 2 13.3284 2 12.5V10ZM3.5 9.5C3.22386 9.5 3 9.72386 3 10V12.5C3 12.7761 3.22386 13 3.5 13H6C6.27614 13 6.5 12.7761 6.5 12.5V10C6.5 9.72386 6.27614 9.5 6 9.5H3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.5C9.17157 8.5 8.5 9.17157 8.5 10V12.5C8.5 13.3284 9.17157 14 10 14H12.5C13.3284 14 14 13.3284 14 12.5V10C14 9.17157 13.3284 8.5 12.5 8.5H10ZM9.5 10C9.5 9.72386 9.72386 9.5 10 9.5H12.5C12.7761 9.5 13 9.72386 13 10V12.5C13 12.7761 12.7761 13 12.5 13H10C9.72386 13 9.5 12.7761 9.5 12.5V10Z"
      />
    </svg>
  );
};

export default AppIcon;
