import React from 'react';

export default function ZoomInIcon(props: SVGProps): JSX.Element {
  const { title = 'ZoomIn', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M10.647 9.648h-.527l-.186-.18a4.334 4.334 0 00.987-3.56c-.314-1.854-1.86-3.334-3.727-3.56a4.34 4.34 0 00-4.847 4.846c.227 1.867 1.707 3.414 3.56 3.727a4.333 4.333 0 003.56-.987l.18.187v.527l2.84 2.833a.696.696 0 00.987 0l.006-.007a.696.696 0 000-.986l-2.833-2.84zm-4 0c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm-.333-4.334a.33.33 0 01.333-.333.33.33 0 01.333.333v1h1a.33.33 0 01.334.334.33.33 0 01-.334.333h-1v1a.33.33 0 01-.333.333.33.33 0 01-.333-.333v-1h-1a.33.33 0 01-.334-.333.33.33 0 01.334-.334h1v-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}
