import { RefObject } from 'react';

import { Props as TextProps } from '../Text';

/**
 * Props for Checkbox Input
 */
export interface CheckboxInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'ref' | 'children' | 'crossOrigin'
  > {
  /**
   * The ref is forwarded to the root element.
   */
  ref?: RefObject<HTMLInputElement>;

  /**
   * If true, the component appears indeterminate.
   * This set in browser inputRef.current.indeterminate
   * @default false
   */
  indeterminate?: boolean;

  /**
   * If true, the component appears with error scheme.
   * @default false
   */
  error?: boolean;

  /**
   * id used for testing. Will turn data-testid
   */
  testId?: string;

  inputClassName?: string;
}

/**
 * Status for Checkbox Input
 */
export enum CheckboxInputStatus {
  DEFAULT = 'default',
  CHECKED = 'checked',
  INDETERMINATE = 'indeterminate',
}

/**
 * Props for Checkbox Label
 */
export type CheckboxLabelProps = Pick<
  CheckboxInputProps,
  'disabled' | 'testId'
> &
  React.PropsWithChildren<TextProps<'label'>>;

/**
 * Props for Checkbox Wrapper
 */
export type CheckboxWrapperProps = React.HTMLAttributes<HTMLDivElement> &
  Pick<CheckboxInputProps, 'testId'>;

/**
 * Props for Checkbox
 */
export type CheckboxProps = Pick<
  CheckboxInputProps,
  | 'onBlur'
  | 'onChange'
  | 'indeterminate'
  | 'error'
  | 'disabled'
  | 'checked'
  | 'className'
  | 'inputClassName'
  | 'name'
  | 'id'
  | 'testId'
  | 'ref'
  | 'name'
  | 'value'
  | 'onClick'
> &
  Pick<CheckboxLabelProps, 'children'>;

/**
 * Props for CheckboxDependent used on useCheckbox
 */
export interface CheckboxDependentProps extends CheckboxProps {
  dependents?: CheckboxDependentProps[];
}
