import React from 'react';
// Components
import { Controller, FieldValues } from 'react-hook-form';

import css from './styles.css';

import type {
  ButtonGroupProps,
  SimpleButtonProps,
} from '../../../ButtonGroup/_types';
// Types
import type { FormOptions } from '../../_types';

import {
  ButtonGroupButton,
  ButtonGroupWrapper,
} from '../../../ButtonGroup/elements';
import Text from '../../../Text';

export type FormButtonGroupProps<V extends FieldValues, T> = Omit<
  ButtonGroupProps<T>,
  'onChange' | 'onBlur' | 'value' | 'setSelectedButton'
> &
  FormOptions<V>;

const FormButtonGroup = <V extends FieldValues = any, T = any>({
  control,
  name,
  buttons,
  type = 'secondary',
  buttonProps,
  ...restProps
}: FormButtonGroupProps<V, T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <>
        <ButtonGroupWrapper
          {...restProps}
          onBlur={onBlur}
          selectedButton={value}
          setSelectedButton={onChange}
          type={type}
        >
          {(buttons as SimpleButtonProps<T>[]).map(
            ({ btnValue, label, ...restBtnProps }, index) => (
              <ButtonGroupButton
                {...buttonProps}
                {...restBtnProps}
                data-testid={`btn-${index}`}
                btnValue={btnValue}
                key={label}
              >
                {label}
              </ButtonGroupButton>
            )
          )}
        </ButtonGroupWrapper>
        {error && (
          <Text size="small" className={css.error}>
            {error.message}
          </Text>
        )}
      </>
    )}
  />
);

export default FormButtonGroup;
