import { cva } from 'class-variance-authority';

export const iconsVariants = cva('[&>svg]:fill-current', {
  variants: {
    size: {
      tiny: '[&>svg]:w-sizing-comp-button-tiny-icon [&>svg]:h-sizing-comp-button-tiny-icon',
      small:
        '[&>svg]:w-sizing-comp-button-small-icon [&>svg]:h-sizing-comp-button-small-icon',
      medium:
        '[&>svg]:w-sizing-comp-button-medium-icon [&>svg]:h-sizing-comp-button-medium-icon',
    },
    loading: { true: 'invisible', false: '' },
  },
});
