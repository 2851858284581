// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

import Text, { Props as TextProps } from '../../../../Text';

export interface FooterTextProps extends TextProps {
  typeText: 'error' | 'helper';
}

const FooterText = (
  { typeText, className = '', ...restProps }: FooterTextProps,
): JSX.Element => {
  const classes = cn({
    [styles.errorFooterText]: typeText === 'error',
    [styles.helperFooterText]: typeText === 'helper',
    [className]: Boolean(className),
  });

  return <Text<'p'> size="small" className={classes} {...restProps} />;
};

export default FooterText;
