import React from 'react';

export default function OfficeIcon(props: SVGProps): JSX.Element {
  const { title = 'Office', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6875 2.625V0.875H10.9375V2.625H7C6.0335 2.625 5.25 3.4085 5.25 4.375V26.25H9.625V24.5H7V21.875H9.625V20.125H7V17.5H9.625V15.75H7V13.125H16.625V15.75H14C13.0335 15.75 12.25 16.5335 12.25 17.5V26.25H22.75V17.5C22.75 16.5335 21.9665 15.75 21 15.75H18.375V4.375C18.375 3.4085 17.5915 2.625 16.625 2.625H12.6875ZM7 4.375V7H16.625V4.375H7ZM7 8.75H16.625V11.375H7V8.75ZM21 17.5V20.125H18.375V17.5H21ZM16.625 17.5V20.125H14V17.5H16.625ZM16.625 21.875H14V24.5H16.625V21.875ZM21 21.875H18.375V24.5H21V21.875Z"
      />
    </svg>
  );
}
