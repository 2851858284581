import React from 'react';

export default function MicrophoneIcon(props: SVGProps): JSX.Element {
  const { title = 'Microphone', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M12 15C13.655 15 14.99 13.655 14.99 12L15 6C15 4.34 13.66 3 12 3C10.345 3 9 4.34 9 6V12C9 13.655 10.345 15 12 15ZM17.3 12C17.3 15 14.765 17.1 12 17.1C9.24 17.1 6.7 15 6.7 12H5C5 15.415 7.72 18.235 11 18.72V22H13V18.72C16.28 18.235 19 15.415 19 12H17.3Z" />
    </svg>
  );
}
