import classnames from 'classnames';
import React from 'react';

import styles from '../index.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

function DataContainerWrapper(
  { children, className = '', disabled = false, ...restProps }: Props,
): React.ReactElement {
  return (
    <div
      className={classnames(styles.container, className)}
      data-testid="data-container-wrapper"
      {...restProps}
    >
      {children}
      {disabled && (
        <div
          data-testid="data-container-wrapper-disabled"
          className={styles.disabled}
        />
      )}
    </div>
  );
}

export default DataContainerWrapper;
