// Hooks
// Utils
import _isEqual from 'lodash/isEqual';
import { useRef } from 'react';

type PrimitiveTypes = string | number | boolean;
type VoidTypes = null | undefined;
type NotListOfPrimitiveTypes<T> =
  T extends Array<PrimitiveTypes | VoidTypes> ? never : T;

export const useDeepCompare = <Dependencies>(
  dependencies: NotListOfPrimitiveTypes<Dependencies>,
): [number] => {
  const ref = useRef<Dependencies>();
  const signalRef = useRef<number>(0);

  if (!_isEqual(dependencies, ref.current)) {
    // _isEqual is where the deep comparison of dependency arrays is happening
    ref.current = dependencies;
    signalRef.current += 1;
  }

  // By returning an updated number as the dep array,
  // React is able to easily compare two different numbers
  return [signalRef.current];
};
