import { noop } from 'lodash';
import React from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import FormWizardActions from './components/FormWizardActions';
import FormWizardContent from './components/FormWizardContent';
import FormWizardContentWrapper from './components/FormWizardContentWrapper';
import FormWizardHeader from './components/FormWizardHeader';
import FormWizardModalBody from './components/FormWizardModalBody';
import FormWizardSidebar from './components/FormWizardSidebar';

import { FormWizardProps } from './types';

import { Modal } from '../ModalV2';
import { FormWizardProvider } from './FormWizardProvider';

const FormWizard = <T extends FieldValues>(
  {
    steps,
    startingStep = 0,
    isOpen,
    onClose,
    form,
    onSubmit,
    onInvalid = noop,
    getProgressText,
    showProgress = true,
    displayedMaxSteps,
    modalProps = {},
    modalBodyProps = {},
    sidebarProps = {},
    showSidebar = false,
    children = null,
  }: FormWizardProps<T>,
) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    allowBackdropClose
    allowEscapeClose
    {...modalProps}
  >
    <FormProvider {...form}>
      <FormWizardProvider
        steps={steps}
        startingStep={startingStep}
        onSubmit={onSubmit}
        onInvalid={onInvalid}
        getProgressText={getProgressText}
        showProgress={showProgress}
        displayedMaxSteps={displayedMaxSteps}
        formFieldObject={form.getValues()}
      >
        <FormWizardHeader />
        <FormWizardModalBody {...modalBodyProps}>
          <FormWizardContentWrapper>
            {showSidebar && <FormWizardSidebar {...sidebarProps} />}
            <FormWizardContent />
          </FormWizardContentWrapper>
        </FormWizardModalBody>
        <FormWizardActions />
        {children}
      </FormWizardProvider>
    </FormProvider>
  </Modal>
);

export default FormWizard;
