import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '../../../Button';
import { useFormWizardContext } from '../../FormWizardProvider';

export interface Props {
  className?: string;
}

const FormWizardProgressBtns: React.FC<Props> = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentStepIndex: currentStep,
    maxSteps,
    currentStepElement,
    onSubmit,
    onInvalid,
    goToStepIndex,
  } = useFormWizardContext();
  const { trigger, handleSubmit } = useFormContext();
  const isEndStep = useMemo(
    () => maxSteps === currentStep,
    [maxSteps, currentStep],
  );

  const onNext = async () => {
    const validationResult = await trigger(
      currentStepElement.formFieldNames ?? [],
    );

    if (!validationResult) {
      return;
    }

    if (currentStepElement.isSubmitStep || isEndStep) {
      await handleSubmit(
        (values) =>
          onSubmit({
            values,
            setIsLoading,
            goToStepIndex,
          }),
        onInvalid,
      )();
    }

    if (!isEndStep) {
      currentStepElement.onNext!(goToStepIndex, currentStep);
    }
  };

  const {
    showBtn: showNextBtn,
    isDisabled: isNextDisabled,
    ...nextBtnProps
  } = currentStepElement.nextBtnProps ?? {};
  const {
    showBtn: showBackBtn,
    isDisabled: isBackDisabled,
    ...backBtnProps
  } = currentStepElement.backBtnProps ?? {};

  const classes = classNames({ [className!]: className || '' });

  return (
    <div className={classes}>
      {showBackBtn ? (
        <Button
          {...backBtnProps}
          disabled={isBackDisabled}
          onClick={() => {
            if (currentStepElement.onPrevious) {
              currentStepElement.onPrevious(goToStepIndex, currentStep);
            }
          }}
        />
      ) : null}
      {showNextBtn ? (
        <Button
          {...nextBtnProps}
          disabled={isNextDisabled}
          isLoading={isLoading}
          onClick={onNext}
        />
      ) : null}
    </div>
  );
};

export default FormWizardProgressBtns;
