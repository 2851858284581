import { ButtonSize, ButtonType, ButtonVariant } from 'types';

/**
 * Returns the corresponding spinner size based on the given button size.
 * @param size - The size of the button.
 * @returns The corresponding spinner size.
 */
export const getSpinnerSize = (size: ButtonSize) => {
  switch (size) {
    case 'tiny':
      return 'small';
    case 'small':
      return 'medium';
    case 'medium':
      return 'medium';
  }
};

/**
 * Determines the spinner variant based on the button variant and type.
 *
 * @param variant - The button variant.
 * @param type - The button type.
 * @returns The spinner variant.
 */
export const getSpinnerVariant = (variant: ButtonVariant, type: ButtonType) => {
  if (variant === 'strong' && type === 'filled') {
    return 'strong';
  }
  if (variant === 'inverted' && ['ghost', 'outline'].includes(type)) {
    return 'strong';
  }
  return 'inverted';
};
