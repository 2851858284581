import React from 'react';

export default function CancelIcon(props: SVGProps): JSX.Element {
  const { title = 'Cancel', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M12 2.00003C6.475 2.00003 2 6.47503 2 12C2 17.525 6.475 22 12 22C17.525 22 22 17.525 22 12C22 6.47503 17.525 2.00003 12 2.00003ZM17 15.585L15.585 17L12 13.415L8.415 17L7 15.585L10.585 12L7 8.41503L8.415 7.00003L12 10.585L15.585 7.00003L17 8.41503L13.415 12L17 15.585Z" />
    </svg>
  );
}
