// Utils
import { isEqual } from 'lodash';
import React from 'react';
// Components
import { Controller } from 'react-hook-form';

// Styles
import css from './styles.css';

// Types
import type { CheckboxProps } from '../../../Checkbox/types';
import type { FieldValues, FormOptions } from '../../_types';

import Checkbox, { useCheckbox } from '../../../Checkbox';
import Text from '../../../Text';

interface CheckboxOption
  extends Omit<
    CheckboxProps,
    'onBlur' | 'onChange' | 'value' | 'name' | 'children' | 'checked'
  > {
  label: React.ReactNode;
  value: any;
}

export interface FormCheckboxGroupProps<T extends FieldValues = FieldValues>
  extends FormOptions<T> {
  options: CheckboxOption[];
}

const FormCheckboxGroup = <T extends FieldValues = FieldValues>(
  { name, control, options, defaultValue, rules }: FormCheckboxGroupProps<T>,
) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
      const valueArray = (value as Array<any>) ?? [];

      return (
        <>
          {options.map(({ label, ...opt }, index) => {
            const [
              ,
              {
                onChange: checkboxOnChange,
                onBlur: checkboxOnBlur,
                ...checkboxProps
              },
            ] = useCheckbox({});

            return (
              <Checkbox
                {...checkboxProps}
                {...opt}
                error={!!error}
                testId={`${name}-${index}`}
                key={`${name}-${index}`}
                checked={valueArray.some((val) => isEqual(val, opt.value))}
                value={opt.value}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (checkboxOnBlur) {
                    checkboxOnBlur(event);
                  }
                  onBlur();
                }}
                onChange={(event) => {
                  if (checkboxOnChange) {
                    checkboxOnChange(event);
                  }

                  if (event.target.checked) {
                    onChange([...valueArray, opt.value]);
                  } else {
                    onChange(
                      valueArray.filter((val) => !isEqual(val, opt.value)),
                    );
                  }
                }}
              >
                {label}
              </Checkbox>
            );
          })}
          {error && (
            <Text size="small" className={css.error}>
              {error.message}
            </Text>
          )}
        </>
      );
    }}
  />
);

export default FormCheckboxGroup;
