/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResizeObserver } from '@juggle/resize-observer';
import { SpringConfig, TransitionFn, useTransition } from '@react-spring/web';
import {
  LegacyRef,
  MutableRefObject,
  RefCallback,
  useMemo,
  useRef,
} from 'react';
import useMeasure, { RectReadOnly } from 'react-use-measure';

export const mergeRefs = <T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T> | undefined | null>,
): RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export const ANIMATION_CONFIG_ENTER = {
  mass: 1,
  tension: 250,
  friction: 25,
};

export const ANIMATION_CONFIG_LEAVE = {
  mass: 1,
  tension: 250,
  friction: 25,
  clamp: true,
};
type Transitions = TransitionFn<
  boolean,
  {
    opacity: number;
    height: number;
    overflow: string;
  }
>;

export const useHeight = (ref: MutableRefObject<any>, bounds: RectReadOnly) => {
  return useMemo(() => {
    if (!ref || !ref?.current) {
      return 0;
    }

    const { marginTop, marginBottom } = window.getComputedStyle(
      ref.current as Element,
    );

    const marginTopConverted = parseFloat(marginTop) || 0;
    const marginBottomConverted = parseFloat(marginBottom) || 0;

    return marginTopConverted + marginBottomConverted + bounds.height;
  }, [bounds.height, ref]);
};

export const useAnimateHeight = (
  isVisible: boolean,
  config: SpringConfig | null = null,
): [LegacyRef<HTMLDivElement>, Transitions] => {
  const localRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

  const height = useHeight(localRef, bounds);

  const defaultConfig = useMemo(
    () => (isVisible ? ANIMATION_CONFIG_ENTER : ANIMATION_CONFIG_LEAVE),
    [isVisible],
  );

  const transitions = useTransition(isVisible, {
    update: { height },
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    enter: { opacity: 1, height, overflow: 'visible' },
    leave: { opacity: 0, height: 0, overflow: 'hidden' },
    config: config || defaultConfig,
  });

  const mergedRefs = mergeRefs([localRef, ref]);

  return [mergedRefs, transitions];
};
