import React from 'react';
// Components
import { Controller } from 'react-hook-form';

import type { UploadedFileType } from '../../../FileUpload/_components/_types';

import type { FieldValues, FormOptions } from '../../_types';

import FileUpload from '../../../FileUpload';
// Types
import type { Props } from '../../../FileUpload';

export type FormFileUploadProps<T extends FieldValues = FieldValues> = Props &
  FormOptions<T>;

const FormFileUpload = <T extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  setUploadedFiles,
  uploadedFiles,
  onDelete,
  ...fileUploadProps
}: FormFileUploadProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => {
      let finalFile = uploadedFiles ? uploadedFiles[0] : value;
      if (error) {
        finalFile = finalFile || {};
        finalFile.error = {
          ...error,
          name: 'File Upload Error',
          message: error.message || '',
        };
      } else if (finalFile) {
        finalFile.error = undefined;
      }
      return (
        <FileUpload
          {...fileUploadProps}
          uploadedFiles={finalFile ? [finalFile] : []}
          setUploadedFiles={(files: UploadedFileType[]) => {
            const hasSetUploadFileFn = !!setUploadedFiles;
            if (hasSetUploadFileFn) setUploadedFiles(files);
            onChange(files[0]);
          }}
          onDelete={() => {
            onChange(undefined);
            if (onDelete) {
              onDelete(0);
            }
          }}
        />
      );
    }}
  />
);

export default FormFileUpload;
