import cn from 'classnames';
import React from 'react';

import * as css from './index.css';

import Button from '../Button';
import { ChevronIcon } from '../Icons';
import Loader from '../Loader';

type Props = {
  hasData: boolean;
  isLoading: boolean;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
  className?: string;
};

const Pagination: CommonComponent<Props, 'div'> = (
  {
    hasData,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    onNext,
    onPrevious,
    className = '',
    ...restProps
  },
) => (
  <div className={cn([css.paginationButtonsWrapper, className])} {...restProps}>
    <div>
      {hasData && isLoading && <Loader className={css.loader} delay={400} />}
    </div>
    <div>
      <Button
        type="tertiary"
        className={css.paginationButton}
        onClick={onPrevious}
        disabled={!hasPreviousPage || isLoading}
        Icon={(): JSX.Element => (
          <ChevronIcon
            direction="left"
            // For accessibility
            title="Previous Page"
          />
        )}
      />
      <Button
        type="tertiary"
        className={css.paginationButton}
        onClick={onNext}
        disabled={!hasNextPage || isLoading}
        Icon={(): JSX.Element => (
          <ChevronIcon
            direction="right"
            // For accessibility
            title="Next Page"
          />
        )}
      />
    </div>
  </div>
);

export default Pagination;
