import React from 'react';

export default function FolderOpenIcon(props: SVGProps): JSX.Element {
  const { title = 'Folder open icon', fill, ...restProps } = props;

  return (
    <svg viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fill={fill}
        d="M53.3333 16.0018H31.9999L26.6666 10.6685H10.6666C7.71992 10.6685 5.35992 13.0551 5.35992 16.0018L5.33325 48.0018C5.33325 50.9485 7.71992 53.3351 10.6666 53.3351H53.3333C56.2799 53.3351 58.6666 50.9485 58.6666 48.0018V21.3351C58.6666 18.3885 56.2799 16.0018 53.3333 16.0018ZM53.3333 48.0018H10.6666V21.3351H53.3333V48.0018Z"
      />
    </svg>
  );
}
