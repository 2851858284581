import { useRouter } from 'next/router';
import { useCallback } from 'react';

import InfoDrawer, { DrawerID } from 'components/InfoDrawer/InfoDrawer';

const DRAWER_QUERY_STRING = 'drawer-info';

type UseInfoDrawer = {
  component: JSX.Element;
  setter: (x: DrawerID) => void;
};

const useInfoDrawer = (): UseInfoDrawer => {
  const router = useRouter();
  const drawerID = router.query[DRAWER_QUERY_STRING] as DrawerID;

  const onClose = useCallback(() => {
    const { [DRAWER_QUERY_STRING]: removedQueryParam, ...routerQuery } =
      router.query;
    removedQueryParam && router.replace({ query: { ...routerQuery } });
  }, [router]);

  return {
    component: (
      <InfoDrawer
        isOpen={!!drawerID}
        onClose={onClose}
        drawerID={drawerID || ''}
      />
    ),
    setter: (id: DrawerID) =>
      router.push(
        { query: { ...router.query, [DRAWER_QUERY_STRING]: id } },
        undefined,
        { scroll: false },
      ),
  };
};

export default useInfoDrawer;
