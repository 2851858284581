import React from 'react';

export default function GlobeIcon(props: SVGProps): JSX.Element {
  const { title = 'Globe', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M11.995 2C6.47 2 2 6.475 2 12C2 17.525 6.47 22 11.995 22C17.52 22 22 17.525 22 12C22 6.475 17.52 2 11.995 2ZM18.92 8H15.97C15.645 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.345 18.92 8V8ZM12 4.035C12.835 5.235 13.485 6.57 13.91 8H10.09C10.515 6.57 11.165 5.235 12 4.035ZM4.26 14C4.095 13.36 4 12.69 4 12C4 11.31 4.095 10.64 4.26 10H7.635C7.555 10.655 7.5 11.32 7.5 12C7.5 12.68 7.555 13.345 7.64 14H4.26ZM5.075 16H8.025C8.35 17.25 8.805 18.45 9.405 19.565C7.565 18.935 6.035 17.655 5.075 16V16ZM8.025 8H5.075C6.035 6.345 7.565 5.065 9.405 4.435C8.805 5.55 8.35 6.75 8.025 8ZM12 19.965C11.17 18.765 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.765 12 19.965ZM14.34 14H9.66C9.565 13.345 9.5 12.68 9.5 12C9.5 11.32 9.565 10.655 9.66 10H14.34C14.435 10.655 14.5 11.32 14.5 12C14.5 12.68 14.435 13.345 14.34 14ZM14.595 19.56C15.195 18.445 15.65 17.25 15.975 16H18.925C17.96 17.655 16.43 18.93 14.595 19.56ZM16.36 14C16.44 13.345 16.5 12.68 16.5 12C16.5 11.32 16.445 10.655 16.36 10H19.735C19.9 10.64 20 11.31 20 12C20 12.69 19.905 13.36 19.735 14H16.36Z" />
    </svg>
  );
}
