import React from 'react';

export default function MinusIcon(props: SVGProps): JSX.Element {
  const { title = 'MinusIcon', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 37" {...restProps}>
      <title>{title}</title>
      <path d="M28.5 20h-21v-3h21v3z"/>
    </svg>
  );
}
