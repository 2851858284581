import type { StepVariant } from './_types';

export enum STEP_VARIANTS {
  warning = 'warning',
  error = 'error',
  active = 'active',
  complete = 'complete',
  incomplete = 'incomplete',
}

export const HIGHLIGHTABLE_VARIANTS: StepVariant[] = ['warning', 'error'];
