import React, { CSSProperties } from 'react';

import type { Direction, SVGProps } from './_types';

type ChevronProps = SVGProps & { direction: Direction };

const DIRECTION_MAPPING: Record<Direction, CSSProperties> = {
  left: { transform: 'rotate(0deg)' },
  right: { transform: 'rotate(180deg)' },
  up: { transform: 'rotate(90deg)' },
  down: { transform: 'rotate(270deg)' },
};

export default function ChevronIcon(props: ChevronProps): JSX.Element {
  const {
    title = 'Chevron',
    direction = 'left',
    style = {},
    ...restProps
  } = props;

  return (
    <svg
      style={{ ...DIRECTION_MAPPING[direction], ...style }}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30.83 14.83L28 12L16 24L28 36L30.83 33.17L21.66 24L30.83 14.83Z" />
    </svg>
  );
}
