import * as React from 'react';

import Tooltip, { Props as TooltipProps } from '../Tooltip';

type MaybeTooltipProps = TooltipProps & {
  shouldShowTooltip: boolean;
  children: JSX.Element;
};

const MaybeTooltip = ({
  shouldShowTooltip,
  children,
  ...tooltipProps
}: MaybeTooltipProps) => shouldShowTooltip ? (
    <Tooltip {...tooltipProps}>{children}</Tooltip>
  ) : (
    children
  );

export default MaybeTooltip;
