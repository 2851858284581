import cn from 'classnames';
import _uniqueId from 'lodash/uniqueId';
import React from 'react';
import Select, { NonceProvider, components } from 'react-select-v5';
import CreatableSelect from 'react-select-v5/creatable';

import { getSelectStyles } from './utils';

import styles from './index.css';

import type { GroupBase, SelectV2Props } from './types';

import Label from '../InputV2/elements/Label';
import ClearIndicator from './_elements/ClearIndicator';
import DropdownIndicator from './_elements/DropdownIndicator';
import Footnotes from './_elements/Footnotes';
import LoadingMessage from './_elements/LoadingMessage';
import MultiValueLabel from './_elements/MultiValueLabel';
import MultiValueRemove from './_elements/MultiValueRemove';
import Option from './_elements/Option';
import { SELECT_OPTION_HEIGHT } from './constants';

const SelectV2 = <
  Option extends { hoverContent?: string; [key: string]: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  {
    isDisabled = false,
    isError = false,
    hasCreatableOptions = false,
    className = '',
    containerClassName = '',
    errorFooterText = '',
    helperFooterText = '',
    maxMenuHeight = 240,
    menuPlacement = 'auto',
    label,
    inputId,
    options = [],
    ...restProps
  }: SelectV2Props<Option, IsMulti, Group>,
): JSX.Element => {
  const selectClasses = cn({
    [styles.select]: true,
    [styles.disabled]: isDisabled,
    [styles.error]: isError,
    [className]: Boolean(className),
  });

  const containerClasses = cn({
    [containerClassName]: Boolean(containerClassName),
    [styles.container]: true,
  });

  const inputID = React.useMemo(() => inputId || _uniqueId(), [inputId]);

  const SelectComponent = hasCreatableOptions ? CreatableSelect : Select;

  const minMenuHeight = React.useMemo(
    () =>
      options.length
        ? SELECT_OPTION_HEIGHT * options.length
        : SELECT_OPTION_HEIGHT,
    [options.length],
  );

  const selectStyles = getSelectStyles<Option, IsMulti, Group>();
  return (
    <div className={containerClasses}>
      {label && <Label htmlFor={inputID}>{label}</Label>}
      <SelectComponent<Option, IsMulti, Group>
        inputId={inputID}
        className={selectClasses}
        classNamePrefix="select"
        isDisabled={isDisabled}
        placeholder=""
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        minMenuHeight={minMenuHeight}
        styles={{
          ...selectStyles,
          ...restProps.styles,
        }}
        menuPortalTarget={document.body}
        options={options}
        isOptionDisabled={({ isDisabled: optionDisabled }) =>
          Boolean(optionDisabled)
        }
        {...restProps}
        components={{
          IndicatorSeparator: undefined,
          LoadingIndicator: undefined,
          DropdownIndicator,
          ClearIndicator,
          MultiValueLabel,
          MultiValueRemove,
          LoadingMessage,
          Option,
          ...restProps.components,
        }}
      />
      <Footnotes
        errorFooterText={errorFooterText}
        helperFooterText={helperFooterText}
      />
    </div>
  );
};

export default SelectV2;

export { components, NonceProvider };
