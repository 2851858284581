// Utils
import cn from 'classnames';
import React from 'react';

// CSS
import css from './index.css';

function Breadcrumbs({
  children,
  className = '',
  ...restProps
}: CommonComponentProps<Record<string, any>, 'nav'>): CommonComponentReturn {
  const wrapperClasses = cn({
    [css.wrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <nav className={wrapperClasses} {...restProps}>
      {children}
    </nav>
  );
}

export default Breadcrumbs;
export { default as Breadcrumb } from './Breadcrumb';
