import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import css from './index.css';

import { Body as ModalBody } from '../../../ModalV2';
import { Props } from '../../../Text';

const FormWizardModalBody: React.FC<PropsWithChildren<Props>> = (
  { children, className = '', ...restProps },
) => {
  const classes = cn({
    [css.modalBody]: true,
    [className]: className,
  });
  return (
    <ModalBody className={classes} {...restProps}>
      {children}
    </ModalBody>
  );
};

export default FormWizardModalBody;
