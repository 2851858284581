import { isEqual } from 'lodash';
import React from 'react';
// Components
import { Controller } from 'react-hook-form';

// Types
import type {
  GroupBase,
  OnChangeValue,
  SelectOption,
  SelectV2Props,
} from '../../../SelectV2/types';
import type { FieldValues, FormOptions } from '../../_types';

import SelectV2 from '../../../SelectV2';

export type FormSelectProps<
  T extends FieldValues = FieldValues,
  O extends SelectOption<string, string | JSX.Element> = SelectOption<
    string,
    string
  >,
  IsMulti extends boolean = false,
  Group extends GroupBase<O> = GroupBase<O>,
> = Omit<SelectV2Props<O, IsMulti, Group>, 'onBlur' | 'onChange' | 'value'> &
  FormOptions<T>;

const FormSelect = <
  T extends FieldValues = FieldValues,
  O extends SelectOption<string, string | JSX.Element> = SelectOption<
    string,
    string
  >,
  IsMulti extends boolean = false,
  Group extends GroupBase<O> = GroupBase<O>,
>({
  control,
  defaultValue,
  name,
  options,
  rules,
  isMulti,
  ...selectProps
}: FormSelectProps<T, O, IsMulti, Group>) => {
  function findValueInOptions(value: O['value']) {
    let valueToReturn: O | null = null;

    // Using every here so we don't need to go through every single option
    // after we found the correct match! This is an optimization for extremely
    // long selects.
    options?.every((groupOrOption) => {
      if ('options' in groupOrOption) {
        let notFound = true;
        groupOrOption.options.forEach((option) => {
          if (isEqual(option.value, value)) {
            valueToReturn = option;
            notFound = false;
          }
        });

        return notFound;
      }

      if (isEqual(groupOrOption.value, value)) {
        valueToReturn = groupOrOption;
        return false;
      }

      return true;
    });

    return valueToReturn;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        function handleChange(newValue: OnChangeValue<O, IsMulti>) {
          if (!newValue) {
            onChange('');
            return;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Type 'NonNullable<OnChangeValue<O, IsMulti>>' may represent a primitive value, which is not permitted as the right operand of the 'in' operator
          if ('value' in newValue) {
            onChange(newValue.value);
            return;
          }

          onChange(newValue.map((newV) => newV.value));
        }

        return (
          <SelectV2<O, IsMulti, Group>
            {...selectProps}
            isMulti={isMulti}
            key={name}
            onBlur={onBlur}
            onChange={handleChange}
            options={options}
            isError={!!error || selectProps.isError}
            errorFooterText={error?.message || selectProps.errorFooterText}
            value={
              Array.isArray(value)
                ? value.map((v: O['value']) => findValueInOptions(v))
                : findValueInOptions(value)
            }
          />
        );
      }}
    />
  );
};

export default FormSelect;
