import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import FormAsyncSelectProvider from './_components/FormAsyncProvider';
import FormAsyncSelectInner from './_components/FormAsyncSelectInner';

import { SelectOption } from '../../../SelectV2/types';
import { FormAsyncSelectProps } from './types';

const FormAsyncSelect = <
  T extends FieldValues = FieldValues,
  IsMulti extends boolean = false,
>(
  {
    control,
    name,
    loadOptions,
    dynamicVars,
    ...selectProps
  }: FormAsyncSelectProps<T, SelectOption<string, string>, IsMulti>,
) => {
  const {
    field: { value },
  } = useController({ control, name });

  return (
    <FormAsyncSelectProvider
      loadOptions={loadOptions}
      value={value}
      dynamicVars={dynamicVars}
      disabled={selectProps.isDisabled}
    >
      <FormAsyncSelectInner control={control} name={name} {...selectProps} />
    </FormAsyncSelectProvider>
  );
};

export default FormAsyncSelect;
