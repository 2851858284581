/* eslint-disable @typescript-eslint/ban-ts-comment */

// Components
import { animated, useTransition } from '@react-spring/web';
import useEventListener from '@use-it/event-listener';
// Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

import type { ModalBackdropProps } from '../_types';

// Types & Constants & Hooks
import { CloseActionEnum } from '../_constants';
import useModalContext from '../useModalContext';

// TODO (@volkan-anchor) [05/19/2021]: Add Focus trap
const Backdrop: React.FunctionComponent<ModalBackdropProps> = ({
  allowEscapeClose = false,
  allowBackdropClose = false,
  children,
  className = '',
  style,
  ...restProps
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const prevActiveEl = React.useRef<HTMLElement>(null);
  const { onClose, isOpen } = useModalContext();
  const transitions = useTransition(isOpen, {
    from: {
      scale: 0.9,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0.9,
      opacity: 0,
    },
    config: (_item, _index, state) => {
      switch (state) {
        case 'enter':
          return {
            mass: 5,
            tension: 1000,
            friction: 100,
          };
        case 'leave':
          return {
            mass: 5,
            tension: 1000,
            friction: 50,
            clamp: true,
          };
        default:
          return {};
      }
    },
  });

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
      if (ref.current) {
        // @ts-ignore
        prevActiveEl.current = document.activeElement;
        ref.current.focus();
      }
    } else {
      document.body.classList.remove(styles.noScroll);
      if (prevActiveEl.current) {
        prevActiveEl.current.focus();
      }
    }

    // cleanup on unmount
    // Fixes scrolling not working on the web app
    // Happens when backdrop is not being removed from the DOM whithout using isOpen false
    return () => {
      document.body.classList.remove(styles.noScroll);
      if (prevActiveEl.current) {
        prevActiveEl.current.focus();
      }
    };

  }, [isOpen]);

  useEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && allowEscapeClose && isOpen) {
        e.preventDefault();
        e.stopPropagation();
        onClose(CloseActionEnum.Escape);
      }
    },
    ref.current
  );

  function handleOnClick(e: React.MouseEvent): void {
    if (e.target === ref.current && allowBackdropClose) {
      onClose(CloseActionEnum.Backdrop);
    }
  }

  const classes = cn({
    [className]: Boolean(className),
    [styles.backdrop]: true,
  });

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <animated.div
              style={{ opacity: props.opacity }}
              className={classes}
              onMouseDown={handleOnClick}
              tabIndex={0}
              ref={ref}
            >
              <animated.div
                style={{
                  ...style,
                  willChange: 'transform, opacity',
                  opacity: props.opacity,
                  transform: props.scale.to((v) => `scale3d(${v}, ${v}, 1)`),
                }}
                {...restProps}
              >
                {children}
              </animated.div>
            </animated.div>
          )
      )}
    </>
  );
};

export default Backdrop;
