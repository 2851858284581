import React from 'react';

export default function DomainIcon(props: SVGProps): JSX.Element {
  const { title = 'Domain', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22" {...restProps}>
      <title>{title}</title>
      <path d="M11.9997 5.16667V0.5H0.333008V21.5H23.6663V5.16667H11.9997ZM4.99967 19.1667H2.66634V16.8333H4.99967V19.1667ZM4.99967 14.5H2.66634V12.1667H4.99967V14.5ZM4.99967 9.83333H2.66634V7.5H4.99967V9.83333ZM4.99967 5.16667H2.66634V2.83333H4.99967V5.16667ZM9.66634 19.1667H7.33301V16.8333H9.66634V19.1667ZM9.66634 14.5H7.33301V12.1667H9.66634V14.5ZM9.66634 9.83333H7.33301V7.5H9.66634V9.83333ZM9.66634 5.16667H7.33301V2.83333H9.66634V5.16667ZM21.333 19.1667H11.9997V16.8333H14.333V14.5H11.9997V12.1667H14.333V9.83333H11.9997V7.5H21.333V19.1667ZM18.9997 9.83333H16.6663V12.1667H18.9997V9.83333ZM18.9997 14.5H16.6663V16.8333H18.9997V14.5Z" />
    </svg>
  );
}
