// @Generated
// This is a generated file, DO NOT MODIFY
import colors from './colors';
import fontFamily from './fontFamily';
import fontSize from './fontSize';
import opacity from './opacity';
import screens from './screens';
import spacing from './spacing';
import zIndex from './zIndex';

export { default as colors } from './colors';
export { default as fontFamily } from './fontFamily';
export { default as fontSize } from './fontSize';
export { default as opacity } from './opacity';
export { default as screens } from './screens';
export { default as spacing } from './spacing';
export { default as zIndex } from './zIndex';

export default {
  colors,
  fontFamily,
  fontSize,
  opacity,
  screens,
  spacing,
  zIndex,
};
