import React from 'react';

import { UIDeveloperError, reportError } from '../../utils/errors';

// Styles
import styles from './index.css';

// Types
import { ComposedInputProps, StandaloneInputProps } from './_types';

import { SearchIcon } from '../Icons';
import InputContainer from './elements/Container';
import InputHelperText from './elements/HelperText';
import InputLabel from './elements/Label';
import InputPrefix from './elements/Prefix';
import InputSuffix from './elements/Suffix';
// Components
import InputWrapper from './elements/Wrapper';
// Utils
import useInputContext from './useInputContext';

export type InputProps = StandaloneInputProps | ComposedInputProps;

const InputV2 = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      containerClassName,
      label,
      prefix,
      suffix,
      helperText,
      error,
      disabled = false,
      type = 'text',
      ...restProps
    },
    ref
  ): JSX.Element => {
    const { isBeingUsedWithBuildingBlocks, disabled: contextDisabled } =
      useInputContext();

    const isStandalonePropsMisused =
      [label, prefix, suffix, helperText, error, containerClassName].filter(
        (prop) => prop !== undefined
      ).length > 0 && isBeingUsedWithBuildingBlocks;

    if (isStandalonePropsMisused) {
      reportError(
        new UIDeveloperError(
          `Composed Input doesn't work with label, prefix, suffix, helperText, containerClassName, or error as props. Please use it by composing with building blocks & use className to style.`
        )
      );
    }

    if (isBeingUsedWithBuildingBlocks) {
      return (
        <input
          data-testid="input"
          disabled={contextDisabled}
          type={type}
          placeholder=""
          {...restProps}
        />
      );
    }

    const inputSuffix =
      type === 'search' && !suffix ? (
        <SearchIcon className={styles.icon} />
      ) : (
        suffix
      );

    return (
      <InputContainer
        className={containerClassName}
        error={error}
        disabled={disabled}
      >
        {label && <InputLabel htmlFor={restProps.id}>{label}</InputLabel>}
        <InputWrapper>
          {prefix && <InputPrefix>{prefix}</InputPrefix>}
          <input
            data-testid="input"
            disabled={disabled}
            type={type}
            placeholder=""
            {...restProps}
            ref={ref}
          />
          {inputSuffix && <InputSuffix>{inputSuffix}</InputSuffix>}
        </InputWrapper>
        {helperText && <InputHelperText>{helperText}</InputHelperText>}
      </InputContainer>
    );
  }
);
InputV2.displayName = 'InputV2';

export default InputV2;
