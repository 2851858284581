import { QuestionnaireEntityType } from 'generated/graphql';

export const SESSION_TYPE_HEADER = 'X-ANCHORAGE-ENROLL-FORM-TYPE';

export const SESSION_TYPE = {
  institution: QuestionnaireEntityType.CLIENT,
  affiliate: QuestionnaireEntityType.AFFILIATE,
  individual: QuestionnaireEntityType.INDIVIDUAL,
  submission: QuestionnaireEntityType.SUBMISSION,
  // TODO we need a new type on BE
  form: QuestionnaireEntityType.SUBMISSION,
};

export const claimSessionURL = (
  sessionId: string,
  sessionAccess: string,
): string => `/v1/claim_session/${sessionId}/${sessionAccess}`;

// the url should be like this:
// "/<institution|affiliate|individual>?id=someId&access=someAccess"
// the first part is the sessionFormType
// and then we have the id & the access
export const getSessionInfo = (): {
  id: string;
  access: string;
  sessionFormType: string;
} => {
  let formType = '';
  let id = 'unknown';
  let access = 'unknown';

  if (typeof window !== 'undefined') {
    // TODO: remove this formType logic after the onboarding is fully migrated
    formType =
      window.location.pathname
        .split('/')
        .filter((p) => !!p && p !== 'onboarding')[0] ?? formType;

    const params = new URLSearchParams(window.location.search);

    id = params.get('id') ?? id;
    access = params.get('access') ?? access;
    formType = params.get('formType') ?? formType;
  }

  return { id, access, sessionFormType: formType };
};
