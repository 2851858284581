import React from 'react';

export default function FileTableIcon(props: SVGProps): JSX.Element {
  const { title = 'FileTable', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 26" {...restProps}>
      <title>{title}</title>
      <path d="M7.25 14.75L14.25 14.75V13L7.25 13V14.75Z" />
      <path d="M3.75 14.75H5.5V13H3.75L3.75 14.75Z" />
      <path d="M5.5 18.25H3.75L3.75 16.5H5.5V18.25Z" />
      <path d="M3.75 21.75H5.5V20H3.75L3.75 21.75Z" />
      <path d="M7.25 21.75H12.5V20H7.25V21.75Z" />
      <path d="M14.25 18.25H7.25V16.5L14.25 16.5V18.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 1.26256C7.57819 0.934375 8.02331 0.75 8.48744 0.75H15.125C16.5747 0.75 17.75 1.92525 17.75 3.375V22.625C17.75 24.0747 16.5747 25.25 15.125 25.25H2.875C1.42525 25.25 0.25 24.0747 0.25 22.625V8.98744C0.25 8.52331 0.434374 8.07819 0.762563 7.75L7.25 1.26256ZM9 2.5V6.875C9 8.32475 7.82475 9.5 6.375 9.5H2V22.625C2 23.1082 2.39175 23.5 2.875 23.5H15.125C15.6082 23.5 16 23.1082 16 22.625V3.375C16 2.89175 15.6082 2.5 15.125 2.5L9 2.5ZM3.23744 7.75H6.375C6.85825 7.75 7.25 7.35825 7.25 6.875V3.73744L3.23744 7.75Z"
      />
    </svg>
  );
}
