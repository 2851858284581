import React from 'react';
// Types
import type { ClearIndicatorProps, GroupBase } from 'react-select-v5';

// Styles
import styles from './index.css';

// Components
import { CancelIcon } from '../../../Icons';

const ClearIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  innerProps,
}: ClearIndicatorProps<Option, IsMulti, Group>): JSX.Element => (
  <div className={styles.clearIndicatorWrapper} {...innerProps}>
    <CancelIcon className={styles.clearIndicator} title="Clear" />
  </div>
);

export default ClearIndicator;
