import React from 'react';

export default function CoinIcon(props: SVGProps): JSX.Element {
  const { title = 'Coin', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9997 22.0218C12.9271 22.4904 11.7429 22.75 10.5 22.75C5.66751 22.75 1.75 18.8325 1.75 14C1.75 9.16751 5.66751 5.25 10.5 5.25C11.7429 5.25 12.9271 5.50964 13.9997 5.9782C15.0716 5.50983 16.2555 5.25 17.5 5.25C22.3325 5.25 26.25 9.16751 26.25 14C26.25 18.8325 22.3325 22.75 17.5 22.75C16.2555 22.75 15.0716 22.4902 13.9997 22.0218ZM3.5 14C3.5 10.134 6.63401 7 10.5 7C11.0269 7 11.5397 7.05807 12.0325 7.16809C10.0314 8.77163 8.75 11.2362 8.75 14C8.75 16.7638 10.0314 19.2284 12.0325 20.8319C11.5397 20.9419 11.0269 21 10.5 21C6.63401 21 3.5 17.866 3.5 14ZM10.5 14C10.5 10.134 13.634 7 17.5 7C21.366 7 24.5 10.134 24.5 14C24.5 17.866 21.366 21 17.5 21C13.634 21 10.5 17.866 10.5 14Z"
      />
    </svg>
  );
}
