import React from 'react';

export default function SupervisorIcon(props: SVGProps): JSX.Element {
  const { title = 'Supervisor', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M33 24C35.76 24 37.98 21.76 37.98 19C37.98 16.24 35.76 14 33 14C30.24 14 28 16.24 28 19C28 21.76 30.24 24 33 24ZM18 22C21.31 22 23.98 19.31 23.98 16C23.98 12.69 21.31 10 18 10C14.69 10 12 12.69 12 16C12 19.31 14.69 22 18 22ZM33 28C29.33 28 22 29.84 22 33.5V38H44V33.5C44 29.84 36.67 28 33 28ZM18 26C13.33 26 4 28.34 4 33V38H18V33.5C18 31.8 18.67 28.83 22.74 26.56C21 26.19 19.31 26 18 26Z" />
    </svg>
  );
}
