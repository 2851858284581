import React from 'react';

// Types
import { BannerProps } from './_types';

import BannerActions from './elements/Actions';
import BannerActionsWrapper from './elements/ActionsWrapper';
import BannerIcon from './elements/Icon';
import BannerSubtitle from './elements/Subtitle';
import BannerTextWrapper from './elements/TextWrapper';
import BannerTitle from './elements/Title';
// Components
import BannerWrapper from './elements/Wrapper';

const Banner: React.FC<BannerProps> = ({
  type = 'info',
  actions,
  title,
  children,
  closeable = false,
  onClose,
  Icon,
  ...restProps
}) => {
  const hasBannerActions = closeable || Boolean(actions?.length);

  return (
    <BannerWrapper type={type} {...restProps}>
      <BannerIcon Icon={Icon} />

      <BannerTextWrapper>
        <BannerTitle title={title} />
        {children && <BannerSubtitle>{children}</BannerSubtitle>}
      </BannerTextWrapper>

      {hasBannerActions && (
        <BannerActionsWrapper onClose={closeable ? onClose : undefined}>
          {actions?.length && <BannerActions actions={actions} />}
        </BannerActionsWrapper>
      )}
    </BannerWrapper>
  );
};

export default Banner;
