import React from 'react';

export default function DoNotDisturbIcon(props: SVGProps): JSX.Element {
  const { title = 'DoNotDisturbIcon', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...restProps}>
      <title>{title}</title>
      <path d="M6.999.333A6.665 6.665 0 00.332 7a6.665 6.665 0 006.667 6.667A6.665 6.665 0 0013.665 7 6.665 6.665 0 007 .333zm3.333 7.334H3.665V6.333h6.667v1.334z" />
    </svg>
  );
}
