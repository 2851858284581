// Hooks & Utils
import cn from 'classnames';
import React from 'react';

// Styles
import styles from '../../index.css';

import useBannerContext from '../../useBannerContext';

const BannerTextWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = '',
  ...restProps
}) => {
  const { type, hasActions } = useBannerContext();
  const classes = cn({
    [className]: !!className,
    [styles.textSection]: true,
    [styles[type]]: type,
    [styles.topAlignmentSpacing]: hasActions,
  });

  return (
    <div className={classes} data-testid="banner-text-wrapper" {...restProps}>
      {children}
    </div>
  );
};

export default BannerTextWrapper;
