import React from 'react';

export default function TradeIcon(props: SVGProps): JSX.Element {
  const { title = 'Trade', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M8.04853 2.54869L5.09722 5.5H16.4999V6.875H5.09712L8.04853 9.82642L7.07626 10.7987L3.4374 7.15982C2.90043 6.62285 2.90043 5.75225 3.4374 5.21528L7.07626 1.57642L8.04853 2.54869Z" />
      <path d="M16.9025 15.125H5.49986V16.5H16.9026L13.9512 19.4514L14.9235 20.4237L18.5623 16.7848C19.0993 16.2479 19.0993 15.3773 18.5623 14.8403L14.9235 11.2014L13.9512 12.1737L16.9025 15.125Z" />
    </svg>
  );
}
