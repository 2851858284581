import copyToClipboard from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';

const errorHandler = (error: Error) => console.error(error);

export const useCopyToClipboard = (
  resetInterval = 0,
): [(text: string) => void, boolean] => {
  const [isCopied, setCopied] = useState(false);

  const copy = useCallback((text: string | number) => {
    if (typeof text === 'string' || typeof text === 'number') {
      copyToClipboard(text.toString());
      if (resetInterval > 0) {
        setCopied(true);
      }
    } else {
      setCopied(false);
      errorHandler(
        new Error(
          `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval > 0) {
      timeout = setTimeout(() => setCopied(false), resetInterval);
    }
    return (): void => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [copy, isCopied];
};
