import { cva } from 'class-variance-authority';

export const spinnerVariants = cva(
  [
    'rounded-full',
    'border-2',
    'after:rounded-full',
    'after:border-2',
    'after:content-""',
    'after:block',
    'after:border-b-transparent',
    'after:border-r-transparent',
    'after:border-l-transparent',
    'after:-m-0.5',
    'after:-m-0.5',
    'animate-spin',
  ],
  {
    variants: {
      size: {
        small: [
          'size-sizing-comp-spinner-small',
          'after:size-sizing-comp-spinner-small',
        ],
        medium: [
          'size-sizing-comp-spinner-medium',
          'after:size-sizing-comp-spinner-medium',
        ],
        large: [
          'size-sizing-comp-spinner-large',
          'after:size-sizing-comp-spinner-large',
        ],
      },
      variant: {
        primary: [
          'border-color-comp-spinner-primary-container',
          'after:border-color-comp-spinner-primary-on-container',
        ],
        inverted: [
          'border-color-comp-spinner-inverted-container',
          'after:border-color-comp-spinner-inverted-on-container',
        ],
        strong: [
          'border-color-comp-spinner-strong-container',
          'after:border-color-comp-spinner-strong-on-container',
        ],
      },
    },
    defaultVariants: {
      size: 'small',
      variant: 'primary',
    },
  },
);
