import React from 'react';

export default function PlayArrowIcon(props: SVGProps): JSX.Element {
  const { title = 'Play Arrow', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M7.3335 4.58325V17.4166L17.4168 10.9999L7.3335 4.58325Z" />
    </svg>
  );
}
