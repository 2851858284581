// Utils
import cn from 'classnames';
import React, { ReactNode } from 'react';

// Styles
import * as css from './index.css';

type TableHeadProps = {
  children?: ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLTableSectionElement>;

const TableHead = React.forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ children, className = '', ...restProps }, ref) => {
    const classes = cn({
      [css.tableHead]: true,
      [className]: Boolean(className),
    });

    return (
      <thead className={classes} ref={ref} {...restProps}>
        {children}
      </thead>
    );
  },
);

TableHead.displayName = 'TableHead';

export default TableHead;
