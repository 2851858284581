// @Generated

// AUTO-GENERATED FROM tailwind.config.js and scripts/generate-style-vars.js
// DO NOT MODIFY

const fontFamily = {
  sans: 'Inter',
  mono: 'Akkurat Mono',
};

export default fontFamily;
