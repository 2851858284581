import classnames from 'classnames';
import React from 'react';

import styles from '../index.css';

type Props = {
  className?: string;
  counter?: number;
  title: string;
  // All other props
  [x: string]: any;
};

const DataContainerText = ({
  className,
  counter,
  title,
  ...restProps
}: Props): React.ReactElement => (
  <div
    data-testid="data-container-text"
    className={classnames(styles.textContainer, className)}
    {...restProps}
  >
    {title}
    {counter && (
      <span data-testid="data-container-counter">
        <span
          data-testid="data-container-counter-separator"
          className={styles.textSeparator}
        >
          |
        </span>
        {counter}
      </span>
    )}
  </div>
);

export default DataContainerText;
