// @Generated
// This is a generated file, DO NOT MODIFY
import useAnimateHeight from './useAnimateHeight';
import useCopyToClipboard from './useCopyToClipboard';
import useCursorBasedPagination from './useCursorBasedPagination';
import useDebounce from './useDebounce';
import useDeepCompare from './useDeepCompare';
import useSpacing from './useSpacing';

export { default as useAnimateHeight } from './useAnimateHeight';
export { default as useCopyToClipboard } from './useCopyToClipboard';
export { default as useCursorBasedPagination } from './useCursorBasedPagination';
export { default as useDebounce } from './useDebounce';
export { default as useDeepCompare } from './useDeepCompare';
export { default as useSpacing } from './useSpacing';

export default {
  useAnimateHeight,
  useCopyToClipboard,
  useCursorBasedPagination,
  useDebounce,
  useDeepCompare,
  useSpacing,
};
