import React from 'react';

import styles from '../index.css';

import Loader from '../../Loader';

const Loading = ({
  isLoading,
}: {
  isLoading?: boolean;
}): JSX.Element | null => {
  if (!isLoading) {
    return null;
  }

  return (
    <div data-testid="table-loading" className={styles.noData}>
      <Loader data-testid="table-loader" className={styles.loader} />
    </div>
  );
};

export default Loading;
