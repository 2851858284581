import React from 'react';

export default function DocumentArrowsIcon(props: SVGProps): JSX.Element {
  const { title = 'DocumentArrows', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4.8125C2.75 3.67341 3.67341 2.75 4.8125 2.75H17.1875C18.3266 2.75 19.25 3.67341 19.25 4.8125V9.625H17.875V4.8125C17.875 4.4328 17.5672 4.125 17.1875 4.125H4.8125C4.4328 4.125 4.125 4.4328 4.125 4.8125V17.1875C4.125 17.5672 4.4328 17.875 4.8125 17.875H9.625V19.25H4.8125C3.67341 19.25 2.75 18.3266 2.75 17.1875V4.8125Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2849 10.4826L9.35529 12.1978C8.97775 12.5334 8.49017 12.7188 7.98504 12.7188H5.5V11.3438H7.98504C8.15342 11.3438 8.31594 11.282 8.44179 11.1701L10.3714 9.45491L11.2849 10.4826Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5508 7.06852C11.2775 6.87721 10.9063 6.90971 10.6704 7.1456L8.45136 9.36466C8.06457 9.75145 7.53997 9.96875 6.99296 9.96875H5.5V8.59375H6.99296C7.17529 8.59375 7.35016 8.52132 7.47909 8.39239L9.69815 6.17333C10.4058 5.46565 11.5194 5.36815 12.3393 5.94207L14.4767 7.43822C14.5922 7.51911 14.7299 7.5625 14.8709 7.5625H15.8125V8.9375H14.8709C14.4478 8.9375 14.0348 8.80734 13.6882 8.56467L11.5508 7.06852Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.125 17.1875H20.2812V18.5625H15.125V17.1875Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3889 19.7951L19.309 17.875L17.3889 15.9549L18.3611 14.9826L20.2812 16.9027C20.8182 17.4397 20.8182 18.3103 20.2812 18.8473L18.3611 20.7674L17.3889 19.7951Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8125 15.8125L10.6562 15.8125V14.4375L15.8125 14.4375V15.8125Z"
        fill="#E3E8F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5486 13.2049L11.6285 15.125L13.5486 17.0451L12.5764 18.0174L10.6563 16.0973C10.1193 15.5603 10.1193 14.6897 10.6563 14.1527L12.5764 12.2326L13.5486 13.2049Z"
        fill="#E3E8F1"
      />
    </svg>
  );
}
