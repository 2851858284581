import React from 'react';

export default function WebIcon(props: SVGProps): JSX.Element {
  const { title = 'Web', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...restProps}>
      <title>{title}</title>
      <path d="M15 3H3c-.829 0-1.492.671-1.492 1.5l-.008 9A1.5 1.5 0 003 15h12a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0015 3zm-3.75 10.5H3v-3h8.25v3zm0-3.75H3v-3h8.25v3zM15 13.5h-3V6.75h3v6.75z" />
    </svg>
  );
}
