import { SPLIT_OPTIONS } from 'constants/split';
import * as React from 'react';

// Components
import { FeatureFlagProvider } from '@anchorage/feature-flags';

export const FeatureFlagsContext = React.createContext({});

function FeatureFlagsProvider({ children }: { children: JSX.Element }) {
  return (
    <FeatureFlagProvider
      baseAttributes={{}}
      defaults={SPLIT_OPTIONS.defaults}
      options={{
        ...SPLIT_OPTIONS.options,
        trafficType: 'user',
        trafficKey: 'onboarding',
        project: 'onboarding-questionnaires',
      }}
    >
      {children}
    </FeatureFlagProvider>
  );
}

export default FeatureFlagsProvider;
