import React from 'react';
// Types
import type { GroupBase, NoticeProps } from 'react-select-v5';

import styles from './index.css';

import Loader from '../../../Loader';
// Components
import Text from '../../../Text';

const LoadingMessage = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
}: Pick<NoticeProps<Option, IsMulti, Group>, 'children'>): JSX.Element => (
  <div className={styles.container}>
    <Text size="small">{children}</Text>
    <Loader className={styles.loader} />
  </div>
);

export default LoadingMessage;
