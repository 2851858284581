import { animated, useTransition } from '@react-spring/web';
import cn from 'classnames';
import React from 'react';

import css from './index.css';

import { useFormWizardContext } from '../../FormWizardProvider';

type Props = {
  className?: string;
};

const FormWizardContent: React.FC<Props> = ({ className = '' }) => {
  const { currentStepElement, currentStepIndex } = useFormWizardContext();
  const containerClassName = cn({
    [css.container!]: css.container,
    [className]: className,
  });

  const transitions = useTransition(currentStepIndex, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { display: 'none' },
  });

  return transitions((props) => (
    <animated.div style={props} className={css.animatedDiv}>
      <div className={containerClassName}>{currentStepElement.element}</div>
    </animated.div>
  ));
};

export default FormWizardContent;
