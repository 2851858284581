import React from 'react';

export default function StakingCoinsWithHandIcon(props: SVGProps): JSX.Element {
  const { title = 'StakingCoinsWithHand', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 252 214"
      {...restProps}
    >
      <title>{title}</title>

      <g clipPath="url(#clip0_6108_20541)">
        <path
          d="M92.465 115.807L125.463 147.963C125.463 147.963 131.188 127.591 118.727 112.27L102.395 96.168H92.5334L92.4609 115.803L92.465 115.807Z"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M113.733 66.7148H2.09375C2.09375 66.7148 9.23238 85.7458 33.3595 86.0882H68.7021"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M68.7035 86.4258L1.92188 125.507C1.92188 125.507 19.0836 139.099 45.2532 124.488L68.8485 110.754"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M174.716 32.215L154.823 51.9429C154.823 51.9429 137.955 67.4609 112.096 66.7841H75.992C72.0883 66.7841 68.9219 69.9506 68.9219 73.8543V163.031C68.9219 163.031 90.511 154.712 92.5333 131.999V96.0195H151.101C158.755 96.0195 162.836 105.047 157.776 110.792L134.14 137.618C134.14 137.618 122.671 151.787 129.866 175.173L223.293 81.2346L174.72 32.2109L174.716 32.215Z"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M182.489 1.37422L161.891 21.9727L229.762 89.8441L250.36 69.2456L182.489 1.37422Z"
          fill="#5681F6"
          stroke="#5681F6"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M68.6758 154.108C68.6758 154.108 79.7745 150.313 81.2328 136.277H69.0827"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M105 159.547C94.8032 155.333 87.7452 148.932 89.0464 145.137H89.0343L86.3392 151.659C84.7802 155.438 91.8986 161.96 102.236 166.23C112.573 170.501 122.222 170.899 123.785 167.125L126.544 160.441C124.985 164.22 115.337 163.817 104.996 159.547H105Z"
          fill="#5681F6"
          stroke="#5681F6"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M126.546 160.436C128.106 156.658 120.989 150.134 110.649 145.864C100.309 141.594 90.6624 141.195 89.1022 144.972C87.5421 148.75 94.6594 155.274 104.999 159.544C115.339 163.815 124.986 164.214 126.546 160.436Z"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M91.7459 197.309L91.6331 204.786C91.5687 209.112 101.116 212.766 112.964 212.952C124.812 213.133 134.469 209.773 134.537 205.446L134.654 197.792"
          fill="#5681F6"
        />
        <path
          d="M91.7459 197.309L91.6331 204.786C91.5687 209.112 101.116 212.766 112.964 212.952C124.812 213.133 134.469 209.773 134.537 205.446L134.654 197.792"
          stroke="#5681F6"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M134.652 197.793C134.719 193.466 125.169 189.811 113.323 189.629C101.477 189.447 91.8196 192.807 91.7532 197.134C91.6867 201.461 101.236 205.116 113.082 205.298C124.929 205.48 134.586 202.12 134.652 197.793Z"
          fill="#222325"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M106.124 189.181C94.4371 189.149 84.9499 185.709 84.7041 181.463H84.692L84.6719 188.94C84.6598 193.266 94.2559 196.803 106.104 196.836C117.952 196.868 127.568 193.387 127.58 189.061L127.6 181.406C127.588 185.733 117.972 189.218 106.124 189.181Z"
          fill="#222325"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
        <path
          d="M127.602 181.409C127.614 177.082 118.019 173.547 106.172 173.514C94.324 173.481 84.7097 176.962 84.6977 181.289C84.6856 185.617 94.2802 189.152 106.128 189.185C117.975 189.218 127.59 185.737 127.602 181.409Z"
          fill="#222325"
          stroke="white"
          strokeWidth="1.61143"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6108_20541">
          <rect
            width="251"
            height="213.526"
            fill="white"
            transform="translate(0.5 0.234375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
