import React from 'react';

export default function BankTransferIcon(props: SVGProps): JSX.Element {
  const { title = 'BankTransfer', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M31.05 30.5714V23.5H38.1V18.7857L47.5 27.0357L38.1 35.2857V30.5714H31.05ZM28.7 15.7214V18.7857H0.5V15.7214L14.6 7L28.7 15.7214ZM0.5 35.2857H28.7V40H0.5V35.2857ZM12.25 21.1429H16.95V32.9286H12.25V21.1429ZM2.85 21.1429H7.55V32.9286H2.85V21.1429ZM21.65 21.1429H26.35V32.9286H21.65V21.1429Z" />
    </svg>
  );
}
