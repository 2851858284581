import cn from 'classnames';
// Utils
import _uniqueId from 'lodash/uniqueId';
import React, { useMemo } from 'react';

// Styles
import css from './_components/index.css';
// Context
import { AccordionContext } from './_components/useAccordionContext';

// Types
import type { AccordionProps } from './_types';

/* Note: ricardo costa (2023-01-25)
 * This is a bare minimum version of the Accordion component to fit the use cases
 * of the Policies & Permissions project.
 * Please reach out if you want to add more use cases to the Accordion, such as
 * grouping, or shared state between multiple accordions.
 */
const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  (
    { children, expanded = false, itemID, className = '', ...restProps },
    ref
  ) => {
    const [_isExpanded, _setIsExpanded] = React.useState<boolean>(expanded);

    const _itemId = useMemo(() => itemID ?? _uniqueId(), []);

    const contextValue = useMemo(
      () => ({
        isExpanded: _isExpanded,
        itemId: _itemId,
        toggle: () => _setIsExpanded(!_isExpanded),
      }),
      [_isExpanded]
    );

    const classes = cn({
      [css.accordionRoot]: true,
      [className]: Boolean(className),
    });

    return (
      <AccordionContext.Provider value={contextValue}>
        <div className={classes} ref={ref} {...restProps}>
          {children}
        </div>
      </AccordionContext.Provider>
    );
  }
);

Accordion.displayName = 'Accordion';

export default Accordion;
export { default as AccordionHeader } from './_components/Header';
export { default as AccordionPanel } from './_components/Panel';
