// Utils
import cn from 'classnames';
import * as React from 'react';
// Components
import ReactDatePicker from 'react-datepicker';

import DatePickerHeader from './_components/DatePickerHeader';
import DatePickerInput from './_components/DatePickerInput';
import DatePickerRangePresetOptions from './_components/DatePickerRangePresetOptions';
import DateTimePickerWrapper from './_components/DateTimePickerWrapper';
import TimePicker from './_components/TimePicker';

// Styles
import css from './index.css';

// Types
import type { DatePickerHeaderProps, DateTimePickerProps } from './_types';

import { CalendarIcon } from '../Icons';
// Constants
import { TIME_PICKER_CONTAINER_ID } from './_constants';

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  wrapperProps = {},
  pickerProps,
  inputProps,
  headerProps,
  timePickerProps,
  presetProps,
}: DateTimePickerProps) => {
  const {
    calendarClassName = '',
    showTimeInput,
    ...pickerRestProps
  } = pickerProps;
  const { className: wrapperClassName = '', ...wrapperRestProps } =
    wrapperProps;

  const pickerClasses = cn({
    [css.calendar]: true,
    [calendarClassName]: !!calendarClassName,
  });

  const wrapperClasses = cn({
    [css.dateTimeCalendar]: showTimeInput,
    [wrapperClassName]: !!wrapperClassName,
  });

  return (
    <DateTimePickerWrapper className={wrapperClasses} {...wrapperRestProps}>
      <ReactDatePicker
        popperClassName={css.popperContainer}
        calendarClassName={pickerClasses}
        customInput={<DatePickerInput Icon={CalendarIcon} {...inputProps} />}
        // the custom time input is rendered through the header because we are portaling it into the calendar.
        // Passing it directly through the customTimeInput prop causes major lag and re-rendering issues.
        renderCustomHeader={(
          passedProps: DatePickerHeaderProps
        ): JSX.Element => (
          <>
            <DatePickerHeader {...passedProps} {...headerProps} />
            {showTimeInput ? <TimePicker {...timePickerProps} /> : null}
            {presetProps ? (
              <DatePickerRangePresetOptions {...presetProps} />
            ) : null}
          </>
        )}
        customTimeInput={<div id={TIME_PICKER_CONTAINER_ID} />}
        showTimeInput={showTimeInput}
        {...pickerRestProps}
      />
    </DateTimePickerWrapper>
  );
};

DateTimePicker.displayName = 'DateTimePicker';

export default DateTimePicker;
