import { animated, useSpring } from '@react-spring/web';
// Utils
import cn from 'classnames';
import React from 'react';
import useMeasure from 'react-use-measure';

// Styles
import css from './index.css';

// Constants
import { ACCORDION_TRANSITION_DURATION } from '../_constants';
// Hooks
import useAccordionContext from './useAccordionContext';

type Props = {
  children:
    | React.ReactNode
    | React.ReactNode[]
    | (({
        isExpanded,
      }: {
        isExpanded: boolean;
      }) => React.ReactNode | React.ReactNode[]);
  className?: string;
  // All other props
  [x: string]: any;
};

const Panel = (
  { children, className = '', ...restProps }: Props,
): React.ReactElement => {
  const { isExpanded, itemId } = useAccordionContext();
  const [ref, bounds] = useMeasure({
    scroll: true,
  });

  const panelStyle = useSpring({
    height: isExpanded ? bounds.height : 0,
    opacity: isExpanded ? 1 : 0,
    config: {
      duration: ACCORDION_TRANSITION_DURATION,
    },
  });

  const classes = cn({
    [css.panelWrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <animated.section
      id={`accordion-panel-${itemId}`}
      data-testid={`accordion-panel-${itemId}`}
      aria-labelledby={`accordion-header-${itemId}`}
      aria-hidden={!isExpanded}
      className={classes}
      style={panelStyle}
      {...restProps}
    >
      <animated.div ref={ref}>
        {typeof children === 'function' ? children({ isExpanded }) : children}
      </animated.div>
    </animated.section>
  );
};

export default Panel;
