import React from 'react';

export default function VaultIcon(props: SVGProps): JSX.Element {
  const { title = 'Vault', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1875 8C6.1875 6.4467 7.4467 5.1875 9 5.1875C10.3385 5.1875 11.4586 6.12251 11.7428 7.375H13.375V8.625H11.7428C11.4586 9.87749 10.3385 10.8125 9 10.8125C7.4467 10.8125 6.1875 9.5533 6.1875 8ZM9 6.4375C8.13706 6.4375 7.4375 7.13706 7.4375 8C7.4375 8.86295 8.13706 9.5625 9 9.5625C9.86295 9.5625 10.5625 8.86295 10.5625 8C10.5625 7.13706 9.86295 6.4375 9 6.4375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2.375C1.5 1.33947 2.33947 0.5 3.375 0.5H14.625C15.6605 0.5 16.5 1.33947 16.5 2.375V13.625C16.5 14.6605 15.6605 15.5 14.625 15.5H14V16.75H12.75V15.5H5.25V16.75H4V15.5H3.375C2.33947 15.5 1.5 14.6605 1.5 13.625V12.8328C1.12637 12.6166 0.875 12.2127 0.875 11.75V11.125C0.875 10.6623 1.12637 10.2584 1.5 10.0422V5.95777C1.12637 5.74164 0.875 5.33768 0.875 4.875V4.25C0.875 3.78732 1.12637 3.38336 1.5 3.16723V2.375ZM2.75 10.0422V5.95777C3.12363 5.74164 3.375 5.33768 3.375 4.875V4.25C3.375 3.78732 3.12363 3.38336 2.75 3.16723V2.375C2.75 2.02982 3.02982 1.75 3.375 1.75H14.625C14.9702 1.75 15.25 2.02982 15.25 2.375V13.625C15.25 13.9702 14.9702 14.25 14.625 14.25H3.375C3.02982 14.25 2.75 13.9702 2.75 13.625V12.8328C3.12363 12.6166 3.375 12.2127 3.375 11.75V11.125C3.375 10.6623 3.12363 10.2584 2.75 10.0422Z"
      />
    </svg>
  );
}
