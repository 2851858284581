// Joins the elements of the array with commas except the last element which
// is joined using "and"
export default function readableJoinStringArray(strs: Array<string>): string {
  if (!strs || !strs.length) {
    return '';
  }
  switch (strs.length) {
    case 1:
      return strs[0];
    case 2:
      return strs.join(' and ');
    default:
      return `${strs.slice(0, strs.length - 1).join(', ')} and ${
        strs[strs.length - 1]
      }`;
  }
}
