import React from 'react';

function LowQuantityIcon(props: SVGProps): JSX.Element {
  const { title = 'LowQuantityIcon', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>

      <path d="M5 8C2.79 8 1 9.79 1 12C1 14.21 2.79 16 5 16C7.21 16 9 14.21 9 12C9 9.79 7.21 8 5 8ZM17 6C13.685 6 11 8.685 11 12C11 15.315 13.685 18 17 18C20.315 18 23 15.315 23 12C23 8.685 20.315 6 17 6ZM17 16C14.79 16 13 14.21 13 12C13 9.79 14.79 8 17 8C19.21 8 21 9.79 21 12C21 14.21 19.21 16 17 16Z" />
    </svg>
  );
};

export default LowQuantityIcon;
