export type DisplayAmount = {
  displayValue: string;
  assetTypeInfo: {
    abbreviation: string;
  };
};

export default function formatAmount(amount: DisplayAmount): string {
  const {
    displayValue,
    assetTypeInfo: { abbreviation },
  } = amount;

  return `${displayValue} ${abbreviation}`;
}
