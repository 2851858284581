import { GraphQLError } from 'graphql';

// Create a unique message for each path/message combo.
// If this gets noisy we can look into removing the path and
// using fingerprints instead.
const formatMessage = ({ message, path }: GraphQLError): string =>
  `${message} <${path && path.join('/')}>`;

// ClientGraphQLError allows us to take an object that was originally
// a GraphQL error but was passed through Apollo middleware and is no
// longer an instanceof Error, and turn it into an instanceof Error
// with a custom `message` property for more useful error reports

// Flow allows classes to operate as both a value and a type
export default class ClientGraphQLError extends Error {
  cause: GraphQLError;

  constructor(graphQLError: GraphQLError) {
    // Create a unique message for each path/message combo
    super(formatMessage(graphQLError));

    // Set the name of this error for display purposes
    this.name = this.constructor.name;

    // Keep a reference to the original error that caused this
    this.cause = graphQLError;

    // captureStackTrace may not exist (IE)
    if (Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace')) {
      // Pass this.constructor to get rid of this constructor
      // in the stack trace
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(graphQLError.message).stack;
    }
  }
}
