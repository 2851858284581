import React from 'react';

export default function InvalidLinkIcon(props: SVGProps): JSX.Element {
    const { title = "Invalid Link", ...restProps } = props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 229 206"
        fill="none"
        {...restProps}
      >
        <title>{title}</title>
        <g clipPath="url(#clip0_968_1027)">
          <path
            d="M8.67 1.38501H220.12C224.21 1.38501 227.54 4.70501 227.54 8.80501V204.615H1.25V8.80501C1.25 4.70501 4.57 1.38501 8.67 1.38501Z"
            stroke="#5681F6"
            strokeWidth="2.5"
            strokeMiterlimit="10"
          />
          <path
            d="M181.53 21.275C183.662 21.275 185.39 19.5468 185.39 17.415C185.39 15.2832 183.662 13.555 181.53 13.555C179.398 13.555 177.67 15.2832 177.67 17.415C177.67 19.5468 179.398 21.275 181.53 21.275Z"
            fill="white"
          />
          <path
            d="M194.95 21.275C197.082 21.275 198.81 19.5468 198.81 17.415C198.81 15.2832 197.082 13.555 194.95 13.555C192.818 13.555 191.09 15.2832 191.09 17.415C191.09 19.5468 192.818 21.275 194.95 21.275Z"
            fill="white"
          />
          <path
            d="M208.37 21.275C210.502 21.275 212.23 19.5468 212.23 17.415C212.23 15.2832 210.502 13.555 208.37 13.555C206.238 13.555 204.51 15.2832 204.51 17.415C204.51 19.5468 206.238 21.275 208.37 21.275Z"
            fill="white"
          />
          <path
            d="M140.25 114.615L162.03 81.815C169.31 70.845 166.33 56.045 155.36 48.765L152.18 46.655C141.21 39.375 126.41 42.355 119.13 53.325L100.21 81.825C92.93 92.795 95.91 107.595 106.88 114.875L110.06 116.985"
            stroke="white"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.8801 95.025L66.7501 129.855C59.4701 140.825 62.4501 155.625 73.4201 162.905L76.6001 165.015C87.5701 172.295 102.37 169.315 109.65 158.345L128.57 129.845C135.85 118.875 132.87 104.075 121.9 96.795L118.72 94.685"
            stroke="white"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
          <path
            d="M51.6799 62.945L176.68 146.605"
            stroke="#5681F6"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_968_1027">
            <rect
              width="228.78"
              height="205.73"
              fill="white"
              transform="translate(0 0.13501)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }

