import React from 'react';

export default function CloseIcon(props: SVGProps): JSX.Element {
  const { title = 'Close', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M38 12.83L35.17 10L24 21.17L12.83 10L10 12.83L21.17 24L10 35.17L12.83 38L24 26.83L35.17 38L38 35.17L26.83 24L38 12.83Z" />
    </svg>
  );
}
