import cn from 'classnames';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import css from './index.css';

import { SidebarProps } from '../../types';

import { useFormWizardContext } from '../../FormWizardProvider';
import { SidebarItemStatus } from '../../constants';
import FormWizardSidebarStep from '../FormWizardSidebarStep';

const FormWizardSidebar: React.FC<SidebarProps> = (
  { isClickable = true, className = '', ...restProps },
) => {
  const { steps, currentStepIndex, goToStepIndex } = useFormWizardContext();
  const { trigger } = useFormContext();

  // Note: This function needs a for-loop due to the fact that we need an early exit upon validation failure. Calling trigger will cause the red error validation to show on the page and we don't want that for every form page.
  const onStepClick = async (goToIndex: number) => {
    // jumping forward, run validation on every step in between
    if (currentStepIndex < goToIndex) {
      const stepsToValidate = steps.slice(currentStepIndex, goToIndex);

      let areAllPagesValid = true;
      for (let i = 0; i < stepsToValidate.length; i += 1) {
        const step = stepsToValidate[i];
        // eslint-disable-next-line no-await-in-loop
        const isValid = await trigger(step.formFieldNames ?? []);
        if (!isValid) {
          areAllPagesValid = false;
          return;
        }
      }
      if (!areAllPagesValid) {
        return;
      }
    }

    goToStepIndex(goToIndex);
  };

  const getSidebarStepStatus = useCallback(
    (index: number) => {
      if (index < currentStepIndex) {
        return SidebarItemStatus.COMPLETED;
      }
      if (index === currentStepIndex) {
        return SidebarItemStatus.CURRENT;
      }
      return SidebarItemStatus.INCOMPLETE;
    },
    [currentStepIndex],
  );

  return (
    <div
      className={cn(css.sidebarRoot, className)}
      data-testid="form-wizard-sidebar"
      {...restProps}
    >
      {steps.map((step, index) => {
        if (!step.sidebarProps) {
          return null;
        }

        return (
          <FormWizardSidebarStep
            key={index}
            {...step.sidebarProps}
            status={getSidebarStepStatus(index)}
            onClick={isClickable ? () => onStepClick(index) : undefined}
          />
        );
      })}
    </div>
  );
};

export default FormWizardSidebar;
