import React from 'react';

export default function CompleteIcon(props: SVGProps): JSX.Element {
  const { title = 'Complete', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM6 12.6814L10.2857 17L18 9.22649L16.7914 8L10.2857 14.5557L7.20857 11.4635L6 12.6814Z" />
    </svg>
  );
}
