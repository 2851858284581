import * as React from 'react';

import { Drawer as CommonDrawer } from '@anchorage/common/dist/components';
import { OnCloseDrawerFn } from '@anchorage/common/dist/components/Drawer/types';

type Props = {
  children: React.ReactNode;
  onClose: OnCloseDrawerFn;
  visible: boolean;
  [x: string]: unknown;
};

function Drawer({ children, onClose, visible, ...restProps }: Props) {
  return (
    <CommonDrawer
      drawerStyles={{
        body: {
          top: '0',
          left: '0',
          padding: '36px',
        },
      }}
      closable={true}
      onClose={onClose}
      open={visible} // This is so the snackbar shows on top of the drawer
      {...restProps}
    >
      {children}
    </CommonDrawer>
  );
}

export default Drawer;
