import * as React from 'react';
import ReactDOM from 'react-dom';

// Hooks
import useDeepCompare from '../../../hooks/useDeepCompare';

// Types
import type { TimePickerProps } from '../_types';

// Constants
import {
  BEGINNING_OF_DAY,
  NUM_CHARACTERS_IN_TIMESTAMP,
  TimeOfDay,
} from '../_constants';
// Components
import TimePickerButtons from './TimePickerButtons';
import TimePickerInput from './TimePickerInput';
import TimePickerWrapper from './TimePickerWrapper';

type Timestamp = {
  // need a internally stored date so that "selected" is the source of truth
  // and outside changes to this value don't trigger unwanted effects.
  date: Date | null;
  timestamp: string;
};
// en-US converts 00:00:00 datetime object into 24:00:00 timestamp
// however, creating a new Date() where an hour > 23 throws an error
// which is why this format function is needed.
const formatTimestamp = (date: Date | null | undefined): Timestamp => {
  if (!date) {
    return { date: null, timestamp: BEGINNING_OF_DAY };
  }
  const timestamp = date.toLocaleTimeString('en-US', { hour12: false });
  const [hour, minute, seconds] = timestamp.split(':');
  const adjustedHour = hour === '24' ? '00' : hour;
  return { date, timestamp: `${adjustedHour}:${minute}:${seconds}` };
};

// react-datepicker automatically turns value (Date) into a string 00:00, doesn't support seconds
// and replaces any passed onChange method with their internal one
// so we use setSelected and selected instead as onChange and value respectively
const TimePicker = React.forwardRef<HTMLInputElement, TimePickerProps>(
  ({ selected, setSelected, inputProps, filterTime, ...restProps }, ref) => {
    const [timestampObject, setTimestampObject] = React.useState<Timestamp>(
      () => formatTimestamp(selected)
    );

    const node = document.getElementsByClassName(
      'react-datepicker__month-container'
    )[0];

    const timeOfDay: TimeOfDay = React.useMemo(() => {
      const { date, timestamp } = timestampObject;
      if (!date) {
        return TimeOfDay.AM;
      }

      const hours = parseInt(timestamp.split(':')[0], 10);
      return hours < 12 ? TimeOfDay.AM : TimeOfDay.PM;
    }, [timestampObject.timestamp]);

    const isTimestampInvalid: boolean = React.useMemo(
      () => timestampObject.timestamp.length !== NUM_CHARACTERS_IN_TIMESTAMP,
      [timestampObject.timestamp]
    );

    const newDate: Date | null = React.useMemo(() => {
      const { date, timestamp } = timestampObject;
      if (!date) {
        return null;
      }

      const tempNewDate = date ? new Date(date.getTime()) : new Date();
      if (isTimestampInvalid) {
        return tempNewDate;
      }

      const [hours, minutes, seconds] = timestamp
        .split(':')
        .map((t) => parseInt(t, 10));

      tempNewDate.setHours(hours, minutes, seconds);
      return tempNewDate;
    }, useDeepCompare([isTimestampInvalid, timestampObject]));

    const errorMessage = React.useMemo(() => {
      if (filterTime && newDate && !filterTime(newDate)) {
        return 'Invalid time';
      }
      return '';
    }, useDeepCompare([newDate, filterTime]));

    React.useEffect(() => {
      if (
        (!selected && timestampObject.timestamp === BEGINNING_OF_DAY)
        || timestampObject.date === selected
      ) {
        return;
      }

      setTimestampObject(formatTimestamp(selected));
    }, useDeepCompare([selected, timestampObject]));

    React.useEffect(() => {
      if (isTimestampInvalid || Boolean(errorMessage) || !newDate) {
        return;
      }

      setSelected(newDate);
      setTimestampObject(formatTimestamp(newDate));
    }, useDeepCompare([isTimestampInvalid, newDate, setSelected, errorMessage]));

    const handleButtonClick = React.useCallback((selectedValue: TimeOfDay) => {
      const { timestamp, date } = timestampObject;
      if (!date || timeOfDay === selectedValue) {
        return;
      }

      const [hours, minutes, seconds] = timestamp.split(':');
      const currentHour = parseInt(hours, 10);
      let adjustedHours: number;
      if (selectedValue === TimeOfDay.PM) {
        adjustedHours = currentHour + 12;
      } else {
        adjustedHours = currentHour - 12;
      }

      setTimestampObject({
        date,
        timestamp: `${adjustedHours.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}:${minutes}:${seconds}`,
      });
    }, useDeepCompare([timestampObject, setTimestampObject]));

    return node
      ? ReactDOM.createPortal(
          <>
            <TimePickerWrapper {...restProps}>
              <TimePickerInput
                {...inputProps}
                ref={ref}
                // to utilize the built in javascript time input,
                // we dont want to alter how the timestamp is stored
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTimestampObject({
                    timestamp: e.target.value,
                    date: timestampObject.date,
                  })
                }
                value={timestampObject.timestamp}
                errorMessage={errorMessage}
              />
              <TimePickerButtons
                selectedTimeOfDay={timeOfDay}
                onClick={handleButtonClick}
              />
            </TimePickerWrapper>
          </>,
          node
        )
      : null;
  }
);
TimePicker.displayName = 'TimePicker';

export default TimePicker;
