import React from 'react';

export default function MoneyIcon(props: SVGProps): JSX.Element {
  const { title = 'Money', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20" {...restProps}>
      <title>{title}</title>
      <path d="M10.125 2.125H0.5V0.375H10.125V2.125Z" />
      <path d="M0.5 5.625H10.125V3.875H0.5V5.625Z" />
      <path d="M11.875 9.125L21.5 9.125V7.375L11.875 7.375V9.125Z" />
      <path d="M10.125 19.625H0.5V17.875H10.125V19.625Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 19.625V17.875H14.725C14.3066 17.3683 13.9899 16.7746 13.8062 16.125H11.875V14.375H13.6446C13.701 13.7495 13.8771 13.1584 14.1497 12.625H11.875V10.875H15.6698C16.4526 10.3237 17.4073 10 18.4375 10C21.0954 10 23.25 12.1546 23.25 14.8125C23.25 16.3069 22.5688 17.6423 21.5 18.525V19.625H11.875ZM18.4375 17.875C20.1289 17.875 21.5 16.5039 21.5 14.8125C21.5 13.1211 20.1289 11.75 18.4375 11.75C16.7461 11.75 15.375 13.1211 15.375 14.8125C15.375 16.5039 16.7461 17.875 18.4375 17.875Z"
      />
      <path d="M10.125 16.125H0.5V14.375H10.125V16.125Z" />
      <path d="M0.5 12.625H10.125V10.875H0.5V12.625Z" />
      <path d="M10.125 9.125H0.5V7.375H10.125V9.125Z" />
    </svg>
  );
}
