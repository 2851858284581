import React from 'react';
// Components
import { Controller } from 'react-hook-form';

// Types
import type { StandaloneInputProps } from '../../../InputV2/_types';
import type { FieldValues, FormOptions } from '../../_types';

import InputV2 from '../../../InputV2';

type InputValues = HTMLInputElement['value'];

export type FormInputProps<T extends FieldValues = FieldValues> = Omit<
  StandaloneInputProps,
  'onBlur' | 'onChange' | 'value' | 'error'
> &
  FormOptions<T>;

const FormInput = <T extends FieldValues = FieldValues>({
  name,
  control,
  helperText,
  defaultValue,
  rules,
  ...inputProps
}: FormInputProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <InputV2
        {...inputProps}
        helperText={error?.message ?? helperText}
        // When the value is specified as undefined, React has no way of
        // knowing if you intended to render a component with an empty
        // value or if you intended for the component to be uncontrolled
        // So we need to set the value as an empty string for
        // it to "update"
        value={(value as InputValues) ?? ''}
        onBlur={onBlur}
        onChange={onChange}
        error={!!error}
      />
    )}
  />
);

export default FormInput;
