import React from 'react';

export default function FaceIDIcon(props: SVGProps): JSX.Element {
  const { title = 'FaceID', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M8 5V3H5C3.9 3 3 3.9 3 5V8H5V5H8Z" />
      <path d="M16 5H19V8H21V5C21 3.9 20.11 3 19 3H16V5Z" />
      <path d="M16 19V21H19C20.11 21 21 20.1 21 19V16H19V19H16Z" />
      <path d="M8 19V21H5C3.9 21 3 20.1 3 19V16H5V19H8Z" />
      <path d="M9 15H15C15 16.1046 14.1046 17 13 17H11C9.89543 17 9 16.1046 9 15Z" />
      <path d="M11 8V13C12.1046 13 13 12.1046 13 11V8H11Z" />
      <path d="M7.75 10.5C8.44036 10.5 9 9.94036 9 9.25C9 8.55964 8.44036 8 7.75 8C7.05964 8 6.5 8.55964 6.5 9.25C6.5 9.94036 7.05964 10.5 7.75 10.5Z" />
      <path d="M17.5 9.25C17.5 9.94036 16.9404 10.5 16.25 10.5C15.5596 10.5 15 9.94036 15 9.25C15 8.55964 15.5596 8 16.25 8C16.9404 8 17.5 8.55964 17.5 9.25Z" />
    </svg>
  );
}
