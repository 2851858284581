// Utils
import cn from 'classnames';
import * as React from 'react';

// Styles
import css from '../index.css';

// Types
import type { TimePickerButtonsProps } from '../_types';

// Components
import Button from '../../Button';
// Constants
import { TimeOfDay } from '../_constants';
import TimePickerButtonsWrapper from './TimePickerButtonsWrapper';

const TimePickerButtons: React.FC<TimePickerButtonsProps> = ({
  onClick,
  selectedTimeOfDay,
}) => {
  const amButtonClasses = cn({
    [css.hourButton]: true,
    [css.amButton]: true,
    [css.activeHourButton]: selectedTimeOfDay === TimeOfDay.AM,
  });

  const pmButtonClasses = cn({
    [css.hourButton]: true,
    [css.pmButton]: true,
    [css.activeHourButton]: selectedTimeOfDay === TimeOfDay.PM,
  });

  return (
    <TimePickerButtonsWrapper>
      <Button
        className={amButtonClasses}
        onClick={(): void => onClick(TimeOfDay.AM)}
      >
        AM
      </Button>
      <Button
        className={pmButtonClasses}
        onClick={(): void => onClick(TimeOfDay.PM)}
      >
        PM
      </Button>
    </TimePickerButtonsWrapper>
  );
};

TimePickerButtons.displayName = 'TimePickerButtons';

export default TimePickerButtons;
