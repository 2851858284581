import React from 'react';

export default function InfoOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'InfoOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path d="M10.083 15.5834H11.9163V10.0834H10.083V15.5834ZM10.9997 1.83337C5.93509 1.83337 1.83301 5.93546 1.83301 11C1.83301 16.0646 5.93509 20.1667 10.9997 20.1667C16.0643 20.1667 20.1663 16.0646 20.1663 11C20.1663 5.93546 16.0643 1.83337 10.9997 1.83337ZM10.9997 18.3334C6.95717 18.3334 3.66634 15.0425 3.66634 11C3.66634 6.95754 6.95717 3.66671 10.9997 3.66671C15.0422 3.66671 18.333 6.95754 18.333 11C18.333 15.0425 15.0422 18.3334 10.9997 18.3334ZM10.083 8.25004H11.9163V6.41671H10.083V8.25004Z" />
    </svg>
  );
}
