import React from 'react';

export default function MessageIcon(props: SVGProps): JSX.Element {
  const { title = 'Message', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...restProps}>
      <title>{title}</title>
      <path d="M15 1.5H3C2.17125 1.5 1.5075 2.17125 1.5075 3L1.5 16.5L4.5 13.5H15C15.8287 13.5 16.5 12.8287 16.5 12V3C16.5 2.17125 15.8287 1.5 15 1.5ZM13.5 10.5H4.5V9H13.5V10.5ZM13.5 8.25H4.5V6.75H13.5V8.25ZM13.5 6H4.5V4.5H13.5V6Z" />
    </svg>
  );
}
