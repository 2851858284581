import React from 'react';

export default function DocumentWarningIcon(props: SVGProps): JSX.Element {
  const { title = 'DocumentWarning', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4.8125C2.75 3.67341 3.67341 2.75 4.8125 2.75H17.1875C18.3266 2.75 19.25 3.67341 19.25 4.8125V9.625H17.875V4.8125C17.875 4.4328 17.5672 4.125 17.1875 4.125H4.8125C4.4328 4.125 4.125 4.4328 4.125 4.8125V17.1875C4.125 17.5672 4.4328 17.875 4.8125 17.875H9.625V19.25H4.8125C3.67341 19.25 2.75 18.3266 2.75 17.1875V4.8125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 8.25H9.625V9.625H5.5V8.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5.5H13.75V6.875H5.5V5.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.125 5.5H16.5V6.875H15.125V5.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8.25H12.375V9.625H11V8.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8125 19.25C17.711 19.25 19.25 17.711 19.25 15.8125C19.25 13.914 17.711 12.375 15.8125 12.375C13.914 12.375 12.375 13.914 12.375 15.8125C12.375 17.711 13.914 19.25 15.8125 19.25ZM20.625 15.8125C20.625 18.4704 18.4704 20.625 15.8125 20.625C13.1546 20.625 11 18.4704 11 15.8125C11 13.1546 13.1546 11 15.8125 11C18.4704 11 20.625 13.1546 20.625 15.8125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.125 16.3281L15.125 13.5781L16.5 13.5781L16.5 16.3281L15.125 16.3281Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.125 18.2188V17.0156H16.5V18.2188H15.125Z"
      />
    </svg>
  );
}
