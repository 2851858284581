import React from 'react';
// Components
import { NoticeProps, components } from 'react-select-v5';

import { ReactSelectProps } from '../../../../../../../SelectV2/types';

const NoOptionsMessage = <Option, IsMulti extends boolean = false>(
  {
    selectProps,
    ...restProps
  }: NoticeProps<Option, IsMulti> & {
    selectProps: ReactSelectProps<Option, IsMulti> & {
      asyncLoading: boolean;
    };
  },
): JSX.Element => (
  <>
    {!selectProps.asyncLoading && (
      <components.NoOptionsMessage selectProps={selectProps} {...restProps} />
    )}
  </>
);

export default NoOptionsMessage;
