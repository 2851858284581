import React, { CSSProperties } from 'react';

import type { Direction, SVGProps } from './_types';

type CompleteArrowForwardProps = SVGProps & { direction: Direction };

const DIRECTION_MAPPING: Record<Direction, CSSProperties> = {
  left: { transform: 'rotate(180deg)' },
  right: { transform: 'rotate(0deg)' },
  up: { transform: 'rotate(270deg)' },
  down: { transform: 'rotate(90deg)' },
};

export default function CompleteArrowForwardIcon(
  props: CompleteArrowForwardProps
): JSX.Element {
  const {
    title = 'CompleteArrowForward',
    direction = 'right',
    ...restProps
  } = props;

  return (
    <svg
      style={DIRECTION_MAPPING[direction]}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M7.99984 0.666504L6.70275 1.96359L11.8223 7.08317H0.666504V8.9165H11.8223L6.70275 14.0361L7.99984 15.3332L15.3332 7.99984L7.99984 0.666504Z" />
    </svg>
  );
}
