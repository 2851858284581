import React from 'react';

export default function PersonIcon(props: SVGProps): JSX.Element {
  const { title = 'Person', ...restProps } = props;

  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M24 24C28.42 24 32 20.41 32 16C32 11.58 28.42 8 24 8C19.58 8 16 11.58 16 16C16 20.41 19.58 24 24 24ZM24 28C18.67 28 8 30.67 8 36V40H40V36C40 30.67 29.33 28 24 28Z" />
    </svg>
  );
}
