import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './index.css';

import Text, { Props as TextProps } from '../../Text';

const Body: React.FC<PropsWithChildren<TextProps>> = (
  { className = '', children, ...restProps },
) => {
  const classes = cn({
    [styles.body]: true,
    [className]: Boolean(className),
  });

  return (
    <Text className={classes} {...restProps}>
      {children}
    </Text>
  );
};

export default Body;
