import React from 'react';

export default function DoubleCheckIcon(props: SVGProps): JSX.Element {
  const { title = 'DoubleCheckIcon', ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M18 7l-1.415-1.415-6.34 6.345 1.415 1.415L18 7zm4.245-1.415L11.655 16.17 7.485 12 6.07 13.415 11.655 19l12-12-1.41-1.415zm-21.83 7.83L6 19l1.415-1.415L1.83 12 .415 13.415z" />
    </svg>
  );
}
