import React from 'react';

export default function StarCircleIcon(props: SVGProps): JSX.Element {
  const { title = 'StarCircle', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path d="M7.997 1.333A6.662 6.662 0 001.333 8a6.662 6.662 0 006.664 6.667 6.668 6.668 0 100-13.334zM10.82 12L8 10.3 5.18 12l.747-3.207L3.44 6.637l3.28-.28L8 3.333l1.28 3.024 3.28.28-2.487 2.156L10.82 12z" />
    </svg>
  );
}
