import React from 'react';

import styles from './index.css';

type Props = {
  children: number;
  // All other props
  [x: string]: any;
};

const Counter = ({ children, ...restProps }: Props): React.ReactElement => (
  <span data-testid="card-counter" {...restProps}>
    <span data-testid="card-counter-separator" className={styles.textSeparator}>
      |
    </span>
    {children}
  </span>
);

export default Counter;
