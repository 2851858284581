import React from 'react';

export default function ConfigurationPanelIcon(props: SVGProps): JSX.Element {
  const { title = 'Configuration panel icon', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2576 4.125C11.9745 3.32393 11.2105 2.75 10.3125 2.75C9.17341 2.75 8.25 3.67341 8.25 4.8125C8.25 5.95159 9.17341 6.875 10.3125 6.875C11.2105 6.875 11.9745 6.30107 12.2576 5.5L19.25 5.5V4.125L12.2576 4.125ZM10.3125 4.125C10.6922 4.125 11 4.4328 11 4.8125C11 5.1922 10.6922 5.5 10.3125 5.5C9.9328 5.5 9.625 5.1922 9.625 4.8125C9.625 4.4328 9.9328 4.125 10.3125 4.125Z"
      />
      <path d="M2.75 5.5H6.875V4.125H2.75V5.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4375 8.9375C15.3355 8.9375 16.0995 9.51143 16.3826 10.3125L19.25 10.3125V11.6875L16.3826 11.6875C16.0995 12.4886 15.3355 13.0625 14.4375 13.0625C13.2984 13.0625 12.375 12.1391 12.375 11C12.375 9.86091 13.2984 8.9375 14.4375 8.9375ZM15.125 11C15.125 10.6203 14.8172 10.3125 14.4375 10.3125C14.0578 10.3125 13.75 10.6203 13.75 11C13.75 11.3797 14.0578 11.6875 14.4375 11.6875C14.8172 11.6875 15.125 11.3797 15.125 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8826 16.5C10.5995 15.6989 9.83553 15.125 8.9375 15.125C7.79841 15.125 6.875 16.0484 6.875 17.1875C6.875 18.3266 7.79841 19.25 8.9375 19.25C9.83553 19.25 10.5995 18.6761 10.8826 17.875L19.25 17.875V16.5L10.8826 16.5ZM9.625 17.1875C9.625 16.8078 9.3172 16.5 8.9375 16.5C8.5578 16.5 8.25 16.8078 8.25 17.1875C8.25 17.5672 8.5578 17.875 8.9375 17.875C9.3172 17.875 9.625 17.5672 9.625 17.1875Z"
      />
      <path d="M5.5 17.875H2.75V16.5H5.5V17.875Z" />
      <path d="M2.75 11.6875L11 11.6875V10.3125L2.75 10.3125V11.6875Z" />
    </svg>
  );
}
