import { isNil, noop } from 'lodash';
import * as React from 'react';
// Utils
import mergeRefs from 'react-merge-refs';

// Styles
import css from '../index.css';

// Types
import type { DatePickerInputProps } from '../_types';

import { CloseIcon } from '../../Icons';
// Components
import {
  Container,
  HelperText,
  Label,
  Suffix,
  Wrapper,
} from '../../InputV2/elements';

const valueEmptyOrNil = (val?: string | number | readonly string[]) =>
  isNil(val) || val.toString().length === 0;

const DatePickerInput = React.forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>(
  (
    {
      Icon,
      setSelected,
      label,
      value,
      hideInputValue = false,
      inputRef,
      helperText,
      disabled,
      onBlur = noop,
      onFocus = noop,
      error,
      ...restProps
    },
    ref
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const IconToShow = React.useMemo(
      () => (isActive || !valueEmptyOrNil(value) ? CloseIcon : Icon),
      [isActive, Icon, value]
    );

    const refToPass = React.useMemo(
      () => (inputRef ? mergeRefs([inputRef, ref]) : ref),
      [ref, inputRef]
    );

    const formattedValue = React.useMemo(
      () => (value ? value.toString().replace('-', '').trim() : ''),
      [value]
    );

    const handleIconClick = () => {
      if (isActive || !valueEmptyOrNil(value)) {
        setSelected(null);
        setIsActive(false);
      }
    };

    const handleOnFocus = () => {
      setIsActive(true);
      onFocus();
    };

    const handleOnBlur = () => {
      const timer = setTimeout(() => {
        setIsActive(false);
        clearTimeout(timer);
      }, 120);
      onBlur();
    };

    return (
      <Container disabled={disabled} error={error}>
        {label && <Label htmlFor={restProps.id}>{label}</Label>}
        <Wrapper>
          <input
            id="date-picker-input"
            {...restProps}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={hideInputValue ? '' : formattedValue}
            ref={refToPass}
            disabled={disabled}
          />
          {IconToShow ? (
            <Suffix>
              <IconToShow
                onClick={handleIconClick}
                className={css.suffixIcon}
              />
            </Suffix>
          ) : null}
        </Wrapper>
        {helperText && <HelperText>{helperText}</HelperText>}
      </Container>
    );
  }
);
DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
