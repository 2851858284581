import React from 'react';

export default function CircleRemoveIcon(props: SVGProps): JSX.Element {
  const { title = 'CircleRemove', ...restProps } = props;

  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M10.9999 10.0277L13.9168 7.11083L14.8891 8.08311L11.9722 11L14.889 13.9168L13.9167 14.8891L10.9999 11.9723L8.08316 14.889L7.11089 13.9167L10.0276 11L7.11083 8.08321L8.08311 7.11094L10.9999 10.0277Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 11C2.75 6.44365 6.44365 2.75 11 2.75C15.5563 2.75 19.25 6.44365 19.25 11C19.25 15.5563 15.5563 19.25 11 19.25C6.44365 19.25 2.75 15.5563 2.75 11ZM11 4.125C7.20304 4.125 4.125 7.20304 4.125 11C4.125 14.797 7.20304 17.875 11 17.875C14.797 17.875 17.875 14.797 17.875 11C17.875 7.20304 14.797 4.125 11 4.125Z"
      />
    </svg>
  );
}
