import React from 'react';

export default function PuzzleIcon(props: SVGProps): JSX.Element {
  const { title = 'Puzzle', ...restProps } = props;

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M14.375 8.25H13.25V5.25C13.25 4.42125 12.5788 3.75 11.75 3.75H8.75V2.625C8.75 1.59 7.91 0.75 6.875 0.75C5.84 0.75 5 1.59 5 2.625V3.75H2C1.17125 3.75 0.5075 4.42125 0.5075 5.25L0.50375 8.1H1.625C2.7425 8.1 3.65 9.0075 3.65 10.125C3.65 11.2425 2.7425 12.15 1.625 12.15H0.50375L0.5 15C0.5 15.8288 1.17125 16.5 2 16.5H4.85V15.375C4.85 14.2575 5.7575 13.35 6.875 13.35C7.9925 13.35 8.9 14.2575 8.9 15.375V16.5H11.75C12.5788 16.5 13.25 15.8288 13.25 15V12H14.375C15.41 12 16.25 11.16 16.25 10.125C16.25 9.09 15.41 8.25 14.375 8.25Z" />
    </svg>
  );
}
