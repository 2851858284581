import React from 'react';

export default function SyncIcon(props: SVGProps): JSX.Element {
  const { title = 'Sync', ...restProps } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path d="M12 4V1L8 5L12 9V6C15.315 6 18 8.685 18 12C18 13.015 17.745 13.965 17.305 14.805L18.765 16.265C19.54 15.025 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.685 18 6 15.315 6 12C6 10.985 6.255 10.035 6.695 9.195L5.235 7.735C4.46 8.975 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" />
    </svg>
  );
}
