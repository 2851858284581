import React from 'react';

export default function WalletAddIcon(props: SVGProps): JSX.Element {
  const { title = 'WalletAdd', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path d="M1 4.5A1.5 1.5 0 012.5 3H12a1 1 0 011 1H2.5a.5.5 0 000 1h11A1.5 1.5 0 0115 6.5V10h-1V6.5a.5.5 0 00-.5-.5H2v5.5a.5.5 0 00.5.5h6v1h-6A1.5 1.5 0 011 11.5v-7z" />
      <path d="M10.5 8a1 1 0 100 2 1 1 0 000-2zM12 12v-2h1v2h2v1h-2v2h-1v-2h-2v-1h2z" />
    </svg>
  );
}
