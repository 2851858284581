// Utils
import classnames from 'classnames';
import React from 'react';

// Styles
import styles from './index.css';

// Components
import Text from '../Text';

export type TabSizes = 'small' | 'medium';

type Props = {
  size?: TabSizes;
  children?: React.ReactNode;
  selected?: boolean;
  className?: string;
  [key: string]: any;
};

export default function Tab(
  {
    children,
    selected = false,
    className = '',
    size = 'medium',
    ...restProps
  }: Props,
): JSX.Element {
  const classes = classnames({
    [styles.tab]: true,
    [styles.selected]: selected,
    [styles[`tab-${size}`]]: true,
    [className]: Boolean(className),
  });

  return (
    <Text className={classes} size={size} {...restProps}>
      {children}
    </Text>
  );
}
