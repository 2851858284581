import { debounce } from 'lodash';
import React from 'react';

import { MenuListProps, components } from 'react-select-v5';

import { ReactSelectProps } from '../../../../../../../SelectV2/types';

import LoadingMessage from '../../../../../../../SelectV2/_elements/LoadingMessage';

import { useFormAsyncSelectContext } from '../../../FormAsyncProvider';

const shouldLoadMore = (
  scrollHeight: number,
  clientHeight: number,
  scrollTop: number
): boolean => {
  const bottomBorder = (scrollHeight - clientHeight) / 2;
  return bottomBorder < scrollTop;
};

const MenuList = <
  Option,
  IsMulti extends boolean = false,
>({
  children,
  ...restProps
}: MenuListProps<Option, IsMulti> & {
  selectProps: ReactSelectProps<Option, IsMulti> & {
    asyncLoading: boolean;
  };
}): JSX.Element => {
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState(0);
  const { loadMoreOptions, hasMore } = useFormAsyncSelectContext();

  const debouncedLoadMore = debounce(loadMoreOptions, 500);

  React.useEffect(() => {
    if (menuRef.current) {
      menuRef.current.onscroll = (): void => {
        if (menuRef.current) {
          const { scrollHeight, clientHeight, scrollTop } = menuRef.current;

          if (shouldLoadMore(scrollHeight, clientHeight, scrollTop)) {
            debouncedLoadMore({});
          }
        }
      };
    }
  }, [loadMoreOptions]);

  React.useEffect(() => {
    // get next page when we don't have scroll
    // but we have more to show
    if (
      menuRef.current
      && hasMore
      && !restProps.selectProps.asyncLoading
    ) {
      const { scrollHeight, scrollTop } = menuRef.current;
      const dontHaveScroll = scrollTop === 0 && scrollHeight === height;
      if (dontHaveScroll) {
        loadMoreOptions({});
      }
    }
  }, [height]);

  React.useEffect(() => {
    setHeight((prevHeight) => {
      if (
        !!menuRef.current?.clientHeight
        && prevHeight !== menuRef.current?.clientHeight
      ) {
        return menuRef.current?.clientHeight;
      }

      return prevHeight;
    });
  }, [menuRef.current?.clientHeight]);

  return (
    <components.MenuList
      {...restProps}
      innerRef={(ref): void => {
        menuRef.current = ref;
      }}
    >
      {children}
      {/* react-select dont show loading if it has options */}
      {/* in async mode we want to show the loading in the end of the options */}
      {restProps.selectProps.asyncLoading && (
        <LoadingMessage>
          {restProps.selectProps.loadingMessage({
            inputValue: restProps.selectProps.inputValue,
          })}
        </LoadingMessage>
      )}
    </components.MenuList>
  );
};

export default MenuList;
