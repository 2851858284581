import React from 'react';
// Components
import { useController } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';

// Styles
import css from './styles.css';

import type { FieldValues } from '../../_types';

import Checkbox, { useCheckbox } from '../../../Checkbox';
// Types
import type { CheckboxProps } from '../../../Checkbox';
import Text from '../../../Text';

export interface FormCheckboxProps<T extends FieldValues>
  extends Omit<
      CheckboxProps,
      'onBlur' | 'onChange' | 'children' | 'checked' | 'name' | 'value'
    >,
    UseControllerProps<T> {
  label?: React.ReactNode;
}

const FormCheckbox = <T extends FieldValues>({
  name,
  control,
  label = '',
  defaultValue,
  rules,
  ...checkboxRestProps
}: FormCheckboxProps<T>) => {
  const {
    field: { value, ...field },
    fieldState: { error, isTouched },
  } = useController({ control, defaultValue, name, rules });

  const [, { onChange, onBlur, ...checkboxProps }] =
    useCheckbox(checkboxRestProps);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    field.onChange(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    field.onBlur();
  };

  const hasError = !!error && isTouched;

  return (
    <>
      <Checkbox
        {...checkboxProps}
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        checked={!!value}
        error={hasError}
      >
        {label}
      </Checkbox>
      {hasError ? (
        <Text size="small" className={css.error}>
          {error.message}
        </Text>
      ) : null}
    </>
  );
};

export default FormCheckbox;
