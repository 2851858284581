import React from 'react';

import TableHead from './TableHead';
import TableRow from './TableRow';

const TableHeader: CommonComponent<Record<string, any>, 'tr'> = (
  { children, ...props },
) => (
  <TableHead>
    <TableRow {...props}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ...child.props, type: 'head' }),
      )}
    </TableRow>
  </TableHead>
);

export default TableHeader;
