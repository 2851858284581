import cn from 'classnames';
import React from 'react';

import styles from './index.css';

import { CheckboxLabelProps } from '../../types';

import Text from '../../../Text';

const CheckboxLabel = ({
  children,
  disabled,
  className = '',
  testId = '',
  ...restProps
}: CheckboxLabelProps): JSX.Element => (
  <Text<'label'>
    className={cn({
      [styles.label]: true,
      [styles.disabled]: disabled,
      [className]: className,
    })}
    size="medium"
    type="body"
    tag="label"
    data-testid={testId}
    {...restProps}
  >
    {children}
  </Text>
);

export default CheckboxLabel;
