import React from 'react';

export default function WidgetIcon(props: SVGProps): JSX.Element {
  const { title = 'Widget', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...restProps}>
      <title>{title}</title>
      <path d="M26 26V42H42V26H26ZM6 42H22V26H6V42ZM6 6V22H22V6H6ZM33.31 3.37L22 14.69L33.31 26L44.62 14.69L33.31 3.37Z" />
    </svg>
  );
}
