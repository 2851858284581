import React from 'react';

import styles from './index.css';

type Props = {
  children: React.ReactNode;
  // All other props
  [x: string]: any;
};

const Action = ({ children, ...restProps }: Props): React.ReactElement => (
  <div data-testid="card-action" className={styles.action} {...restProps}>
    {children}
  </div>
);

export default Action;
