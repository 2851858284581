import React from 'react';

export default function ExpandIcon(props: SVGProps): JSX.Element {
  const { title = 'Expand', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M16 1.333v5.334h-1.334v-4.39L9.805 7.137l-.943-.943 4.862-4.862h-4.39V0h5.332C15.403 0 16 .597 16 1.333zM0 14.667V9.333h1.333v4.39l4.862-4.861.943.943-4.862 4.862h4.39V16H1.334A1.333 1.333 0 010 14.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}
