/* eslint-disable @typescript-eslint/ban-ts-comment */
import cn from 'classnames';
import { debounce, isEqual } from 'lodash';
import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import Select from 'react-select-v5';



import { getSelectStyles } from '../../../../../SelectV2/utils';



import css from './styles.css';



import { OnChangeValue, SelectOption } from '../../../../../SelectV2/types';



import Label from '../../../../../InputV2/elements/Label';
import ClearIndicator from '../../../../../SelectV2/_elements/ClearIndicator';
import DropdownIndicator from '../../../../../SelectV2/_elements/DropdownIndicator';
import Footnotes from '../../../../../SelectV2/_elements/Footnotes';
import LoadingMessage from '../../../../../SelectV2/_elements/LoadingMessage';
import MultiValueLabel from '../../../../../SelectV2/_elements/MultiValueLabel';
import MultiValueRemove from '../../../../../SelectV2/_elements/MultiValueRemove';
import Option from '../../../../../SelectV2/_elements/Option';
import { FormSelectProps } from '../../../FormSelect';
import { useFormAsyncSelectContext } from '../FormAsyncProvider';
import MenuList from './_elements/MenuList';
import NoOptionsMessage from './_elements/NoOptionsMessage';


const FormAsyncSelectInner = <
  T extends FieldValues = FieldValues,
  IsMulti extends boolean = false,
>(
  {
    control,
      isSearchable = true,
    name,
    rules,
    isMulti,
    label,
    className = '',
    errorFooterText = '',
  helperFooterText = '',
    isError,
    components = {},
    ...selectProps
  }: FormSelectProps<T, SelectOption<string, string>, IsMulti>
) => {
  const selectStyles = getSelectStyles();
  const selectClasses = cn({
    [css.select]: true,
    [css.disabled]: selectProps.isDisabled,
    [css.error]: isError,
    [className]: Boolean(className),
  });

  const { options, setInputValue, loading, hasMore, initialOptions, resetSelectState } = useFormAsyncSelectContext();

  // For some reason the initial option disappears if we don't do this then search
  function findValueInOptions(value: string) {
    return options.find((option) => isEqual(option.value, value)) || initialOptions.find((option) => isEqual(option.value, value));
  }

  const debouncedInput = debounce((inputValue: string) => {
    setInputValue(inputValue);
  }, 500);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        function handleChange(newValue: OnChangeValue<SelectOption<string, string>, IsMulti>) {
          if (!newValue) {
            onChange('');
            return;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Type 'NonNullable<OnChangeValue<O, IsMulti>>' may represent a primitive value, which is not permitted as the right operand of the 'in' operator
          if ('value' in newValue) {
            onChange(newValue.value);
            return;
          }

          onChange(newValue.map((newV) => newV.value));
        }

        return (
          <div className={css.container}>
            {label && <Label>{label}</Label>}
            <Select<SelectOption<string, string>, IsMulti>
              {...selectProps}
              className={selectClasses}
              asyncLoading={loading}
              hasMore={hasMore}
              classNamePrefix="select"
              isMulti={isMulti}
              key={name}
              onBlur={onBlur}
              onChange={handleChange}
              options={options}
              menuPortalTarget={document.body}
              components={{
            IndicatorSeparator: undefined,
            LoadingIndicator: undefined,
            DropdownIndicator,
            ClearIndicator,
            MultiValueLabel,
            MultiValueRemove,
            LoadingMessage,
            // checkout react-select documentation fot this ignore
            // https://react-select.com/components#replacing-components
            // search for Custom Component with selectProps Example
            // sandbox: https://codesandbox.io/s/rdcg5x?module=/example.tsx
            // @ts-ignore
            MenuList,
            // @ts-ignore
            NoOptionsMessage,
            Option,
            ...components,
          }}
          // @ts-ignore
          styles={{
          ...selectStyles,
          ...selectProps.styles,
        }}
              onInputChange={(inputValue, meta) => {
                if (meta.action === 'input-change') {
                  debouncedInput(inputValue);
                }

                if(meta.action === 'menu-close') {
                  resetSelectState();
                }
              }}
              value={
                Array.isArray(value)
                  ? value.map((v: string) => findValueInOptions(v)) ?? null
                  : findValueInOptions(value) ?? null
              }
                      filterOption={isSearchable ? (): boolean => true : undefined}

            />
            <Footnotes
              helperFooterText={helperFooterText}
              errorFooterText={error?.message || errorFooterText}
            />
          </div>
        );
      }}
    />
  );
};

export default FormAsyncSelectInner;
