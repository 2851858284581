import * as React from 'react';

type Props = React.HTMLAttributes<SVGElement> & {
  selected?: boolean;
};

const RadioButtonIcon: React.FunctionComponent<Props> = (props) => {
  const { title = 'RadioButton', selected, ...restProps } = props;

  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      {selected ? (
        <path d="M10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5ZM10 0C4.475 0 0 4.475 0 10C0 15.525 4.475 20 10 20C15.525 20 20 15.525 20 10C20 4.475 15.525 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
      ) : (
        <path d="M10 0C4.475 0 0 4.475 0 10C0 15.525 4.475 20 10 20C15.525 20 20 15.525 20 10C20 4.475 15.525 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
      )}
    </svg>
  );
};

export default RadioButtonIcon;
