import React from 'react';

export default function LocationOutlineIcon(props: SVGProps): JSX.Element {
  const { title = 'LocationOutline', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
      <title>{title}</title>
      <path d="M12 7.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
      <path
        fillRule="evenodd"
        d="M19.5 9a7.5 7.5 0 00-15 0v.144c0 2.028.805 3.972 2.239 5.406l2.079 2.079A8.303 8.303 0 0111.25 22.5h1.5c0-2.202.875-4.314 2.432-5.871l2.079-2.08A7.644 7.644 0 0019.5 9.145V9zM6 9a6 6 0 1112 0v.144c0 1.63-.647 3.193-1.8 4.345l-2.079 2.079A9.8 9.8 0 0012 18.739a9.8 9.8 0 00-2.121-3.171l-2.08-2.079A6.144 6.144 0 016 9.144V9z"
        clipRule="evenodd"
      />
    </svg>
  );
}
