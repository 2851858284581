// Utils
import cn from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { useDeepCompare } from '../../../hooks';

// Styles
import css from '../index.css';

// Types
import type { DatePickerRangePresetOptionsProps } from '../_types';

// Components
import Text from '../../Text';

const DatePickerRangePresetOptions: React.FC<
  DatePickerRangePresetOptionsProps
> = ({
  className = '',
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  presetOptions,
  ...restProps
}) => {
  const node = document.getElementsByClassName('react-datepicker')[0];

  const classes = cn({
    [css.presetRangeContainer]: true,
    [className]: Boolean(className),
  });

  const presetLinks = React.useMemo(
    () =>
      presetOptions.map(({ title, getDates }) => {
        const [presetStartDate, presetEndDate] = getDates();
        const linkClasses = cn({
          [css.presetLink]: true,
          [css.activePresetLink]:
            presetStartDate.toDateString() === startDate?.toDateString()
            && presetEndDate.toDateString() === endDate?.toDateString(),
        });
        const onClick = () => {
          setStartDate(presetStartDate);
          setEndDate(presetEndDate);
        };
        return { title, linkClasses, onClick };
      }),
    useDeepCompare([presetOptions])
  );

  return node
    ? ReactDOM.createPortal(
        <div className={classes} {...restProps}>
          <Text size="tiny" className={css.presetRangeTitle}>
            PRESET RANGES
          </Text>
          {presetLinks.map(({ title, linkClasses, onClick }) => (
            <Text
              key={title}
              type="link"
              size="small"
              className={linkClasses}
              onClick={onClick}
            >
              {title}
            </Text>
          ))}
        </div>,
        node
      )
    : null;
};

DatePickerRangePresetOptions.displayName = 'DatePickerRangePresetOptions';

export default DatePickerRangePresetOptions;
