import React from 'react';

export default function TransactionRequestsIcon(props: SVGProps): JSX.Element {
  const { title = 'TransactionRequests', ...restProps } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 17.1876C10.9999 16.0485 11.9233 15.1251 13.0624 15.1251H17.8749C19.014 15.1251 19.9374 16.0485 19.9374 17.1876V19.2501H10.9999V17.1876ZM13.0624 16.5001C12.6827 16.5001 12.3749 16.8079 12.3749 17.1876V17.8751H18.5624V17.1876C18.5624 16.8079 18.2546 16.5001 17.8749 16.5001H13.0624Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4686 8.76568C14.3296 8.76568 13.4061 9.68909 13.4061 10.8282C13.4061 11.9673 14.3296 12.8907 15.4686 12.8907C16.6077 12.8907 17.5311 11.9673 17.5311 10.8282C17.5311 9.68909 16.6077 8.76568 15.4686 8.76568ZM12.0311 10.8282C12.0311 8.9297 13.5702 7.39068 15.4686 7.39068C17.3671 7.39068 18.9061 8.9297 18.9061 10.8282C18.9061 12.7267 17.3671 14.2657 15.4686 14.2657C13.5702 14.2657 12.0311 12.7267 12.0311 10.8282Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1249 5.76359L11.1718 5.76359V7.13859L4.1249 7.13859V5.76359Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59189 8.96704L10.1995 6.35943L7.59189 3.75181L8.56416 2.77954L11.1718 5.38715C11.7087 5.92413 11.7087 6.79473 11.1718 7.3317L8.56416 9.93931L7.59189 8.96704Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1405 14.0021H3.09365V12.6271H10.1405V14.0021Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.67353 10.7987L4.06592 13.4063L6.67353 16.0139L5.70126 16.9862L3.09365 14.3786C2.55668 13.8416 2.55668 12.971 3.09365 12.434L5.70126 9.82642L6.67353 10.7987Z"
      />
    </svg>
  );
}
