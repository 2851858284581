import React from 'react';

import styles from '../index.css';

export type PaginationProps = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  pageSize?: number;
};

type DefaultPaginationProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
};

type Props = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  isLoading?: boolean;
  nextPage: () => void;
  previousPage: () => void;
  pagination?: PaginationProps;
};

function getPaginationProps(props: Props): DefaultPaginationProps {
  const { canNextPage, canPreviousPage, nextPage, previousPage, pagination } =
    props;

  if (pagination) {
    return {
      canNextPage: pagination.hasNextPage,
      canPreviousPage: pagination.hasPreviousPage,
      nextPage: pagination.onNext || ((): void => undefined),
      previousPage: pagination.onPrevious || ((): void => undefined),
    };
  }

  return {
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
  };
}

const Pagination = ({
  isLoading,
  ...remainingProps
}: Props): JSX.Element | null => {
  const { canNextPage, canPreviousPage, nextPage, previousPage } =
    getPaginationProps(remainingProps);

  if (isLoading || (!canPreviousPage && !canNextPage)) {
    return null;
  }

  const previousButton = 'previous';
  const nextButton = 'next';

  return (
    <div className={styles.paginationWrapper} data-testid="table-pagination">
      <button
        className={styles.button}
        data-testid="table-pagination-previous-button"
        disabled={!canPreviousPage}
        onClick={(): void => previousPage()}
        type="button"
      >
        {previousButton}
      </button>
      <button
        className={styles.button}
        data-testid="table-pagination-next-button"
        disabled={!canNextPage}
        onClick={(): void => nextPage()}
        type="button"
      >
        {nextButton}
      </button>
    </div>
  );
};

export default Pagination;
